import { forwardRef, ForwardRefExoticComponent } from 'react';

import * as Select from '@radix-ui/react-select';
import './UnitSelect.css';

const Trigger: ForwardRefExoticComponent<Select.SelectTriggerProps & React.RefAttributes<HTMLButtonElement>> =
  forwardRef(({ className, ...props }, forwardedRef) => {
    return <Select.Trigger className={`unit-select-trigger ${className}`} {...props} ref={forwardedRef} />;
  });

const Item: ForwardRefExoticComponent<
  Select.SelectItemProps & React.RefAttributes<HTMLDivElement> & { value: string }
> = forwardRef(({ className, value, ...props }, forwardedRef) => {
  return <Select.Item className={`unit-select-item ${className}`} value={value} {...props} ref={forwardedRef} />;
});

const Content: ForwardRefExoticComponent<Select.SelectContentProps & React.RefAttributes<HTMLDivElement>> = forwardRef(
  ({ className, ...props }, forwardedRef) => {
    return <Select.Content className={`unit-select-content ${className}`} {...props} ref={forwardedRef} />;
  },
);

const Viewport: ForwardRefExoticComponent<Select.SelectViewportProps & React.RefAttributes<HTMLDivElement>> =
  forwardRef(({ className, ...props }, forwardedRef) => {
    return <Select.Viewport className={`unit-select-viewport ${className}`} {...props} ref={forwardedRef} />;
  });

export default {
  ...Select,
  Trigger,
  Item,
  Content,
  Viewport,
};
