import { ComponentProps, FC } from 'react';
import { Icon } from 'src/components/primitives/Icon';
import { VStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';

export const ContentEmpty: FC<{
  type?: 'general' | 'tag' | 'order' | 'dashboard_order' | 'message' | 'like';
  icon?: ComponentProps<typeof Icon>['name'];
}> = ({ type = 'general', icon = 'forest' }) => {
  const { i18n } = useI18n();
  return (
    <VStack asChild my="lg">
      <section>
        <Icon name={icon} width="96" height="96" fill="var(--color-black-400)" />
        <Text size="large" weight="bold" asChild>
          <h1>{i18n.t(`empty.${type}.title`)}</h1>
        </Text>
        <Text size="medium" weight="regular" align="center" asChild>
          <p>{i18n.t(`empty.${type}.guide`)}</p>
        </Text>
      </section>
    </VStack>
  );
};
