import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { RegisterForm } from 'src/components/features/account/RegisterForm';
import { SimpleLayout } from 'src/components/Layout';
import { Heading } from 'src/components/primitives/Heading';
import { useI18n } from 'src/lib/i18n';

export const RegisterFormPage: FC = () => {
  const { i18n } = useI18n();
  return (
    <SimpleLayout>
      <Helmet>
        <title>{i18n.t('title.signup_to_nagaku')}</title>
        <meta property="og:title" content={`${i18n.t('title.signup_to_nagaku')}`} />
        <meta name="description" content={i18n.t('description.short')} />
        <meta property="og:description" content={i18n.t('description.short')} />
        <link rel="canonical" href={`https://${import.meta.env.VITE_REPAIR_WEB_DOMAIN || 'nagaku.com'}/register`} />
      </Helmet>
      <Heading>{i18n.t('title.signup_to_nagaku')}</Heading>
      <RegisterForm />
    </SimpleLayout>
  );
};
