import './FormFieldSuggestion.css';

import * as Popover from '@radix-ui/react-popover';
import {
  KeyboardEventHandler,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import { AriaAttributesContext } from 'src/lib/a11y';

type FormFieldSuggestion<S> = PropsWithChildren & {
  onSuggest: (value: string) => Promise<Array<S>>;
  onSelect: (item: S) => void;
  value: string;
  item: (item: S) => ReactNode;
  fallback?: ReactNode;
};

type ShiftDirection = 'up' | 'down';

export const FormFieldSuggestion = <S extends { value: string }>({
  onSuggest,
  onSelect,
  item: itemRenderer,
  children,
  value,
  fallback,
}: FormFieldSuggestion<S>) => {
  // hooks
  const id = useId();
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [items, setItems] = useState<Array<S> | undefined>(undefined);
  const [activeValue, setActiveValue] = useState<string | undefined>();
  useEffect(() => {
    let isActive = true;
    (async () => {
      if (value == '') {
        return setItems(undefined);
      }
      const items = await onSuggest(value);
      if (isActive) {
        // Focus check
        const activeElement = document.activeElement;
        const hasFocusElement = anchorRef.current?.contains(activeElement);
        if (hasFocusElement) {
          setItems(items);
        } else {
          setItems(undefined);
        }
        setActiveValue(undefined);
      }
    })();
    return () => {
      isActive = false;
    };
  }, [value]);

  // handlers
  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      // アイテムが与えられていない場合、キーボードイベントを考慮する必要はないので、スキップする。
      if (typeof items == 'undefined' || items.length == 0) return;
      // move active item
      if (event.key == 'ArrowUp' || event.key == 'Tab' || event.key == 'ArrowDown') {
        event.preventDefault();
        const direction: ShiftDirection =
          event.key == 'ArrowUp' || (event.shiftKey && event.key == 'Tab') ? 'up' : 'down';
        setActiveValue((currentValue) => {
          const currentIndex = items.findIndex((item) => item.value == currentValue);
          if (currentIndex >= 0) {
            return items[(currentIndex + items.length + (direction == 'up' ? -1 : 1)) % items.length]?.value;
          } else {
            switch (direction) {
              case 'up': // activate last item
                return items[items.length - 1]?.value;
              case 'down': // activate first item
                return items[0]?.value;
            }
          }
        });
      }
      if (event.key == 'Escape') {
        setItems(undefined);
        setActiveValue(undefined);
      }
      if (event.key == 'Enter') {
        const currentItem = items.find((item) => item.value == activeValue);
        if (currentItem) {
          event.preventDefault();
          onSelect(currentItem);
          setItems(undefined);
        }
      }
    },
    [items, onSelect, activeValue],
  );
  const onClick = useCallback(
    (item: S) => {
      onSelect(item);
      setItems(undefined);
    },
    [onSelect],
  );

  return (
    <Popover.Root open={(items?.length || 0) > 0}>
      <Popover.Anchor ref={anchorRef} className="form-filed-suggestion-anchor" onKeyDown={onKeyDown}>
        <AriaAttributesContext.Provider
          value={{
            'aria-autocomplete': 'list',
            'aria-controls': id,
            'aria-expanded': (items?.length || 0) > 0,
            'aria-activedescendant': activeValue ? `${id}-${activeValue}` : undefined,
          }}
        >
          {children}
          {value && typeof items != 'undefined' && items.length == 0 && fallback}
        </AriaAttributesContext.Provider>
      </Popover.Anchor>
      <Popover.Portal>
        <Popover.Content asChild className="form-field-suggestion" onOpenAutoFocus={(event) => event.preventDefault()}>
          <ul role="listbox">
            {items?.map((item) => (
              <li
                key={item.value}
                id={`${id}-${item.value}`}
                className="item"
                role="option"
                aria-selected={item.value == activeValue}
                tabIndex={0}
                onClick={() => onClick(item)}
              >
                {itemRenderer(item)}
              </li>
            ))}
          </ul>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
