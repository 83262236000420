import { defineFactory, FactoryBlueprint } from 'src/catalog/factories/factoryBuilder';
import { fakeGid } from 'src/catalog/factories/gid';
import { Tag } from 'src/models/v1/tag';

const base: FactoryBlueprint<Tag> = {
  typeface: 'アンティーク',
  title: '',
  description: '',
  ogp_description: '',
  featured: false,
  hero: {
    gid: fakeGid(),
    url: 'https://picsum.photos/300?t=1',
  },
  statistics: {
    accounts: 5,
    works: 10,
  },
};

const shortTypeface: Partial<Tag> = {
  typeface: '金継ぎ',
};

const longTypeface: Partial<Tag> = {
  typeface: 'オーダーメイドジュエリー',
};

const heroBlank: Partial<Tag> = {
  hero: null,
};

export const factoryTag = defineFactory(base, { shortTypeface, longTypeface, heroBlank });
