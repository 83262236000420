import './ReplacePhotoOverlay.css';
import { ChangeEventHandler, DragEvent, FC, KeyboardEvent, useCallback, useRef, useState } from 'react';
import { Icon } from 'src/components/primitives/Icon';
import { useI18n } from 'src/lib/i18n';
import { useToast } from 'src/lib/toast';
import { getMimeTypeAlertMessage, isValidFileType } from 'src/lib/uploadFile';

const defaultId: string = 'replace-photo-overlay'; // NOTE: If use multiple ReplacePhotoOverlay in same page, replace it.

export const ReplacePhotoOverlay: FC<{
  onChange?: ChangeEventHandler<HTMLInputElement>;
  accept?: string | undefined;
  id?: string;
  disabled?: boolean;
}> = ({ onChange, accept, id = defaultId, disabled = false }) => {
  const toast = useToast();
  const { i18n } = useI18n();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDragOver, setIsDragOver] = useState(false);

  const onDragOver = useCallback((e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setIsDragOver(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setIsDragOver(false);
  }, []);

  const onDrop = useCallback(
    (e: DragEvent<HTMLLabelElement>) => {
      e.preventDefault();
      setIsDragOver(false);

      if (disabled || !inputRef.current) return;

      const file = e.dataTransfer.files[0];

      if (!accept || isValidFileType(file, accept)) {
        const dt = new DataTransfer();
        dt.items.add(file);
        inputRef.current.files = dt.files;
        inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
      } else {
        toast({ message: getMimeTypeAlertMessage(accept, i18n), variant: 'error' });
      }
    },
    [disabled, accept],
  );

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLLabelElement>) => {
      if (disabled) return;
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        inputRef.current?.click();
      }
    },
    [disabled],
  );

  return (
    <label
      htmlFor={id}
      className={`replace-photo-overlay ${isDragOver && !disabled ? 'drag-over' : ''}`}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onKeyDown={onKeyDown}
      tabIndex={disabled ? -1 : 0}
    >
      <input
        id={id}
        className="file"
        type="file"
        accept={accept}
        onChange={onChange}
        disabled={disabled}
        ref={inputRef}
      />
      <div className="mat">
        <Icon name="party_mode" className="icon" />
      </div>
    </label>
  );
};
