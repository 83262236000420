import { FC } from 'react';
import { LinkButton } from 'src/components/primitives/Button';
import { Icon } from 'src/components/primitives/Icon';
import { VStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';
import { Account } from 'src/models/v1/account';

export const AccountInquiryComplete: FC<{ account: Account }> = ({ account }) => {
  const { i18n } = useI18n();
  return (
    <VStack asChild>
      <section>
        <Icon name="check_circle" />
        <Text asChild>
          <p>{i18n.t('guide.inquiry.complete', { display_name: account.display_name })}</p>
        </Text>
        <LinkButton to={account.screen_name ? `/${account.screen_name}` : `/accounts/${account.gid}`} variant="primary">
          {i18n.t('action.back')}
        </LinkButton>
      </section>
    </VStack>
  );
};
