import { ComponentProps } from 'react';
import { factoryAccount } from 'src/catalog/factories/account';
import { Meta, Story } from 'src/catalog/Story';
import { ProfileForm } from 'src/components/features/account/ProfileForm';
import { CurrentStatusContext } from 'src/lib/currentStatus';
import { Permission } from 'src/models/v1/permission';

type Args = ComponentProps<typeof ProfileForm> & { permissions: Array<Permission> };

const meta: Meta<typeof ProfileForm, Args> = {
  title: 'Profile Form',
  args: {
    account: factoryAccount(),
    permissions: [],
  },
  template: ({ permissions, ...args }) => {
    return (
      <CurrentStatusContext.Provider value={{ me: null, permissions }}>
        <ProfileForm {...args} />
      </CurrentStatusContext.Provider>
    );
  },
};

export default meta;

export const updateProAccountPermittion: Story<typeof ProfileForm, Args> = {
  name: 'update Pro Account Permission',
  args: {
    account: factoryAccount('pro'),
    permissions: [{ name: 'pro_feature' }],
  },
};
