import './LikeSwitchBar.css';

import { FC } from 'react';
import { useI18n } from 'src/lib/i18n';

import { Text } from 'src/components/primitives/Text';
import { AuthRequiredButton } from 'src/components/features/AuthRequiredButton';
import { LikeSwitch } from 'src/components/features/LikeSwitch';
import { useLikeCache } from 'src/lib/storage';

export const LikeSwitchBar: FC<{
  gid: string;
  liked: boolean | null;
  likesCount: number | null;
  returnTo: string;
}> = ({ gid, liked, likesCount, returnTo }) => {
  const { i18n } = useI18n();
  const { likeState, onLike, onUnlike } = useLikeCache(gid, liked, likesCount);

  return (
    <section className="like-switch-bar">
      <AuthRequiredButton returnTo={returnTo}>
        <LikeSwitch gid={gid} liked={likeState.liked} onLike={onLike} onUnlike={onUnlike} />
      </AuthRequiredButton>
      <Text variant="label" weight="bold" size="large" w="full">
        {likeState.count ?? 0}
        <Text variant="label" weight="bold">
          {' '}
          {i18n.t('unit.like_count', likeState.count ?? 0)}
        </Text>
      </Text>
    </section>
  );
};
