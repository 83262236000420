import './MessageBox.css';

import { FC, useState } from 'react';
import { useParams } from 'react-router';
import { BackableBreadcrumb } from 'src/components/features/BackableBreadcrumb';
import { ContentEmpty } from 'src/components/features/ContentEmpty';
import { TicketSummary } from 'src/components/features/message/TicketSummary';
import { Button } from 'src/components/primitives/Button';
import { Spinner } from 'src/components/primitives/Spinner';
import { useApi } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { Message, MessageTicket } from 'src/models/v1/message';
import { Ticket } from 'src/models/v1/ticket';

const messageQueryStates = ['all', 'consultation', 'finalized', 'completed'] as const;
type MessageQueryState = (typeof messageQueryStates)[number];

export const MessageBox: FC = () => {
  const { i18n } = useI18n();
  const { ticket_gid: ticketGid } = useParams();
  const [state, setState] = useState<MessageQueryState>('all');

  return (
    <nav id="message-box">
      <BackableBreadcrumb currentPath="/messages">{i18n.t('title.messages')}</BackableBreadcrumb>
      <StateNavigation state={state} onUpdateState={setState} />
      <MessageList key={state} currentTicketGid={ticketGid!} state={state} />
    </nav>
  );
};

const StateNavigation: FC<{
  state: MessageQueryState;
  onUpdateState: (state: MessageQueryState) => void;
}> = ({ state: currentState, onUpdateState }) => {
  const { i18n } = useI18n();
  return (
    <div className="state-navigation">
      {messageQueryStates.map((state: MessageQueryState) => (
        <Button
          key={state}
          variant={currentState === state ? 'fill' : 'outline'}
          size="small"
          onClick={() => onUpdateState(state)}
        >
          {i18n.t(`attributes.message.status.${state}`)}
        </Button>
      ))}
    </div>
  );
};

const MessageList: FC<{ currentTicketGid: Ticket['gid']; state: MessageQueryState }> = ({
  currentTicketGid,
  state,
}) => {
  const { data, isLoading, isRefreshing } = useApi<{ messages: Array<Message & { ticket: MessageTicket }> }>(
    '/v1/messages/latest',
    buildMessageSearchParams(state),
    { cache: true },
  );
  const { messages } = data || {};
  return (
    <>
      {(isLoading || isRefreshing) && <Spinner size="small" my={isRefreshing ? 'sm' : '2xl'} mx="auto" />}
      {messages?.length != 0 ? (
        <>
          {messages?.map((message) => (
            <TicketSummary
              key={message.gid}
              ticket={message.ticket}
              message={message}
              isSelected={currentTicketGid == message.ticket.gid}
            />
          ))}
        </>
      ) : (
        <ContentEmpty icon="question_answer" type="message" />
      )}
    </>
  );
};

const buildMessageSearchParams: (state: MessageQueryState) => { [name: string]: string } = (
  state: MessageQueryState,
) => {
  switch (state) {
    case 'all':
      return {} as { [name: string]: string };
    case 'consultation':
    case 'finalized':
    case 'completed':
      return { q: `is:open is:${state}` };
  }
};
