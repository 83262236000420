import './EstimateView.css';

import { FC, useContext } from 'react';
import { AccountLinkCard } from 'src/components/features/account/AccountLinkCard';
import { EstimateBreakdown } from 'src/components/features/estimate/EstimateBreakdown';
import { EstimateCalcItem } from 'src/components/features/estimate/EstimateCalcItem';
import { HtmlContent } from 'src/components/primitives/HtmlContent';
import { VStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { Timestamp } from 'src/components/primitives/Timestamp';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';
import { checkTicketRole, TicketContext } from 'src/lib/ticket';
import { Estimate } from 'src/models/v1/estimate';

export const EstimateView: FC<{ estimate: Estimate }> = ({ estimate }) => {
  const { i18n } = useI18n();
  const { me } = useCurrentStatus();
  const ticket = useContext(TicketContext);

  return (
    <VStack align="stretch" className="quotation-view">
      {ticket?.accounts
        .filter((account) => account.gid != me?.gid)
        .map((account) => (
          <AccountLinkCard account={account} title={i18n.t(`attributes.ticket.participants.role.${account.role}`)} />
        ))}
      <EstimateBreakdown estimate={estimate} />
      {checkTicketRole('repairer') &&
        estimate.breakdown.application_fee_base &&
        estimate.breakdown.application_fee_amount &&
        estimate.breakdown.revenue_amount && (
          <div className="application-fee-field">
            <VStack align="start" spacing="sticky" w="full">
              <EstimateCalcItem
                label={i18n.t('attributes.estimate.application_fee_base')}
                amount={estimate.breakdown.application_fee_base}
              />
              <EstimateCalcItem
                label={i18n.t('attributes.estimate.application_fee')}
                amount={estimate.breakdown.application_fee_amount}
              />
              <EstimateCalcItem
                label={i18n.t('attributes.estimate.revenue')}
                amount={estimate.breakdown.revenue_amount}
                bold={true}
              />
              <Text className="hint-label" color="sub" align="center" w="full">
                {i18n.t('attributes.estimate.estimate_not_included')}
              </Text>
            </VStack>
          </div>
        )}
      <Text>
        <HtmlContent html={estimate.html.note} />
      </Text>
      <dl className="quotation-view-properties">
        <div className="quotation-view-property">
          <Text asChild weight="bold">
            <dt>{i18n.t('attributes.estimate.estimate_delivery_days')}</dt>
          </Text>
          <dd>
            <Text>
              {estimate.estimate_delivery_days ?? 0}{' '}
              {i18n.t(`unit.delivery_days.${estimate.delivery_days_unit}`, estimate.estimate_delivery_days ?? 0)}
            </Text>
          </dd>
        </div>
        <div className="quotation-view-property">
          <Text asChild weight="bold">
            <dt>{i18n.t('attributes.estimate.published_date')}</dt>
          </Text>
          <dd>
            {estimate.published_date && <Timestamp value={new Date(estimate.published_date)} format="year-date" />}
          </dd>
        </div>
        <div className="quotation-view-property">
          <Text asChild weight="bold">
            <dt>{i18n.t('attributes.estimate.expires_date')}</dt>
          </Text>
          <dd>
            <Timestamp value={new Date(estimate.expires_date)} format="year-date" />
          </dd>
        </div>
      </dl>
    </VStack>
  );
};
