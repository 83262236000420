import { FC } from 'react';
import { useLoaderData } from 'react-router';
import { useSearchParams } from 'react-router';
import { PasswordRecoveryForm } from 'src/components/features/setting/PasswordRecoveryForm';
import { NavigationLayout } from 'src/components/Layout';
import { PageLoader } from 'src/lib/api';
import { Account } from 'src/models/v1/account';

type Data = {
  account: Account;
};

export const PasswordRecoveryPage: FC = () => {
  const [searchParams] = useSearchParams();
  const { account } = useLoaderData() as Data;
  return (
    <NavigationLayout>
      <PasswordRecoveryForm account={account} token={searchParams.get('token')!} />
    </NavigationLayout>
  );
};

export const passwordRecoveryLoader: PageLoader = async ({ api, request }) => {
  const url = new URL(request.url);
  const token = url.searchParams.get('token');
  return await api.fetch(`/v1/accounts/password_recoveries/${token}`);
};
