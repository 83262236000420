import './SectionHeading.css';

import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { BoxProps, styleBoxProps } from 'src/components/primitives/PrimitiveComponent';

import { Primitive } from '@radix-ui/react-primitive';

type SectionHeadingElement = React.ElementRef<typeof Primitive.h2>;
type PrimitiveSectionHeadingButtonProps = ComponentPropsWithoutRef<typeof Primitive.h2> & BoxProps;
interface SectionHeadingProps extends PrimitiveSectionHeadingButtonProps {}

export const SectionHeading = forwardRef<SectionHeadingElement, SectionHeadingProps>(
  ({ children, ...originalProps }, ref) => {
    const { className, ...props } = styleBoxProps(originalProps);
    return (
      <Primitive.h2 className={`section-heading ${className}`} {...props} ref={ref}>
        {children}
      </Primitive.h2>
    );
  },
);
