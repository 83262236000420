import './Form.css';

import { createContext, PropsWithChildren, ReactNode, useCallback } from 'react';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
  UseFormProps,
  UseFormReset,
} from 'react-hook-form';
import { FormStatusPreview } from 'src/components/control/FormStatusPreview';

type FormComponentProps<TFieldValues extends FieldValues> = PropsWithChildren<{
  className?: HTMLFormElement['className'];
  variant?: 'basic' | 'sectional';
  onSubmit: SubmitHandler<TFieldValues>;
  onSuccess?: (args: { reset: UseFormReset<TFieldValues> }) => void | Promise<void>;
  isDisabled?: boolean;
  defaultRequired?: boolean;
  readOnly?: boolean;
}> &
  UseFormProps<
    TFieldValues,
    // // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  >;

export const FormOptionsContext = createContext({ defaultRequired: false, readOnly: false });

export const FormContext = <R extends FieldValues>({
  children,
}: {
  children: (context: ReturnType<typeof useFormContext<R>>) => ReactNode;
}) => {
  const formContext = useFormContext<R>();
  return <>{children(formContext)}</>;
};

export const Form = <T extends FieldValues>({
  children,
  variant = 'basic',
  onSubmit,
  onSuccess,
  className = '',
  isDisabled = false,
  defaultRequired = false,
  readOnly = false,
  ...formProps
}: FormComponentProps<T>) => {
  const formMethods = useForm<T>(formProps);
  const handleSubmit = useCallback(
    async (values: T) => {
      if (isDisabled) return;
      await onSubmit(values);
      if (onSuccess) {
        await onSuccess({ reset: formMethods.reset });
      }
    },
    [formMethods.reset, onSubmit, isDisabled, onSuccess],
  );

  return (
    <FormOptionsContext value={{ defaultRequired, readOnly }}>
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(handleSubmit)}
          className={`form form-${variant} ${formMethods.formState.isSubmitting ? 'form-submitting' : ''} ${className}`}
          aria-disabled={isDisabled}
        >
          {children}
        </form>
        {import.meta.env.DEV && <FormStatusPreview />}
      </FormProvider>
    </FormOptionsContext>
  );
};
