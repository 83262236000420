import { useCallback } from 'react';
import { useRevalidator } from 'react-router';
import { createAction } from 'src/lib/action';
import { api } from 'src/lib/api';
import { RegisterAccountBody, RegisterAccountResponse } from 'src/models/v1/account';
import { Order } from 'src/models/v1/order';

export const onCompleteOrder = createAction('onCompleteOrder', () => {
  const { revalidate } = useRevalidator();
  return useCallback(
    async (managementNumber: Order['management_number']) => {
      await api.dispatch<RegisterAccountBody, RegisterAccountResponse>(
        'POST',
        `/v1/orders/${managementNumber}/complete`,
      );
      revalidate();
    },
    [revalidate],
  );
});

export const onAcceptOrderCompletion = createAction(
  'onAcceptOrderCompletion',
  ({ managementNumber }: { managementNumber: Order['management_number'] }) => {
    const { revalidate } = useRevalidator();
    return useCallback(async () => {
      await api.dispatch<RegisterAccountBody, RegisterAccountResponse>(
        'POST',
        `/v1/orders/${managementNumber}/accept_completion`,
      );
      revalidate();
    }, [revalidate, managementNumber]);
  },
);
