import { ComponentProps, FC } from 'react';
import { Text } from 'src/components/primitives/Text';
import { isToday } from 'src/lib/date';

type TimestampFormat = 'year-date' | 'date-time' | 'date' | 'time' | 'year?-date?-time' | 'year-date-time';

export const Timestamp: FC<ComponentProps<typeof Text> & { value: Date; format?: TimestampFormat }> = ({
  value: timestamp,
  format = 'year-date-time',
  ...props
}) => {
  const formatTimestamp = (format: TimestampFormat) => {
    switch (format) {
      case 'year-date':
        return `${yearPart(timestamp)}/${datePart(timestamp)}`;
      case 'date-time':
        return `${datePart(timestamp)} ${timePart(timestamp)}`;
      case 'date':
        return `${datePart(timestamp)}`;
      case 'time':
        return `${timePart(timestamp)}`;
      case 'year?-date?-time':
        return `${isToday(timestamp) ? '' : `${yearPart(timestamp)}/${datePart(timestamp)} `}${timePart(timestamp)}`;
      case 'year-date-time':
        return `${yearPart(timestamp)}/${datePart(timestamp)} ${timePart(timestamp)}`;
    }
  };

  return <Text {...props}>{formatTimestamp(format)}</Text>;
};

const yearPart = (timestamp: Date): string => {
  return `${timestamp.getFullYear()}`;
};

const datePart = (timestamp: Date): string => {
  return `${(timestamp.getMonth() + 1).toString().padStart(2, '0')}/${timestamp.getDate().toString().padStart(2, '0')}`;
};

const timePart = (timestamp: Date): string => {
  return `${String(timestamp.getHours())}:${String(timestamp.getMinutes()).padStart(2, '0')}`;
};
