import { ComponentProps } from 'react';
import { factoryAccount } from 'src/catalog/factories/account';
import { Meta, Story } from 'src/catalog/Story';
import { StartOrderCompletionMessageView } from 'src/components/features/message/StartOrderCompletionMessageView';
import { CurrentStatusContext } from 'src/lib/currentStatus';
import { Account } from 'src/models/v1/account';

const meta: Meta<
  typeof StartOrderCompletionMessageView,
  ComponentProps<typeof StartOrderCompletionMessageView> & { me: Account }
> = {
  title: 'Start Order Completion Message View',
  args: {
    me: { ...factoryAccount(), gid: 'REPAIRER' },
    message: {
      type: 'order_completion',
      event: 'start',
      data: {
        order: {
          management_number: 'O12345',
          completion_status: 'pending_acceptance',
          caller_account: {
            ...factoryAccount(),
            gid: 'CALLER',
            display_name: 'Caller Account',
          },
          repairer_account: {
            ...factoryAccount(),
            gid: 'REPAIRER',
            display_name: 'Repairer Account',
          },
        },
        start_at: new Date().toISOString(),
      },
      created_at: new Date().toISOString(),
    },
  },
  template: ({ me, ...args }) => {
    return (
      <CurrentStatusContext.Provider
        value={{
          me,
          permissions: [],
        }}
      >
        <StartOrderCompletionMessageView {...args} />
      </CurrentStatusContext.Provider>
    );
  },
};

export default meta;

export const forCaller: Story<
  typeof StartOrderCompletionMessageView,
  Partial<ComponentProps<typeof StartOrderCompletionMessageView> & { me: Account }>
> = {
  name: 'for Caller',
  args: {
    me: { ...factoryAccount(), gid: 'CALLER' },
  },
};

export const completed: Story<
  typeof StartOrderCompletionMessageView,
  Partial<ComponentProps<typeof StartOrderCompletionMessageView> & { me: Account }>
> = {
  name: 'already completed',
  args: {
    me: { ...factoryAccount(), gid: 'CALLER' },
    message: {
      type: 'order_completion',
      event: 'start',
      data: {
        order: {
          management_number: 'O12345',
          completion_status: 'completed',
          caller_account: {
            ...factoryAccount(),
            gid: 'CALLER',
            display_name: 'Caller Account',
          },
          repairer_account: {
            ...factoryAccount(),
            gid: 'REPAIRER',
            display_name: 'Repairer Account',
          },
        },
        start_at: new Date().toISOString(),
      },
      created_at: new Date().toISOString(),
    },
  },
};
