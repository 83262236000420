import { FC, PropsWithChildren } from 'react';
import { onUpdateBusinessRepresentative } from 'src/actions/business';
import { DateOfBirthField } from 'src/components/control/DateOfBirthField';
import { Form } from 'src/components/control/Form';
import { InputField } from 'src/components/control/InputField';
import { BusinessIssuesView } from 'src/components/features/business/BusinessIssuesView';
import { Icon } from 'src/components/primitives/Icon';
import { Note } from 'src/components/primitives/Note';
import { HStack } from 'src/components/primitives/Stack';
import { useI18n } from 'src/lib/i18n';
import { Business, BusinessIndividual, BusinessRepresentative } from 'src/models/v1/business';

type BusinessRepresentativePropType = Pick<Business, 'business_type' | 'status'> &
  (
    | {
        business_type: 'individual';
        individual: Pick<BusinessIndividual, 'last_name' | 'last_name_kana' | 'first_name' | 'first_name_kana' | 'dob'>;
      }
    | {
        business_type: 'company';
        representative: Pick<
          BusinessRepresentative,
          'last_name' | 'last_name_kana' | 'first_name' | 'first_name_kana' | 'dob' | 'title'
        >;
      }
  );

export const BusinessRepresentativeForm: FC<
  PropsWithChildren<{ business: BusinessRepresentativePropType; onSuccess: () => void }>
> = ({ business, onSuccess, children }) => {
  const { i18n } = useI18n();
  const key = business.business_type == 'individual' ? 'individual' : 'representative';
  const person = business.business_type == 'individual' ? business['individual'] : business['representative'];
  return (
    <Form
      onSubmit={onUpdateBusinessRepresentative({})}
      onSuccess={onSuccess}
      defaultValues={{
        business: {
          [key]: {
            last_name: person.last_name,
            last_name_kana: person.last_name_kana,
            first_name: person.first_name,
            first_name_kana: person.first_name_kana,
            dob: person.dob,
            title: business.business_type == 'individual' ? '' : business.representative.title,
          },
        },
      }}
      defaultRequired
      readOnly={business.status != 'draft'}
      mode="onBlur"
    >
      <BusinessIssuesView kind="review_personal_information" />
      {business.business_type == 'company' && (
        <Note.Root>
          <Icon name="article" />
          <Note.Description>{i18n.t('guide.business.company_representative')}</Note.Description>
        </Note.Root>
      )}
      <HStack asChild align="flex-start">
        <fieldset>
          <InputField name={`business.${key}.last_name`} autoComplete="family-name" options={{ required: true }} />
          <InputField name={`business.${key}.first_name`} autoComplete="given-name" options={{ required: true }} />
        </fieldset>
      </HStack>
      <HStack asChild align="flex-start">
        <fieldset>
          <InputField
            name={`business.${key}.last_name_kana`}
            options={{
              required: true,
              pattern: {
                value: /^[\u3040-\u309F\u30A0-\u30FFー]+$/,
                message: i18n.t('validation.kana', { name: i18n.t(`attributes.business.${key}.last_name_kana`) }),
              },
            }}
          />
          <InputField
            name={`business.${key}.first_name_kana`}
            options={{
              required: true,
              pattern: {
                value: /^[\u3040-\u309F\u30A0-\u30FFー]+$/,
                message: i18n.t('validation.kana', { name: i18n.t(`attributes.business.${key}.first_name_kana`) }),
              },
            }}
          />
        </fieldset>
      </HStack>
      <DateOfBirthField name={`business.${key}.dob`} options={{ required: true }} />
      {business.business_type == 'company' && (
        <InputField name={`business.${key}.title`} options={{ required: true }} autoComplete="off" />
      )}
      {children}
    </Form>
  );
};
