import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { Form } from 'src/components/control/Form';
import { FormButton } from 'src/components/control/FormButton';
import { HiddenField } from 'src/components/control/HiddenField';
import { PasswordField } from 'src/components/control/PasswordField';
import { Icon } from 'src/components/primitives/Icon';
import { Text } from 'src/components/primitives/Text';
import { api } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { Account, UpdatePasswordBody } from 'src/models/v1/account';

export const PasswordRecoveryForm: FC<{ account: Pick<Account, 'display_name' | 'gid'>; token: string }> = ({
  account,
  token,
}) => {
  const { i18n } = useI18n();
  const navigate = useNavigate();
  const onRecoverPassword = useCallback(
    async (body: UpdatePasswordBody) => {
      await api.dispatch('POST', `/v1/accounts/${account.gid}/change_password`, {}, body);
      navigate('/login', { replace: true });
    },
    [navigate, account.gid, token],
  );
  return (
    <Form
      className="email-verification-form"
      defaultValues={{ account: { recovery_token: token } }}
      onSubmit={onRecoverPassword}
    >
      <Icon name="verified" className="icon" />
      <Text>{i18n.t('guide.hello', { name: account.display_name })}</Text>
      <Text>{i18n.t('guide.reset_password')}</Text>
      <PasswordField
        name={'account.password'}
        label={i18n.t('attributes.account.new_password')}
        options={{ required: true }}
      />
      <HiddenField name="account.recovery_token" />
      <FormButton>{i18n.t('action.reset_password')}</FormButton>
    </Form>
  );
};
