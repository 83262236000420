import { FC } from 'react';
import { Link } from 'react-router';
import { onOpenBusinessAccount } from 'src/actions/business_application';
import { Form } from 'src/components/control/Form';
import { Button } from 'src/components/primitives/Button';
import { Icon } from 'src/components/primitives/Icon';
import { Note } from 'src/components/primitives/Note';
import { usePermission } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';
import { AccountSetting } from 'src/models/v1/account';
import { Business } from 'src/models/v1/business';

export const OpenBusinessForm: FC<{
  business: Pick<Business, 'account_gid' | 'open_allowed'>;
  emailVerified: AccountSetting['email_verified'];
}> = ({ business, emailVerified }) => {
  const { i18n } = useI18n();
  const onOpen = onOpenBusinessAccount({ accountGid: business.account_gid });
  const { permit: ticketPermit } = usePermission('ticket');
  return (
    <>
      {emailVerified == false && (
        <Note.Root asChild variant="error">
          <Link to="/settings/account">
            <Icon name="mark_email_read" />
            <Note.Description>{i18n.t('guide.business.need_to_verify_email_to_open')}</Note.Description>
            <Icon name="chevron_right" />
          </Link>
        </Note.Root>
      )}
      {!ticketPermit && (
        <Note.Root variant="normal">
          <Icon name="info" />
          <Note.Description>{i18n.t('guide.business.open')}</Note.Description>
        </Note.Root>
      )}
      <Form onSubmit={onOpen}>
        <Button type="submit" variant="primary" disabled={!emailVerified || !ticketPermit || !business.open_allowed}>
          {i18n.t('action.open_pro_account')}
        </Button>
      </Form>
    </>
  );
};
