import { ComponentProps, forwardRef } from 'react';
import { RemoveScroll as OriginalRemoveScroll } from 'react-remove-scroll';

export const RemoveScroll = forwardRef<HTMLElement, ComponentProps<typeof OriginalRemoveScroll>>(
  ({ ...props }, parentRef) => {
    if (import.meta.env.SSR) {
      return <div className={props.className}>{props.children}</div>;
    }
    return <OriginalRemoveScroll {...props} ref={parentRef} />;
  },
);
