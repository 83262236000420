import { Helmet } from 'react-helmet-async';
import { redirect, useLoaderData } from 'react-router';
import { AccountLinkCard } from 'src/components/features/account/AccountLinkCard';
import { BackableBreadcrumb } from 'src/components/features/BackableBreadcrumb';
import { AccountInquiryForm } from 'src/components/features/contact/AccountInquiryForm';
import { NavigationLayout } from 'src/components/Layout';
import { PageLoader } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { Account, AccountContact } from 'src/models/v1/account';

type Data = {
  account: Account;
  contact: AccountContact;
};

export const InquiryPage = () => {
  const { i18n } = useI18n();
  const { account, contact } = useLoaderData<Data>();
  return (
    <NavigationLayout>
      <Helmet>
        <title>{`${i18n.t('title.inquiry')} - ${account.display_name}`}</title>
        <meta name="robots" content="noindex" />
        <link rel="canonical" href={`https://nagaku.com/${account.screen_name || `accounts/${account.gid}`}/inquiry`} />
      </Helmet>
      <BackableBreadcrumb
        homePath={account.screen_name ? `/${account.screen_name}` : `/accounts/${account.gid}`}
        currentPath={`/accounts/${account.gid}/inquiry`}
      >
        {i18n.t('title.inquiry')}
      </BackableBreadcrumb>
      <AccountLinkCard account={account} />
      <AccountInquiryForm account={account} contact={contact} />
    </NavigationLayout>
  );
};

export const contactLoader: PageLoader = async ({ params, api, request }) => {
  const { gid, screen_name } = params;
  const { account } = await api.fetch<{ account: Account }>(`/v1/accounts/${gid || screen_name}`, {
    include: 'business_profile',
  });
  if (gid == 'me') {
    const trustAccountPath = account.screen_name
      ? `/${account.screen_name}/inquiry`
      : `/accounts/${account.gid}/inquiry`;
    return redirect(request.url.replace(/\/accounts\/me/, trustAccountPath), { status: 302 });
  }
  if (account.screen_name && !screen_name) {
    return redirect(request.url.replace(`/accounts/${account.gid}/inquiry`, `/${account.screen_name}/inquiry`), {
      status: 301,
    });
  }
  const { contact } = await api.fetch<{ contact: AccountContact }>(`/v1/accounts/${screen_name || gid}/contact`);
  return { account, contact };
};
