import { defineFactory, FactoryBlueprint } from 'src/catalog/factories/factoryBuilder';
import { fakeGid } from 'src/catalog/factories/gid';
import { Account, AccountSetting } from 'src/models/v1/account';

// MARK: base

const base: FactoryBlueprint<AccountSetting> = {
  email: 'factory@nagaku.test',
  email_verified: false,
  email_verification: false,
};

// MARK: verified

const verified: Partial<AccountSetting> = {
  email_verified: true,
};

// MARK: factory

export const factoryAccountSetting = defineFactory(base, {
  verified,
});
