import './Error.css';

import { FC } from 'react';
import { ErrorDescription } from 'src/components/error/ErrorDescription';
import { ApiError } from 'src/lib/errors';

export const RuntimeErrorView: FC<{ error: ApiError }> = ({ error }) => {
  return (
    <div className="runtime-error-view">
      <ErrorDescription error={error} shorthand m="none" />
    </div>
  );
};
