import { factoryBusinessProfile } from 'src/catalog/factories/businessProfile';
import { Meta } from 'src/catalog/Story';
import { BusinessProfileForm } from 'src/components/features/business_profile/BusinessProfileForm';

const meta: Meta<typeof BusinessProfileForm> = {
  title: 'Business Profile Form',
  args: {
    businessProfile: factoryBusinessProfile(),
    businessStatus: 'verified',
  },
  template: (args) => <BusinessProfileForm {...args} />,
};

export default meta;
