import { FC } from 'react';
import { OrderMessageView } from 'src/components/features/message/OrderMessageView';
import { RepairRequestMessageView } from 'src/components/features/message/RepairRequestMessageView';
import { TicketAcceptanceMessageView } from 'src/components/features/message/TicketAcceptanceMessageView';
import { TicketCommentMessageView } from 'src/components/features/message/TicketCommentMessageView';
import { StartOrderCompletionMessageView } from 'src/components/features/message/StartOrderCompletionMessageView';
import { Code } from 'src/components/primitives/Code';
import { Message } from 'src/models/v1/message';
import { Ticket } from 'src/models/v1/ticket';
import { AcceptOrderCompletionMessageView } from 'src/components/features/message/AcceptOrderCompletionMessageView';

export const MessageView: FC<{ ticket: Pick<Ticket, 'procedure'>; message: Message }> = ({ ticket, message }) => {
  switch (message.type) {
    case 'ticket_comment':
      return <TicketCommentMessageView message={message} />;
    case 'ticket_acceptance':
      return <TicketAcceptanceMessageView message={message} />;
    case 'repair_request':
      return <RepairRequestMessageView message={message} ticket={ticket} />;
    case 'order':
      return <OrderMessageView message={message} />;
    case 'order_completion':
      switch (message.event) {
        case 'start':
          return <StartOrderCompletionMessageView message={message} />;
        case 'accept':
          return <AcceptOrderCompletionMessageView message={message} />;
      }
    default:
      if (import.meta.env.DEV) {
        return <UnknownMessageView message={message} />;
      }
  }
};

const UnknownMessageView: FC<{ message: Message }> = ({ message }) => {
  return <Code>{JSON.stringify(message, null, 2)}</Code>;
};
