import { FC } from 'react';
import { LinkButton } from 'src/components/primitives/Button';
import { Text } from 'src/components/primitives/Text';
import { VStack } from 'src/components/primitives/Stack';
import { useI18n } from 'src/lib/i18n';

export const NotFound: FC = () => {
  const { i18n } = useI18n();
  return (
    <VStack align="stretch" spacing="lg" m="lg">
      <Text asChild variant="display">
        <h1>404 Not Found</h1>
      </Text>
      <Text asChild variant="body">
        <p>{i18n.t('guide.page_not_found')}</p>
      </Text>
      <LinkButton to="/" variant="primary">
        {i18n.t('link.back_to_home')}
      </LinkButton>
    </VStack>
  );
};
