import { ChangeEvent, useCallback } from 'react';
import { useNavigate, useRevalidator } from 'react-router';
import { createAction } from 'src/lib/action';
import { PreventAction } from 'src/lib/errors';
import { api } from 'src/lib/api';
import { GetTicketResponse, OpenTicketBody, PostTicketCommentBody, Ticket } from 'src/models/v1/ticket';
import { blobFromFile, uploadMediaFile } from "src/lib/uploadFile";
import { UseFieldArrayAppend } from "react-hook-form";

export const onOpenTicket = createAction('onOpenTicket', () => {
  const navigate = useNavigate();
  return useCallback(
    async (body: OpenTicketBody) => {
      const { ticket } = await api.dispatch<OpenTicketBody, GetTicketResponse>('POST', `/v1/tickets`, undefined, body);
      navigate(`/messages/${ticket.gid}`);
    },
    [navigate],
  );
});

export const onUploadImage = createAction('onUploadImage', ({ append }: { append: UseFieldArrayAppend<PostTicketCommentBody, "ticket_comment.attachments"> } ) => {
  return useCallback(
    async ( event: ChangeEvent<HTMLInputElement> ) => {
      try {
        const files = event.target.files;

        if ( !files || files.length == 0 ) {
          throw new PreventAction();
        }

        for ( const file of files ) {
          const blob = await blobFromFile(file)
          const image = await uploadMediaFile( blob );
          append( { type: 'file', gid: image.gid, url: image.url } );
        }
      } finally {
        event.target.files = null;
        // 同じファイルが連続で選択された場合にonChangeが呼ばれなくなるため、最後に選択したファイル名を削除
        event.target.value = '';
      }
    },
    [append],
  )
});

export const onComment = createAction(
  'onComment',
  ({
    ticket,
    onCommentOverride,
  }: {
    ticket: Pick<Ticket, 'gid' | 'status' | 'accounts' | 'repair_request'>;
    onCommentOverride?: (body: PostTicketCommentBody) => Promise<void>;
  }) => {
    const { revalidate } = useRevalidator();
    return useCallback(
      async (body: PostTicketCommentBody) => {
        if (onCommentOverride) {
          await onCommentOverride?.(body);
          return;
        }
        await api.dispatch('POST', `/v1/tickets/${ticket.gid}/comments`, undefined, body);
        revalidate();
      },
      [ticket.gid, revalidate],
    );
  },
);
