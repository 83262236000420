import { createContext, useContext } from 'react';
import { Account } from 'src/models/v1/account';
import { Permission } from 'src/models/v1/permission';

export type CurrentStatus = {
  me: Account | null;
  permissions: Array<Permission>;
};

export const CurrentStatusContext = createContext<CurrentStatus | undefined>(undefined);
export const useCurrentStatus: () => CurrentStatus = () => {
  return useContext(CurrentStatusContext) || { me: null, permissions: [] };
};

export const usePermission = (permission: Permission['name']) => {
  const { permissions } = useCurrentStatus();
  return {
    permit: typeof permissions?.find(({ name }) => name == permission) != 'undefined',
    permissions,
  };
};
