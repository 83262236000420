import { useState } from 'react';
import { Meta, Story } from 'src/catalog/Story';
import { CurrentStatusContext } from 'src/lib/currentStatus';
import { Account } from 'src/models/v1/account';
import { TicketCommentControl } from 'src/components/features/ticket/TicketCommentControl';
import { factoryAccount } from 'src/catalog/factories/account';

const me: Account = {
  ...factoryAccount(),
  gid: 'ME',
  display_name: 'Authenticated Account',
};

const meta: Meta<typeof TicketCommentControl> = {
  title: 'Ticket Comment Control',
  args: {
    ticket: {
      gid: '0000',
      status: 'open',
      accounts: [],
      repair_request: {
        gid: '',
        title: 'title',
        body: 'body',
        account: factoryAccount(),
        photo_media_files: [],
        created_at: '',
      },
      permitted_attachments: ['image', 'estimate', 'quotation'],
    },
  },
  template: (args) => {
    const [comments, setComments] = useState<Array<{ body: string; timestamp: Date }>>([]);
    return (
      <CurrentStatusContext.Provider value={{ me, permissions: [] }}>
        <TicketCommentControl
          {...args}
          onComment={async (body) =>
            setComments((comments) => [{ body: body.ticket_comment.body, timestamp: new Date() }, ...comments])
          }
        />
        {comments.map(({ body, timestamp }) => (
          <p key={timestamp.toISOString()}>
            <time>{timestamp.toLocaleString()}</time> {body}
          </p>
        ))}
      </CurrentStatusContext.Provider>
    );
  },
};

export default meta;

export const forCaller: Story<typeof TicketCommentControl> = {
  name: 'for Caller',
  args: {
    ticket: {
      gid: '0000',
      status: 'open',
      accounts: [{ ...me, role: 'caller' }],
      repair_request: {
        gid: '',
        title: 'title',
        body: 'body',
        account: factoryAccount(),
        photo_media_files: [],
        created_at: '',
      },
      permitted_attachments: ['image', 'estimate', 'quotation'],
    },
  },
};

export const forRepairer: Story<typeof TicketCommentControl> = {
  name: 'for Repairer',
  args: {
    ticket: {
      gid: '0000',
      status: 'open',
      accounts: [{ ...me, role: 'repairer' }],
      repair_request: {
        gid: '',
        title: 'title',
        body: 'body',
        account: factoryAccount(),
        photo_media_files: [],
        created_at: '',
      },
      permitted_attachments: ['image', 'estimate', 'quotation'],
    },
  },
};
