import { OrderMessageView } from 'src/components/features/message/OrderMessageView';
import { Meta } from 'src/catalog/Story';
import { factoryAccount } from 'src/catalog/factories/account';

const meta: Meta<typeof OrderMessageView> = {
  title: 'Order Message View',
  args: {
    message: {
      type: 'order',
      created_at: '2024-12-31T12:00:00.000+0900',
      data: {
        management_number: '1234567890',
        completion_status: 'completed',
        repairer_account: factoryAccount(),
        caller_account: factoryAccount(),
      },
    },
  },
  template: (args) => <OrderMessageView {...args} />,
};

export default meta;
