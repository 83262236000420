import { factoryTag } from 'src/catalog/factories/tag';
import { Meta } from 'src/catalog/Story';
import { TagList } from 'src/components/features/tag/TagList';

const meta: Meta<typeof TagList> = {
  title: 'Tag List',
  args: {
    tags: [
      factoryTag(),
      factoryTag(),
      factoryTag(),
      factoryTag('shortTypeface'),
      factoryTag('shortTypeface'),
      factoryTag('shortTypeface'),
      factoryTag('longTypeface'),
      factoryTag('longTypeface'),
      factoryTag('longTypeface'),
    ],
  },
  template: (args) => <TagList {...args} />,
};

export default meta;
