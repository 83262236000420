import './FormLabel.css';

import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { BoxProps, styleBoxProps } from 'src/components/primitives/PrimitiveComponent';
import { HStack } from 'src/components/primitives/Stack';

import { Primitive } from '@radix-ui/react-primitive';

type LabelSize = 'default' | 'small';

type FormLabelElement = React.ElementRef<typeof Primitive.label>;
type PrimitiveLabelProps = ComponentPropsWithoutRef<typeof Primitive.label> & BoxProps;
interface FormLabelProps extends PrimitiveLabelProps {
  variant?: 'fill' | 'danger' | 'outline' | 'ghost' | 'primary';
  size?: LabelSize;
}

export const FormLabel = forwardRef<FormLabelElement, FormLabelProps>(
  ({ size = 'lg', children, ...originalProps }, forwardedRef) => {
    const { className, ...props } = styleBoxProps(originalProps);
    return (
      <HStack asChild align="center" justify="flex-start" spacing="sm" m="none">
        <label className={`form-label form-label-${size} ${className}`} {...props} ref={forwardedRef}>
          {children}
        </label>
      </HStack>
    );
  },
);
