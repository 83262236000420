import './ThumbnailCollection.css';

import { MediaImage } from 'src/components/primitives/MediaImage';
import { useDragScroll } from 'src/lib/dragScroll';
import { FC } from 'react';

const THUMBNAIL_SIZE = '512x512';

export const ThumbnailCollection: FC<{
  images: string[];
  onSelect?: (image: string) => void;
}> = ({ images, onSelect }) => {
  const dragScroll = useDragScroll();
  return (
    <div className="thumbnail-collection" ref={dragScroll.targetRef}>
      {images.map((image, index) => (
        <figure
          key={index}
          role="figure"
          tabIndex={0}
          className="item"
          onKeyDown={(event) => (event.key === 'Enter' || event.key == ' ') && onSelect && onSelect(image)}
          onClick={() => onSelect && onSelect(image)}
        >
          <MediaImage src={image} size={THUMBNAIL_SIZE} />
        </figure>
      ))}
    </div>
  );
};
