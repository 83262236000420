import { ComponentProps } from 'react';
import { TicketInspector } from 'src/components/features/ticket/TicketInspector';
import { Meta } from 'src/catalog/Story';
import { factoryAccount } from 'src/catalog/factories/account';

const meta: Meta<typeof TicketInspector, Omit<ComponentProps<typeof TicketInspector>, 'onComplete'>> = {
  title: 'Ticket Inspector',
  args: {
    ticket: {
      procedure: 'undefined',
      repair_request: {
        gid: 'xxxx',
        title: 'Title',
        body: 'This is a body of repair request',
        account: factoryAccount(),
        photo_media_files: [],
        created_at: '2024-04-01T12:00:00.000Z',
      },
      accounts: [],
      phase: 'consultation',
    },
    orders: [],
  },
  template: (args) => {
    return <TicketInspector {...args} onComplete={() => {}} />;
  },
};

export default meta;
