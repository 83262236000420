import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { SimpleLayout } from 'src/components/Layout';
import { Text } from 'src/components/primitives/Text';
import { VStack } from 'src/components/primitives/Stack';
import { useI18n } from 'src/lib/i18n';

export const JapaneseActOnSpecifiedTransactionPage: FC = () => {
  const { i18n } = useI18n();
  return (
    <SimpleLayout isWide>
      <Helmet>
        <title>{`${i18n.t('title.japanese_act_on_specified_transactions')} | ${i18n.t('nagaku')}`}</title>
        <meta
          property="og:title"
          content={`${i18n.t('title.japanese_act_on_specified_transactions')} | ${i18n.t('nagaku')}`}
        />
        <meta name="description" content="" />
        <meta property="og:description" content="" />
      </Helmet>
      <Text asChild variant="display" weight="bold">
        <h1>{i18n.t('title.japanese_act_on_specified_transactions')}</h1>
      </Text>
      {i18n.t('guide.japanese_act_on_specified_transactions_translation') && (
        <Text asChild color="hint" data-nosnippet>
          <aside>{i18n.t('guide.japanese_act_on_specified_transactions_translation')}</aside>
        </Text>
      )}
      <VStack asChild align="stretch" m="none" spacing="sm">
        <dl>
          <Text asChild variant="title" weight="bold">
            <dt>
              <dfn>事業者</dfn>
            </dt>
          </Text>
          <Text asChild>
            <dd>ナガク株式会社</dd>
          </Text>
          <Text asChild variant="title" weight="bold">
            <dt>
              <dfn>運営責任者</dfn>
            </dt>
          </Text>
          <Text asChild>
            <dd>渡部一紀</dd>
          </Text>
          <Text asChild variant="title" weight="bold">
            <dt>
              <dfn>所在地</dfn>
            </dt>
          </Text>
          <Text asChild>
            <dd>
              〒380-0845
              <br />
              長野県長野市西後町610-12 R-DEPOT 2F-D
            </dd>
          </Text>
          <Text asChild variant="title" weight="bold">
            <dt>
              <dfn>電話番号</dfn>
            </dt>
          </Text>
          <Text asChild>
            <dd>050-1809-0233</dd>
          </Text>
          <Text asChild variant="title" weight="bold">
            <dt>
              <dfn>メールアドレス</dfn>
            </dt>
          </Text>
          <Text asChild>
            <dd>
              <a href={`mailto:info@nagaku.com?subject=${i18n.t('inquiry_subject')}`}>info@nagaku.com</a>
            </dd>
          </Text>
          <Text asChild variant="title" weight="bold">
            <dt>
              <dfn>ウェブサイト</dfn>
            </dt>
          </Text>
          <Text asChild>
            <dd>
              <a href="https://corp.nagaku.com/">https://corp.nagaku.com/</a>
            </dd>
          </Text>
          <Text asChild variant="title" weight="bold">
            <dt>
              <dfn>購入可能な範囲の制限</dfn>
            </dt>
          </Text>
          <Text asChild>
            <dd>特にございませんが、日本の国内法に準じてご利用いただきます。</dd>
          </Text>
          <Text asChild variant="title" weight="bold">
            <dt>
              <dfn>注文方法</dfn>
            </dt>
          </Text>
          <Text asChild>
            <dd>インターネット</dd>
          </Text>
        </dl>
      </VStack>
      <VStack asChild align="stretch" m="none">
        <section>
          <Text asChild variant="headline" weight="bold">
            <h2>ナガクについて</h2>
          </Text>
          <VStack asChild align="stretch" m="none">
            <section>
              <Text asChild variant="title" size="large" weight="bold">
                <h3>サービス利用料</h3>
              </Text>
              <Text>
                <p>
                  サービス利用料として、受注毎に受注金額（消費税含む、送料実費を含まない）の20%の金額をいただきます。
                </p>
              </Text>
              <Text asChild variant="title" weight="bold">
                <h4>支払方法</h4>
              </Text>
              <Text asChild>
                <p>
                  サービス利用料、振込手数料を受注金額（消費税含む、送料を含まない）から差し引きご指定の銀行口座にお振り込みします。
                </p>
              </Text>
              <Text asChild variant="title" weight="bold">
                <h4>支払時期</h4>
              </Text>
              <Text asChild>
                <p>
                  リペア・リメイクが完了した日の当月末日をもって当月内の受注金額を集計し、サービス利用料、振込手数料を差し引いた金額を翌月末日にお振り込みします。
                </p>
              </Text>
            </section>
          </VStack>
          <VStack asChild align="stretch" m="none">
            <section>
              <Text asChild variant="title" size="large" weight="bold">
                <h3>振込手数料等</h3>
              </Text>
              <Text asChild>
                <p>銀行振込にて売上を振り込む際、一律300円（税込）の振込手数料をいただきます。</p>
              </Text>
            </section>
          </VStack>
          <VStack asChild align="stretch" m="none">
            <section>
              <Text asChild variant="title" size="large" weight="bold">
                <h3>利用可能な決済手段</h3>
              </Text>
              <Text asChild>
                <p>
                  <dfn>利用可能なクレジットカード</dfn>: Visa、Mastercard、JCB、American Express
                </p>
              </Text>
            </section>
          </VStack>
          <VStack asChild align="stretch" m="none">
            <section>
              <Text asChild variant="title" size="large" weight="bold">
                <h3>役務の提供時期</h3>
              </Text>
              <Text asChild>
                <p>サービスに登録後、すぐにご利用いただけます。</p>
              </Text>
            </section>
          </VStack>
          <VStack asChild align="stretch" m="none">
            <section>
              <Text asChild variant="title" size="large" weight="bold">
                <h3>返金・キャンセル</h3>
              </Text>
              <Text asChild>
                <p>返金、キャンセルは受け付けておりません。</p>
              </Text>
            </section>
          </VStack>
        </section>
      </VStack>
    </SimpleLayout>
  );
};
