import './ProfileForm.css';

import { FC, PropsWithChildren, useCallback, useState, useTransition } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { onUpdateProfile, onUploadBanner, onUploadIcon } from 'src/actions/account';
import { ConfirmationButton } from 'src/components/features/ConfirmationButton';
import { Icon } from 'src/components/primitives/Icon';
import { MediaImage } from 'src/components/primitives/MediaImage';
import { Note } from 'src/components/primitives/Note';
import { Text } from 'src/components/primitives/Text';
import { usePermission } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';
import { ACCEPT_IMAGE } from 'src/lib/uploadFile';
import { validateMaxLengthOption, validateMinLengthOption } from 'src/lib/validation';
import { Account, buildAccountDisplayNameOptions, UpdateAccountBody } from 'src/models/v1/account';
import { BusinessProfile } from 'src/models/v1/business';

import { Form } from '../../control/Form';
import { FormButton } from '../../control/FormButton';
import { InputField } from '../../control/InputField';
import { TextField } from '../../control/TextField';
import { Avatar } from '../../primitives/Avatar';
import { ReplacePhotoOverlay } from '../../primitives/ReplacePhotoOverlay';
import { Toolbox } from '../../singleton/Toolbox';
import { MediaFile } from 'src/models/v1/media_file';
import { Spinner } from '../../primitives/Spinner';

export const ProfileForm: FC<{
  account: Pick<Account, 'gid' | 'display_name' | 'screen_name' | 'bio' | 'banner_url' | 'icon_url' | 'kind'> & {
    business_profile?: Pick<BusinessProfile, 'tagline'> | null;
  };
}> = ({ account }) => {
  const { i18n } = useI18n();
  const { permit: updateProAccountPermit } = usePermission('pro_feature');
  return (
    <Form
      onSubmit={onUpdateProfile({})}
      defaultValues={{
        account: {
          display_name: account.display_name,
          screen_name: account.screen_name || '',
          tagline: account.business_profile?.tagline || '',
          bio: account.bio,
        },
      }}
      mode="all"
    >
      {updateProAccountPermit ? (
        <>
          <BannerField account={account} />
          <AvatarField account={account} />
        </>
      ) : (
        <AvatarField account={account} size="xl" />
      )}
      <InputField
        name="account.display_name"
        autoComplete="name"
        options={{
          required: true,
          ...buildAccountDisplayNameOptions(),
        }}
      />
      <InputField
        name="account.screen_name"
        autoComplete="name"
        options={{
          maxLength: validateMaxLengthOption({ name: 'account.screen_name', maxLength: 32, i18n }),
          minLength: validateMinLengthOption({ name: 'account.screen_name', minLength: 3, i18n }),
          pattern: /^[a-z0-9\-_+\.]+$/,
        }}
      />
      <ProfileURLPreview />
      {updateProAccountPermit && (
        <InputField
          name="account.tagline"
          options={{
            maxLength: validateMaxLengthOption({ name: 'account.tagline', maxLength: 32, i18n }),
          }}
        />
      )}
      <TextField name="account.bio" />
      {updateProAccountPermit && account.kind !== 'pro' && (
        <Note.Root>
          <Icon name="error" />
          <Note.Description>{i18n.t('guide.pro_account_profile')}</Note.Description>
        </Note.Root>
      )}
      <Toolbox>
        <DiscardConfirmationButton to={`/accounts/${account.gid}`}>{i18n.t('action.close')}</DiscardConfirmationButton>
        <FormButton>{i18n.t('action.update')}</FormButton>
      </Toolbox>
    </Form>
  );
};

const BannerField: FC<{ account: Pick<Account, 'banner_url'> }> = ({ account }) => {
  const { setValue } = useFormContext<UpdateAccountBody>();
  const [bannerUrl, setBannerUrl] = useState<string | undefined>(account.banner_url || undefined);
  const [isUploading, startTransition] = useTransition();

  const setBanner = (banner: MediaFile) => {
    setValue('account.banner_media_file', banner.gid, { shouldDirty: true });
    setBannerUrl(banner.url);
  };

  const uploadBanner = onUploadBanner({ setBanner, startTransition });

  return (
    <div className="banner-field">
      <MediaImage className="banner-image" src={bannerUrl} size={'banner'} />
      <div className="layer">
        {isUploading ? (
          <Spinner />
        ) : (
          <ReplacePhotoOverlay id="banner-overlay" onChange={uploadBanner} accept={ACCEPT_IMAGE} />
        )}
      </div>
    </div>
  );
};

const AvatarField: FC<{ account: Pick<Account, 'icon_url'>; size?: 'lg' | 'xl' }> = ({ account, size = 'lg' }) => {
  const { setValue } = useFormContext<UpdateAccountBody>();
  const [iconUrl, setIconUrl] = useState<string | undefined>(account.icon_url || undefined);
  const [isUploading, startTransition] = useTransition();

  const setIcon = (icon: MediaFile) => {
    setValue('account.icon_media_file', icon.gid, { shouldDirty: true });
    setIconUrl(icon.url);
  };

  const uploadIcon = onUploadIcon({ setIcon, startTransition });

  return (
    <div className="avatar-field">
      <Avatar src={iconUrl} size={size} />
      <div className="layer">
        {isUploading ? (
          <Spinner size={size === 'lg' ? 'medium' : 'large'} />
        ) : (
          <ReplacePhotoOverlay id="avatar-overlay" onChange={uploadIcon} accept={ACCEPT_IMAGE} />
        )}
      </div>
    </div>
  );
};

const ProfileURLPreview: FC = () => {
  const { watch } = useFormContext<UpdateAccountBody>();
  const screenName = watch('account.screen_name');
  if (screenName) {
    return (
      <Text color="note" variant="body">
        nagaku.com/
        <Text color="note" variant="body" weight="bold">
          {screenName}
        </Text>
      </Text>
    );
  } else {
    return (
      <Text color="note" variant="body">
        nagaku.com/
        <Text color="note" weight="bold">
          {'{your-account-id}'}
        </Text>
      </Text>
    );
  }
};

const DiscardConfirmationButton: FC<PropsWithChildren<{ to: string }>> = ({ to, children }) => {
  const navigate = useNavigate();
  const {
    formState: { isDirty },
  } = useFormContext();

  const onClose = useCallback(() => {
    navigate(to);
  }, [to, navigate]);

  return (
    <ConfirmationButton scope="confirmation.profile.discard" variant="outline" isConfirm={!isDirty} onConfirm={onClose}>
      {children}
    </ConfirmationButton>
  );
};
