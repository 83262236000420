import './ProPromoteView.css';

import { FC } from 'react';
import { LinkButton } from 'src/components/primitives/Button';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';

const IMAGES = ['https://nagaku.com/image/pro-account-promote/1.webp'];

export const ProPromoteView: FC = () => {
  const { i18n } = useI18n();
  return (
    <aside className="pro-promote-view" data-nosnippet style={{ backgroundImage: `url(${IMAGES[0]})` }}>
      <Text asChild size="medium" weight="bold" color="invert">
        <p>
          <Body />
        </p>
      </Text>
      <LinkButton to="/about/pro" variant="whiteline" size="small">
        {i18n.t('action.show_details')}
      </LinkButton>
    </aside>
  );
};

const Body: FC = () => {
  const { i18n } = useI18n();
  switch (i18n.locale()) {
    case 'en':
    // TODO
    default:
      return (
        <>
          プロアカウントになって、
          <wbr />
          リペアやリメイクを受注しませんか？
        </>
      );
  }
};
