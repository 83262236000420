import { RouteObject } from 'react-router';
import { ModelCatalog } from 'src/catalog/ModelCatalog';
import { Module, StoryCatalog, StoryContainer } from 'src/catalog/Story';
import { ToastCatalog } from 'src/catalog/ToastCatalog';

const devRoutes: RouteObject[] = [];

if (import.meta.env.DEV || import.meta.env.VITE_STAGE == 'staging') {
  const modules = import.meta.glob('/src/components/**/*.stories.tsx', { eager: true });
  const resolvedModules: { [path: string]: Module<any> } = Object.keys(modules).reduce((resolvedModules, path) => {
    // '/src/components/primitives/Button.stories.tsx' => 'components/primitives/Button'
    const resolvedPath = path.replace(/\.stories\.tsx?$/, '').replace(/^\/src\/components\//, '');
    return { ...resolvedModules, [resolvedPath]: modules[path] };
  }, {});
  const componentCatalogRoute: RouteObject = {
    path: 'components',
    element: <StoryCatalog modules={resolvedModules} />,
    children: [],
  };
  devRoutes.push(componentCatalogRoute);
  Object.keys(resolvedModules).forEach(async (path) => {
    const module = resolvedModules[path] as Module<any>;
    componentCatalogRoute.children?.push({
      path,
      element: <StoryContainer path={path} module={module} />,
    });
  });
  devRoutes.push({
    path: 'toasts',
    element: <ToastCatalog />,
  });
  devRoutes.push({
    path: 'models',
    element: <ModelCatalog />,
  });
}

export default devRoutes;
