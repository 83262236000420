import './QuotationSection.css';

import { FC, PropsWithChildren, ReactNode } from 'react';
import { Text } from 'src/components/primitives/Text';

export const QuotationSection: FC<PropsWithChildren<{ title: ReactNode }>> = ({ children, title }) => {
  return (
    <section className="quotation-section">
      <Text asChild variant="title" size="large" weight="bold">
        <h2>{title}</h2>
      </Text>
      {children}
    </section>
  );
};
