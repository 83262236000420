import { FC, PropsWithChildren, ReactNode, useContext } from 'react';
import { FormOptionsContext } from 'src/components/control/Form';
import { FormLabel } from 'src/components/control/FormLabel';
import { NoticeLabel } from 'src/components/primitives/NoticeLabel';
import { VStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';

export const FormControl: FC<PropsWithChildren<{ id: string; name?: string; label: ReactNode; required: boolean }>> = ({
  id,
  name,
  label,
  required,
  children,
}) => {
  const { i18n } = useI18n();
  return (
    <VStack align="start" spacing="sm" m="none">
      <FormLabel htmlFor={id}>
        {label} <IndicatorTag required={required} />
      </FormLabel>
      {name && i18n.has('validation_guides.'.concat(name)) && (
        <Text asChild variant="label" color="note" size="large">
          <p>{i18n.t('validation_guides.'.concat(name))}</p>
        </Text>
      )}
      {name && i18n.has('annotations.'.concat(name)) && (
        <Text asChild variant="label" color="note" size="large">
          <p>{i18n.t('annotations.'.concat(name))}</p>
        </Text>
      )}
      {children}
    </VStack>
  );
};

export const IndicatorTag: FC<{ required: boolean }> = ({ required }) => {
  const { i18n } = useI18n();
  const { defaultRequired } = useContext(FormOptionsContext);
  if (required && !defaultRequired) {
    return (
      <NoticeLabel variant="required" size="small">
        {i18n.t('control.required')}
      </NoticeLabel>
    );
  }
  if (!required && defaultRequired) {
    return <NoticeLabel size="small">{i18n.t('control.optional')}</NoticeLabel>;
  }
};
