import './RepairRequestMessageView.css';

import { FC, useState } from 'react';
import { MessageBalloon } from 'src/components/features/message/MessageBalloon';
import { Message, RepairRequestMessage } from 'src/models/v1/message';
import { ImageViewer } from 'src/components/features/ImageViewer';
import { ThumbnailCollection } from 'src/components/features/ThumbnailCollection';
import { DataNote } from 'src/components/features/DataNote';
import { useI18n } from 'src/lib/i18n';
import { Ticket } from 'src/models/v1/ticket';

export const RepairRequestMessageView: FC<{
  ticket: Pick<Ticket, 'procedure'>;
  message: Message & RepairRequestMessage;
}> = ({ ticket, message }) => {
  const { i18n } = useI18n();
  const images = message.data.photo_media_files.map(({ url }) => url);
  const [currentImage, setCurrentImage] = useState<string | undefined>(undefined);
  return (
    <MessageBalloon account={message.data.account} body={message.html.body} timestamp={message.data.created_at}>
      {ticket.procedure !== 'undefined' && (
        <DataNote
          icon={'handshake'}
          label={i18n.t('attributes.ticket.procedure')}
          value={i18n.t(`enum.procedure.${ticket.procedure}`)}
        />
      )}
      <div className="repair-request-attachments-collection">
        <ImageViewer images={images} currentImage={currentImage} setCurrentImage={setCurrentImage} />
        <ThumbnailCollection images={images} onSelect={(image) => setCurrentImage(image)} />
      </div>
    </MessageBalloon>
  );
};
