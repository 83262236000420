import { TicketCommentMessageView } from 'src/components/features/message/TicketCommentMessageView';
import { Meta, Story } from 'src/catalog/Story';
import { factoryAccount } from 'src/catalog/factories/account';
import { factoryQuotation } from 'src/catalog/factories/quotation';

const meta: Meta<typeof TicketCommentMessageView> = {
  title: 'Ticket Comment Message View',
  args: {
    message: {
      type: 'ticket_comment',
      html: {
        body: 'Test Comment',
      },
      data: {
        account: factoryAccount(),
        body: 'This is a test comment',
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        attachments: [],
      },
    },
  },
  template: (args) => <TicketCommentMessageView {...args} />,
};

export default meta;

export const LongBody: Story<typeof TicketCommentMessageView> = {
  name: 'Long Body with Quotation',
  args: {
    message: {
      type: 'ticket_comment',
      html: {
        body: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      },
      data: {
        account: factoryAccount('iconic'),
        body: 'lorem ipsum（ロレム・イプサム、略してリプサム lipsum ともいう）とは、出版、ウェブデザイン、グラフィックデザインなどの諸分野において使用されている典型的なダミーテキスト（英語版）。書籍やウェブページや広告などのデザインのプロトタイプを制作したり顧客にプレゼンテーションしたりする際に、まだ正式な文章の出来上がっていないテキスト部分の書体（フォント）、タイポグラフィ、レイアウトなどといった視覚的なデザインを調整したりわかりやすく見せるために用いられる。',
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        attachments: [{ ...factoryQuotation(), type: 'quotation' }],
      },
    },
  },
};
