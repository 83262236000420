import React, { FC, KeyboardEventHandler, useCallback, useRef, useState, useEffect } from 'react';
import './BeforeAfterSlider.css';
import { Icon } from 'src/components/primitives/Icon';
import { MediaImage } from 'src/components/primitives/MediaImage';
import { useI18n } from 'src/lib/i18n';

const KEYCODE_REVEAL_DELTA: { [code: string]: number } = {
  ArrowRight: 0.05,
  ArrowUp: 0.05,
  ArrowLeft: -0.05,
  ArrowDown: -0.05,
  PageUp: 0.2,
  PageDown: -0.2,
  Home: -1, // n (0~1) - 1 -> always less than 0
  End: 1, // n (0~1) + 1 -> always greater than 1
};

/**
 * 0: Main button pressed, usually the left button or the un-initialized state
 * 1: Auxiliary button pressed, usually the wheel button or the middle button (if present)
 * 2: Secondary button pressed, usually the right button
 * 3: Fourth button, typically the Browser Back button
 * 4: Fifth button, typically the Browser Forward button
 * see https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
 */
const BUTTON_NUM_PRIMARY = 0;

/**
 * Note: TouchEvent is not defined on Mac Safari, see https://developer.mozilla.org/en-US/docs/Web/API/TouchEvent#browser_compatibility
 */
class SliderController {
  private onSlideFunction: ((xPosition: number) => void) | null = null;
  private mousedownPagePosition: [number, number] = [0, 0];
  private mousedownTimestamp: number = 0; // msec
  private cancelPositionDistance = 8; // pixels
  private startSliding = false;
  clickCancellation = false;
  private isActive = false;
  private element: HTMLElement | null = null;

  constructor(element: HTMLElement) {
    this.element = element;
  }

  setup = (fn: SliderController['onSlideFunction']) => {
    this.onSlideFunction = fn;
    this.addEventListeners();
  };

  teardown = () => {
    this.removeEventListeners();
    this.onSlideFunction = null;
  };

  private addEventListeners = () => {
    if (!this.element) return;
    this.element.addEventListener('mousedown', this.handleDown);
    this.element.addEventListener('touchstart', this.handleDown, { passive: false });
    document.addEventListener('mousemove', this.handleMove);
    document.addEventListener('touchmove', this.handleMove, { passive: false });
    document.addEventListener('mouseup', this.handleUp);
    document.addEventListener('touchend', this.handleUp);
  };

  private removeEventListeners = () => {
    if (!this.element) return;
    this.element.removeEventListener('mousedown', this.handleDown);
    this.element.removeEventListener('touchstart', this.handleDown);
    document.removeEventListener('mousemove', this.handleMove);
    document.removeEventListener('touchmove', this.handleMove);
    document.removeEventListener('mouseup', this.handleUp);
    document.removeEventListener('touchend', this.handleUp);
  };

  private handleDown = (event: MouseEvent | TouchEvent) => {
    if (event instanceof MouseEvent && event.button !== BUTTON_NUM_PRIMARY) {
      return;
    }
    this.isActive = true;
    this.initialize(event);
  };

  private handleMove = (event: MouseEvent | TouchEvent) => {
    if (!this.isActive || !this.onSlideFunction) return;
    if (typeof TouchEvent !== 'undefined' && event instanceof TouchEvent && event.touches.length === 0) return;
    const pageX = event instanceof MouseEvent ? event.pageX : event.touches[0].clientX;
    const pageY = event instanceof MouseEvent ? event.pageY : event.touches[0].clientY;
    if (this.startSliding === false && this.checkCancellation(event) === true) {
      // check to start sliding
      const distance = [
        Math.abs(pageX - this.mousedownPagePosition[0]),
        Math.abs(pageY - this.mousedownPagePosition[1]),
      ];
      if (distance[0] > distance[1]) {
        // horizontal mousemove: start
        this.startSliding = true;
      } else {
        // vertical mousemove: cancel
      }
    }
    if (this.startSliding && event.cancelable) {
      this.onSlideFunction(pageX);
      if (event.type === 'touchmove') {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  };

  private handleUp = (event: MouseEvent | TouchEvent) => {
    this.isActive = false;
    if (this.checkCancellation(event) === true) this.clickCancellation = true;
  };

  private initialize = (event: MouseEvent | TouchEvent) => {
    const pageX = event instanceof MouseEvent ? event.pageX : event.touches[0].clientX;
    const pageY = event instanceof MouseEvent ? event.pageY : event.touches[0].clientY;
    this.mousedownPagePosition = [pageX, pageY];
    this.mousedownTimestamp = new Date().getTime(); // msec
    this.clickCancellation = false;
    this.startSliding = false;
  };

  private checkCancellation = (event: MouseEvent | TouchEvent) => {
    if (typeof TouchEvent !== 'undefined' && event instanceof TouchEvent && event.touches.length === 0) {
      return true;
    }
    const pageX = event instanceof MouseEvent ? event.pageX : event.touches[0].clientX;
    const pageY = event instanceof MouseEvent ? event.pageY : event.touches[0].clientY;
    // mousemoveSquaredDistance = Δx ^ 2 + Δy ^ 2 (Pythagorean theorem)
    const mousemoveSquaredDistance =
      Math.pow(pageX - this.mousedownPagePosition[0], 2) + Math.pow(pageY - this.mousedownPagePosition[1], 2);
    const cancelSquaredDistance = Math.pow(this.cancelPositionDistance, 2);
    // If the mouse moved more than the allowed distance, it returns true = cancellation.
    if (mousemoveSquaredDistance > cancelSquaredDistance) {
      return true;
    }
    const mouseupTimestamp = new Date().getTime(); // msec
    // If the time between mousedown and mouseup is greater than 150ms, it returns true = cancellation.
    if (this.mousedownTimestamp + 150 < mouseupTimestamp) {
      return true;
    }
    return false;
  };
}

export const BeforeAfterSlider: FC<{
  className?: string;
  beforeImage?: string | undefined;
  afterImage?: string | undefined;
}> = ({ className, beforeImage, afterImage }) => {
  const { i18n } = useI18n();
  const [revealPercentage, setRevealPercentage] = useState(0.5);
  const ref = useRef<HTMLDivElement>(null);
  const controllerRef = useRef<SliderController | null>(null);

  const onSlide = useCallback(
    (xPosition: number): void => {
      if (ref.current) {
        const containerBoundingRect = ref.current.getBoundingClientRect();
        setRevealPercentage(() => {
          const nextRevealPercentage = (xPosition - containerBoundingRect.left) / containerBoundingRect.width;
          // Clamp 0 ~ 1
          return Math.min(1, Math.max(0, nextRevealPercentage));
        });
      }
    },
    [ref, setRevealPercentage],
  );

  useEffect(() => {
    if (ref.current && !controllerRef.current) {
      // create new SliderController if needed
      controllerRef.current = new SliderController(ref.current);
    }
    const controller = controllerRef.current;

    // setup slide controller
    controller?.setup(onSlide);

    return () => {
      // teardown slide controller
      controller?.teardown();
    };
  }, [onSlide]);

  const positioningStyle = {
    clipImage: {
      clipPath: `polygon(0 0, ${revealPercentage * 100}% 0, ${revealPercentage * 100}% 100%, 0 100%)`,
    },
    sliderHandleContainer: {
      left: `${revealPercentage * 100}%`,
    },
  };

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      let delta: undefined | number = KEYCODE_REVEAL_DELTA[event.code];
      // when user down movement keys, update reveal percentage.
      if (typeof delta === 'number') {
        event.preventDefault();
        event.stopPropagation();
        setRevealPercentage((c) => Math.min(1, Math.max(0, c + delta)));
      }
      // when user down 1 ~ 9 keys, set 10% ~ 90% reveal percentage.
      const int = parseInt(event.key);
      if (1 <= int && int <= 9) {
        event.preventDefault();
        event.stopPropagation();
        setRevealPercentage(int / 10.0);
      }
    },
    [setRevealPercentage],
  );

  const onClickCancellationCheck = useCallback((event: React.MouseEvent) => {
    if (controllerRef.current?.clickCancellation) {
      event.preventDefault();
      event.stopPropagation();
    }
  }, []);

  return (
    <figure ref={ref} className={`before-after-slider ${className || ''}`} onClick={onClickCancellationCheck}>
      {!afterImage && !beforeImage ? (
        <img
          src="/svg/blankImage.svg"
          alt={i18n.t('BeforeAfterSlider.BlankImage')}
          className="blank-image"
          draggable="false"
        />
      ) : (
        <MediaImage
          src={afterImage}
          size="2048x2048"
          alt={i18n.t('BeforeAfterSlider.AfterImage')}
          className="after-image"
          draggable="false"
        />
      )}
      {beforeImage && (
        <MediaImage
          src={beforeImage}
          style={positioningStyle.clipImage}
          size="2048x2048"
          alt={i18n.t('BeforeAfterSlider.BeforeImage')}
          className="before-image"
          draggable="false"
        />
      )}
      {beforeImage && (
        <label style={positioningStyle.sliderHandleContainer} className="handle-container">
          <Icon role="slider" className="handle" name="slider_arrow" aria-label={i18n.t('BeforeAfterSlider.Handle')} />
          <input
            className="slider"
            type="range"
            tabIndex={0}
            aria-valuenow={revealPercentage}
            aria-valuemin={0}
            aria-valuemax={1}
            onKeyDown={onKeyDown}
          />
        </label>
      )}
    </figure>
  );
};
