import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCurrentStatus } from 'src/lib/currentStatus';

type LikeState = {
  liked: boolean | null;
  count: number | null;
};

type FollowState = {
  followed: boolean | null;
};

export const useCacheKey = (
  namespace: string,
  phrases: { path?: string; search?: URLSearchParams | { [name: string]: string }; hash?: string; value?: any },
) => {
  const { me } = useCurrentStatus();
  const accountGid = me?.gid || 'unauthorized';
  
  return useMemo(() => {
    let key = [namespace, accountGid];
    if (phrases.path) key.push(phrases.path);
    if (phrases.search) key.push(JSON.stringify(phrases.search));
    if (phrases.hash) key.push(phrases.hash);
    if (phrases.value) key.push(JSON.stringify(phrases.value));
    return key.join(':');
  }, [namespace, JSON.stringify(phrases), accountGid]);
};

export const useSessionCache = <S>(
  namespace: string,
  phrases: { path?: string; search?: URLSearchParams | { [name: string]: string }; hash?: string; value?: any },
) => {
  const name = useCacheKey(namespace, phrases);
  const stored = useMemo(() => {
    const item = window.sessionStorage.getItem(name);
    if (item) {
      try {
        return JSON.parse(item);
      } catch {
        console.error(`invalid session storage item at '${name}', flush it.`);
        window.sessionStorage.removeItem(name);
        return undefined;
      }
    } else {
      return undefined;
    }
  }, [name]);
  const [data, setStateData] = useState<S>(stored || undefined);

  const setData: typeof setStateData = useCallback(
    (args) => {
      setStateData((prevState) => {
        const newState = typeof args == 'function' ? (args as (prevState: S) => S)(prevState) : args;
        if (typeof newState != 'undefined') {
          window.sessionStorage.setItem(name, JSON.stringify(newState));
        } else {
          window.sessionStorage.removeItem(name);
        }
        return newState;
      });
    },
    [setStateData],
  );

  return [data, setData] as ReturnType<typeof useState<S>>;
};

export const useLikeCache = (gid: string, apiLiked: boolean | null, apiCount: number | null) => {
  if (import.meta.env.SSR)
    return { likeState: { liked: apiLiked, count: apiCount }, onLike: () => {}, onUnlike: () => {} };

  const storedValue = useMemo(() => {
    const item = sessionStorage.getItem(gid);
    return item ? JSON.parse(item) : { liked: apiLiked, count: apiCount };
  }, [gid, apiLiked, apiCount]);

  const [likeState, setLikeState] = useState<LikeState>(storedValue);

  const onLike = useCallback(() => {
    setLikeState((like) => {
      const newState = { liked: true, count: like.count != null ? like.count + 1 : null };
      setStorageItem(gid, newState);
      return newState;
    });
  }, [setLikeState, setStorageItem]);

  const onUnlike = useCallback(() => {
    setLikeState((like) => {
      const newState = { liked: false, count: like.count != null ? like.count - 1 : null };
      setStorageItem(gid, newState);
      return newState;
    });
  }, [setLikeState, setStorageItem]);

  useEffect(() => {
    setLikeState(storedValue);
    if (JSON.stringify(likeState) !== JSON.stringify({ liked: apiLiked, count: apiCount })) {
      sessionStorage.removeItem(gid);
    }
  }, [gid, apiLiked, apiCount]);

  return { likeState, onLike, onUnlike };
};

export const useFollowCache = (gid: string, apiFollowed: boolean | null) => {
  if (import.meta.env.SSR) return { followState: { followed: apiFollowed }, onFollow: () => {}, onUnfollow: () => {} };

  const storedValue = useMemo(() => {
    const item = sessionStorage.getItem(gid);
    return item ? JSON.parse(item) : { followed: apiFollowed };
  }, [gid, apiFollowed]);

  const [followState, setFollowState] = useState<FollowState>(storedValue);

  const onFollow = useCallback(() => {
    setFollowState(() => {
      const newState = { followed: true };
      setStorageItem(gid, newState);
      return newState;
    });
  }, [setFollowState, setStorageItem]);

  const onUnfollow = useCallback(() => {
    setFollowState(() => {
      const newState = { followed: false };
      setStorageItem(gid, newState);
      return newState;
    });
  }, [setFollowState, setStorageItem]);

  useEffect(() => {
    setFollowState(storedValue);
    if (JSON.stringify(followState) !== JSON.stringify({ followed: apiFollowed })) {
      sessionStorage.removeItem(gid);
    }
  }, [gid, apiFollowed]);

  return { followState, onFollow, onUnfollow };
};

const setStorageItem = (gid: string, newState: LikeState | FollowState) => {
  sessionStorage.setItem(gid, JSON.stringify(newState));
};
