import { FC } from 'react';
import { onUpdateJpActOnSpecifiedCommercialTransaction } from 'src/actions/jp_act_on_specified_commercial_transaction';
import { Form } from 'src/components/control/Form';
import { FormButton } from 'src/components/control/FormButton';
import { FormLabel } from 'src/components/control/FormLabel';
import { InputField } from 'src/components/control/InputField';
import { RadioField } from 'src/components/control/RadioField';
import { SaveDraft } from 'src/components/control/SaveDraft';
import { TextField } from 'src/components/control/TextField';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';
import { validateMaxLengthOption } from 'src/lib/validation';
import { Business } from 'src/models/v1/business';
import { JpActOnSpecifiedCommercialTransaction } from 'src/models/v1/jp_act_on_specified_commercial_transaction';

export const JpActOnSpecifiedCommercialTransactionForm: FC<{
  business: Pick<Business, 'business_type' | 'status' | 'open_requirements'>;
  jp_act_on_specified_commercial_transaction: Omit<
    JpActOnSpecifiedCommercialTransaction,
    'display_address' | 'display_phone'
  >;
}> = ({ business, jp_act_on_specified_commercial_transaction }) => {
  const { i18n } = useI18n();
  const onUpdate = onUpdateJpActOnSpecifiedCommercialTransaction({ businessStatus: business.status });
  return (
    <Form
      className="jp-act-on-specified-commercial-transaction-form"
      onSubmit={onUpdate}
      defaultValues={{
        jp_act_on_specified_commercial_transaction: {
          ...jp_act_on_specified_commercial_transaction,
          address_hidden: parseHiddenFlag(jp_act_on_specified_commercial_transaction.address_hidden),
          phone_hidden: parseHiddenFlag(jp_act_on_specified_commercial_transaction.address_hidden),
        },
      }}
      defaultRequired
      mode="all"
    >
      <Text>
        {i18n.t('guide.jp_act_on_specified_commercial_transaction_for_pro_account')}
        <LocalizedHelpLink />
      </Text>
      <SaveDraft path="/v1/accounts/me/business/jp_act_on_specified_commercial_transaction" />
      {business.business_type == 'company' && (
        <InputField name="jp_act_on_specified_commercial_transaction.corporate_name" options={{ required: true }} />
      )}
      <InputField
        name="jp_act_on_specified_commercial_transaction.representative_name"
        options={{ required: true }}
        maxLength={32}
      />
      <InputField
        name="jp_act_on_specified_commercial_transaction.address"
        options={{ required: true }}
        maxLength={100}
      />
      {business.business_type == 'individual' && (
        <RadioField
          name="jp_act_on_specified_commercial_transaction.address_hidden"
          items={{
            yes: i18n.t('enum.jp_act_on_specified_commercial_transaction.address_hidden.yes'),
            no: i18n.t('enum.jp_act_on_specified_commercial_transaction.address_hidden.no'),
          }}
          options={{ required: true }}
        />
      )}
      <InputField name="jp_act_on_specified_commercial_transaction.phone" options={{ required: true }} maxLength={14} />
      {business.business_type == 'individual' && (
        <RadioField
          name="jp_act_on_specified_commercial_transaction.phone_hidden"
          items={{
            yes: i18n.t('enum.jp_act_on_specified_commercial_transaction.phone_hidden.yes'),
            no: i18n.t('enum.jp_act_on_specified_commercial_transaction.phone_hidden.no'),
          }}
          options={{ required: true }}
        />
      )}
      <CustomTextField name="jp_act_on_specified_commercial_transaction.business_hours_and_closed_days" />
      <CustomTextField name="jp_act_on_specified_commercial_transaction.pricing" />
      <CustomTextField name="jp_act_on_specified_commercial_transaction.additional_fees" />
      <FormLabel>{i18n.t('attributes.jp_act_on_specified_commercial_transaction.payment_method')}</FormLabel>
      <Text>ナガクが提供する支払方法</Text>
      <FormLabel>{i18n.t('attributes.jp_act_on_specified_commercial_transaction.payment_timing')}</FormLabel>
      <Text>お見積りが確定し、ご注文時に決済となります。</Text>
      <CustomTextField name="jp_act_on_specified_commercial_transaction.estimated_delivery" />
      <CustomTextField name="jp_act_on_specified_commercial_transaction.cancellation_policy" />
      <CustomTextField name="jp_act_on_specified_commercial_transaction.after_service_policy" />
      <FormButton>{i18n.t('action.save')}</FormButton>
    </Form>
  );
};

const LocalizedHelpLink = () => {
  const { i18n } = useI18n();
  switch (i18n.locale()) {
    case 'ja':
      return (
        <>
          詳しくは「
          <a
            href="https://help.nagaku.com/hc/ja/articles/42337584957593"
            title="ヘルプ：「特定商取引法に基づく表記」の設定"
            target="_blank"
            rel="noopener"
          >
            「特定商取引法に基づく表記」の設定
          </a>
          」をご確認ください。
        </>
      );
    case 'en':
      return (
        <>
          For more details, please refer to the article "
          <a
            href="https://help.nagaku.com/hc/en/articles/42337584957593"
            title="Help: Configuring 'Notices Based on the Act on Specified Commercial Transactions'"
            target="_blank"
            rel="noopener"
          >
            Configuring 'Notices Based on the Act on Specified Commercial Transactions' - Japanese only
          </a>
          ."
        </>
      );
    default:
      return undefined;
  }
};

const CustomTextField: FC<{ name: string }> = ({ name }) => {
  const { i18n } = useI18n();
  return (
    <TextField
      scale="thin"
      name={name}
      options={{ required: true, maxLength: validateMaxLengthOption({ name, maxLength: 200, i18n }) }}
    />
  );
};

const parseHiddenFlag = (value: boolean | null) => {
  if (value === true) return 'yes';
  if (value === false) return 'no';
  return null;
};
