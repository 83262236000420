import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router';
import { ProfileForm } from 'src/components/features/account/ProfileForm';
import { NavigationLayout } from 'src/components/Layout';
import { Heading } from 'src/components/primitives/Heading';
import { PageLoader } from 'src/lib/api';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';
import { Account } from 'src/models/v1/account';

type Data = {
  account: Account;
};

export const EditProfilePage: FC = () => {
  const { account } = useLoaderData() as Data;
  const { me } = useCurrentStatus();
  const { i18n } = useI18n();
  if (account.gid != me?.gid) {
    throw 'access denied'; // TODO: refine error
  }
  return (
    <NavigationLayout>
      <Helmet>
        <title>{`${i18n.t('title.profile_edit_page')} | ${account.display_name} | ${i18n.t('nagaku')}`}</title>
        <meta name="description" content="" />
      </Helmet>
      <Heading>{i18n.t('title.profile_edit_page')}</Heading>
      <ProfileForm account={account} />
    </NavigationLayout>
  );
};

export const editProfileLoader: PageLoader = async ({ params, api }) => {
  const { gid } = params;
  return await api.fetch(`/v1/accounts/${gid}`, {
    include: 'business_profile',
  });
};
