import './NavigationProgress.css';

import { FC, useEffect, useState, useRef } from 'react';
import { useNavigation, useLocation } from 'react-router';

const ANIMATION_DURATION_MS = 400; // ミリ秒
const ANIMATION_DURATION_S = ANIMATION_DURATION_MS / 1000; // 秒に変換

// リソースの読み込み状態も監視するための変数
let isDocumentLoading = false;
let loadingStartTime = 0;

export const NavigationProgressBar: FC = () => {
  const [visible, setVisible] = useState(false);
  const [complete, setComplete] = useState(false);
  const navigation = useNavigation();
  const location = useLocation();
  const prevLocationRef = useRef(location);
  const isLoading = navigation.state === 'loading';
  const isMountedRef = useRef(false);

  // コンポーネントのマウント状態を追跡
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  // document全体のイベントを監視 (リダイレクトや他のリソース読み込みも捕捉)
  useEffect(() => {
    // リソース読み込み開始のイベントハンドラ
    const handleLoadStart = () => {
      if (!isDocumentLoading) {
        isDocumentLoading = true;
        loadingStartTime = Date.now();
        if (isMountedRef.current) {
          setVisible(true);
          setComplete(false);
        }
      }
    };

    // リソース読み込み完了のイベントハンドラ
    const handleLoadComplete = () => {
      if (isDocumentLoading) {
        isDocumentLoading = false;
        // 最低でも200ms間はローディング状態を維持
        const loadingDuration = Date.now() - loadingStartTime;
        const additionalDelay = Math.max(0, 200 - loadingDuration);

        setTimeout(() => {
          if (isMountedRef.current) {
            setComplete(true);

            // アニメーション完了後に非表示に
            setTimeout(() => {
              if (isMountedRef.current) {
                setVisible(false);
                setComplete(false);
              }
            }, ANIMATION_DURATION_MS);
          }
        }, additionalDelay);
      }
    };

    // "beforeunload"イベントも監視 (ページ遷移時)
    const handleBeforeUnload = () => {
      handleLoadStart();
    };

    // pushStateやreplaceStateを監視 (React Routerのナビゲーション)
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function () {
      handleLoadStart();
      const result = originalPushState.apply(this, arguments as any);
      setTimeout(handleLoadComplete, 50);
      return result;
    };

    window.history.replaceState = function () {
      handleLoadStart();
      const result = originalReplaceState.apply(this, arguments as any);
      setTimeout(handleLoadComplete, 50);
      return result;
    };

    // popstateイベント (ブラウザバックやフォワード) を監視
    window.addEventListener('popstate', handleLoadStart);
    window.addEventListener('beforeunload', handleBeforeUnload);

    // 他の重要なイベントも監視
    window.addEventListener('load', handleLoadComplete);
    document.addEventListener('readystatechange', () => {
      if (document.readyState === 'complete') {
        handleLoadComplete();
      }
    });

    return () => {
      // クリーンアップ
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
      window.removeEventListener('popstate', handleLoadStart);
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('load', handleLoadComplete);
      document.removeEventListener('readystatechange', handleLoadComplete);
    };
  }, []);

  // React Routerのナビゲーション状態も監視
  useEffect(() => {
    let hideTimeout: NodeJS.Timeout;

    if (isLoading) {
      setVisible(true);
      setComplete(false);
    } else if (visible && isLoading === false) {
      // ナビゲーション完了
      setComplete(true);

      hideTimeout = setTimeout(() => {
        if (isMountedRef.current) {
          setVisible(false);
          setComplete(false);
        }
      }, ANIMATION_DURATION_MS);
    }

    return () => {
      clearTimeout(hideTimeout);
    };
  }, [isLoading, visible]);

  // locationの変更も監視
  useEffect(() => {
    // 初回レンダリング時はスキップ
    if (prevLocationRef.current === location) {
      prevLocationRef.current = location;
      return;
    }

    // パスまたはクエリパラメータの変更を検知
    const isNewLocation =
      prevLocationRef.current.pathname !== location.pathname || prevLocationRef.current.search !== location.search;

    if (isNewLocation && !isLoading && !isDocumentLoading) {
      // 他の方法で検知されていない場合のみ処理
      setVisible(true);
      setComplete(false);

      // 少し待ってから完了として処理
      const completeTimeout = setTimeout(() => {
        setComplete(true);

        // アニメーション完了後に非表示
        const hideTimeout = setTimeout(() => {
          if (isMountedRef.current) {
            setVisible(false);
            setComplete(false);
          }
        }, ANIMATION_DURATION_MS);

        return () => clearTimeout(hideTimeout);
      }, 50);

      return () => clearTimeout(completeTimeout);
    }

    prevLocationRef.current = location;
  }, [location, isLoading]);

  if (!visible) {
    return null;
  }

  // CSS変数としてアニメーション時間を設定
  const style = {
    '--progress-animation-duration': `${ANIMATION_DURATION_S}s`,
  } as React.CSSProperties;

  return (
    <div className={`progress-bar ${complete ? 'progress-bar--complete' : 'progress-bar--loading'}`} style={style} />
  );
};
