import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { createAction } from 'src/lib/action';
import { api } from 'src/lib/api';
import { UpdateWorkBody, UpdateWorkResponse } from 'src/models/v1/work';

export const onCopyWorkUrl = createAction('onCopyWorkUrl', ({ gid }: { gid: string }) => {
  return useCallback(async () => {
    const url = `${window.location.origin}/works/${gid}`;
    await navigator.clipboard.writeText(url);
  }, [gid]);
});

export const onPublishWork = createAction('onPublishWork', ({ gid }: { gid: string }) => {
  const navigate = useNavigate();
  return useCallback(
    async (body: UpdateWorkBody) => {
      if (body.work.after_image_media_file == '') {
        body.work.after_image_media_file = null;
      }
      if (body.work.before_image_media_file == '') {
        body.work.before_image_media_file = null;
      }
      await api.dispatch<UpdateWorkBody, UpdateWorkResponse>('PUT', `/v1/works/${gid}`, undefined, body);
      navigate(`/works/${gid}`);
    },
    [navigate],
  );
});

export const onSaveDraftWork = createAction('onSaveDraftWork', ({ gid }: { gid: string }) => {
  const navigate = useNavigate();
  return useCallback(
    async (body: UpdateWorkBody) => {
      if (body.work.after_image_media_file == '') {
        body.work.after_image_media_file = null;
      }
      if (body.work.before_image_media_file == '') {
        body.work.before_image_media_file = null;
      }
      await api.dispatch<UpdateWorkBody, UpdateWorkResponse>('PUT', `/v1/works/${gid}`, undefined, body);
      navigate(`/works/${gid}`);
    },
    [navigate],
  );
});

export const onUpdateWork = createAction(
  'onUpdateWork',
  ({
    onPublish,
    onSaveDraft,
  }: {
    onPublish?: ReturnType<typeof onPublishWork>;
    onSaveDraft?: ReturnType<typeof onSaveDraftWork>;
  }) => {
    return useCallback(async (body: UpdateWorkBody) => {
      if (body.work.status == 'published') await onPublish?.(body);
      else await onSaveDraft?.(body);
    }, []);
  },
);
