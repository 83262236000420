import { RefObject, useEffect, useState } from "react";

export const useInView = (ref: RefObject<HTMLElement | null>) => {
  // OpenTicketStickyBarの制御上都合が良かった（ロード時にfalse -> trueの状態変化を起こしたくなかった）ためデフォルト値をtrueにしています。
  const [inView, setInView] = useState(true);

  useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }

    const observer = new IntersectionObserver(([entry]) =>
      setInView(entry.isIntersecting),
    );
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, setInView]);

  return inView;
};
