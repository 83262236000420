import { factoryJpActOnSpecifiedCommercialTransaction } from 'src/catalog/factories/jp_act_on_specified_commercial_transaction';
import { Meta } from 'src/catalog/Story';
import { JpActOnSpecifiedCommercialTransactionForm } from 'src/components/features/business/JpActOnSpecifiedCommercialTransactionForm';

const meta: Meta<typeof JpActOnSpecifiedCommercialTransactionForm> = {
  title: 'JP Act on Specified Commercial Transaction Form',
  args: {
    business: {
      business_type: 'individual',
      status: 'open',
      open_requirements: {},
    },
    jp_act_on_specified_commercial_transaction: factoryJpActOnSpecifiedCommercialTransaction(),
  },
  template: (args) => <JpActOnSpecifiedCommercialTransactionForm {...args} />,
};

export default meta;
