import './ToastContainer.css';

import { useCallback, useEffect, useState } from 'react';
import { Toast } from 'src/components/primitives/Toast';
import { VStack } from 'src/components/primitives/Stack';
import { SHOW_TOAST_EVENT_NAME, ToastEventPayload } from 'src/lib/toast';

type ToastMessage = ToastEventPayload & { id: string };

const timeout_table: { [variant in ToastEventPayload['variant']]: number } = {
  success: 3000,
  notification: 3000,
  error: 10000,
};

export const ToastContainer = () => {
  const [toastMessages, setToastMessages] = useState<Array<ToastMessage>>([]);

  const showToast = useCallback(
    (event: CustomEvent<ToastEventPayload>) => {
      const newToast = {
        id: Math.random().toString(36).substring(2, 11),
        message: event.detail.message,
        variant: event.detail.variant,
      };
      setToastMessages((toasts) => [...toasts, newToast]);
      setTimeout(
        () => {
          setToastMessages((toasts) => toasts.filter(({ id }) => id != newToast.id));
        },
        timeout_table[event.detail.variant || 'notification'],
      );
    },
    [setToastMessages],
  );

  useEffect(() => {
    window.addEventListener(SHOW_TOAST_EVENT_NAME, showToast as EventListener);
    return () => window.removeEventListener(SHOW_TOAST_EVENT_NAME, showToast as EventListener);
  }, []);

  if (toastMessages.length == 0) return <></>;

  return (
    <VStack className="toast-container" spacing="lg" my="lg">
      {toastMessages.map((toast) => (
        <Toast
          key={toast.id}
          variant={toast.variant}
          onClick={() => setToastMessages((toasts) => toasts.filter(({ id }) => id != toast.id))}
        >
          {toast.message}
        </Toast>
      ))}
    </VStack>
  );
};
