import { Dispatch, SetStateAction, useCallback } from 'react';
import { useRevalidator } from 'react-router';
import { createAction } from 'src/lib/action';
import { api } from 'src/lib/api';
import { Address, RegisterAddressBody } from 'src/models/v1/address';

export const onRegisterAddress = createAction('onRegisterAddress', () => {
  const { revalidate } = useRevalidator();
  return async (body: RegisterAddressBody) => {
    const { address } = await api.dispatch<RegisterAddressBody, { address: Address }>(
      'POST',
      `/v1/accounts/me/addresses`,
      undefined,
      body,
    );
    revalidate();
    return address;
  };
});

export const onDeleteAddress = createAction(
  'onDeleteAddress',
  ({ address, setLoading }: { address: Address; setLoading?: Dispatch<SetStateAction<boolean>> }) => {
    const { revalidate } = useRevalidator();
    return useCallback(async () => {
      if (setLoading) setLoading(true);
      try {
        await api.dispatch('DELETE', `/v1/accounts/me/addresses/${address.gid}`);
        revalidate();
      } finally {
        if (setLoading) setLoading(false);
      }
    }, [address.gid, setLoading]);
  },
);

export type ApiDispatchStatus = 'none' | 'dispatching' | 'waiting_revalidation';

export const onBecomePrimaryAddress = createAction('onBecomePrimaryAddress', ({ address }: { address: Address }) => {
  const { revalidate } = useRevalidator();
  return async () => {
    await api.dispatch('PUT', `/v1/accounts/me/addresses/${address.gid}`, undefined, { address: { primary: true } });
    await revalidate();
  };
});
