import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router';
import { Breadcrumb } from 'src/components/Breadcrumb';
import { AccountLinkCard } from 'src/components/features/account/AccountLinkCard';
import { QuotationBreakdown } from 'src/components/features/quotation/QuotationBreakdown';
import { NavigationLayout } from 'src/components/Layout';
import { LinkButton } from 'src/components/primitives/Button';
import { PageLoader } from 'src/lib/api';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';
import { Order } from 'src/models/v1/order';
import { Quotation } from 'src/models/v1/quotation';

type Data = {
  order: Order;
  quotations: Array<Quotation>;
};

export const OrderPage: FC = () => {
  const { i18n } = useI18n();
  const { order, quotations } = useLoaderData() as Data;
  const { me } = useCurrentStatus();
  return (
    <NavigationLayout>
      <Helmet>
        <title>{`${order.management_number} | ${i18n.t('title.order')} | ${i18n.t('nagaku')}`}</title>
        <meta
          property="og:title"
          content={`${order.management_number} | ${i18n.t('title.order')} | ${i18n.t('nagaku')}`}
        />
      </Helmet>
      <Breadcrumb>
        {me?.gid == order.repairer_account.gid ? (
          <Breadcrumb.Item to="/dashboard/orders">{i18n.t('title.dashboard.orders')}</Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item to="/orders">{i18n.t('title.orders')}</Breadcrumb.Item>
        )}
        <Breadcrumb.Item terminal>{order.management_number}</Breadcrumb.Item>
      </Breadcrumb>
      {me?.gid != order.repairer_account.gid && (
        <AccountLinkCard account={order.repairer_account} title={i18n.t('label.repairer')} />
      )}
      {me?.gid != order.caller_account.gid && (
        <AccountLinkCard account={order.caller_account} title={i18n.t('label.caller')} />
      )}
      {quotations.map((quotation) => (
        <QuotationBreakdown key={quotation.gid} quotation={quotation} />
      ))}
      <LinkButton to={`/messages/${order.ticket.gid}`} mx="auto">
        {i18n.t('action.goto_the_message_for_order')}
      </LinkButton>
    </NavigationLayout>
  );
};

export const orderPageLoader: PageLoader = async ({ api, params }) => {
  const { management_number } = params;
  const { order } = await api.fetch<{ order: Order }>(`/v1/orders/${management_number}`);
  const { quotations } = await api.fetch<{ quotations: Array<Quotation> }>(
    `/v1/tickets/${order.ticket.gid}/quotations?status=paid`,
  );
  return { order, quotations };
};
