import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, useLocation } from 'react-router';
import { useSearchParams } from 'react-router';
import { EmailVerificationForm } from 'src/components/features/setting/EmailVerificationForm';
import { MissingEmailVerificationToken } from 'src/components/features/setting/MissingEmailVerificationToken';
import { NavigationLayout } from 'src/components/Layout';
import { Heading } from 'src/components/primitives/Heading';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';

export const EmailVerificationPage: FC = () => {
  const { i18n } = useI18n();
  const { me } = useCurrentStatus();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  if (token && !me) {
    const searchParams = new URLSearchParams();
    searchParams.set('return_to', `${location.pathname}${location.search}`);
    return <Navigate to={`/login?${searchParams.toString()}`} />;
  }
  return (
    <NavigationLayout>
      <Helmet>
        <title>
          {i18n.t('title.email_verification')} | {i18n.t('nagaku')}
        </title>
        <meta name="description" content="" />
      </Helmet>
      <Heading>{i18n.t('title.email_verification')}</Heading>
      {token && me ? <EmailVerificationForm token={token} account={me} /> : <MissingEmailVerificationToken />}
    </NavigationLayout>
  );
};
