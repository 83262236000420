import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router';
import { WorkEditForm } from 'src/components/features/work/WorkEditForm';
import { NavigationLayout } from 'src/components/Layout';
import { Heading } from 'src/components/primitives/Heading';
import { PageLoader } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { Work } from 'src/models/v1/work';

type Data = {
  work: Work;
};

export const EditWorkPage: FC = () => {
  const { i18n } = useI18n();
  const { work } = useLoaderData() as Data;

  return (
    <NavigationLayout>
      <Helmet>
        <title>{`${i18n.t(work.status == 'draft' ? 'title.new_work' : 'title.edit_work')} · ${work.account.display_name} | ${i18n.t('nagaku')}`}</title>
        <meta name="description" content="" />
      </Helmet>
      <Heading>{i18n.t(work.status == 'draft' ? 'title.new_work' : 'title.edit_work')}</Heading>
      <WorkEditForm work={work} />
    </NavigationLayout>
  );
};

export const editWorkLoader: PageLoader = async ({ params, api }) => {
  const { gid } = params;
  return await api.fetch(`/v1/works/${gid}`);
};
