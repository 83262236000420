import { factoryAccount } from 'src/catalog/factories/account';
import { RepairRequestCard } from './RepairRequestCard';
import { Meta } from 'src/catalog/Story';

const meta: Meta<typeof RepairRequestCard> = {
  title: 'Repair Request Card',
  args: {
    repairRequest: {
      gid: '0000',
      title: 'Please repair this program!',
      body: 'body',
      account: factoryAccount(),
      photo_media_files: [
        {
          gid: '0000',
          content_type: 'image/jpg',
          url: 'https://picsum.photos/300',
          created_at: new Date().toISOString(),
          verified_at: new Date().toISOString(),
        },
        {
          gid: '0001',
          content_type: 'image/jpg',
          url: 'https://picsum.photos/300',
          created_at: new Date().toISOString(),
          verified_at: new Date().toISOString(),
        },
      ],
      created_at: new Date().toISOString(),
    },
  },
  template: (args) => <RepairRequestCard {...args} />,
};

export default meta;
