import Polyglot from 'node-polyglot';
import { api } from 'src/lib/api';
import { Account } from 'src/models/v1/account';
import {
  BusinessVerificationDocument,
  PrepareVerificationDocumentBody,
  PrepareVerificationDocumentResponse,
  VerifyVerificationDocumentBody,
  VerifyVerificationDocumentResponse,
} from 'src/models/v1/business';
import {
  PrepareMediaFileBody,
  PrepareMediaFileResponse,
  VerifyMediaFileBody,
  VerifyMediaFileResponse,
} from 'src/models/v1/media_file';
import { ActionError } from 'src/lib/errors';

export const ACCEPT_IMAGE = ['image/png', 'image/jpeg', 'image/tiff', 'image/gif', 'image/webp'].join(',');

export const blobFromFile: (file: File) => Promise<Blob> = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result as ArrayBuffer;
      resolve(new Blob([result], { type: file.type }));
    };
    reader.onerror = () => {
      reject('reader.onerror');
    };
    reader.readAsArrayBuffer(file);
  });
};

export const uploadMediaFile = async (image: Blob) => {
  const {
    media_file: { upload_url, gid },
  } = await api.dispatch<PrepareMediaFileBody, PrepareMediaFileResponse>(
    'POST',
    `/v1/media_files`,
    {},
    {
      media_file: {
        content_type: image.type,
      },
    },
  );

  try {
    const uploadResult = await fetch(upload_url, {
      method: 'PUT',
      body: image,
      headers: { 'Content-Type': image.type },
    });
    if (uploadResult.ok) {
      if (import.meta.env.DEV) {
        console.info('[File Upload]', 'PUT', uploadResult.url, 'responses successful');
      }
    } else {
      console.info('[File Upload]', 'PUT', uploadResult.url, 'responses status =', uploadResult.status);
      throw new FileUploadError(`${uploadResult.status}: ${uploadResult.statusText}`);
    }
  } catch (e) {
    if (e instanceof FileUploadError) {
      throw e;
    } else if (e instanceof Error) {
      console.info('[File Upload]', 'PUT', upload_url, e);
      throw new FileUploadError(e.message, { cause: e.cause });
    } else {
      console.info('[File Upload]', 'PUT', upload_url, e);
      throw e;
    }
  }

  const { media_file } = await api.dispatch<VerifyMediaFileBody, VerifyMediaFileResponse>(
    'POST',
    `/v1/media_files/${gid}/verify`,
  );
  return media_file;
};

export const uploadVerificationDocument = async (
  account: Account,
  kind: BusinessVerificationDocument['kind'],
  image: Blob,
) => {
  const { verification_document } = await api.dispatch<
    PrepareVerificationDocumentBody,
    PrepareVerificationDocumentResponse
  >(
    'POST',
    `/v1/accounts/${account.gid}/business/verification_documents`,
    {},
    {
      verification_document: {
        content_type: image.type,
        kind,
      },
    },
  );

  const upload_url = verification_document.upload_url;
  if (upload_url) {
    await fetch(upload_url, { method: 'PUT', body: image, headers: { 'Content-Type': image.type } });
    const result = await api.dispatch<VerifyVerificationDocumentBody, VerifyVerificationDocumentResponse>(
      'POST',
      `/v1/accounts/${account.gid}/business/verification_documents/${verification_document.gid}/verify`,
    );
    return result.verification_document;
  } else {
    throw new Error('uploading url is null');
  }
};

// 拡張子とMIMEタイプのマッピング
const EXTENSION_MIME_MAP = {
  '.png': 'image/png',
  '.jpg': 'image/jpeg',
  '.jpeg': 'image/jpeg',
  '.tiff': 'image/tiff',
  '.gif': 'image/gif',
  '.webp': 'image/webp',
} as const;

// MIMEタイプの一意な配列を作成（重複を除去）
const uniqueMimeTypes = [...new Set(Object.values(EXTENSION_MIME_MAP))];

// MimeType型を生成
type MimeType = (typeof uniqueMimeTypes)[number];

// MIMEタイプから表示名への変換
const mimeTypeNameMap: Record<MimeType, string> = Object.fromEntries(
  uniqueMimeTypes.map((mimeType) => [mimeType, mimeType.split('/')[1].toUpperCase()]),
) as Record<MimeType, string>;

// acceptの値から外れたファイルがドロップされた場合のメッセージ
export const getMimeTypeAlertMessage = (mimeTypes: string, i18n: Polyglot): string => {
  const types = mimeTypes.split(',').map((type) => type.trim().toLowerCase());
  const typeNames = types.map((type) => mimeTypeNameMap[type as MimeType]).join(' / ');
  return i18n.t(`validation.mime_type`, { name: typeNames });
};

// ファイル形式をチェックするユーティリティ関数
export const isValidFileType = (file: File, accept: string): boolean => {
  // acceptが空の場合は全て許可
  if (!accept) return true;

  // acceptの値を配列に分割
  const acceptedTypes = accept.split(',').map((type) => type.trim().toLowerCase());

  // 各acceptedTypeが有効な形式かチェック
  const validFormats = acceptedTypes.every((type) => {
    return (
      // MIMEタイプ（image/jpeg）
      /^[a-z]+\/[a-z0-9\-\+\.]+$/.test(type) ||
      // ワイルドカード（image/*）
      /^[a-z]+\/\*$/.test(type) ||
      // 拡張子（.jpg）
      /^\.[a-z0-9]+$/.test(type)
    );
  });

  if (!validFormats) return false;

  const fileType = file.type.toLowerCase();
  const fileExtension = `.${file.name.split('.').pop()?.toLowerCase()}`;

  return acceptedTypes.some((type) => {
    if (type.endsWith('/*')) {
      const baseType = type.split('/')[0];
      return fileType.startsWith(`${baseType}/`);
    }
    return type === fileType || type === fileExtension;
  });
};

export class FileUploadError extends ActionError {}
