import { defineFactory, FactoryBlueprint } from 'src/catalog/factories/factoryBuilder';
import { fakeGid } from 'src/catalog/factories/gid';
import { Account } from 'src/models/v1/account';

// MARK: base

const base: FactoryBlueprint<Account> = {
  gid: fakeGid(),
  display_name: 'Test Account',
  screen_name: null,
  bio: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.😄😊😍👍🙌🙏❤💯",
  kind: null,
  icon_url: null,
  banner_url: null,
  published_works_count: 0,
  tags: ['Test', 'Built by Factory'],
  followed: false,
};

// MARK: iconic

const iconic: Partial<Account> = {
  icon_url: 'https://picsum.photos/128',
  banner_url: 'https://picsum.photos/764/400',
  tags: ['Test', 'Built by Factory', 'Iconic'],
};

// MARK: very long dispay_name

const maxLengthDisplayName: Partial<Account> = {
  display_name: 'abcdefghijklmnopqrstuvwxyz123456',
};

// MARK: pro trait

const pro: Partial<Account> = {
  kind: 'pro',
  business_profile: {
    description: null,
    notes: null,
    cancellation_policy: null,
    shipping_method: null,
    support_areas: null,
    business_hours: null,
    regular_closed_days: null,
    tagline: 'We are Repairer.',
    support_phone: null,
    support_types: ['shipping', 'in_store_drop_off', 'on_site_pickup', 'on_site_repair'],
    accept_repair_request: false,
    address_hidden: false,
    area: '福岡県福岡市',
    address: {
      postal_code: '',
      state: '',
      city: '',
      town: '',
      line1: '',
      line1_kana: '',
      line2: '',
      line2_kana: '',
    },
    reception_message: null,
    reception_body_annotation: null,
  },
};

// MARK: caller trait

const caller: Partial<Account> = {
  display_name: 'Test Caller',
  gid: 'CALLER_GID',
};

// MARK: repairer trait

const repairer: Partial<Account> = {
  display_name: 'Test Repairer',
  kind: 'pro',
  gid: 'REPAIRER_GID',
  business_profile: {
    ...pro.business_profile!,
    tagline: 'We are Repairer.',
    area: 'Yokohama, Japan',
    reception_message: 'Thank you for considering our services. If you have any clothing you would like to have dyed, please feel free to contact us.',
  },
};

// MARK: factory

export const factoryAccount = defineFactory(base, {
  iconic,
  maxLengthDisplayName,
  pro,
  caller,
  repairer,
});
