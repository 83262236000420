import './QuotationView.css';

import { FC, useContext } from 'react';
import { AccountLinkCard } from 'src/components/features/account/AccountLinkCard';
import { QuotationBreakdown } from 'src/components/features/quotation/QuotationBreakdown';
import { QuotationCalcItem } from 'src/components/features/quotation/QuotationCalcItem';
import { LinkButton } from 'src/components/primitives/Button';
import { HtmlContent } from 'src/components/primitives/HtmlContent';
import { VStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { Timestamp } from 'src/components/primitives/Timestamp';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';
import { checkTicketRole, TicketContext } from 'src/lib/ticket';
import { Quotation } from 'src/models/v1/quotation';

export const QuotationView: FC<{ quotation: Quotation }> = ({ quotation }) => {
  const { i18n } = useI18n();
  const { me } = useCurrentStatus();
  const ticket = useContext(TicketContext);
  const shouldShowPayButton = checkTicketRole('caller') && quotation.status !== 'paid';

  return (
    <VStack align="stretch" className="quotation-view">
      {ticket?.accounts
        .filter((account) => account.gid != me?.gid)
        .map((account) => (
          <AccountLinkCard account={account} title={i18n.t(`attributes.ticket.participants.role.${account.role}`)} />
        ))}
      <QuotationBreakdown quotation={quotation} />
      {checkTicketRole('repairer') &&
        quotation.breakdown.application_fee_base &&
        quotation.breakdown.application_fee_amount &&
        quotation.breakdown.revenue_amount && (
          <div className="application-fee-field">
            <VStack align="start" spacing="sticky" w="full">
              <QuotationCalcItem
                label={i18n.t('attributes.quotation.application_fee_base')}
                amount={quotation.breakdown.application_fee_base}
              />
              <QuotationCalcItem
                label={i18n.t('attributes.quotation.application_fee')}
                amount={quotation.breakdown.application_fee_amount}
              />
              <QuotationCalcItem
                label={i18n.t('attributes.quotation.revenue')}
                amount={quotation.breakdown.revenue_amount}
                bold={true}
              />
              <Text className="hint-label" color="sub" align="center" w="full">
                {i18n.t('attributes.quotation.estimate_not_included')}
              </Text>
            </VStack>
          </div>
        )}
      <Text>
        <HtmlContent html={quotation.html.note} />
      </Text>
      <dl className="quotation-view-properties">
        <div className="quotation-view-property">
          <Text asChild weight="bold">
            <dt>{i18n.t('attributes.quotation.estimate_delivery_days')}</dt>
          </Text>
          <dd>
            <Text>
              {quotation.estimate_delivery_days ?? 0}{' '}
              {i18n.t(`unit.delivery_days.${quotation.delivery_days_unit}`, quotation.estimate_delivery_days ?? 0)}
            </Text>
          </dd>
        </div>
        <div className="quotation-view-property">
          <Text asChild weight="bold">
            <dt>{i18n.t('attributes.quotation.published_at')}</dt>
          </Text>
          <dd>{quotation.published_at && <Timestamp value={new Date(quotation.published_at)} format="year-date" />}</dd>
        </div>
        <div className="quotation-view-property">
          <Text asChild weight="bold">
            <dt>{i18n.t('attributes.quotation.expires_date')}</dt>
          </Text>
          <dd>
            <Timestamp value={new Date(quotation.expires_date)} format="year-date" />
          </dd>
        </div>
      </dl>
      {shouldShowPayButton && (
        <section className="actions">
          <LinkButton variant="outline" to={`/quotations/${quotation.gid}/print`} mr="auto" target="_blank">
            {i18n.t('action.print_quotation')}
          </LinkButton>
          <LinkButton variant="primary" to={`/quotations/${quotation.gid}/payment`} w="full">
            {i18n.t('action.review_and_pay')}
          </LinkButton>
        </section>
      )}
    </VStack>
  );
};
