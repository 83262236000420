import { checkCompleted as checkVerificationDocumentsCompleted } from 'src/components/features/business/BusinessVerificationDocumentForm';
import {
  Business,
  BusinessBankAccount,
  BusinessCompany,
  BusinessIndividual,
  BusinessIssue,
  BusinessProfile,
  BusinessRepresentative,
  BusinessVerificationDocument,
} from 'src/models/v1/business';

export type VerificationMethod =
  | 'individual_number_card'
  | 'drivers_license'
  | 'residence_card'
  | 'japanese_passport'
  | 'residence_certificate';

export const checkInVerificationDocumentUploadingProgress: (
  documents: Array<Pick<BusinessVerificationDocument, 'kind'>>,
) => VerificationMethod | undefined = (documents) => {
  const uploadedKindList = documents.map(({ kind }) => kind);
  if (
    uploadedKindList.includes('individual_number_card_front') ||
    uploadedKindList.includes('individual_number_card_back')
  ) {
    return 'individual_number_card';
  }
  if (uploadedKindList.includes('drivers_license_front') || uploadedKindList.includes('drivers_license_back')) {
    return 'drivers_license';
  }
  if (uploadedKindList.includes('residence_card_front') || uploadedKindList.includes('residence_card_back')) {
    return 'residence_card';
  }
  if (uploadedKindList.includes('japanese_passport')) {
    return 'japanese_passport';
  }
  if (uploadedKindList.includes('residence_certificate')) {
    return 'residence_certificate';
  }
  return undefined;
};

export type BusinessFormStep =
  | 'overview'
  | 'company_overview'
  | 'company_address'
  | 'person'
  | 'address'
  | 'verification_documents'
  | 'contact'
  | 'bank_account'
  | 'submit';

export type StepStatus = 'inputting' | 'completed' | 'has_issue';

// MARK: Review Functions

export const reviewOverviewStep: (
  business: Pick<Business, 'business_type'> & { profile: Pick<BusinessProfile, 'description'> },
) => StepStatus = (business) => {
  if (['individual', 'company'].includes(business.business_type) && business.profile.description) {
    return 'completed';
  }
  return 'inputting';
};

export const reviewCompanyOverviewStep: (business: {
  company: Pick<BusinessCompany, 'name' | 'name_kana' | 'phone' | 'tax_id'>;
}) => StepStatus = ({ company }) => {
  if (company.name && company.name_kana && company.phone && company.tax_id) {
    return 'completed';
  }
  return 'inputting';
};

export const reviewPersonalInformationStep: (
  business:
    | {
        business_type: 'individual';
        individual: Pick<BusinessIndividual, 'first_name' | 'first_name_kana' | 'last_name' | 'last_name_kana' | 'dob'>;
      }
    | {
        business_type: 'company';
        representative: Pick<
          BusinessRepresentative,
          'first_name' | 'first_name_kana' | 'last_name' | 'last_name_kana' | 'dob' | 'title'
        >;
      },
) => StepStatus = (business) => {
  switch (business.business_type) {
    case 'individual':
      if (
        business.individual.first_name &&
        business.individual.first_name_kana &&
        business.individual.last_name &&
        business.individual.last_name_kana &&
        business.individual.dob
      ) {
        return 'completed';
      }
      break;
    case 'company':
      if (
        business.representative.first_name &&
        business.representative.first_name_kana &&
        business.representative.last_name &&
        business.representative.last_name_kana &&
        business.representative.dob &&
        business.representative.title
      ) {
        return 'completed';
      }
      break;
  }
  return 'inputting';
};

export const reviewContactStep: (
  business:
    | {
        business_type: 'individual';
        individual: Pick<BusinessIndividual, 'phone' | 'email'>;
      }
    | {
        business_type: 'company';
        representative: Pick<BusinessRepresentative, 'phone' | 'email'>;
      },
) => StepStatus = (business) => {
  switch (business.business_type) {
    case 'individual':
      if (business.individual.phone && business.individual.email) {
        return 'completed';
      }
      break;
    case 'company':
      if (business.representative.phone && business.representative.email) {
        return 'completed';
      }
      break;
  }
  return 'inputting';
};

export const reviewAddress: (address: {
  postal_code: string;
  state: string;
  city: string;
  city_kana: string;
  town: string;
  town_kana: string;
  line1: string;
  line1_kana: string;
  line2: string;
  line2_kana: string;
}) => StepStatus = (address) => {
  if (
    address.postal_code &&
    address.state &&
    address.city &&
    address.city_kana &&
    address.town &&
    address.town_kana &&
    address.line1 &&
    address.line1_kana
  ) {
    if (address.line2 && address.line2_kana == '') {
      return 'inputting';
    }
    return 'completed';
  }
  return 'inputting';
};

export const reviewVerificationDocuments: (
  documents: Array<Pick<BusinessVerificationDocument, 'kind'>>,
) => StepStatus = (documents) => {
  const uploaded = documents.map(({ kind }) => kind);
  if (uploaded.includes('individual_number_card_front') && uploaded.includes('individual_number_card_back')) {
    return 'completed';
  }
  if (uploaded.includes('drivers_license_front') && uploaded.includes('drivers_license_back')) {
    return 'completed';
  }
  if (uploaded.includes('residence_card_front') && uploaded.includes('residence_card_back')) {
    return 'completed';
  }
  if (uploaded.includes('japanese_passport')) {
    return 'completed';
  }
  if (uploaded.includes('residence_certificate')) {
    return 'completed';
  }
  return 'inputting';
};

export const reviewBankAccount: (business: { bank_account: BusinessBankAccount }) => StepStatus = ({
  bank_account,
}) => {
  if (
    bank_account.bank_number &&
    bank_account.branch_number &&
    bank_account.account_number &&
    bank_account.account_holder_name
  ) {
    return 'completed';
  }
  return 'inputting';
};

const reviewFunctions: { [step in BusinessFormStep]: (business: Business) => StepStatus } = {
  overview: reviewOverviewStep,
  company_overview: (business) =>
    business.business_type == 'company' ? reviewCompanyOverviewStep(business) : 'has_issue',
  company_address: (business) => (business.business_type == 'company' ? reviewAddress(business.company) : 'has_issue'),
  person: reviewPersonalInformationStep,
  address: (business: Business) =>
    business.business_type == 'individual'
      ? reviewAddress(business.individual)
      : reviewAddress(business.representative),
  verification_documents: (business: Business) => reviewVerificationDocuments(business.verification_documents),
  contact: (business: Business) => reviewContactStep(business),
  bank_account: (business: Business) => reviewBankAccount(business),
  submit: (business: Business) => 'completed',
};

// MARK: checkBusinessSteps

const INDIVIDUAL_STEPS_MAP: Array<BusinessFormStep> = [
  'overview',
  'person',
  'address',
  'verification_documents',
  'contact',
  'bank_account',
  'submit',
];

const COMPANY_STEPS_MAP: Array<BusinessFormStep> = [
  'overview',
  'company_overview', // only company
  'company_address', // only company
  'person',
  'address',
  'verification_documents',
  'contact',
  'bank_account',
  'submit',
];

const ISSUE_STEP_MAP: Record<BusinessIssue['kind'], BusinessFormStep> = {
  review_overview: 'overview',
  review_company: 'company_overview',
  review_company_address: 'company_address',
  review_personal_information: 'person',
  review_contact: 'contact',
  review_address: 'address',
  review_verification_documents: 'verification_documents',
  unprocessable_bank_account: 'bank_account',
};

export const checkBusinessSteps: (
  business: Business,
  issues: Array<BusinessIssue>,
) => {
  completedSteps: Set<BusinessFormStep>;
  current: BusinessFormStep;
  openSteps: BusinessFormStep[];
} = (business, issues) => {
  const result = {
    completedSteps: new Set<BusinessFormStep>(),
    current: 'overview' as BusinessFormStep,
    openSteps: [] as BusinessFormStep[],
  };
  const issueSteps = new Set(issues.map((issue) => ISSUE_STEP_MAP[issue.kind]));

  (business.business_type === 'company' ? COMPANY_STEPS_MAP : INDIVIDUAL_STEPS_MAP).toReversed().forEach((step) => {
    let stepStatus = reviewFunctions[step](business);
    if (issueSteps.has(step)) {
      stepStatus = 'has_issue';
    }
    switch (stepStatus) {
      case 'completed':
        result.completedSteps.add(step);
        break;
      case 'inputting':
        result.current = step;
        break;
      case 'has_issue':
        result.openSteps.push(step);
        break;
    }
  });
  return result;
};
