import './AccountsGallery.css';

import { FC, ReactNode, useMemo } from 'react';
import { PagingNavigation } from 'src/components/control/PagingNavigation';
import { RuntimeErrorView } from 'src/components/error/RuntimeErrorView';
import { AccountCard } from 'src/components/features/account/AccountCard';
import { ContentEmpty } from 'src/components/features/ContentEmpty';
import { Spinner } from 'src/components/primitives/Spinner';
import { useApi } from 'src/lib/api';
import { ListAccountsResponse } from 'src/models/v1/account';

export const AccountsGallery: FC<{
  path: string;
  search?: { [name: string]: string };
  pageSize?: number;
  empty?: ReactNode;
  enablePagination?: boolean;
}> = ({ path, search: _search, pageSize, empty = <ContentEmpty />, enablePagination = true }) => {
  if (import.meta.env.SSR) {
    return (
      <div className="accounts-gallery" suppressHydrationWarning>
        <Spinner size="medium" />
      </div>
    );
  }

  const search = useMemo(() => {
    const search = Object.assign({}, _search);
    if (pageSize) {
      search.per = pageSize.toString();
    }
    return search;
  }, [_search, pageSize]);

  const { data, isLoading, error } = useApi<ListAccountsResponse>(path, search, { cache: true });
  const { accounts, pagination } = data || {};

  return (
    <div className="accounts-gallery" suppressHydrationWarning>
      {accounts?.map((account) => <AccountCard key={account.gid} account={account} />)}
      {(accounts?.length || 0) === 0 && (isLoading ? <Spinner size="medium" /> : empty)}
      {error && <RuntimeErrorView error={error} />}
      {enablePagination && pagination && (
        <PagingNavigation current={pagination.current} totalPages={pagination.pages} />
      )}
    </div>
  );
};
