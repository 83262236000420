import './Spinner.css';
import { ComponentPropsWithoutRef, FC } from 'react';

import { Primitive } from '@radix-ui/react-primitive';
import { BoxProps, styleBoxProps } from 'src/components/primitives/PrimitiveComponent';

type SpinnerSize = 'large' | 'medium' | 'small';

type PrimitiveSpinnerProps = ComponentPropsWithoutRef<typeof Primitive.div> & Omit<BoxProps, 'w' | 'h'>;
interface SpinnerProps extends PrimitiveSpinnerProps {
  size?: SpinnerSize;
}

export const Spinner: FC<SpinnerProps> = ({ size = 'large', ...originalProps }) => {
  const { className, ...props } = styleBoxProps<Omit<BoxProps, 'w' | 'h'>>(originalProps);
  return (
    <div role="alert" aria-busy className={`spinner spinner-size-${size} ${className}`} {...props}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
