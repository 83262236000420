import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router';
import { NavigationLayout } from 'src/components/Layout';
import { AboutProView } from 'src/components/promote/AboutProView';
import { PageLoader } from 'src/lib/api';
import { ApiError } from 'src/lib/errors';
import { useI18n } from 'src/lib/i18n';
import { Business } from 'src/models/v1/business';

type Data = {
  business?: Business;
};

export const AboutProPage: FC = () => {
  const { business } = (useLoaderData() as Data) || {};
  const { i18n } = useI18n();
  return (
    <NavigationLayout>
      <Helmet>
        <title>{`プロアカウントになって、リペア・リメイクを受注しませんか？ | ${i18n.t('nagaku')}`}</title>
        <meta
          property="og:title"
          content={`プロアカウントになって、リペア・リメイクを受注しませんか？ | ${i18n.t('nagaku')}`}
        />
      </Helmet>
      <AboutProView business={business} />
    </NavigationLayout>
  );
};

export const aboutProLoader: PageLoader = async ({ api }) => {
  try {
    return (await api.optionalFetch('/v1/accounts/me/business')) || null;
  } catch (error) {
    if (error instanceof ApiError && error.code == 'authentication_required') {
      return null;
    }
    throw error;
  }
};
