import { redirect } from 'react-router';
import { PageLoader } from 'src/lib/api';
import { Account } from 'src/models/v1/account';
import { Permission } from 'src/models/v1/permission';

export const currentAccountLoader: PageLoader = async ({ api }) => {
  const { account, permissions } = await api.fetch<{ account: Account | null; permissions: Array<Permission> }>(
    '/v1/status',
  );
  return { account, permissions };
};

export const checkUnauthenticationLoader: PageLoader = async ({ request, api }) => {
  const { authenticated } = await api.fetch<{ authenticated: boolean }>('/v1/status');
  if (authenticated) {
    const returnTo = new URL(request.url).searchParams.get('return_to');
    return redirect(returnTo?.startsWith('/') ? returnTo : '/');
  } else {
    return null;
  }
};
