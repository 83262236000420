import { factoryAccount } from 'src/catalog/factories/account';
import { Meta } from 'src/catalog/Story';
import { AskFormHeader } from 'src/components/features/ticket/AskFormHeader';

const meta: Meta<typeof AskFormHeader> = {
  title: 'Ask Form Header',
  args: {
    account: factoryAccount('repairer'),
  },
  template: (args) => <AskFormHeader {...args} />,
};

export default meta;
