import { FC, useCallback, useRef, useState } from 'react';
import { Outlet, useNavigate, useParams, useOutletContext } from 'react-router';
import { TagsInput, CustomEvent } from 'src/components/control/TagsInput';
import { NavigationLayout } from 'src/components/Layout';
import { LinkButton } from 'src/components/primitives/Button';
import { Heading } from 'src/components/primitives/Heading';
import { Icon } from 'src/components/primitives/Icon';
import { HStack } from 'src/components/primitives/Stack';
import { PageLoader } from 'src/lib/api';
import { ApiError } from 'src/lib/errors';
import { useI18n } from 'src/lib/i18n';
import { Tag } from 'src/models/v1/tag';

export type Data = {
  tag: Tag;
};

export const ExplorePage: FC = () => {
  const { i18n } = useI18n();
  // const { pathname } = useLocation();
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { typeface } = useParams();
  const [tag, setTag] = useState<Tag>();
  // const onNavigate = useCallback(
  //   (value: ExploreNavigationValue) => navigate(`/explore${NAVIGATION_TABLE[value]}`, { replace: true }),
  //   [],
  // );
  // const defaultValue = useMemo(() => {
  //   if (pathname.endsWith('/accounts')) return 'accounts';
  //   return 'works';
  // }, []);

  const onChange = useCallback(
    (event: CustomEvent<HTMLInputElement | HTMLButtonElement | null, { value: string[] }>) => {
      const values = [...event.currentTarget.value]; // Create a copy to avoid modifying the original
      const lastTag = values.length > 0 ? values[values.length - 1] : undefined;
      inputRef.current?.blur();
      if (lastTag) {
        navigate(`/explore/${encodeURIComponent(lastTag)}`, { preventScrollReset: true, replace: true });
      } else {
        navigate(`/explore`, { preventScrollReset: true, replace: true });
      }
    },
    [],
  );

  return (
    <NavigationLayout>
      {tag?.typeface ? (
        <Heading asChild>
          <HStack asChild align="center" justify="flex-start" spacing="sticky">
            <h1>
              <Icon name="tag" width="32px" height="32px" aria-label="#" /> {tag.typeface}
            </h1>
          </HStack>
        </Heading>
      ) : (
        <HStack align="center" justify="space-between">
          <Heading>{i18n.t('title.explore')}</Heading>
          <LinkButton to="/tags" variant="fill" size="small">
            タグ一覧へ
          </LinkButton>
        </HStack>
      )}
      <TagsInput
        ref={inputRef}
        icon="search"
        value={typeface ? [typeface] : undefined}
        placeholder={i18n.t('placeholder.tags')}
        onChange={onChange}
      />
      {/* <ExploreNavigation onValueChange={onNavigate} defaultValue={defaultValue} /> */}
      <Outlet context={{ setTag }} />
    </NavigationLayout>
  );
};

type ExploreContext = { setTag: React.Dispatch<React.SetStateAction<Tag | undefined>> };

export function useTag() {
  return useOutletContext<ExploreContext>();
}

export const exploreTagLoader: PageLoader = async ({ api, params }) => {
  const { typeface } = params;
  if (!typeface) {
    return { tag: { typeface: '' } };
  }
  try {
    return await api.fetch(`/v1/tags/${encodeURIComponent(typeface)}`, { include: 'statistics' });
  } catch (error: unknown) {
    if (error instanceof ApiError && error.code == 'resource_not_found') {
      return { tag: { typeface } };
    }
  }
};
