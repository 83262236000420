import './BusinessProfileView.css';

import { DetailedHTMLProps, FC, PropsWithChildren } from 'react';
import { BusinessProfile } from 'src/models/v1/business';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';
import { Card } from 'src/components/primitives/Card';
import { Link } from 'react-router';
import { Icon } from 'src/components/primitives/Icon';

export const BusinessProfileView: FC<{ businessProfile: BusinessProfile; accountGid: string }> = ({
  businessProfile,
  accountGid,
}) => {
  return (
    <article className="business-profile-view">
      <Text asChild className="description">
        <p>{businessProfile.description}</p>
      </Text>
      <Section name="shipping_method">
        <Text asChild>
          <p>{businessProfile.shipping_method}</p>
        </Text>
      </Section>
      <Section name="support_areas">
        <Text asChild>
          <p>{businessProfile.support_areas}</p>
        </Text>
      </Section>
      {businessProfile.business_hours && (
        <Section name="business_hours">
          <Text asChild>
            <p>{businessProfile.business_hours}</p>
          </Text>
        </Section>
      )}
      {businessProfile.regular_closed_days && (
        <Section name="regular_closed_days">
          <Text asChild>
            <p>{businessProfile.regular_closed_days}</p>
          </Text>
        </Section>
      )}
      <Section name="notes">
        <Text asChild>
          <p>{businessProfile.notes}</p>
        </Text>
      </Section>
      <Section name="cancellation_policy">
        <Text asChild>
          <p>{businessProfile.cancellation_policy}</p>
        </Text>
      </Section>
      <Card asChild className="guide" borderRadius="lg">
        <section>
          <GuideItem
            to={`/accounts/${accountGid}/jp_act_on_specified_commercial_transaction`}
            name="label.japanese_act_on_specified_transactions"
          />
          <GuideItem to={`/accounts/${accountGid}/privacy_policy`} name="label.privacy_policy" />
        </section>
      </Card>
    </article>
  );
};

const Section: FC<
  PropsWithChildren<{ name: string } & DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>>
> = ({ name, className, children, ...props }) => {
  const { i18n } = useI18n();
  return (
    <section className={`section ${className || ''}`} {...props}>
      <Text asChild variant="title" size="medium" weight="bold">
        <h2>{i18n.t(`attributes.business_profile.${name}`)}</h2>
      </Text>
      {children}
    </section>
  );
};

const GuideItem: FC<{ to: string; name: string }> = ({ to, name }) => {
  const { i18n } = useI18n();

  return (
    <Link to={to} className="guide-item">
      <Text weight="bold">{i18n.t(name)}</Text>
      <Icon name="keyboard_arrow_right" />
    </Link>
  );
};
