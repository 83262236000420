import { FC, PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';
import { onUpdateEmail } from 'src/actions/email';
import { Form } from 'src/components/control/Form';
import { FormButton } from 'src/components/control/FormButton';
import { InputField } from 'src/components/control/InputField';
import { ConfirmationButton } from 'src/components/features/ConfirmationButton';
import { HStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { useEditable } from 'src/lib/form';
import { useI18n } from 'src/lib/i18n';

export const EmailSettingForm: FC = () => {
  const { i18n } = useI18n();
  return (
    <Form
      onSubmit={onUpdateEmail({})}
      defaultValues={{ account: { email: undefined, current_password: undefined } }}
      defaultRequired
      mode="all"
    >
      <InputField
        id="email_update_new_email"
        name="account.email"
        type="email"
        placeholder="nagaku@example.com"
        options={{ required: true }}
      />
      <InputField name="account.current_password" type="password" options={{ required: true }} />
      <HStack justify="space-between">
        <FormButton size="small">{i18n.t('action.save')}</FormButton>
        <DiscardConfirmationButton>
          <Text variant="label" color="note" weight="bold">
            {i18n.t('action.close_without_saving')}
          </Text>
        </DiscardConfirmationButton>
      </HStack>
      <Text>{i18n.t('guide.verify_new_email')}</Text>
    </Form>
  );
};

const DiscardConfirmationButton: FC<PropsWithChildren> = ({ children }) => {
  const {
    formState: { isDirty },
  } = useFormContext();
  const { onEndEdit } = useEditable();
  return (
    <ConfirmationButton
      scope="confirmation.account_setting.discard"
      variant="ghost"
      isConfirm={!isDirty}
      onConfirm={onEndEdit}
    >
      {children}
    </ConfirmationButton>
  );
};
