import './EmptyUserPosts.css';

import { FC } from 'react';
import { LinkButton } from 'src/components/primitives/Button';
import { Icon } from 'src/components/primitives/Icon';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';

import { VStack } from '../primitives/Stack';

export const EmptyUserPosts: FC<{ isMe: boolean }> = ({ isMe }) => {
  const { i18n } = useI18n();
  return (
    <div className="user-posts-container">
      <div className="circle-icon-container">
        <Icon name="chair" fontSize="large" className="chair-icon" />
      </div>
      <VStack spacing="sm" m="none">
        <Text variant="title" size="medium" weight="bold">
          {i18n.t('guide.no_works_yet')}
        </Text>
        {isMe && (
          <>
            <Text size="medium">{i18n.t('guide.post_a_work')}</Text>
            <LinkButton to="/works/new" variant="primary" size="default">
              {i18n.t('action.post')}
            </LinkButton>
          </>
        )}
      </VStack>
    </div>
  );
};
