import { defineFactory, FactoryBlueprint } from 'src/catalog/factories/factoryBuilder';
import { fakeGid } from 'src/catalog/factories/gid';
import { MediaFile } from 'src/models/v1/media_file';

// MARK: base

const base: FactoryBlueprint<MediaFile> = {
  gid: fakeGid(),
  content_type: 'image/jpeg',
  url: () => `https://picsum.photos/300?t=${Math.floor(Math.random() * 100)}`,
  created_at: new Date().toISOString(),
  verified_at: new Date().toISOString(),
};

// MARK: factory

export const factoryMediaFile = defineFactory(base, {});
