import './AccountLinkCard.css';

import { FC, ReactNode } from 'react';
import { Link } from 'react-router';
import { Avatar } from 'src/components/primitives/Avatar';
import { Account } from 'src/models/v1/account';

export const AccountLinkCard: FC<{
  account: Account;
  title?: ReactNode;
  annotation?: ReactNode;
}> = ({ account, title, annotation }) => {
  return (
    <Link
      className="account-link-card"
      to={account.screen_name ? `/${account.screen_name}` : `/accounts/${account.gid}`}
    >
      <Avatar className="icon" account={account} src={account.icon_url || undefined} size="md" />
      {title && <div className="title">{title}</div>}
      <div className="display-name">{account.display_name}</div>
      {annotation && <div className="annotation">{annotation}</div>}
    </Link>
  );
};
