import './Error.css';

import { FC } from 'react';
import { isRouteErrorResponse, Link } from 'react-router';
import { NotFound } from 'src/components/NotFound';
import { LinkButton } from 'src/components/primitives/Button';
import { HStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { ApiError, ApiErrorBody_Maintenance, NotFoundError } from 'src/lib/errors';
import { useI18n } from 'src/lib/i18n';
import { BoxProps } from 'src/components/primitives/PrimitiveComponent';

export const ErrorDescription: FC<BoxProps & { error: unknown; shorthand?: boolean; m?: string }> = ({
  error,
  shorthand = false,
  ...originalProps
}) => {
  if (import.meta.env.SSR) {
    console.error('[ErrorDescription]', error);
    if (error instanceof ApiError) {
      console.log('[ErrorDescription] url =', error.response.url);
    }
  }

  if (error instanceof NotFoundError) {
    return <NotFound />;
  }

  if (isRouteErrorResponse(error)) {
    switch (error.status) {
      case 401:
        return <ErrorDescription401 shorthand={shorthand} />;
      case 403:
        return <ErrorDescription403 code={error.data.error} {...originalProps} />;
      case 404:
        return <NotFound />;
      case 503:
        return <ErrorDescriptionMaintenance body={error.data} {...originalProps} />;
      default:
        break;
    }
  }
  // Failback
  return <ErrorDescription500 {...originalProps} />;
};

const ErrorDescription401: FC<{ shorthand: boolean }> = ({ shorthand }) => {
  const { i18n } = useI18n();
  return (
    <>
      <ErrorDescription401Body />
      {shorthand == false && (
        <>
          <Text asChild align="start" variant="body">
            <p>{i18n.t('guide.navigate_customer_support')}</p>
          </Text>
          <HStack w="full" justify="stretch">
            <LinkButton variant="outline" to="/register">
              {i18n.t('action.signup')}
            </LinkButton>
            <LinkButton variant="fill" to="/login">
              {i18n.t('action.login')}
            </LinkButton>
          </HStack>
        </>
      )}
    </>
  );
};

const ErrorDescription401Body: FC = () => {
  const { i18n, locale } = useI18n();
  switch (locale) {
    case 'ja':
      return (
        <Text asChild align="start" variant="body">
          <p>
            {i18n.t('guide.unauthorized')}
            <Link to="/login">ログイン</Link>または<Link to="/register">新規登録</Link>をお試しください。
          </p>
        </Text>
      );
    default:
      return (
        <Text asChild align="start" variant="body">
          <p>
            {i18n.t('guide.unauthorized')}
            Please try <Link to="/login">logging in</Link> or <Link to="/register">registering</Link> for a new account.
          </p>
        </Text>
      );
  }
};

const ErrorDescription403: FC<{ code: string }> = ({ code }) => {
  return (
    <>
      <Text asChild variant="body">
        <p>このページは利用できません。</p>
      </Text>
      {code == 'invalid_api_key' && (
        <Text asChild variant="body">
          <p>APIキーが不正です。</p>
        </Text>
      )}
    </>
  );
};

const ErrorDescription500: FC<BoxProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...props
}) => {
  return (
    <>
      <Text asChild variant="headline" weight="bold">
        <h1>なにかがおかしいようです</h1>
      </Text>
      <Text asChild variant="body">
        <p>トラブルが発生したため、ページを表示することができません。しばらく待ってからやり直してみてください。</p>
      </Text>
      <Text asChild variant="body">
        <p>どうしても解決しない場合、サポートにお問い合わせください。</p>
      </Text>
    </>
  );
};

const ErrorDescriptionMaintenance: FC<{ body: ApiErrorBody_Maintenance }> = ({ body }) => {
  return (
    <>
      <svg className="error-symbol" focusable="false" aria-hidden="true" viewBox="0 0 24 24">
        <path d="m19.93 8.35-3.6 1.68L14 7.7V6.3l2.33-2.33 3.6 1.68c.38.18.82.01 1-.36.18-.38.01-.82-.36-1l-3.92-1.83c-.38-.18-.83-.1-1.13.2L13.78 4.4c-.18-.24-.46-.4-.78-.4-.55 0-1 .45-1 1v1H8.82C8.34 4.66 6.96 3.75 5.4 4.06c-1.17.23-2.13 1.19-2.35 2.36-.25 1.34.4 2.54 1.43 3.16L7.08 18H5.5c-.83 0-1.5.67-1.5 1.5S4.67 21 5.5 21h10c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-2.12L8.41 8.77c.17-.24.31-.49.41-.77H12v1c0 .55.45 1 1 1 .32 0 .6-.16.78-.4l1.74 1.74c.3.3.75.38 1.13.2l3.92-1.83c.38-.18.54-.62.36-1-.18-.37-.62-.54-1-.36M6 8c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1"></path>
      </svg>
      <Text asChild variant="headline" weight="bold">
        <h1>メンテナンス中: {body.title}</h1>
      </Text>
      <Text asChild variant="body">
        <p>現在メンテナンス中です。</p>
      </Text>
      {body.note && (
        <Text asChild variant="body">
          <p>{body.note}</p>
        </Text>
      )}
    </>
  );
};
