import { FC, PropsWithChildren, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRevalidator } from 'react-router';
import { RegisterAddressForm } from 'src/components/features/address/RegisterAddressForm';
import { ConfirmationButton } from 'src/components/features/ConfirmationButton';
import { Button } from 'src/components/primitives/Button';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';
import { Address } from 'src/models/v1/address';

export const RegisterAddressButton: FC<{ onRegister?: (address: Address) => void }> = ({ onRegister }) => {
  const { i18n } = useI18n();
  const [isOpen, setOpen] = useState<boolean>(false);
  const { revalidate } = useRevalidator();

  if (isOpen) {
    return (
      <RegisterAddressForm
        onRegister={async (address) => {
          onRegister?.(address);
          await revalidate();
          setOpen(false);
        }}
      >
        <DiscardConfirmationButton onClose={() => setOpen(false)}>
          <Text variant="label" color="note" weight="bold">
            {i18n.t('action.close_without_saving')}
          </Text>
        </DiscardConfirmationButton>
      </RegisterAddressForm>
    );
  } else {
    return (
      <Button variant="fill" size="small" mr="auto" onClick={() => setOpen(true)}>
        {i18n.t('action.register_address')}
      </Button>
    );
  }
};

const DiscardConfirmationButton: FC<PropsWithChildren<{ onClose: () => void }>> = ({ onClose, children }) => {
  const {
    formState: { isDirty },
    reset,
  } = useFormContext();

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <ConfirmationButton
      scope="confirmation.account_setting.discard"
      variant="ghost"
      isConfirm={!isDirty}
      onConfirm={handleClose}
    >
      {children}
    </ConfirmationButton>
  );
};
