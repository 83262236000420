import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router';
import { AddressManagementView } from 'src/components/features/address/AddressManagementView';
import { BackableBreadcrumb } from 'src/components/features/BackableBreadcrumb';
import { NavigationLayout } from 'src/components/Layout';
import { PageLoader } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { Address } from 'src/models/v1/address';

export const AddressesSettingPage: FC = () => {
  const { i18n } = useI18n();
  const { addresses } = useLoaderData<{ addresses: Array<Address> }>();
  return (
    <NavigationLayout>
      <Helmet>
        <title>{`${i18n.t('title.addresses_setting')} · ${i18n.t('title.setting')} | ${i18n.t('nagaku')}`}</title>
        <meta
          property="og:title"
          content={`${i18n.t('title.addresses_setting')} · ${i18n.t('title.setting')} | ${i18n.t('nagaku')}`}
        />
        <meta name="description" content="" />
        <meta property="og:description" content="" />
      </Helmet>
      <BackableBreadcrumb homePath="/settings" currentPath="/settings/addresses">
        {i18n.t('title.addresses_setting')}
      </BackableBreadcrumb>
      <AddressManagementView addresses={addresses} />
    </NavigationLayout>
  );
};

export const addressesSettingLoader: PageLoader = async ({ api }) => {
  return await api.fetch(`/v1/accounts/me/addresses`);
};
