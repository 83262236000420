import './ImageViewer.css';

import * as RadixDialog from '@radix-ui/react-dialog';
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { Icon } from 'src/components/primitives/Icon';
import { MediaImage } from 'src/components/primitives/MediaImage';
import { Spinner } from 'src/components/primitives/Spinner';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';

export const ImageViewer: FC<{
  images: Array<string>;
  currentImage: string | undefined;
  setCurrentImage: Dispatch<SetStateAction<string | undefined>>;
}> = ({ images, currentImage, setCurrentImage }) => {
  const [validatedCurrentImage, currentIndex] = useMemo(() => {
    if (images.find((elem) => elem == currentImage)) {
      const currentIndex = images.findIndex((elem) => elem == currentImage);
      return [currentImage, currentIndex];
    } else {
      return [undefined, undefined];
    }
  }, [currentImage]);
  const onPrev = () => {
    if (currentIndex === undefined) return;
    setCurrentImage(images[(currentIndex - 1 + images.length) % images.length]);
  };
  const onNext = () => {
    if (currentIndex === undefined) return;
    setCurrentImage(images[(currentIndex + 1 + images.length) % images.length]);
  };
  const onClose = () => {
    setCurrentImage(undefined);
  };
  const { i18n } = useI18n();
  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key == 'ArrowRight') return onNext();
      if (event.key == 'ArrowLeft') return onPrev();
      if (event.key == 'Escape') return onClose();
    },
    [currentImage],
  );
  useEffect(() => {
    if (validatedCurrentImage) {
      document.addEventListener('keydown', onKeyDown);
      return () => document.removeEventListener('keydown', onKeyDown);
    }
  }, [currentImage]);

  return (
    <RadixDialog.Root open={!!validatedCurrentImage}>
      <RadixDialog.Portal>
        <RadixDialog.Overlay className="image-viewer-background-overlay" onClick={onClose} />
        <RadixDialog.Content className="image-viewer-container" aria-describedby={undefined}>
          <RadixDialog.DialogTitle />
          <MediaImage
            src={validatedCurrentImage}
            size="c2048"
            preloadedSize={'c2048'}
            alt={`Image ${currentIndex}`}
            className="image"
            fallback={
              <div className="image-viewer-fallback">
                <Spinner />
              </div>
            }
          />
          <RadixDialog.Close asChild>
            <button className="control-icon close-icon" aria-label={i18n.t('action.close')} onClick={onClose}>
              <Icon name="close" />
            </button>
          </RadixDialog.Close>
          <button className="control-icon left-arrow" onClick={onPrev} aria-label={i18n.t(`action.previous_image`)}>
            <Icon name="keyboard_arrow_left" fontSize="large" />
          </button>
          <button className="control-icon right-arrow" onClick={onNext} aria-label={i18n.t(`action.next_image`)}>
            <Icon name="keyboard_arrow_right" fontSize="large" />
          </button>
          <Text color="invert" className="index">
            {(currentIndex || 0) + 1} / {images.length}
          </Text>
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};
