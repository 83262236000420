import './DropdownMenu.css';

import { forwardRef, ForwardRefExoticComponent } from 'react';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

const Content: ForwardRefExoticComponent<DropdownMenu.DropdownMenuContentProps & React.RefAttributes<HTMLDivElement>> =
  forwardRef(({ className, ...props }, forwardedRef) => {
    return <DropdownMenu.Content className={`dropdown-menu-content ${className}`} {...props} ref={forwardedRef} />;
  });

export default {
  ...DropdownMenu,
  Content,
};
