import './CheckField.css';
import { FC, KeyboardEventHandler, PropsWithChildren, useCallback, useContext } from 'react';
import { FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { AriaAttributesContext } from 'src/lib/a11y';

export const CheckField: FC<PropsWithChildren<{ name?: string; options?: RegisterOptions<FieldValues, string> }>> = ({
  name = 'confirmation',
  children,
  options,
}) => {
  const { register, watch, setValue } = useFormContext<FieldValues>();
  const a11yAttributes = useContext(AriaAttributesContext);

  const handleKeyDown: KeyboardEventHandler<HTMLLabelElement> = useCallback(
    (event) => {
      if (event.code == 'Space' || event.code == 'Enter') {
        setValue(name, !watch(name), { shouldValidate: true });
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [setValue, watch],
  );
  return (
    <label className="check-field" role="checkbox" htmlFor={name} tabIndex={0} onKeyDown={handleKeyDown}>
      <input
        className="check-field-input"
        type="checkbox"
        id={name}
        {...register(name, { ...options })}
        {...a11yAttributes}
      />
      {children}
    </label>
  );
};
