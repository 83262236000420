import { FC, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, redirect, useLoaderData } from 'react-router';
import { AccountBar } from 'src/components/features/AccountBar';
import { BackableBreadcrumb } from 'src/components/features/BackableBreadcrumb';
import { NavigationLayout } from 'src/components/Layout';
import { Note } from 'src/components/primitives/Note';
import { VStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { PageLoader } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { Account } from 'src/models/v1/account';
import { JpActOnSpecifiedCommercialTransaction } from 'src/models/v1/jp_act_on_specified_commercial_transaction';

type Data = {
  account: Account;
  jp_act_on_specified_commercial_transaction: JpActOnSpecifiedCommercialTransaction;
};

export const AccountJpActOnSpecifiedCommercialTransactionPage: FC = () => {
  const { i18n } = useI18n();
  const { account, jp_act_on_specified_commercial_transaction } = useLoaderData() as Data;
  return (
    <NavigationLayout>
      <Helmet>
        <title>{`${i18n.t('title.jp_act_on_specified_commercial_transaction')} | ${account.display_name} | ${i18n.t('nagaku')}`}</title>
        <meta name="robots" content="noindex" />
        <link
          rel="canonical"
          href={`https://nagaku.com/${account.screen_name || `accounts/${account.gid}`}/jp_act_on_specified_commercial_transaction`}
        />
      </Helmet>
      <BackableBreadcrumb
        homePath={`/accounts/${account.gid}`}
        currentPath={`/accounts/${account.gid}/jp_act_on_specified_commercial_transaction`}
      >
        {i18n.t('title.jp_act_on_specified_commercial_transaction')}
      </BackableBreadcrumb>
      <AccountBar account={account} avatarSize="md" />
      {jp_act_on_specified_commercial_transaction.corporate_name && (
        <Section name="corporate_name">
          <Text asChild>
            <p style={{ whiteSpace: 'pre-wrap' }}>{jp_act_on_specified_commercial_transaction.corporate_name}</p>
          </Text>
        </Section>
      )}
      <Section name="representative_name">
        <Text asChild>
          <p style={{ whiteSpace: 'pre-wrap' }}>{jp_act_on_specified_commercial_transaction.representative_name}</p>
        </Text>
      </Section>
      <Section name="address">
        <Text asChild>
          <p style={{ whiteSpace: 'pre-wrap' }}>{jp_act_on_specified_commercial_transaction.display_address}</p>
        </Text>
        {jp_act_on_specified_commercial_transaction.address_hidden && <HiddenNote phrase="address" account={account} />}
      </Section>
      <Section name="phone">
        <Text asChild>
          <p style={{ whiteSpace: 'pre-wrap' }}>{jp_act_on_specified_commercial_transaction.display_phone}</p>
        </Text>
        {jp_act_on_specified_commercial_transaction.phone_hidden && <HiddenNote phrase="phone" account={account} />}
      </Section>
      <Section name="business_hours_and_closed_days">
        <Text asChild>
          <p style={{ whiteSpace: 'pre-wrap' }}>
            {jp_act_on_specified_commercial_transaction.business_hours_and_closed_days}
          </p>
        </Text>
      </Section>
      <Section name="pricing">
        <Text asChild>
          <p style={{ whiteSpace: 'pre-wrap' }}>{jp_act_on_specified_commercial_transaction.pricing}</p>
        </Text>
      </Section>
      <Section name="additional_fees">
        <Text asChild>
          <p style={{ whiteSpace: 'pre-wrap' }}>{jp_act_on_specified_commercial_transaction.additional_fees}</p>
        </Text>
      </Section>
      <Section name="payment_method">
        <Text asChild>
          <p style={{ whiteSpace: 'pre-wrap' }}>ナガクが提供する支払方法</p>
        </Text>
      </Section>
      <Section name="payment_timing">
        <Text asChild>
          <p style={{ whiteSpace: 'pre-wrap' }}>お見積りが確定し、ご注文時に決済となります。</p>
        </Text>
      </Section>
      <Section name="estimated_delivery">
        <Text asChild>
          <p style={{ whiteSpace: 'pre-wrap' }}>{jp_act_on_specified_commercial_transaction.estimated_delivery}</p>
        </Text>
      </Section>
      <Section name="cancellation_policy">
        <Text asChild>
          <p style={{ whiteSpace: 'pre-wrap' }}>{jp_act_on_specified_commercial_transaction.cancellation_policy}</p>
        </Text>
      </Section>
      <Section name="after_service_policy">
        <Text asChild>
          <p style={{ whiteSpace: 'pre-wrap' }}>{jp_act_on_specified_commercial_transaction.after_service_policy}</p>
        </Text>
      </Section>
    </NavigationLayout>
  );
};

const Section: FC<PropsWithChildren<{ name: string }>> = ({ name, children }) => {
  const { i18n } = useI18n();
  return (
    <VStack asChild spacing="md" align="stretch">
      <section>
        <Text asChild size="medium" variant="title" weight="bold">
          <h2>{i18n.t(`attributes.jp_act_on_specified_commercial_transaction.${name}`)}</h2>
        </Text>
        {children}
      </section>
    </VStack>
  );
};

const HiddenNote: FC<{ phrase: string; account: Account }> = ({ phrase, account }) => {
  const { i18n } = useI18n();

  switch (i18n.locale()) {
    case 'ja':
      return (
        <Note.Root>
          <Note.Description>
            本サービスを運営しているナガク株式会社の
            {i18n.t(`label.jp_act_on_specified_commercial_transaction.hidden_${phrase}`)}を記載しています。
            {account.display_name}に関するお問い合わせは「
            <Link to={`/accounts/${account.gid}/inquiry`}>お問い合わせページ</Link>
            」からお願いいたします。
          </Note.Description>
        </Note.Root>
      );
    case 'en':
      return (
        <Note.Root mt="sm">
          <Note.Description>
            This page is the {i18n.t(`label.jp_act_on_specified_commercial_transaction.hidden_${phrase}`)} of the
            company that operates this service, NAGAKU Inc. For inquiries about {account.display_name}, please visit the{' '}
            <Link to={`/accounts/${account.gid}/inquiry`}>Inquiry Page</Link>.
          </Note.Description>
        </Note.Root>
      );
  }
  return null;
};

export const accountJpActOnSpecifiedCommercialTransactionLoader: PageLoader = async ({ request, params, api }) => {
  const { gid, screen_name } = params;
  const { account } = await api.fetch<{ account: Account }>(`/v1/accounts/${gid || screen_name}`, {
    include: 'business_profile',
  });
  if (account.screen_name && !screen_name) {
    return redirect(request.url.replace(`/accounts/${account.gid}`, `/${account.screen_name}`), { status: 301 });
  }
  return {
    account,
    ...(await api.fetch(`/v1/accounts/${account.gid}/business/jp_act_on_specified_commercial_transaction`)),
  };
};
