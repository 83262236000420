import { factoryWork } from 'src/catalog/factories/work';
import { Meta } from 'src/catalog/Story';
import { LikeSwitchBar } from 'src/components/features/LikeSwitchBar';

const meta: Meta<typeof LikeSwitchBar> = {
  title: 'Like Switch Bar',
  args: {
    gid: factoryWork().gid,
    liked: factoryWork().liked,
    likesCount: factoryWork().likes_count,
    returnTo: '/',
  },
  template: (args) => <LikeSwitchBar {...args} />,
};

export default meta;
