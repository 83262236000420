import './TicketCommentAttachmentsPreview.css';

import { FC } from 'react';
import { QuotationSummaryCard } from 'src/components/features/quotation/QuotationSummaryCard';
import { IconButton } from 'src/components/primitives/IconButton';
import { MediaImage } from 'src/components/primitives/MediaImage';
import { Spinner } from 'src/components/primitives/Spinner';
import { HStack, VStack } from 'src/components/primitives/Stack';
import { useApi } from 'src/lib/api';
import { Quotation, RetrieveQuotationResponse } from 'src/models/v1/quotation';
import { Estimate, RetrieveEstimateResponse } from 'src/models/v1/estimate';
import { TicketCommentAttachment } from 'src/models/v1/ticket';
import { attachmentsByType } from 'src/lib/ticketComment';
import { EstimateSummaryCard } from 'src/components/features/estimate/EstimateSummaryCard';
export const TicketCommentAttachmentsPreview: FC<{
  attachments: Array<TicketCommentAttachment>;
  onDelete: (targetGid: string) => void;
}> = ({ attachments, onDelete }) => {
  const { quotations, estimates, files } = attachmentsByType(attachments);
  return (
    <VStack className="ticket-comment-attachments-preview" align="start" spacing="xs" w="full">
      {quotations.length !== 0 && (
        <HStack className="inner-stack" spacing="sm">
          {quotations.map((quotation) => (
            <div key={quotation.gid} className="attachment-container">
              <QuotationCardPreview gid={quotation.gid} />
              <IconButton className="delete" icon={'close'} onClick={() => onDelete(quotation.gid)} />
            </div>
          ))}
        </HStack>
      )}
      {estimates.length !== 0 && (
        <HStack className="inner-stack" spacing="sm">
          {estimates.map((estimate) => (
            <div key={estimate.gid} className="attachment-container">
              <EstimateCardPreview gid={estimate.gid} />
            </div>
          ))}
        </HStack>
      )}
      {files.length !== 0 && (
        <HStack className="inner-stack" justify="start" spacing="sm">
          {files.map((attachment) => (
            <div key={attachment.gid} className="attachment-container">
              <MediaImage className="ticket-comment-attachments-preview-file" src={attachment.url} size="512x512" />
              <IconButton className="delete" icon={'close'} onClick={() => onDelete(attachment.gid)} />
            </div>
          ))}
        </HStack>
      )}
    </VStack>
  );
};

const QuotationCardPreview: FC<{ gid: Quotation['gid'] }> = ({ gid }) => {
  const { data, isLoading } = useApi<RetrieveQuotationResponse>(`/v1/quotations/${gid}`);
  const { quotation } = data || {};
  if (isLoading) return <Spinner />;
  if (quotation)
    return (
      <QuotationSummaryCard
        className="ticket-comment-attachments-preview-quotation"
        quotation={quotation}
        isAttached={true}
      />
    );
};

const EstimateCardPreview: FC<{ gid: Estimate['gid'] }> = ({ gid }) => {
  const { data, isLoading } = useApi<RetrieveEstimateResponse>(`/v1/estimates/${gid}`);
  const { estimate } = data || {};
  if (isLoading) return <Spinner />;
  if (estimate)
    return (
      <EstimateSummaryCard
        className="ticket-comment-attachments-preview-estimate"
        estimate={estimate}
        isAttached={true}
      />
    );
};
