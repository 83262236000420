import * as Sentry from "@sentry/react";

/**
 * Base error class for action-related errors
 */
export class ActionError extends Error {}

/**
 * Error class used for 404 Not Found errors
 */
export class NotFoundError extends ActionError {}

/**
 * Error class used to prevent an action from executing
 */
export class PreventAction extends Error {}

export type ApiErrorBody = ApiErrorBodyBase | ApiErrorBody_Maintenance;

export type ApiErrorBodyBase = {
  error:
    | 'invalid_or_expired_token'
    | 'missing_refresh_token'
    | 'authentication_required'
    | 'expired_or_completed_process'
    | 'record_invalid'
    | 'resource_not_found'
    | 'route_not_found'
    | 'unknown_error';
  message: string;
};

export type ApiErrorBody_Maintenance = {
  error: 'service_now_maintenance';
  message: string;
  title: string;
  note: string | null;
};

/**
 * API error class for handling API-specific errors
 */
export class ApiError extends ActionError {
  response: Response;
  body: ApiErrorBody;
  
  constructor(response: Response, body: ApiErrorBody) {
    super(body.message || body.error);
    this.body = body;
    this.response = response;
  }
  
  get code() {
    return this.body.error;
  }
  
  inspect() {
    return {
      error: this.message,
      status: this.response.status,
      url: this.response.url,
      body: this.body,
    };
  }
}

/**
 * Captures an error with custom fingerprinting in Sentry based on error type.
 * This helps to split different error types into separate Sentry issues.
 * 
 * @param error The error to capture
 * @param actionKey The key of the action where the error occurred
 */
export function captureErrorWithCustomFingerprint(error: unknown, actionKey: string): void {
  Sentry.withScope((scope) => {
    // Set tags for better filtering in Sentry
    scope.setTag('action_key', actionKey);
    
    // Check if error is an ApiError by using instanceof
    if (error instanceof ApiError) {
      // For ApiError, include the error code in the fingerprint
      scope.setFingerprint(['{{ default }}', 'ApiError', error.code]);
      
      // Add additional context for ApiError
      scope.setContext('api_error', {
        code: error.code,
        status: error.response.status,
        url: error.response.url,
      });
    } else if (error instanceof ActionError) {
      // For other ActionError types, use the error constructor name
      scope.setFingerprint(['{{ default }}', 'ActionError', error.constructor.name]);
    } else {
      // For unknown errors, use a generic fingerprint
      scope.setFingerprint(['{{ default }}', 'UnknownError']);
    }
    
    Sentry.captureException(error);
  });
}
