import { FC, PropsWithChildren } from 'react';
import { onUpdateBusinessBankAccount } from 'src/actions/business';
import { BankAccountField } from 'src/components/control/BankAccountField';
import { Form } from 'src/components/control/Form';
import { Business } from 'src/models/v1/business';

export const BusinessBankAccountForm: FC<PropsWithChildren<{ business: Business; onSuccess: () => void }>> = ({
  business,
  children,
  onSuccess,
}) => {
  return (
    <Form
      onSubmit={onUpdateBusinessBankAccount({})}
      onSuccess={onSuccess}
      defaultValues={{
        business: {
          bank_account: {
            ...business.bank_account,
            account_holder_name: getAccountHolderName(business),
          },
        },
      }}
      defaultRequired
      readOnly={business.status != 'draft'}
      mode="all"
    >
      <BankAccountField namePrefix="business.bank_account" required={true} />
      {children}
    </Form>
  );
};

function getAccountHolderName(business: Business): string {
  if (business.bank_account.account_holder_name) {
    return business.bank_account.account_holder_name;
  }
  if (
    business.business_type === 'individual' &&
    business.individual.last_name_kana &&
    business.individual.first_name_kana
  ) {
    return `${business.individual.last_name_kana}${business.individual.first_name_kana}`;
  }
  if (business.business_type === 'company' && business.company.name_kana) {
    return business.company.name_kana;
  }
  return '';
}
