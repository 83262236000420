// base generated by ChatGPT

const base32Chars = '0123456789ABCDEFGHJKMNPQRSTVWXYZ';
const ulidLength = 26;

export const fakeGid = () => {
  let ulid = '';
  for (let i = 0; i < ulidLength; i++) {
    const randomIndex = Math.floor(Math.random() * base32Chars.length);
    ulid += base32Chars[randomIndex];
  }
  return ulid;
};
