import './IconicLabel.css';

import { ComponentProps, ComponentPropsWithoutRef, forwardRef } from 'react';
import { Primitive } from '@radix-ui/react-primitive';
import { Icon } from 'src/components/primitives/Icon';

type IconicLabelElement = React.ElementRef<typeof Primitive.div>;
interface IconicLabelProps extends ComponentPropsWithoutRef<typeof Primitive.div> {
  icon: ComponentProps<typeof Icon>['name'];
  variant: 'fill' | 'gray' | 'black';
}

export const IconicLabel = forwardRef<IconicLabelElement, IconicLabelProps>(
  ({ icon, variant = 'black', children, ...props }, forwardedRef) => {
    return (
      <div className={['iconic-label', `iconic-label-${variant}`].join(' ')} {...props} ref={forwardedRef}>
        <Icon className="label-icon" name={icon} />
        {children}
      </div>
    );
  },
);
