import { factoryQuotation } from 'src/catalog/factories/quotation';
import { Meta } from 'src/catalog/Story';

import { QuotationOverview } from './QuotationOverview';

const meta: Meta<typeof QuotationOverview> = {
  title: 'Quotation Overview',
  args: {
    quotation: factoryQuotation(),
  },
  template: (args) => <QuotationOverview {...args} />,
};

export default meta;
