import { factoryAccount } from 'src/catalog/factories/account';
import { defineFactory, FactoryBlueprint } from 'src/catalog/factories/factoryBuilder';
import { fakeGid } from 'src/catalog/factories/gid';
import { factoryTicket } from 'src/catalog/factories/ticket';
import { RepairRequest } from 'src/models/v1/ticket';

// MARK: base

const base: FactoryBlueprint<RepairRequest> = {
  gid: fakeGid(),
  created_at: '2024-01-01T00:00:00.000Z',
  title: '',
  body: '',
  account: factoryAccount(),
  photo_media_files: [],
};

// MARK: factory

export const factoryRepairRequest = defineFactory(base, {});
