import './Banner.css';

import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { styleBoxProps } from 'src/components/primitives/PrimitiveComponent';

import { Primitive } from '@radix-ui/react-primitive';
import { Account } from 'src/models/v1/account';
import { getRandomBackgroundColorByAccount } from 'src/lib/color';

type BannerElement = React.ElementRef<typeof Primitive.div>;
type PrimitiveBannerProps = Omit<ComponentPropsWithoutRef<typeof Primitive.div>, 'role' | 'draggable' | 'style'>;
interface BannerProps extends PrimitiveBannerProps {
  account: Pick<Account, 'icon_url' | 'banner_url' | 'gid'>;
}

export const Banner = forwardRef<BannerElement, BannerProps>(({ account, ...originalProps }, forwardedRef) => {
  const { className, ...props } = styleBoxProps(originalProps);
  const backgroundColor = getRandomBackgroundColorByAccount(account);
  if (account.banner_url) {
    return (
      <div
        role="img"
        className={['banner', className].join(' ')}
        {...props}
        draggable="false"
        ref={forwardedRef}
        style={{ backgroundImage: `url(${implementUrlSuffix(account.banner_url, '/banner')})` }}
      ></div>
    );
  }

  if (account.icon_url) {
    return (
      <div
        role="img"
        className={['banner', 'alternative', className].join(' ')}
        {...props}
        draggable="false"
        ref={forwardedRef}
      >
        <div aria-hidden style={{ backgroundImage: `url(${implementUrlSuffix(account.icon_url, '/banner')})` }}></div>
      </div>
    );
  }
  return (
    <div
      role="img"
      className={['banner', 'background', className].join(' ')}
      {...props}
      ref={forwardedRef}
      style={{ backgroundColor }}
    />
  );
});

function implementUrlSuffix(src: string, suffix: string) {
  const url = new URL(src);
  if (url.host == 'picsum.photos') return src;
  return src + suffix;
}
