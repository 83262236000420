// MARK: base

import { defineFactory, FactoryBlueprint } from 'src/catalog/factories/factoryBuilder';
import { JpActOnSpecifiedCommercialTransaction } from 'src/models/v1/jp_act_on_specified_commercial_transaction';

const base: FactoryBlueprint<JpActOnSpecifiedCommercialTransaction> = {
  corporate_name: '',
  representative_name: '長久太郎',
  address: '長野県 長野市 大字南長野字幅下 692-2',
  display_address: '長野県 長野市 大字南長野字幅下 692-2',
  address_hidden: false,
  phone: '03-3000-1234',
  display_phone: '03-3000-1234',
  phone_hidden: false,
  business_hours_and_closed_days: '不定休',
  pricing: '見積もりに含む',
  additional_fees: '見積もりに含む',
  estimated_delivery: '見積もりに含む',
  cancellation_policy: '依頼確定後のキャンセルは不可',
  after_service_policy: '対応不可',
};

// MARK: factory

export const factoryJpActOnSpecifiedCommercialTransaction = defineFactory(base, {});
