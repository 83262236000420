export const normalizePostalCode = (value: string | null | undefined) => {
  if (value === null) return value;
  if (typeof value == 'undefined') return value;
  value = value.replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0)).replace(/[^\d]/g, '');
  if (value.match(/^\d{7}$/)) {
    return `${value.substring(0, 3)}-${value.substring(3, 7)}`;
  }
  return value;
};

export const normalizeString = (value: string | null | undefined) => {
  if (typeof value != 'string') return value;
  return value.replace(/[！-～]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0)).replace(/　/g, ' ');
};

export const normalizeAmount = (value: string | null | undefined) => {
  if (typeof value != 'string') return value;
  const isNegative = value.startsWith('-');
  const normalizedValue = value
    .replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0))
    .replace(/[^\d]/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return isNegative ? `-${normalizedValue}` : normalizedValue;
};

export const checkKanaIsRequired = (value: string | null | undefined) => {
  if (typeof value != 'string') return false;
  const normalizedValue = normalizeString(value)!;
  // \u0001-\u007E: ASCII characters, \uFF61-\uFF9F: Half-width katakana
  const asciiValue = normalizedValue?.replaceAll(/[^\u0001-\u007E\uFF61-\uFF9F]+/g, '');
  return normalizedValue != asciiValue;
};

export const splitTags = (value: string) => {
  return Array.from(
    // Split on comma (,), full-width comma (，), ideographic comma (、), space, ideographic space, hashtag (#), and full-width hashtag (＃)
    new Set(value.split(/[\u002C\uFF0C\u3001\u0020\u3000\u0023\uFF03]+/).filter((value) => value !== '')),
  );
};

export const normalizedTypeface = (value: string) => {
  // Remove hashtag (#), full-width hashtag (＃), and question mark (?)
  return value.replace(/^[\u0023\uFF03]/, '');
};
