export const SHOW_TOAST_EVENT_NAME = 'show-toast';

export type ToastEventPayload = {
  message: string;
  variant: 'error' | 'notification' | 'success';
};

const toast = (payload: ToastEventPayload) => {
  window.dispatchEvent(new CustomEvent<ToastEventPayload>(SHOW_TOAST_EVENT_NAME, { detail: payload }));
};

export const useToast = () => {
  return toast;
};
