import './HtmlContent.css';
import { FC } from 'react';

export const HtmlContent: FC<{
  html: string;
}> = ({ html }) => {
  if (html == '') {
    return <></>;
  } else {
    return <div className="user-generated-content" dangerouslySetInnerHTML={{ __html: html }} />;
  }
};
