import './BackableBreadcrumb.css';

import { FC, PropsWithChildren } from 'react';
import { Link, useLocation } from 'react-router';
import { Heading } from 'src/components/primitives/Heading';
import { Icon } from 'src/components/primitives/Icon';
import { useI18n } from 'src/lib/i18n';

export const BackableBreadcrumb: FC<PropsWithChildren<{ homePath?: string; currentPath: string }>> = ({
  homePath = '/',
  currentPath,
  children,
}) => {
  const { i18n } = useI18n();
  const { pathname } = useLocation();
  return (
    <ol className="backable-breadcrumb" aria-label={i18n.t('label.page_hierarchy')}>
      <li className="home">
        <Link to={homePath} aria-label={i18n.t('label.menu.home')}>
          <Icon
            name="arrow_back_ios_new"
            width="1.5rem"
            height="1.5rem"
            aria-hidden="true"
            aria-label="Arrow Back Icon"
          />
          <span className="sr-only">{i18n.t('label.menu.home')}</span>
        </Link>
      </li>
      <li aria-current={currentPath == pathname}>
        <Heading>{children}</Heading>
      </li>
    </ol>
  );
};
