import { TicketCommentAttachment } from 'src/models/v1/ticket';

export const attachmentsByType: (attachments: Array<TicketCommentAttachment>) => {
  files: Array<Extract<TicketCommentAttachment, { type: 'file' }>>;
  quotations: Array<Extract<TicketCommentAttachment, { type: 'quotation' }>>;
  estimates: Array<Extract<TicketCommentAttachment, { type: 'estimate' }>>;
} = (attachments) => {
  return {
    files: attachments.filter((attachment) => attachment.type == 'file'),
    quotations: attachments.filter((attachment) => attachment.type == 'quotation'),
    estimates: attachments.filter((attachment) => attachment.type == 'estimate'),
  };
};
