import './QuotationNotice.css';

import { Link } from 'react-router';
import { useI18n } from 'src/lib/i18n';

export const QuotationNotice = () => {
  const { i18n } = useI18n();
  switch (i18n.locale()) {
    case 'ja':
      return (
        <section className="quotation-notice">
          <p>ボタンを押すと、決済サービス Stripe の画面に遷移し、決済を完了するとナガクに戻り依頼が確定します。</p>
          <p>
            お困りの場合は、<Link to="/help">ヘルプページ</Link>をご覧いただくか、
            <Link to="/contact_us">お問い合わせ</Link>ください。
          </p>
        </section>
      );
    case 'en':
      return (
        <section className="quotation-notice">
          <p>
            Click the button to go to the payment service Stripe screen, and when the payment is complete, the request
            will be confirmed and returned to NAGAKU.
          </p>
          <p>
            If you are having trouble, please refer to the <Link to="/help">Help</Link> or{' '}
            <Link to="/contact_us">Contact Us</Link>.
          </p>
        </section>
      );
  }
};
