import './QuotationCalcItem.css';

import { FC } from 'react';
import { PriceTag } from 'src/components/primitives/PriceTag';
import { HStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';

export const QuotationCalcItem: FC<{ label: string; amount: number; bold?: boolean }> = ({
  label,
  amount,
  bold = false,
}) => {
  return (
    <HStack justify="space-between" w="full" className="quotation-calc-item">
      <Text weight={bold ? 'bold' : 'regular'}>{label}</Text>
      <Text weight={bold ? 'bold' : 'regular'}>
        <PriceTag value={amount} />
      </Text>
    </HStack>
  );
};
