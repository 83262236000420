import { Account } from 'src/models/v1/account';

export const getRandomBackgroundColorByAccount = (account: Pick<Account, 'gid'>): string => {
  const lastDigit = parseInt(account.gid.slice(-1));
  const base32Chars = '0123456789ABCDEFGHIJKLMNOPQRSTUV';
  const lastDigitDecimal = base32Chars.indexOf(lastDigit.toString().toUpperCase());
  if (lastDigitDecimal === -1) {
    return getRandomBackgroundColor(0); // fallback to first color if invalid base32
  }
  return getRandomBackgroundColor(lastDigitDecimal);
};

const getRandomBackgroundColor = (seed: number): string => {
  const colors = ['var(--color-blue-300)', 'var(--color-red-300)', 'var(--color-green-300)', 'var(--color-orange-300)'];
  return colors[seed % 4];
};
