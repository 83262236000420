import './TicketInspector.css';

import { ComponentProps, FC } from 'react';
import { onCompleteOrder } from 'src/actions/order';
import { AccountDebug } from 'src/components/features/account/AccountDebug';
import { RepairRequestCard } from 'src/components/features/repair_request/RepairRequestCard';
import { Button } from 'src/components/primitives/Button';
import { useI18n } from 'src/lib/i18n';
import { Order } from 'src/models/v1/order';
import { Ticket } from 'src/models/v1/ticket';
import { Icon } from 'src/components/primitives/Icon';
import { Text } from 'src/components/primitives/Text';

import { Primitive } from '@radix-ui/react-primitive';
import { TicketPhaseLabel } from 'src/components/features/ticket/TicketPhaseLabel';
import { VStack } from 'src/components/primitives/Stack';
import { Link } from 'react-router';
import { AccountLinkCard } from 'src/components/features/account/AccountLinkCard';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { DataNote } from 'src/components/features/DataNote';

export const TicketInspector: FC<
  {
    ticket: Pick<Ticket, 'repair_request' | 'accounts' | 'phase' | 'procedure'>;
    orders: Array<Pick<Order, 'management_number' | 'completion_status'>>;
    onComplete?: (managementNumber: Order['management_number']) => Promise<void> | void;
  } & ComponentProps<typeof Primitive.div>
> = ({ ticket, orders, onComplete = onCompleteOrder({}), ...props }) => {
  const { i18n } = useI18n();
  return (
    <aside className="ticket-inspector" {...props}>
      <RepairRequestCard repairRequest={ticket.repair_request} />
      {ticket.accounts.map((account) => (
        <AccountDebug key={account.gid} account={account} />
      ))}
      {orders
        .filter((order) => order.completion_status != 'completed')
        .map((order) => (
          <Button key={order.management_number} variant="primary" onClick={() => onComplete(order.management_number)}>
            {i18n.t('action.complete_order')}
          </Button>
        ))}
      <VStack asChild align="flex-start" spacing="lg">
        <section>
          <h2>{i18n.t('label.status')}</h2>
          <TicketPhaseLabel phase={ticket.phase} />
        </section>
      </VStack>
      {ticket.procedure !== 'undefined' && (
        <DataNote
          icon={'handshake'}
          label={i18n.t('attributes.ticket.procedure')}
          value={i18n.t(`enum.procedure.${ticket.procedure}`)}
        />
      )}
      <TicketParticipantSection participants={ticket.accounts} />
      <VStack asChild align="flex-start" spacing="lg">
        <section>
          <h2>{i18n.t('label.other')}</h2>
          <Link to={'/help'} target="_blank" rel="noopener noreferrer" className="help">
            <Text weight="bold">{i18n.t('label.help_and_contact_us')}</Text>
            <Icon name="keyboard_arrow_right" />
          </Link>
        </section>
      </VStack>
    </aside>
  );
};

const TicketParticipantSection: FC<{ participants: Ticket['accounts'] }> = ({ participants }) => {
  const { i18n } = useI18n();
  const { me } = useCurrentStatus();

  const accounts = participants.sort((a, b) => (a.gid === me?.gid ? -1 : b.gid === me?.gid ? 1 : 0));

  return (
    <VStack asChild align="flex-start" spacing="lg" w="full">
      <section>
        <h2>{i18n.t('label.ticket_participants')}</h2>
        {accounts.map((account) => (
          <AccountLinkCard key={account.gid} account={account} title={i18n.t(`label.${account.role}`)} />
        ))}
      </section>
    </VStack>
  );
};
