// 半角カタカナから全角カタカナへの変換マップ
const HALF_TO_FULL_MAP = {
  // 基本カタカナ
  ｱ: 'ア',
  ｲ: 'イ',
  ｳ: 'ウ',
  ｴ: 'エ',
  ｵ: 'オ',
  ｶ: 'カ',
  ｷ: 'キ',
  ｸ: 'ク',
  ｹ: 'ケ',
  ｺ: 'コ',
  ｻ: 'サ',
  ｼ: 'シ',
  ｽ: 'ス',
  ｾ: 'セ',
  ｿ: 'ソ',
  ﾀ: 'タ',
  ﾁ: 'チ',
  ﾂ: 'ツ',
  ﾃ: 'テ',
  ﾄ: 'ト',
  ﾅ: 'ナ',
  ﾆ: 'ニ',
  ﾇ: 'ヌ',
  ﾈ: 'ネ',
  ﾉ: 'ノ',
  ﾊ: 'ハ',
  ﾋ: 'ヒ',
  ﾌ: 'フ',
  ﾍ: 'ヘ',
  ﾎ: 'ホ',
  ﾏ: 'マ',
  ﾐ: 'ミ',
  ﾑ: 'ム',
  ﾒ: 'メ',
  ﾓ: 'モ',
  ﾔ: 'ヤ',
  ﾕ: 'ユ',
  ﾖ: 'ヨ',
  ﾗ: 'ラ',
  ﾘ: 'リ',
  ﾙ: 'ル',
  ﾚ: 'レ',
  ﾛ: 'ロ',
  ﾜ: 'ワ',
  ｦ: 'ヲ',
  ﾝ: 'ン',

  // 小文字
  ｧ: 'ァ',
  ｨ: 'ィ',
  ｩ: 'ゥ',
  ｪ: 'ェ',
  ｫ: 'ォ',
  ｯ: 'ッ',
  ｬ: 'ャ',
  ｭ: 'ュ',
  ｮ: 'ョ',

  // 記号
  ｰ: 'ー',
  '･': '・',
} as const;

// 濁点・半濁点の処理用マップ
const DAKUTEN_MAP = {
  カ: 'ガ',
  キ: 'ギ',
  ク: 'グ',
  ケ: 'ゲ',
  コ: 'ゴ',
  サ: 'ザ',
  シ: 'ジ',
  ス: 'ズ',
  セ: 'ゼ',
  ソ: 'ゾ',
  タ: 'ダ',
  チ: 'ヂ',
  ツ: 'ヅ',
  テ: 'デ',
  ト: 'ド',
  ハ: 'バ',
  ヒ: 'ビ',
  フ: 'ブ',
  ヘ: 'ベ',
  ホ: 'ボ',
} as const;

const HANDAKUTEN_MAP = {
  ハ: 'パ',
  ヒ: 'ピ',
  フ: 'プ',
  ヘ: 'ペ',
  ホ: 'ポ',
} as const;

export function convertHalfWidthToFullWidth(input: string): string {
  if (!input) return '';

  const chars = input.split('');
  let result = '';
  let i = 0;

  while (i < chars.length) {
    const char = chars[i];
    const nextChar = chars[i + 1];

    // 濁点・半濁点の処理
    if (nextChar === 'ﾞ' || nextChar === 'ﾟ') {
      const baseChar = HALF_TO_FULL_MAP[char as keyof typeof HALF_TO_FULL_MAP];
      if (baseChar) {
        if (nextChar === 'ﾞ') {
          result += DAKUTEN_MAP[baseChar as keyof typeof DAKUTEN_MAP] || baseChar;
        } else {
          result += HANDAKUTEN_MAP[baseChar as keyof typeof HANDAKUTEN_MAP] || baseChar;
        }
        i += 2;
        continue;
      }
    }

    // 通常の文字変換
    result += HALF_TO_FULL_MAP[char as keyof typeof HALF_TO_FULL_MAP] || char;
    i++;
  }

  return result;
}

export function trimKanji(str: string): string {
  // 漢字の Unicode 範囲にマッチする正規表現
  const kanjiRegex = /[\u4E00-\u9FFF]/g;
  return str.replace(kanjiRegex, '');
}
