import './NoticeLabelCollection.css';

import { FC, PropsWithChildren } from 'react';
import { Sizes } from 'src/components/primitives/PrimitiveComponent';

export const NoticeLabelCollection: FC<PropsWithChildren<{ spacing?: Extract<Sizes, 'sm' | 'xs'> }>> = ({
  children,
  spacing = 'sm',
}) => {
  return <div className={`notice-label-collection notice-label-collection-spacing-${spacing}`}>{children}</div>;
};
