import './MessageBalloon.css';

import { FC, PropsWithChildren, ReactNode } from 'react';
import { Avatar } from 'src/components/primitives/Avatar';
import { HtmlContent } from 'src/components/primitives/HtmlContent';
import { Text } from 'src/components/primitives/Text';
import { Timestamp } from 'src/components/primitives/Timestamp';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { Account } from 'src/models/v1/account';

export const MessageBalloon: FC<
  PropsWithChildren<{
    account: Pick<Account, 'gid' | 'kind' | 'icon_url' | 'display_name'>;
    body: string;
    timestamp: string;
    action?: ReactNode;
    hint?: string;
  }>
> = ({ account, body, timestamp, action, hint, children }) => {
  const { me } = useCurrentStatus();
  const isMe = me?.gid == account.gid;
  return (
    <section className={`message-balloon ${isMe ? 'me' : 'other'}`}>
      {isMe == false && (
        <Avatar account={account} src={account.icon_url || undefined} size="sm" enabledProSymbol={false} />
      )}
      <div className="content">
        <div className={`message-balloon-header ${isMe ? 'me' : 'other'}`}>
          {isMe == false && (
            <Text variant="label" size="medium" weight="bold" ellipsis>
              {account.display_name}
            </Text>
          )}
          <Timestamp
            className="timestamp"
            value={new Date(timestamp)}
            color="sub"
            size="small"
            format="year?-date?-time"
          />
        </div>
        {children}
        <Text variant="body" size="large" className="body">
          <HtmlContent html={body} />
        </Text>
        {action}
        {hint && (
          <Text asChild variant="body" size="small" className="hint">
            <p>{hint}</p>
          </Text>
        )}
      </div>
    </section>
  );
};
