import { ComponentProps, FC, PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'src/components/primitives/Button';

export const FormButton: FC<
  PropsWithChildren<Omit<ComponentProps<typeof Button> & { type: 'submit' }, 'type' | 'onClick' | 'slot' | 'ref'>>
> = ({ children, variant = 'fill', disabled = false, ...props }) => {
  const {
    formState: { isValid, isDirty, isSubmitting },
  } = useFormContext();
  return (
    <Button
      type="submit"
      loading={isSubmitting}
      disabled={!(isValid && isDirty) || disabled}
      variant={variant}
      {...props}
    >
      {children}
    </Button>
  );
};
