import { EstimateSummaryCard } from 'src/components/features/estimate/EstimateSummaryCard';
import { Meta, Story } from 'src/catalog/Story';
import { factoryEstimate } from 'src/catalog/factories/estimate';

const meta: Meta<typeof EstimateSummaryCard> = {
  title: 'Estimate Summary Card',
  args: {
    estimate: factoryEstimate(),
  },
  template: (args) => <EstimateSummaryCard {...args} />,
};

export default meta;

export const asAttachment: Story<typeof EstimateSummaryCard> = {
  name: 'Estimate Summary Card in Ticket Comment Attachment Preview',
  args: {
    estimate: factoryEstimate(),
    isAttached: true,
  },
};
