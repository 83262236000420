import { MessageBalloon } from 'src/components/features/message/MessageBalloon';
import { Card, CardContent } from 'src/components/primitives/Card';
import { Meta, Story } from 'src/catalog/Story';
import { CurrentStatusContext } from 'src/lib/currentStatus';
import { factoryAccount } from 'src/catalog/factories/account';

const meta: Meta<typeof MessageBalloon> = {
  title: 'Message Balloon',
  args: {
    account: factoryAccount(),
    body: 'Message Body',
    timestamp: new Date().toISOString(),
  },
  template: (args) => (
    <CurrentStatusContext.Provider
      value={{
        me: { ...factoryAccount(), gid: 'ME' },
        permissions: [],
      }}
    >
      <MessageBalloon {...args} />
    </CurrentStatusContext.Provider>
  ),
};

export default meta;

export const me: Story<typeof MessageBalloon> = {
  name: 'from Me',
  args: {
    account: { ...factoryAccount(), gid: 'ME' },
    body: 'Message Body',
    timestamp: '2024-12-22T23:28:00.099Z',
  },
};

export const additional: Story<typeof MessageBalloon> = {
  name: 'Additional Component',
  args: {
    account: factoryAccount('iconic'),
    body: 'Message Body',
    timestamp: '2024-02-02T23:28:00.099Z',
    children: (
      <Card>
        <CardContent>Additional Component</CardContent>
      </Card>
    ),
  },
};
