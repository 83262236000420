import './IconButton.css';

import { ComponentProps, ComponentPropsWithoutRef, forwardRef } from 'react';
import { ButtonCustomProps } from 'src/components/primitives/Button';
import { Icon } from 'src/components/primitives/Icon';
import { BoxProps, styleBoxProps } from 'src/components/primitives/PrimitiveComponent';

import { Primitive } from '@radix-ui/react-primitive';

type IconButtonElement = React.ElementRef<typeof Primitive.button>;
type PrimitiveIconButtonProps = ComponentPropsWithoutRef<typeof Primitive.button> & BoxProps;
interface IconButtonProps extends PrimitiveIconButtonProps, ButtonCustomProps {
  icon: ComponentProps<typeof Icon>['name'];
}

export const IconButton = forwardRef<IconButtonElement, IconButtonProps>(
  ({ variant = 'outline', size = 'default', icon, children, ...originalProps }, forwardedRef) => {
    const { className, ...props } = styleBoxProps(originalProps);

    return (
      <button
        className={['icon-button', `icon-button-size-${size}`, `icon-button-${variant}`, className].join(' ')}
        {...props}
        ref={forwardedRef}
      >
        <Icon name={icon} focusable={false} aria-hidden />
        {children}
      </button>
    );
  },
);
