import { FC, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLoaderData, useLocation, useSearchParams } from 'react-router';
import { WorksGallery } from 'src/components/features/work/WorksGallery';
import { useI18n } from 'src/lib/i18n';
import { generateTitle } from 'src/lib/tag';
import { Data, useTag } from 'src/pages/explore';

export const ExploreTagWorksPage: FC = () => {
  const location = useLocation();
  const { i18n } = useI18n();
  const { tag } = useLoaderData() as Data;
  const { setTag } = useTag();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || '';

  useLayoutEffect(() => setTag(tag));
  return (
    <>
      <Helmet key={location.key}>
        <title>{generateTitle(tag, `${i18n.t('title.tag')} - ${i18n.t('nagaku')}`)}</title>
        <meta property="og:title" content={generateTitle(tag, `${i18n.t('title.tag')} - ${i18n.t('nagaku')}`)} />
        <meta
          name="description"
          content={
            tag.description === ''
              ? `${i18n.t('description.prefix')}${i18n.t('description.tag', { typeface: tag.typeface })}`
              : `${i18n.t('description.prefix')}${tag.description || ''}`
          }
        />
        <meta
          property="og:description"
          content={
            tag.ogp_description === ''
              ? `${i18n.t('description.prefix')}${i18n.t('description.tag', { typeface: tag.typeface })}`
              : `${i18n.t('description.prefix')}${tag.ogp_description || ''}`
          }
        />
        <link rel="canonical" href={`https://nagaku.com/explore/${tag.typeface}`} />
      </Helmet>
      <WorksGallery key={`works-${tag.typeface}-${page}`} path="/v1/works" search={{ tag: tag.typeface }} page={page} />
    </>
  );
};
