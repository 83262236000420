import { Breadcrumb } from 'src/components/Breadcrumb';
import { Avatar } from 'src/components/primitives/Avatar';
import { Meta } from 'src/catalog/Story';

const meta: Meta<typeof Breadcrumb> = {
  title: 'Breadcrumb',
  args: {
    children: (
      <>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item icon={<Avatar size="xs" />} terminal>
          username
        </Breadcrumb.Item>
      </>
    ),
  },
  template: (args) => <Breadcrumb {...args} />,
};

export default meta;
