import './Note.css';

import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { BoxProps, styleBoxProps } from 'src/components/primitives/PrimitiveComponent';

import { Primitive } from '@radix-ui/react-primitive';
import { Text } from 'src/components/primitives/Text';

type NoteElement = React.ElementRef<typeof Primitive.div>;
type NotePreviewProps = ComponentPropsWithoutRef<typeof Primitive.div> & BoxProps;
interface NoteProps extends NotePreviewProps {
  variant?: 'normal' | 'error' | 'success';
}

type NoteDescriptionElement = React.ElementRef<typeof Primitive.p>;
type NoteDescriptionPreviewProps = ComponentPropsWithoutRef<typeof Primitive.p> & BoxProps;
interface NoteDescriptionProps extends NoteDescriptionPreviewProps {}

const Root = forwardRef<NoteElement, NoteProps>(({ variant = 'normal', ...originalProps }, forwardedRef) => {
  const { className, ...props } = styleBoxProps(originalProps);
  return (
    <Primitive.div className={['note', `note-${variant}`, className || ''].join(' ')} {...props} ref={forwardedRef} />
  );
});

Root.displayName = 'Note';

const Description = forwardRef<NoteDescriptionElement, NoteDescriptionProps>(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <Text asChild className={`note-description ${className ? className : ''}`} ref={forwardedRef}>
        <p {...props}>{children}</p>
      </Text>
    );
  },
);

Description.displayName = 'Note.Description';

export const Note = { Root, Description };
