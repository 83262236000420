import { FC, useContext, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { RemoveScroll } from 'react-remove-scroll';
import { useLoaderData, useRevalidator, useSearchParams } from 'react-router';
import { MessageView } from 'src/components/features/message/MessageView';
import { PaymentSuccessDialog } from 'src/components/features/payment/PaymentSuccessDialog';
import { TicketCommentControl } from 'src/components/features/ticket/TicketCommentControl';
import { TicketInspector } from 'src/components/features/ticket/TicketInspector';
import { PageLoader } from 'src/lib/api';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';
import { TicketContext } from 'src/lib/ticket';
import { Message } from 'src/models/v1/message';
import { Order } from 'src/models/v1/order';
import { Ticket } from 'src/models/v1/ticket';
import { MessagePageStateContext } from 'src/pages/messages';

type Data = {
  ticket: Ticket;
  messages: Array<Message>;
  orders: Array<Order>;
};

export const MessageThreadPage: FC = () => {
  const { i18n } = useI18n();
  const { me } = useCurrentStatus();
  const { ticket, messages, orders } = useLoaderData() as Data;

  const pageState = useContext(MessagePageStateContext);
  const [searchParams] = useSearchParams();
  const { revalidate } = useRevalidator();

  const couterparts = useMemo(() => ticket.accounts.filter((account) => account.gid != me?.gid), [me, ticket.accounts]);
  useEffect(() => {
    const subscription = setInterval(() => {
      if (document.hidden) return; // Skip refreshing when the browser tab is inactive.
      console.log('refresh messages via revalidate function.');
      revalidate();
    }, 30 * 1000); // 30 sec
    return () => clearInterval(subscription);
  }, [revalidate]);
  useEffect(() => {
    window.addEventListener('focus', revalidate, false);
    return () => window.removeEventListener('focus', revalidate, false);
  }, [revalidate]);
  return (
    <>
      <Helmet>
        <title>
          {couterparts.map(({ display_name }) => display_name).join(', ')} · {i18n.t('title.messages')} |{' '}
          {i18n.t('nagaku')}
        </title>
      </Helmet>
      <main>
        <TicketContext.Provider value={ticket}>
          <TicketCommentControl ticket={ticket} />
          {messages.map((message) => (
            <MessageView key={message.gid} ticket={ticket} message={message} />
          ))}
        </TicketContext.Provider>
        {searchParams.get('payment') == 'success' && <PaymentSuccessDialog ticketGid={ticket.gid} />}
      </main>
      <div
        id="message-inspector"
        aria-hidden={pageState.collapseMessageInspector && pageState.isOpenInspector == false}
      >
        <RemoveScroll enabled={pageState.collapseMessageInspector && pageState.isOpenInspector}>
          <TicketInspector ticket={ticket} orders={orders} />
        </RemoveScroll>
      </div>
    </>
  );
};

export const ticketLoader: PageLoader = async ({ params, api }) => {
  const { ticket_gid } = params;
  return await api.combine(
    api.fetch(`/v1/tickets/${ticket_gid}`),
    api.fetch(`/v1/tickets/${ticket_gid}/messages`),
    api.fetch(`/v1/accounts/me/orders?ticket=${ticket_gid}`),
  );
};
