import './QuotationForm.css';

import { FC, PropsWithChildren, useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { AmountField } from 'src/components/control/AmountField';
import { Form } from 'src/components/control/Form';
import { FormButton } from 'src/components/control/FormButton';
import { InputField } from 'src/components/control/InputField';
import { SaveDraft } from 'src/components/control/SaveDraft';
import { TextField } from 'src/components/control/TextField';
import { DaysField } from 'src/components/control/DaysField';
import { QuotationItemFieldset } from 'src/components/features/quotation/QuotationItemFieldset';
import { Button } from 'src/components/styles/Button';
import { Icon } from 'src/components/primitives/Icon';
import { VStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { Toolbox } from 'src/components/singleton/Toolbox';
import { useI18n } from 'src/lib/i18n';
import { calcQuotationItems, getQuotationExpiryDateRange } from 'src/lib/quotation';
import { Account } from 'src/models/v1/account';
import { Quotation, QuotationItem, UpdateQuotationBody } from 'src/models/v1/quotation';
import { onUpdateQuotation } from 'src/actions/quotation';
import { QuotationCalcItem } from 'src/components/features/quotation/QuotationCalcItem';

export const QuotationForm: FC<
  PropsWithChildren<{
    quotation: UpdateQuotationBody['quotation'] & { gid: Quotation['gid'] };
    account: Pick<Account, 'display_name'>;
    onComplete?: (quotation: Quotation) => void | Promise<void>;
  }>
> = ({ quotation, account, children, onComplete }) => {
  const { i18n } = useI18n();
  const { gid } = quotation;
  const onSubmit = onUpdateQuotation({ quotation, onComplete });
  return (
    <Form
      className="quotation-form"
      onSubmit={onSubmit}
      defaultValues={{
        quotation: {
          title: quotation.title,
          expires_date: quotation.expires_date,
          items: quotation.items,
          estimate_delivery_days: quotation.estimate_delivery_days,
          delivery_days_unit: quotation.delivery_days_unit,
          note: quotation.note,
          shipping_fee: quotation.breakdown?.shipping_fee,
        },
      }}
      defaultRequired
      mode="all"
    >
      <SaveDraft path={`/v1/quotations/${gid}`} />
      <InputField
        name="quotation.title"
        placeholder={i18n.t('placeholder.quotation.title', account)}
        options={{ required: true }}
      />
      <InputField
        type="date"
        name="quotation.expires_date"
        options={{
          required: true,
          valueAsDate: true,
        }}
        {...getQuotationExpiryDateRange()}
      />
      <AmountField name={'quotation.shipping_fee'} />
      <QuotationItems />
      <DaysField
        name="quotation.estimate_delivery_days"
        unitName="quotation.delivery_days_unit"
        options={{
          required: true,
        }}
      />
      <TextField name="quotation.note" />
      <Toolbox>
        <FormButton>{i18n.t('action.complete_edit')}</FormButton>
        {children}
      </Toolbox>
      <DiscardButton />
    </Form>
  );
};

const DiscardButton: FC = () => {
  const { i18n } = useI18n();
  const { reset } = useFormContext();

  const onDelete = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      reset({
        quotation: {
          title: '',
          expires_date: '',
          items: [],
          estimate_delivery_days: null,
          delivery_days_unit: 'week',
          note: '',
          shipping_fee: null,
        },
      });
    },
    [reset],
  );

  return (
    <button className={Button.class} {...Button.build({ variant: 'ghost' })} onClick={onDelete}>
      <Icon name="delete" width="20px" height="20px" />
      {i18n.t('action.quotation_delete')}
    </button>
  );
};

const DEFAULT_QUOTATION_ITEM: QuotationItem = {
  label: '',
  amount: null,
  quantity: 1,
};

const QuotationItems: FC = () => {
  const { i18n } = useI18n();
  const { control, setValue } = useFormContext<UpdateQuotationBody>();
  const { fields, append, remove } = useFieldArray({ control, name: 'quotation.items' });
  const { watch } = useFormContext();
  const shipping_fee = Number(watch('quotation.shipping_fee')) || 0;
  const { subtotal, consumptionTax, platformFee, applicationFeeTargetAmount } = calcQuotationItems(
    watch('quotation.items') || [],
  );
  const onDelete = useCallback(
    (index: number) => {
      if (fields.length == 1) {
        // Reset last item, [!] replace and update produced by useFieldArray are not working.
        (Object.keys(DEFAULT_QUOTATION_ITEM) as Array<keyof QuotationItem>).forEach((key) => {
          setValue(`quotation.items.${index}.${key}`, DEFAULT_QUOTATION_ITEM[key]);
        });
      } else {
        remove(index);
      }
    },
    [remove, setValue, fields.length],
  );
  return (
    <VStack spacing="lg" align="start" w="full">
      <div className="quotation-items">
        <VStack spacing="lg" align="start" w="full">
          {fields.map((_, index) => (
            <QuotationItemFieldset
              key={index}
              namePrefix={`quotation.items.${index}`}
              onDelete={() => onDelete(index)}
            />
          ))}
          <button
            className={Button.class}
            {...Button.build({ variant: 'black', size: 'sm' })}
            onClick={() => append(DEFAULT_QUOTATION_ITEM)}
          >
            {i18n.t('attributes.quotation.add_item')}
          </button>
          <VStack align="start" spacing="sticky" w="full">
            <QuotationCalcItem label={i18n.t('attributes.quotation.subtotal_amount')} amount={subtotal} />
            <div className="divider" />
            <QuotationCalcItem label={i18n.t('attributes.quotation.consumption_tax_amount')} amount={consumptionTax} />
            <QuotationCalcItem label={i18n.t('attributes.quotation.shipping_fee')} amount={shipping_fee} />
            <QuotationCalcItem
              label={i18n.t('attributes.quotation.total_amount')}
              amount={subtotal + consumptionTax + shipping_fee}
              bold={true}
            />
          </VStack>
        </VStack>
      </div>
      <div className="quotation-items">
        <VStack align="start" spacing="sticky" w="full">
          <QuotationCalcItem
            label={i18n.t('attributes.quotation.application_fee_base')}
            amount={applicationFeeTargetAmount}
          />
          <QuotationCalcItem label={i18n.t('attributes.quotation.application_fee')} amount={platformFee} />
          <QuotationCalcItem
            label={i18n.t('attributes.quotation.revenue')}
            amount={subtotal + consumptionTax - platformFee}
            bold={true}
          />
          <Text className="hint-label" color="sub" align="center" w="full">
            {i18n.t('attributes.quotation.estimate_not_included')}
          </Text>
        </VStack>
      </div>
    </VStack>
  );
};
