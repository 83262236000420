import './BusinessProfileForm.css';

import { FC, useState } from 'react';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';

import { Form } from 'src/components/control/Form';
import { FormButton } from 'src/components/control/FormButton';
import { InputField } from 'src/components/control/InputField';
import { TextField } from 'src/components/control/TextField';
import { Business, BusinessProfile } from 'src/models/v1/business';
import { CheckField } from 'src/components/control/CheckField';
import { AddressField } from 'src/components/control/AddressField';
import { HStack } from 'src/components/primitives/Stack';
import { onUpdateBusinessProfile } from 'src/actions/business_profile';
import { FormLabel } from 'src/components/control/FormLabel';
import { validateMaxLengthOption } from 'src/lib/validation';
import RadioGroup from 'src/components/styled-radix/RadioGroup';
import { Card } from 'src/components/styles/Card';

export const BusinessProfileForm: FC<{
  businessStatus: Business['status'];
  businessProfile: BusinessProfile;
}> = ({ businessProfile, businessStatus }) => {
  const { i18n } = useI18n();
  const [hideAddressDetails, setHideAddressDetails] = useState<boolean>(true);
  return (
    <Form
      className="business-profile-form"
      onSubmit={onUpdateBusinessProfile({ businessStatus })}
      mode="all"
      defaultRequired
      defaultValues={{
        business_profile: {
          description: businessProfile.description,
          notes: businessProfile.notes,
          cancellation_policy: businessProfile.cancellation_policy,
          shipping_method: businessProfile.shipping_method,
          support_areas: businessProfile.support_areas,
          business_hours: businessProfile.business_hours,
          regular_closed_days: businessProfile.regular_closed_days,
          support_phone: businessProfile.support_phone,
          support_shipping: businessProfile.support_types.includes('shipping'),
          support_in_store_drop_off: businessProfile.support_types.includes('in_store_drop_off'),
          support_on_site_pickup: businessProfile.support_types.includes('on_site_pickup'),
          support_on_site_repair: businessProfile.support_types.includes('on_site_repair'),
          // accept_repair_request: business_profile.accept_repair_request,
          address_hidden: hideAddressDetails,
          postal_code: businessProfile.address.postal_code,
          state: businessProfile.address.state,
          city: businessProfile.address.city,
          town: businessProfile.address.town,
          line1: businessProfile.address.line1,
          line1_kana: businessProfile.address.line1_kana,
          line2: businessProfile.address.line2,
          line2_kana: businessProfile.address.line2_kana,
        },
      }}
    >
      <Text>{i18n.t('guide.edit_business_profile_page')}</Text>
      <TextField
        scale="thin"
        name="business_profile.description"
        options={{
          required: true,
          maxLength: validateMaxLengthOption({
            name: 'business_profile.description',
            maxLength: 500,
            i18n,
          }),
        }}
      />
      <FormLabel>{i18n.t('attributes.business_profile.support_types')}</FormLabel>
      <HStack justify="start" m="none" spacing="lg" wrap="wrap">
        <CheckField name="business_profile.support_shipping">
          {i18n.t('attributes.business_profile.support_shipping')}
        </CheckField>
        <CheckField name="business_profile.support_in_store_drop_off">
          {i18n.t('attributes.business_profile.support_in_store_drop_off')}
        </CheckField>
        <CheckField name="business_profile.support_on_site_pickup">
          {i18n.t('attributes.business_profile.support_on_site_pickup')}
        </CheckField>
        <CheckField name="business_profile.support_on_site_repair">
          {i18n.t('attributes.business_profile.support_on_site_repair')}
        </CheckField>
      </HStack>
      <InputField
        name="business_profile.shipping_method"
        placeholder={i18n.t('placeholder.business_profile.shipping_method')}
        options={{
          maxLength: validateMaxLengthOption({
            name: 'business_profile.shipping_method',
            maxLength: 30,
            i18n,
          }),
        }}
      />
      <FormLabel>{i18n.t('form.business_profile.address')}</FormLabel>
      <fieldset className={Card.class} {...Card.build({ color: 'base' })}>
        <AddressField namePrefix="business_profile" options={{ required: true }} />
      </fieldset>
      <FormLabel>{i18n.t('form.business_profile.address_visibility_settings')}</FormLabel>
      <RadioGroup.Root
        defaultValue={hideAddressDetails ? 'hide' : 'show'}
        value={hideAddressDetails ? 'hide' : 'show'}
        onValueChange={(value) => setHideAddressDetails(value === 'hide')}
      >
        <label htmlFor="hide">
          <RadioGroup.Item value="hide" id="hide">
            <RadioGroup.Indicator />
          </RadioGroup.Item>
          {i18n.t('form.business_profile.hide_details')}
        </label>
        <label htmlFor="show">
          <RadioGroup.Item value="show" id="show">
            <RadioGroup.Indicator />
          </RadioGroup.Item>
          {i18n.t('form.business_profile.show_details')}
        </label>
      </RadioGroup.Root>
      <Text color="note">{i18n.t('annotations.business_profile.address_hidden')}</Text>
      <InputField
        name="business_profile.support_areas"
        options={{
          maxLength: validateMaxLengthOption({
            name: 'business_profile.support_areas',
            maxLength: 30,
            i18n,
          }),
        }}
      />
      <InputField
        name="business_profile.business_hours"
        options={{
          maxLength: validateMaxLengthOption({
            name: 'business_profile.business_hours',
            maxLength: 30,
            i18n,
          }),
        }}
      />
      <InputField
        name="business_profile.regular_closed_days"
        options={{
          maxLength: validateMaxLengthOption({
            name: 'business_profile.regular_closed_days',
            maxLength: 30,
            i18n,
          }),
        }}
      />
      <TextField
        scale="thin"
        name="business_profile.notes"
        options={{
          maxLength: validateMaxLengthOption({
            name: 'business_profile.notes',
            maxLength: 1000,
            i18n,
          }),
        }}
      />
      <TextField
        scale="thin"
        name="business_profile.cancellation_policy"
        options={{
          required: true,
          maxLength: validateMaxLengthOption({
            name: 'business_profile.cancellation_policy',
            maxLength: 1000,
            i18n,
          }),
        }}
      />
      {/* <CheckField name="business_profile.accept_repair_request">
        {i18n.t('attributes.business_profile.accept_repair_request')}
      </CheckField> */}
      <FormButton>{i18n.t('action.save')}</FormButton>
    </Form>
  );
};
