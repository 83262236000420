import { EstimateItem } from 'src/models/v1/estimate';

export const calcEstimateItems: (items: EstimateItem[]) => {
  subtotal: number;
  consumptionTax: number;
  platformFee: number;
  applicationFeeTargetAmount: number;
} = (items) => {
  const subtotal: number = items.reduce(
    (subtotal, item) => subtotal + (Number(item.amount) || 0) * (Number(item.quantity) || 1),
    0,
  );
  const consumptionTax: number = Math.floor(subtotal * 0.1);
  const platformFee: number = Math.floor((subtotal + consumptionTax) * 0.2);
  const applicationFeeTargetAmount: number = subtotal + consumptionTax;
  return { subtotal, consumptionTax, platformFee, applicationFeeTargetAmount };
};

export const getEstimateExpiryDateRange = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const min = new Date(today);
  min.setDate(today.getDate() + 7);
  const max = new Date(today);
  max.setDate(today.getDate() + 180);
  return {
    min: min.toISOString().split('T')[0],
    max: max.toISOString().split('T')[0],
  };
};
