import { FormProvider, useForm } from 'react-hook-form';
import { factoryQuotation } from 'src/catalog/factories/quotation';
import { Meta, Story } from 'src/catalog/Story';
import { QuotationPaymentButton } from 'src/components/features/quotation/QuotationPaymentButton';
import { CurrentStatusContext } from 'src/lib/currentStatus';
import { TicketContext } from 'src/lib/ticket';
import { CreateQuotationPaymentBody } from 'src/models/v1/quotation';

const meta: Meta<typeof QuotationPaymentButton> = {
  title: 'Quotation Payment Button',
  args: {
    quotation: factoryQuotation('ticket'),
  },
  template: (args) => {
    const methods = useForm<CreateQuotationPaymentBody>();
    return (
      <FormProvider {...methods}>
        <CurrentStatusContext value={{ me: args.quotation.payor!, permissions: [] }}>
          <TicketContext.Provider value={args.quotation.ticket!}>
            <QuotationPaymentButton {...args} />
          </TicketContext.Provider>
        </CurrentStatusContext>
      </FormProvider>
    );
  },
};

export default meta;

export const AlreadyPaid: Story<typeof QuotationPaymentButton> = {
  name: 'Already Paid',
  args: {
    quotation: { ...factoryQuotation('ticket'), status: 'paid' },
  },
};

export const Expired: Story<typeof QuotationPaymentButton> = {
  name: 'Expired',
  args: {
    quotation: { ...factoryQuotation('ticket'), status: 'expired' },
  },
};
