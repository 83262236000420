import './TagTileCollection.css';

import { FC } from 'react';
import { Link } from 'react-router';
import { MediaImage } from 'src/components/primitives/MediaImage';
import { Text } from 'src/components/primitives/Text';
import { Tag } from 'src/models/v1/tag';

export const TagTileCollection: FC<{ tags: Pick<Tag, 'typeface' | 'hero'>[] }> = ({ tags }) => {
  return (
    <section className="tag-tile-collection">
      {tags.map((tag) => (
        <TagTileItem key={tag.typeface} typeface={tag.typeface} url={tag.hero?.url} />
      ))}
    </section>
  );
};

const TagTileItem: FC<{ typeface: string; url: string | undefined }> = ({ typeface, url }) => {
  return (
    <Link className="item" to={`/tags/${typeface}`}>
      {url ? (
        <MediaImage className="hero" src={url} size="216x216" alt="" />
      ) : (
        <div className="hero-blank" aria-hidden="true" />
      )}
      <Text className="item-text" weight="bold" ellipsis>
        {typeface}
      </Text>
    </Link>
  );
};
