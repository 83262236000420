import { FC, ReactNode } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';

export const ErrorMessage: FC<{ name: string; label?: ReactNode }> = ({ name, label }) => {
  const { i18n } = useI18n();
  const { formState, getFieldState } = useFormContext<FieldValues>();
  const { error } = getFieldState(name, formState);

  if (error) {
    return (
      <Text variant="label" size="medium" color="error">
        {error.message?.toString() ||
          i18n.t(`validation.${error.type}`, { name: label || i18n.t(`attributes.${name}`) })}
      </Text>
    );
  }
};
