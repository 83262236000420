import './AccountProfile.css';

import { FC } from 'react';
import { AccountMenu } from 'src/components/features/account/AccountMenu';
import { FollowButton } from 'src/components/features/FollowButton';
import { NoticeLabelCollection } from 'src/components/features/NoticeLabelCollection';
import { Avatar } from 'src/components/primitives/Avatar';
import { Banner } from 'src/components/primitives/Banner';
import { LinkButton } from 'src/components/primitives/Button';
import { CounterLabel } from 'src/components/primitives/CounterLabel';
import { Icon } from 'src/components/primitives/Icon';
import { NoticeLabel } from 'src/components/primitives/NoticeLabel';
import { HStack, VStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { useCurrentStatus, usePermission } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';
import { Account } from 'src/models/v1/account';

export const AccountProfile: FC<{
  account: Account;
}> = ({ account }) => {
  const { i18n } = useI18n();
  const { me } = useCurrentStatus();
  const { permit: followPermit } = usePermission('follow');
  const isMe = me?.gid == account.gid;
  return (
    <VStack className="account-profile" align="start" spacing="sm" m="none">
      <Banner className="banner-adjuster" account={account} />
      <HStack justify="space-between" w="full">
        <Avatar account={account} src={account.icon_url || undefined} />
        {isMe ? (
          <LinkButton to={`/accounts/${account.gid}/edit`} variant="outline" size="default">
            {i18n.t('action.edit_profile')}
          </LinkButton>
        ) : (
          followPermit && (
            <FollowButton gid={account.gid} followed={account.followed} returnTo={`/accounts/${account.gid}`} />
          )
        )}
        <AccountMenu account={account} />
      </HStack>
      <Text variant="title" weight="bold">
        {account.display_name}
      </Text>
      {account.kind == 'pro' && account.business_profile && (
        <>
          {account.business_profile.tagline && <Text>{account.business_profile.tagline}</Text>}
          <NoticeLabelCollection>
            <NoticeLabel size="small">
              <Icon name="location_on" />
              <Text variant="label" weight="bold">
                {account.business_profile.area}
              </Text>
            </NoticeLabel>
            {account.business_profile.support_types.length != 0 && (
              <NoticeLabel size="small">
                <Icon name="local_shipping" />
                <Text variant="label" weight="bold">
                  {account.business_profile.support_types
                    .map((type) => i18n.t(`attributes.business_profile.support_${type}`))
                    .join(' / ')}
                </Text>
              </NoticeLabel>
            )}
          </NoticeLabelCollection>
        </>
      )}
      <HStack justify="start">
        <CounterLabel unit="unit.works" count={account.published_works_count} />
        {/* Repair CounterLabel */}
        {/* Like CounterLabel */}
      </HStack>
      <Text twemojiEnabled>{account.bio}</Text>
    </VStack>
  );
};
