export const Card = {
  class: 'primitive-card',
  build: ({ color }: { color?: 'gray' | 'base' }) => {
    const props: Record<string, string> = {};
    if (color) {
      props['data-card-color'] = color;
    }
    return props;
  },
};
