import './OrderTable.css';

import { FC } from 'react';
import { Link } from 'react-router';
import { PriceTag } from 'src/components/primitives/PriceTag';
import { Text } from 'src/components/primitives/Text';
import { Timestamp } from 'src/components/primitives/Timestamp';
import { useI18n } from 'src/lib/i18n';
import { Order } from 'src/models/v1/order';

type OrderSummary = Pick<Order, 'management_number' | 'timestamp' | 'amount'>;

export const OrderTable: FC<{ orders: Array<OrderSummary> }> = ({ orders }) => {
  const { i18n } = useI18n();
  return (
    <div className="order-table-container">
      <section className="order-table" role="table">
        <div className="header">
          <span role="columnheader">{i18n.t('attributes.order.timestamp')}</span>
          <span role="columnheader">ID</span>
          <span role="columnheader">{i18n.t('attributes.order.amount')}</span>
        </div>
        {orders.map((order) => (
          <OrderCell key={order.management_number} order={order} />
        ))}
      </section>
    </div>
  );
};

const OrderCell: FC<{ order: OrderSummary }> = ({ order }) => {
  return (
    <Link to={`/orders/${order.management_number}`} className="row">
      <span role="cell">
        <Timestamp value={new Date(order.timestamp)} />
      </span>
      <Text role="rowheader" tabIndex={0}>
        {order.management_number}
      </Text>
      <Text role="cell">
        <PriceTag value={order.amount} />
      </Text>
    </Link>
  );
};
