import { FC, useCallback, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { api } from 'src/lib/api';

export const SaveDraft: FC<{ path: string }> = ({ path }) => {
  const data = useWatch();
  const onSaveDraft = useCallback(async (body: any) => await api.dispatch('PUT', path, undefined, body), [path]);
  useEffect(() => {
    const subscribe = setTimeout(() => onSaveDraft(data), 500);
    return () => clearInterval(subscribe);
  }, [data]);
  return <></>;
};
