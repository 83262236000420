import './Tabs.css';

import { forwardRef, ForwardRefExoticComponent } from 'react';
import * as Tabs from '@radix-ui/react-tabs';

const Root: ForwardRefExoticComponent<Tabs.TabsProps & React.RefAttributes<HTMLDivElement>> = forwardRef(
  ({ className, ...props }, forwardedRef) => {
    return <Tabs.Root className={`tabs ${className}`} {...props} ref={forwardedRef} />;
  },
);

const List: ForwardRefExoticComponent<Tabs.TabsListProps & React.RefAttributes<HTMLDivElement>> = forwardRef(
  ({ className, ...props }, forwardedRef) => {
    return <Tabs.List className={`tabs-list ${className}`} {...props} ref={forwardedRef} />;
  },
);

const Trigger: ForwardRefExoticComponent<Tabs.TabsTriggerProps & React.RefAttributes<HTMLDivElement>> = forwardRef(
  (
    { className, value, ...props },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _,
  ) => {
    return <Tabs.Trigger className={`tabs-trigger ${className}`} value={value} {...props} />;
  },
);

export default {
  ...Tabs,
  Root,
  List,
  Trigger,
};
