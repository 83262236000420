import { useCallback } from 'react';
import { createAction } from 'src/lib/action';
import { api } from 'src/lib/api';
import { UpdateBusinessBody } from 'src/models/v1/business';

export const onUpdateBusinessCompany = createAction('onUpdateBusinessCompany', () => {
  return useCallback(async (body: UpdateBusinessBody) => {
    await api.dispatch('PUT', '/v1/accounts/me/business', undefined, body);
  }, []);
});

export const onUpdateBusinessBankAccount = createAction('onUpdateBusinessBankAccount', () => {
  return useCallback(async (body: UpdateBusinessBody) => {
    await api.dispatch('PUT', '/v1/accounts/me/business', undefined, body);
  }, []);
});

export const onUpdateBusinessCompanyAddress = createAction('onUpdateBusinessCompanyAddress', () => {
  return useCallback(async (body: UpdateBusinessBody) => {
    await api.dispatch('PUT', '/v1/accounts/me/business', undefined, body);
  }, []);
});

export const onUpdateBusinessOverview = createAction('onUpdateBusinessOverview', () => {
  return useCallback(async (body: UpdateBusinessBody) => {
    await api.dispatch('PUT', '/v1/accounts/me/business', undefined, body);
  }, []);
});

export const onUpdateBusinessRepresentative = createAction('onUpdateBusinessRepresentative', () => {
  return useCallback(async (body: UpdateBusinessBody) => {
    await api.dispatch('PUT', '/v1/accounts/me/business', undefined, body);
  }, []);
});

export const onUpdateBusinessRepresentativeAddress = createAction('onUpdateBusinessRepresentativeAddress', () => {
  return useCallback(async (body: UpdateBusinessBody) => {
    await api.dispatch('PUT', '/v1/accounts/me/business', undefined, body);
  }, []);
});

export const onUpdateBusinessRepresentativeContact = createAction('onUpdateBusinessRepresentativeContact', () => {
  return useCallback(async (body: UpdateBusinessBody) => {
    await api.dispatch('PUT', '/v1/accounts/me/business', undefined, body);
  }, []);
});
