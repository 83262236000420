import './QuotationPrint.css';

import { FC } from 'react';
import { HtmlContent } from 'src/components/primitives/HtmlContent';
import { Timestamp } from 'src/components/primitives/Timestamp';
import NagakuLogo from 'src/icons/logo.svg?react';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';
import { normalizeAmount } from 'src/lib/value';
import { Quotation } from 'src/models/v1/quotation';

export const QuotationPrint: FC<{ quotation: Quotation }> = ({ quotation }) => {
  const { i18n } = useI18n();
  const { me } = useCurrentStatus();
  return (
    <article className="quotation-print">
      <header>
        <h1>{i18n.t('title.quotation')}</h1>
        <h2>{quotation.title}</h2>
      </header>
      <section className="properties">
        <h2 className="sr-only">{i18n.t('label.quotation.properties')}</h2>
        <dl>
          <dt>
            {i18n.t('attributes.quotation.published_date')}
            &nbsp;:&nbsp;
          </dt>
          <dd>
            <Timestamp value={new Date(quotation.published_at)} format="year-date" />
          </dd>
          <dt>
            {i18n.t('attributes.quotation.expires_date')}
            &nbsp;:&nbsp;
          </dt>
          <dd>
            <Timestamp value={new Date(quotation.expires_date)} format="year-date" />
          </dd>
          <dt>
            {i18n.t('attributes.quotation.management_number')}
            &nbsp;:&nbsp;
          </dt>
          <dd>{quotation.management_number}</dd>
        </dl>
      </section>
      <section className="accounts">
        <h2 className="sr-only">{i18n.t('label.quotation.accounts')}</h2>
        <section>
          <h3>{i18n.t('label.quotation.caller')}</h3>
          <p>{(quotation.payor ?? me)?.display_name}</p>
        </section>
        {quotation.author && (
          <section>
            <h3>{i18n.t('label.quotation.repairer')}</h3>
            <p>{quotation.author.display_name}</p>
            {quotation.author.business_profile?.jp_trn_number && (
              <p>
                {i18n.t('label.quotation.jp_trn_number')}: {quotation.author.business_profile.jp_trn_number}
              </p>
            )}
            {quotation.author.business_profile?.address && (
              <>
                <p>〒 {quotation.author.business_profile?.address.postal_code}</p>
                <p>
                  {quotation.author.business_profile?.address.state}
                  {quotation.author.business_profile?.address.city}
                  {quotation.author.business_profile?.address.town}
                  {quotation.author.business_profile?.address.line1}
                </p>
                {quotation.author.business_profile?.address.line2 && (
                  <p>{quotation.author.business_profile?.address.line2}</p>
                )}
              </>
            )}
          </section>
        )}
      </section>
      <section className="items">
        <h2 className="sr-only">{i18n.t('label.quotation.items')}</h2>
        <table>
          <thead>
            <tr>
              <th>{i18n.t('attributes.quotation.items.label')}</th>
              <th>{i18n.t('attributes.quotation.items.amount')}</th>
              <th>{i18n.t('attributes.quotation.items.quantity')}</th>
              <th>{i18n.t('attributes.quotation.items.sum')}</th>
            </tr>
          </thead>
          <tbody>
            {quotation.items.map((item, index) => (
              <tr key={index}>
                <th scope="row">{item.label}</th>
                <td>¥{normalizeAmount(item.amount?.toString())}</td>
                <td>{item.quantity || 1}</td>
                <td>¥{normalizeAmount(((item.amount ?? 0) * (item.quantity || 1)).toString())}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={3} scope="row">
                {i18n.t('attributes.quotation.subtotal_amount')}
              </th>
              <td>¥{normalizeAmount(quotation.breakdown.subtotal_amount.toString())}</td>
            </tr>
            <tr>
              <th colSpan={3} scope="row">
                {i18n.t('attributes.quotation.consumption_tax_amount')}
              </th>
              <td>¥{normalizeAmount(quotation.breakdown.consumption_tax_amount.toString())}</td>
            </tr>
            {(quotation.breakdown.shipping_fee ?? 0) !== 0 && (
              <tr>
                <th colSpan={3} scope="row">
                  {i18n.t('attributes.quotation.shipping_fee')}
                </th>
                <td>¥{normalizeAmount(quotation.breakdown.shipping_fee?.toString() ?? '0')}</td>
              </tr>
            )}
            <tr className="total">
              <th colSpan={3} scope="row">
                {i18n.t('attributes.quotation.total_amount')}
              </th>
              <td>¥{normalizeAmount(quotation.breakdown.total_amount.toString())}</td>
            </tr>
          </tfoot>
        </table>
      </section>
      <section className="note">
        <h2>{i18n.t('label.quotation.note')}</h2>
        <HtmlContent html={quotation.html.note} />
      </section>
      <footer>
        <NagakuLogo className="logo" aria-label="NAGAKU" />
        <p>この見積書兼請求書はリペア・リメイクサービス『ナガク』を利用して発行されました。</p>
        <p>https://nagaku.com/</p>
      </footer>
    </article>
  );
};
