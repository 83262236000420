import { FC, useCallback } from 'react';
import { Avatar } from 'src/components/primitives/Avatar';
import { Button } from 'src/components/primitives/Button';
import { HStack } from 'src/components/primitives/Stack';
import { api } from 'src/lib/api';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { Account } from 'src/models/v1/account';

export const AccountDebug: FC<{ account: Account }> = ({ account }) => {
  if (!import.meta.env.DEV) return <></>;
  const { me } = useCurrentStatus();
  const onSwitch = useCallback(async () => {
    // This API Endpoint exists in non production environment.
    await api.dispatch('POST', '/v1/auth/gid', undefined, { gid: account.gid });
    window.location.reload();
  }, [account.gid]);
  return (
    <HStack justify="flex-start">
      <Avatar account={account} src={account.icon_url || undefined} size="xs" />
      {me?.gid != account.gid && (
        <Button size="tiny" onClick={onSwitch}>
          Switch {account.display_name}
        </Button>
      )}
    </HStack>
  );
};
