/**
 * Client-Side Rendering (CSR) entry point.
 *
 * IMPORTANT: This file works in conjunction with entrypoint-server.tsx which handles Server-Side Rendering (SSR).
 * When modifying this file, consider whether corresponding changes are needed in entrypoint-server.tsx to maintain
 * consistency between client and server rendering.
 */
import { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';
import { App } from 'src/App';
import { Locale } from 'src/lib/i18n';
import { routes } from 'src/routes';

import * as Sentry from '@sentry/react';

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: import.meta.env.VITE_STAGE + '-' + import.meta.env.VITE_COMMIT_SHA,
    environment: import.meta.env.VITE_STAGE,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/(api\.)?nagaku\.com/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    debug: import.meta.env.DEV,
  });
}

const router = createBrowserRouter(routes);
const lang =
  document.getElementsByTagName('html').item(0)?.getAttribute('lang') ||
  new URLSearchParams(window.location.search).get('locale') ||
  'ja';

if (import.meta.env.DEV) {
  (() => {
    /**
     * In development mode, the lang attribute of the html element is not set.
     * This is because the html element is not rendered in the browser.
     */
    const html = document.getElementsByTagName('html').item(0);
    if (html) {
      html.setAttribute('lang', lang);
    }
  })();
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <StrictMode>
      <Locale locale={lang}>
        <App router={router} />
      </Locale>
    </StrictMode>,
  );
} else {
  ReactDOM.hydrateRoot(
    document.getElementById('root')!,
    <StrictMode>
      <Locale locale={lang}>
        <App router={router} />
      </Locale>
    </StrictMode>,
  );
}
