import { FC } from 'react';
import { AccountLinkCard } from 'src/components/features/account/AccountLinkCard';
import { DataNote } from 'src/components/features/DataNote';
import { useI18n } from 'src/lib/i18n';
import { normalizeAmount } from 'src/lib/value';
import { Quotation } from 'src/models/v1/quotation';

export const QuotationOverview: FC<{ quotation: Quotation }> = ({ quotation }) => {
  const { i18n } = useI18n();
  return (
    <>
      {quotation.author && (
        <AccountLinkCard account={quotation.author} title={i18n.t(`attributes.ticket.participants.role.repairer`)} />
      )}
      <DataNote icon="article" label={i18n.t('attributes.quotation.title')} value={quotation.title ?? ''} />
      <DataNote
        icon="payment"
        label={i18n.t('attributes.quotation.total_amount')}
        value={`¥${normalizeAmount(quotation.breakdown.total_amount?.toString() ?? '0')}`}
      />
      <DataNote
        icon="calendar_month"
        label={i18n.t('attributes.quotation.estimate_delivery_days')}
        value={`${quotation.estimate_delivery_days}${i18n.t(`unit.delivery_days.${quotation.delivery_days_unit}`, quotation.estimate_delivery_days ?? 0)}`}
      />
    </>
  );
};
