import { FC, useContext } from 'react';
import { useSearchParams } from 'react-router';
import { EmptyUserPosts } from 'src/components/features/EmptyUserPosts';
import { WorksGallery } from 'src/components/features/work/WorksGallery';
import { AccountContext } from 'src/lib/account';
import { useCurrentStatus } from 'src/lib/currentStatus';

export const AccountWorksPage: FC = () => {
  const account = useContext(AccountContext);
  const { me } = useCurrentStatus();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || '';
  if (!account) return <></>;

  return (
    <WorksGallery
      key={`works-${account.gid}-${page}`}
      path={`/v1/accounts/${account.gid}/works`}
      search={{ status: 'published' }}
      empty={<EmptyUserPosts isMe={me?.gid == account.gid} />}
      page={page}
    />
  );
};
