import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { onPayQuotation } from 'src/actions/quotation';
import { Button } from 'src/components/primitives/Button';
import { Icon } from 'src/components/primitives/Icon';
import { Note } from 'src/components/primitives/Note';
import { useI18n } from 'src/lib/i18n';
import { checkTicketRole } from 'src/lib/ticket';
import { CreateQuotationPaymentBody, Quotation } from 'src/models/v1/quotation';

export const QuotationPaymentButton: FC<{ quotation: Quotation }> = ({ quotation }) => {
  const { i18n } = useI18n();
  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useFormContext<CreateQuotationPaymentBody>();
  const onPay = onPayQuotation({ gid: quotation.gid });
  if (!checkTicketRole('caller')) return;
  return (
    <>
      {quotation.status == 'paid' && (
        <Note.Root variant="success">
          <Icon name="check_circle_outline" />
          <Note.Description>{i18n.t('guide.quotation.already_paid')}</Note.Description>
        </Note.Root>
      )}
      {quotation.status == 'expired' && (
        <Note.Root variant="error">
          <Icon name="cancel" />
          <Note.Description>{i18n.t('guide.quotation.already_expired')}</Note.Description>
        </Note.Root>
      )}
      <Button
        variant="primary"
        disabled={!isValid || quotation.status !== 'published'}
        loading={isSubmitting}
        onClick={handleSubmit(onPay)}
        w="full"
      >
        <Icon name={'payment'} />
        {i18n.t('action.order_and_pay')}
      </Button>
    </>
  );
};
