import './HorizontalDivider.css';
import { FC, PropsWithChildren } from 'react';
import { Text } from 'src/components/primitives/Text';

export const HorizontalDivider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Text asChild variant="label" className="horizontal-divider" role="separator">
      <div>{children}</div>
    </Text>
  );
};
