import { factoryAccount } from 'src/catalog/factories/account';
import { Meta } from 'src/catalog/Story';
import { AccountInquiryForm } from 'src/components/features/contact/AccountInquiryForm';

const meta: Meta<typeof AccountInquiryForm> = {
  title: 'Account Inquiry Form',
  args: {
    account: factoryAccount('pro'),
    contact: {
      email_domain: 'nagaku.test',
    },
  },
  template: (args) => <AccountInquiryForm {...args} />,
};

export default meta;
