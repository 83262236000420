import { ComponentProps } from 'react';
import { factoryAccount } from 'src/catalog/factories/account';
import { Meta, Story } from 'src/catalog/Story';
import { Button } from 'src/components/primitives/Button';
import { Header } from 'src/components/singleton/Header';
import { CurrentStatus, CurrentStatusContext } from 'src/lib/currentStatus';
import { Account } from 'src/models/v1/account';

const me: Account = {
  ...factoryAccount(),
};

const meta: Meta<typeof Header, CurrentStatus & Pick<ComponentProps<typeof Header>, 'stage'>> = {
  title: 'Header',
  args: {
    me,
    permissions: [],
    stage: 'production',
  },
  template: ({ stage, ...args }) => (
    <CurrentStatusContext.Provider value={args}>
      <Header stage={stage} isEnableMenu>
        <Button size="small" onClick={() => alert('This button is added by parent component')}>
          O_o
        </Button>
      </Header>
    </CurrentStatusContext.Provider>
  ),
};

export default meta;

export const local: Story<typeof Header, CurrentStatus & Pick<ComponentProps<typeof Header>, 'stage'>> = {
  name: 'Local',
  args: {
    me,
    permissions: [],
    stage: 'local',
  },
};

export const staging: Story<typeof Header, CurrentStatus & Pick<ComponentProps<typeof Header>, 'stage'>> = {
  name: 'Staging',
  args: {
    me,
    permissions: [],
    stage: 'staging',
  },
};
