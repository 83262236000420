import { ChangeEvent, useCallback } from 'react';
import { useNavigate, useRevalidator, useSearchParams } from 'react-router';
import { createAction } from 'src/lib/action';
import { PreventAction } from 'src/lib/errors';
import { api } from 'src/lib/api';
import { resolveReturnPath } from 'src/lib/auth';
import {
  KickPasswordRecoveryBody,
  RegisterAccountBody,
  RegisterAccountResponse,
  UpdateAccountBody,
} from 'src/models/v1/account';
import { blobFromFile, uploadMediaFile } from 'src/lib/uploadFile';
import { MediaFile } from 'src/models/v1/media_file';

export const onRegisterAccount = createAction('onRegisterAccount', () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  return useCallback(
    async (body: RegisterAccountBody) => {
      await api.dispatch<RegisterAccountBody, RegisterAccountResponse>('POST', '/v1/accounts', undefined, body);
      api.update({ auth: 'authenticated' });
      // when primary process is succeeded
      const returnTo = resolveReturnPath(searchParams);
      navigate(returnTo, { replace: true });
    },
    [navigate, searchParams],
  );
});

export const onKickRecoveryPassword = createAction(
  'onKickRecoveryPassword',
  ({ onComplete }: { onComplete: () => void }) => {
    return useCallback(
      async (body: KickPasswordRecoveryBody) => {
        await api.dispatch('POST', '/v1/accounts/password_recoveries', undefined, body);
        onComplete();
      },
      [onComplete],
    );
  },
);

export const onUploadBanner = createAction('onUploadBanner', ({ setBanner }: { setBanner?: ( banner: MediaFile ) => void } ) => {
  return async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!event.target.files?.[0]) {
        throw new PreventAction();
      }
      const blob = await blobFromFile(event.target.files![0]);
      const banner = await uploadMediaFile(blob);
      setBanner && setBanner(banner);
    } catch (error: unknown) {
      event.target.value = ""
      throw error
    }
  };
});

export const onUploadIcon = createAction('onUploadIcon', ({ setIcon }: { setIcon?: ( icon: MediaFile ) => void } ) => {
  return async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!event.target.files?.[0]) {
        throw new PreventAction();
      }
      const blob = await blobFromFile(event.target.files![0]);
      const icon = await uploadMediaFile(blob);
      setIcon && setIcon(icon);
    } catch (error: unknown) {
      event.target.value = ""
      throw error
    }
  };
});

// MARK: Profile

export const onUpdateProfile = createAction('onUpdateProfile', () => {
  const navigation = useNavigate();
  const { revalidate } = useRevalidator();
  return useCallback(
    async (body: UpdateAccountBody) => {
      await api.dispatch('PUT', '/v1/accounts/me', {}, body);
      // when primary process is succeeded
      await revalidate();
      navigation('/accounts/me');
    },
    [revalidate, navigation],
  );
});
