import { Pagination } from '../models/general';

export type PaginationRange = Array<number | null>;

/**
 * Calculates the page numbers to display in pagination UI
 * @param current Current page number
 * @param last Total number of pages
 * @returns Array of page numbers and null (for dots)
 */
export const paginationCalc = (current: number, last: number): PaginationRange => {
  // Validate input parameters
  const validatedLast = Math.max(1, last);
  const validatedCurrent = Math.min(Math.max(1, current), validatedLast);
  
  const delta = 2,
    left = Math.max(1, validatedCurrent - delta),
    right = Math.min(validatedCurrent + delta + 1, validatedLast + 1),
    range: Array<number> = [],
    rangeWithDots: PaginationRange = [];

  for (let i = 1; i <= validatedLast; i++) {
    if (i == 1 || i == validatedLast || (i >= left && i < right)) {
      range.push(i);
    }
  }

  let l = 0;
  for (const i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push(null);
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
};
