import './BusinessPreview.css';

import { FC } from 'react';
import { LinkButton } from 'src/components/primitives/Button';
import { NoticeLabel } from 'src/components/primitives/NoticeLabel';
import { SectionHeading } from 'src/components/primitives/SectionHeading';
import { HStack } from 'src/components/primitives/Stack';
import { checkInVerificationDocumentUploadingProgress } from 'src/lib/business';
import { useI18n } from 'src/lib/i18n';
import { Business } from 'src/models/v1/business';

export const BusinessPreview: FC<{ business: Business }> = (props) => {
  const { i18n } = useI18n();
  return (
    <>
      <OverviewSection {...props} />
      {props.business.business_type == 'company' && (
        <>
          <CompanyOverviewSection business={props.business} />
          <CompanyAddressSection business={props.business} />
        </>
      )}
      <PersonalInformationSection {...props} />
      <PersonalAddressSection {...props} />
      <VerificationDocumentSection {...props} />
      <PersonalContactSection {...props} />
      <BankAccountSection {...props} />
      {props.business.status == 'draft' && (
        <LinkButton w="fit-content" mx="auto" mb="lg" to="/settings/business_application/edit">
          {i18n.t('action.modify_input')}
        </LinkButton>
      )}
    </>
  );
};

const OverviewSection: FC<{ business: Business }> = ({ business }) => {
  const { i18n } = useI18n();
  return (
    <section className="business-preview">
      <SectionHeading asChild>
        <h1>{i18n.t('form.business.overview')}</h1>
      </SectionHeading>
      <dl>
        <dt>{i18n.t('attributes.business.business_type')}</dt>
        <dd>{i18n.t(`values.business.business_type.${business.business_type}`)}</dd>
        <dt>{i18n.t('attributes.business.profile.description')}</dt>
        <dd>{business.profile.description}</dd>
        <dt>
          {i18n.t('attributes.business.jp_trn_number')}{' '}
          <NoticeLabel size="small">{i18n.t('control.optional')}</NoticeLabel>
        </dt>
        <dd>{business.jp_trn_number}</dd>
      </dl>
    </section>
  );
};

const CompanyOverviewSection: FC<{ business: Business & { business_type: 'company' } }> = ({ business }) => {
  const { i18n } = useI18n();
  return (
    <section className="business-preview">
      <SectionHeading asChild>
        <h1>{i18n.t('form.business.company_overview')}</h1>
      </SectionHeading>
      <dl>
        <dt>{i18n.t('attributes.business.company.name_kanji')}</dt>
        <dd>{business.company.name_kanji}</dd>
        <dt>{i18n.t('attributes.business.company.name_kana')}</dt>
        <dd>{business.company.name_kana}</dd>
        <dt>{i18n.t('attributes.business.company.name')}</dt>
        <dd>{business.company.name}</dd>
        <dt>{i18n.t('attributes.business.company.phone')}</dt>
        <dd>{business.company.phone}</dd>
        <dt>{i18n.t('attributes.business.company.tax_id')}</dt>
        <dd>{business.company.tax_id}</dd>
      </dl>
    </section>
  );
};

const CompanyAddressSection: FC<{ business: Business & { business_type: 'company' } }> = ({ business }) => {
  const { i18n } = useI18n();
  return (
    <section className="business-preview">
      <SectionHeading asChild>
        <h1>{i18n.t('form.business.company_address')}</h1>
      </SectionHeading>
      <dl>
        <dt>{i18n.t('attributes.business.company.postal_code')}</dt>
        <dd>〒 {business.company.postal_code}</dd>
        <dt>{i18n.t('attributes.business.company.address_level')}</dt>
        <dd>
          {business.company.state} {business.company.city} {business.company.town}
        </dd>
        <dt>{i18n.t('attributes.business.company.line1')}</dt>
        <dd>{business.company.line1}</dd>
        {business.company.line2 && (
          <>
            <dt>
              {i18n.t('attributes.business.company.line2')}{' '}
              <NoticeLabel size="small">{i18n.t('control.optional')}</NoticeLabel>
            </dt>
            <dd>{business.company.line2}</dd>
            {business.company.line2_kana && (
              <>
                <dt>{i18n.t('attributes.business.company.line2_kana')}</dt>
                <dd>{business.company.line2_kana}</dd>
              </>
            )}
          </>
        )}
      </dl>
    </section>
  );
};

const PersonalInformationSection: FC<{ business: Business }> = ({ business }) => {
  const { i18n } = useI18n();
  const person = business.business_type == 'company' ? business.representative : business.individual;
  return (
    <section className="business-preview">
      <SectionHeading asChild>
        <h1>{i18n.t('form.business.representative')}</h1>
      </SectionHeading>
      <dl>
        <HStack justify="stretch">
          <div>
            <dt>{i18n.t('attributes.business.representative.last_name')}</dt>
            <dd>{person.last_name}</dd>
          </div>
          <div>
            <dt>{i18n.t('attributes.business.representative.first_name')}</dt>
            <dd>{person.first_name}</dd>
          </div>
        </HStack>
        <HStack justify="stretch">
          <div>
            <dt>{i18n.t('attributes.business.representative.last_name_kana')}</dt>
            <dd>{person.last_name_kana}</dd>
          </div>
          <div>
            <dt>{i18n.t('attributes.business.representative.first_name_kana')}</dt>
            <dd>{person.first_name_kana}</dd>
          </div>
        </HStack>
        <dt>{i18n.t('attributes.business.representative.dob')}</dt>
        <dd>{person.dob}</dd>
        {business.business_type == 'company' && (
          <>
            <dt>{i18n.t('attributes.business.representative.title')}</dt>
            <dd>{business.representative.title}</dd>
          </>
        )}
      </dl>
    </section>
  );
};

const PersonalAddressSection: FC<{ business: Business }> = ({ business }) => {
  const { i18n } = useI18n();
  const person = business.business_type == 'company' ? business.representative : business.individual;
  return (
    <section className="business-preview">
      <SectionHeading asChild>
        <h1>{i18n.t('form.business.representative_address')}</h1>
      </SectionHeading>
      <dl>
        <dt>{i18n.t('attributes.business.representative.postal_code')}</dt>
        <dd>〒 {person.postal_code}</dd>
        <dt>{i18n.t('attributes.business.representative.address_level')}</dt>
        <dd>
          {person.state} {person.city} {person.town}
        </dd>
        <dt>{i18n.t('attributes.business.representative.line1')}</dt>
        <dd>{person.line1}</dd>
        {person.line2 && (
          <>
            <dt>
              {i18n.t('attributes.business.representative.line2')}{' '}
              <NoticeLabel size="small">{i18n.t('control.optional')}</NoticeLabel>
            </dt>
            <dd>{person.line2}</dd>
            {person.line2_kana && (
              <>
                <dt>{i18n.t('attributes.business.representative.line2_kana')}</dt>
                <dd>{person.line2_kana}</dd>
              </>
            )}
          </>
        )}
      </dl>
    </section>
  );
};

const VerificationDocumentSection: FC<{ business: Business }> = ({ business }) => {
  const { i18n } = useI18n();
  const method = checkInVerificationDocumentUploadingProgress(business.verification_documents);
  return (
    <section className="business-preview">
      <SectionHeading asChild>
        <h1>{i18n.t('form.business.id_documents')}</h1>
      </SectionHeading>
      <dl>
        <dt>{i18n.t(`attributes.business.verification_document_type`)}</dt>
        <dd>{method ? i18n.t(`label.verification_document.${method}`) : 'Unselected'}</dd>
        {(method == 'individual_number_card' || method == 'drivers_license' || method == 'residence_card') && (
          <>
            <dt>{i18n.t('label.verification_document_side.front')}</dt>
            <dd>
              {business.verification_documents.find((document) => document.kind == `${method}_front`)
                ? i18n.t('label.verification_document_status.uploaded')
                : i18n.t('label.verification_document_status.pending')}
            </dd>
            <dt>{i18n.t('label.verification_document_side.back')}</dt>
            <dd>
              {business.verification_documents.find((document) => document.kind == `${method}_back`)
                ? i18n.t('label.verification_document_status.uploaded')
                : i18n.t('label.verification_document_status.pending')}
            </dd>
          </>
        )}
      </dl>
    </section>
  );
};

const PersonalContactSection: FC<{ business: Business }> = ({ business }) => {
  const { i18n } = useI18n();
  const person = business.business_type == 'company' ? business.representative : business.individual;
  return (
    <section className="business-preview">
      <SectionHeading asChild>
        <h1>{i18n.t('form.business.contact')}</h1>
      </SectionHeading>
      <dl>
        <dt>{i18n.t('attributes.business.representative.phone')}</dt>
        <dd>{person.phone}</dd>
        <dt>{i18n.t('attributes.business.representative.email')}</dt>
        <dd>{person.email}</dd>
      </dl>
    </section>
  );
};

const BankAccountSection: FC<{ business: Business }> = ({ business }) => {
  const { i18n } = useI18n();
  return (
    <section className="business-preview">
      <SectionHeading asChild>
        <h1>{i18n.t('form.business.bank_account')}</h1>
      </SectionHeading>
      <dl>
        <dt>{i18n.t('attributes.business.bank_account.bank_name')}</dt>
        <dd>
          {business.bank_account.bank_name} <small>{business.bank_account.bank_number}</small>
        </dd>
        <dt>{i18n.t('attributes.business.bank_account.branch_number')}</dt>
        <dd>{business.bank_account.branch_number}</dd>
        <dt>{i18n.t('attributes.business.bank_account.account_number')}</dt>
        <dd>{business.bank_account.account_number}</dd>
        <dt>{i18n.t('attributes.business.bank_account.account_holder_name')}</dt>
        <dd>{business.bank_account.account_holder_name}</dd>
      </dl>
    </section>
  );
};
