import './Button.css';

import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';
import { Link } from 'react-router';
import { BoxProps, styleBoxProps } from 'src/components/primitives/PrimitiveComponent';
import { Spinner } from 'src/components/primitives/Spinner';

import { Primitive } from '@radix-ui/react-primitive';

type ButtonSize = 'default' | 'small' | 'tiny';

export type ButtonCustomProps = {
  icon?: ReactNode;
  variant?: 'fill' | 'danger' | 'outline' | 'whiteline' | 'ghost' | 'primary' | 'primary-black';
  size?: ButtonSize;
  loading?: boolean;
};

type ButtonElement = React.ElementRef<typeof Primitive.button>;
type PrimitiveButtonProps = ComponentPropsWithoutRef<typeof Primitive.button> & BoxProps;
interface ButtonProps extends PrimitiveButtonProps, ButtonCustomProps {}

export const Button = forwardRef<ButtonElement, ButtonProps>(
  ({ variant = 'outline', size = 'default', loading, disabled, icon, children, ...originalProps }, forwardedRef) => {
    const { className, ...props } = styleBoxProps(originalProps);
    return (
      <Primitive.button
        className={['button', `button-size-${size}`, `button-${variant}`, className].join(' ')}
        {...props}
        disabled={disabled || loading}
        ref={forwardedRef}
      >
        {loading ? (
          <Spinner size="small" className="button-spinner" />
        ) : (
          <>
            {icon}
            {children}
          </>
        )}
      </Primitive.button>
    );
  },
);

type LinkButtonElement = React.ElementRef<typeof Link>;
type PrimitiveLinkButtonProps = ComponentPropsWithoutRef<typeof Link> & BoxProps;
interface LinkButtonProps extends PrimitiveLinkButtonProps, ButtonCustomProps {}

export const LinkButton = forwardRef<LinkButtonElement, LinkButtonProps>(
  ({ to, variant = 'outline', size = 'default', icon, children, ...originalProps }, forwardedRef) => {
    const { className, ...props } = styleBoxProps(originalProps);
    return (
      <Link
        className={['button', `button-size-${size}`, `button-${variant}`, className].join(' ')}
        to={to}
        {...props}
        ref={forwardedRef}
      >
        {icon}
        {children}
      </Link>
    );
  },
);
