import './AuthRequiredButton.css';

import { FC, MouseEvent, PropsWithChildren, useCallback, useState } from 'react';
import Dialog from 'src/components/styled-radix/Dialog';
import { Icon } from 'src/components/primitives/Icon';
import { LinkButton } from 'src/components/primitives/Button';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';

export const AuthRequiredButton: FC<
  PropsWithChildren<{
    returnTo: string;
  }>
> = ({ returnTo, children }) => {
  const { i18n } = useI18n();
  const { me } = useCurrentStatus();
  const [isOpen, setOpen] = useState(false);

  const onClose = useCallback(() => setOpen(false), [setOpen]);

  const onTrigger = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      if (!me) {
        setOpen(true);
        event.preventDefault();
        event.stopPropagation();
      }
    },
    [setOpen],
  );

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Trigger asChild onClick={onTrigger}>
        {children}
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay onClick={onClose} />
        <Dialog.Content variant="alert">
          <Dialog.Close onClick={onClose} />
          <Dialog.Title>{i18n.t('label.please_login')}</Dialog.Title>
          <section className="auth-required-content-body">
            <Icon className="icon" name="login" width="96px" height="96px" />
            <LinkButton to={`/login?return_to=${returnTo}`} variant="fill">
              {i18n.t('action.signup_or_login')}
            </LinkButton>
          </section>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
