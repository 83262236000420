import './style.css';

import { createContext, FC, useCallback, useEffect, useState } from 'react';
import { Link, useOutlet } from 'react-router';
import { MessageBox } from 'src/components/singleton/MessageBox';
import { Icon } from 'src/components/primitives/Icon';
import { Header } from 'src/components/singleton/Header';
import { Menu } from 'src/components/singleton/Menu';
import { MenuToggleButton } from 'src/components/singleton/MenuToggleButton';
import { useI18n } from 'src/lib/i18n';
import { Button } from 'src/components/primitives/Button';

type MessagePageState = {
  collapseMessageBox: boolean;
  collapseMessageInspector: boolean;
  isOpenInspector: boolean;
  isOpenMenu: boolean;
};

export const MessagePageStateContext = createContext<MessagePageState>({
  collapseMessageBox: false,
  collapseMessageInspector: false,
  isOpenInspector: false,
  isOpenMenu: false,
});

export const MessageBoxPage: FC = () => {
  const { i18n } = useI18n();
  const outlet = useOutlet();
  const [pageState, setPageState] = useState<MessagePageState>({
    collapseMessageBox: true,
    collapseMessageInspector: true,
    isOpenInspector: false,
    isOpenMenu: false,
  });
  const onResizeWindow = useCallback(() => {
    if (window.innerWidth < 700) {
      return setPageState((pageState) => ({ ...pageState, collapseMessageBox: true, collapseMessageInspector: true }));
    }
    return setPageState((pageState) => ({
      ...pageState,
      collapseMessageBox: window.innerWidth < 1000,
      collapseMessageInspector: false,
      isOpenInspector: false,
    }));
  }, [setPageState]);
  useEffect(() => {
    onResizeWindow();
    window.addEventListener('resize', onResizeWindow);
    return () => window.removeEventListener('resize', onResizeWindow);
  }, [onResizeWindow]);

  return (
    <div
      id="message-box-page"
      className={[
        pageState.collapseMessageBox ? 'collapse-message-box' : '',
        pageState.collapseMessageInspector ? 'collapse-message-inspector' : '',
        outlet ? 'open-outlet' : 'none-outlet',
      ].join(' ')}
    >
      <title>
        {i18n.t('title.messages')} | {i18n.t('nagaku')}
      </title>
      <MessagePageStateContext.Provider value={pageState}>
        <Header
          backLink={
            pageState.collapseMessageBox && outlet ? (
              <Link to="/messages">
                <Icon name="arrow_back_ios_new" aria-label={i18n.t('action.back')} />
              </Link>
            ) : undefined
          }
        >
          {pageState.collapseMessageInspector && outlet ? (
            <Button
              variant="fill"
              aria-label={i18n.t('action.open_message_inspector')}
              onClick={() => {
                setPageState((pageState) => ({ ...pageState, isOpenInspector: !pageState.isOpenInspector }));
              }}
            >
              情報を見る
            </Button>
          ) : (
            <MenuToggleButton
              isVisibleMenu={pageState.isOpenMenu}
              onToggleMenu={() => setPageState((pageState) => ({ ...pageState, isOpenMenu: true }))}
            />
          )}
        </Header>
        <MessageBox />
        {outlet}
        <div
          aria-hidden
          id="message-box-overlay"
          data-state={pageState.isOpenInspector ? 'open' : 'close'}
          onClick={() => setPageState((pageState) => ({ ...pageState, isOpenInspector: false }))}
        />
        <Menu
          isVisible={pageState.isOpenMenu}
          onClose={() => setPageState((pageState) => ({ ...pageState, isOpenMenu: false }))}
        />
      </MessagePageStateContext.Provider>
    </div>
  );
};
