import './Button.css';

export const Button = {
  class: 'styled-button',
  /**
   * Build style props for Button style class.
   *
   * default: variant = 'outline', size = 'md'.
   */
  build: ({
    variant = 'outline',
    size = 'md',
  }: {
    variant?:
      | 'green'
      | 'red'
      | 'blue'
      | 'orange'
      | 'black'
      | 'nagaku-black'
      | 'outline'
      | 'white-outline'
      | 'ghost'
      | 'primary-action'
      | 'destructive-action';
    size?: 'sm' | 'md' | 'lg' | 'xl';
  }) => {
    const props: Record<string, string> = {};
    switch (variant) {
      case 'primary-action':
        props['data-button-variant'] = 'green';
        break;
      case 'destructive-action':
        props['data-button-variant'] = 'red';
        break;
      default:
        props['data-button-variant'] = variant;
        break;
    }
    props['data-button-size'] = size;
    return props;
  },
};
