import './Header.css';

import NagakuLogo from 'src/icons/logo.svg?react';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { Link } from 'react-router';
import { useI18n } from 'src/lib/i18n';

export const Header: FC<PropsWithChildren<{ stage?: string; backLink?: ReactNode; isEnableMenu?: boolean }>> = ({
  backLink,
  stage = import.meta.env.VITE_STAGE,
  isEnableMenu = true,
  children,
}) => {
  const { i18n } = useI18n();
  return (
    <header id="header" className={`stage-${stage}`} role="banner">
      {backLink || (
        <Link to="/" className="header-logo" title={`${i18n.t('nagaku')} - https://nagaku.com`}>
          <NagakuLogo className="logo" title={i18n.t('nagaku')} width="129.56" height="20" />
        </Link>
      )}
      {isEnableMenu && <nav>{children}</nav>}
    </header>
  );
};
