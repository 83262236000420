import Polyglot, { PolyglotOptions } from 'node-polyglot';
import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';

import LOCALE_EN from 'src/locale/en';
import LOCALE_JA from 'src/locale/ja';

// // eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LOCALES: { [locale: string]: PolyglotOptions } = {
  en: LOCALE_EN,
  ja: LOCALE_JA,
};

const PolyglotContext = createContext<{ i18n: Polyglot; locale: string }>({
  i18n: new Polyglot({ locale: 'ja', ...LOCALE_JA, interpolation: { prefix: '{{', suffix: '}}' } }),
  locale: 'ja',
});

export const Locale: FC<PropsWithChildren<{ locale: string }>> = ({ locale, children }) => {
  const i18n = useMemo(
    () => new Polyglot({ locale, ...(LOCALES[locale] || LOCALE_JA), interpolation: { prefix: '{{', suffix: '}}' } }),
    [locale],
  );
  return <PolyglotContext.Provider value={{ i18n, locale }}>{children}</PolyglotContext.Provider>;
};

export const useI18n: () => { i18n: Polyglot; locale: string } = () => useContext(PolyglotContext);
