import { FC } from 'react';
import { BackableBreadcrumb } from 'src/components/features/BackableBreadcrumb';
import { Helmet } from 'react-helmet-async';
import { redirect, useLoaderData } from 'react-router';
import { BusinessForm } from 'src/components/features/business/BusinessForm';
import { NavigationLayout } from 'src/components/Layout';
import { PageLoader } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { Business, BusinessIssue, RetrieveBusinessResponse } from 'src/models/v1/business';

type Data = {
  business: Business;
  business_issues: Array<BusinessIssue>;
};

export const BusinessApplicationEditPage: FC = () => {
  const { i18n } = useI18n();
  const { business, business_issues: issues } = useLoaderData() as Data;

  return (
    <NavigationLayout>
      <Helmet>
        <title>{`${i18n.t('title.pro_account_application')} · ${i18n.t('title.setting')} | ${i18n.t('nagaku')}`}</title>
        <meta name="description" content="" />
      </Helmet>
      <BackableBreadcrumb homePath="/settings" currentPath="/settings/business_application/edit">
        {i18n.t('title.pro_account_application')}
      </BackableBreadcrumb>
      {business && <BusinessForm key={business.business_type} business={business} issues={issues} />}
    </NavigationLayout>
  );
};

export const editBusinessApplicationLoader: PageLoader = async ({ api }) => {
  const data = await api.combine(
    api.fetch<RetrieveBusinessResponse>('/v1/accounts/me/business'),
    api.fetch('/v1/accounts/me/business/issues'),
  );
  if (data.business.status != 'draft') {
    return redirect('/settings/business_application', 301);
  }
  return data;
};
