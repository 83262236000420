import './Menu.css';

import { ComponentProps, FC, PropsWithChildren } from 'react';
import { Link, useLocation } from 'react-router';
import { Avatar } from 'src/components/primitives/Avatar';
import { LinkButton } from 'src/components/primitives/Button';
import { Card } from 'src/components/primitives/Card';
import { Icon } from 'src/components/primitives/Icon';
import { HStack, VStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { PostButton } from 'src/components/features/PostButton';
import { Toolbox } from 'src/components/singleton/Toolbox';
import { useCurrentStatus, usePermission } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';

import * as Portal from '@radix-ui/react-portal';

export const Menu: FC<PropsWithChildren<{ isVisible: boolean; onClose: () => void }>> = ({ children, ...props }) => {
  const { me } = useCurrentStatus();
  return (
    <>
      {children ? (
        <nav id="menu" role="navigation" data-nosnippet aria-hidden={!props.isVisible}>
          {children}
        </nav>
      ) : me ? (
        <AccountMenu {...props} />
      ) : (
        <UnauthenticatedMenu {...props} />
      )}
      <Portal.Root>
        <div id="menu-overlay" onClick={props.onClose} aria-hidden></div>
      </Portal.Root>
    </>
  );
};

const AccountMenu: FC<{ isVisible: boolean; onClose: () => void }> = ({ isVisible }) => {
  const { me } = useCurrentStatus();
  const { permit: permitTicket } = usePermission('ticket');
  return (
    <VStack
      asChild
      align="stretch"
      justify="flex-start"
      spacing="sticky"
      id="menu"
      role="navigation"
      data-nosnippet
      aria-hidden={!isVisible}
    >
      <nav>
        <MenuItem to="/" icon="home" name="label.menu.home" />
        <MenuItem to="/explore" icon="search" name="label.menu.search_by_tag" />
        <MenuItem to="/likes" icon="favorite_border" name="label.menu.likes" />
        {/* <MenuItem to="/accounts/me" icon="person_add" name="label.menu.follows" /> */}
        {/* <MenuItem to="/accounts/me" icon="notifications" name="label.menu.notifications" /> */}
        {permitTicket && <MenuItem to="/messages" icon="email" name="label.menu.messages" />}
        {permitTicket && me?.kind == 'pro' && (
          <MenuItem to="/dashboard/orders" icon="list_alt" name="label.menu.dashboard.orders" />
        )}
        {permitTicket && <MenuItem to="/orders" icon="access_time" name="label.menu.order_history" />}
        <MenuItem to="/settings" icon="settings" name="label.menu.settings" />
        <PostButton my="4xl" mr="auto" />
        <aside>
          <Card asChild w="full" className="current-account-card" borderRadius="lg">
            <HStack asChild justify="flex-start" spacing="sm" p="sm">
              <Link to="/accounts/me">
                {/* TODO: makes 40 x 40 pixels */}
                <Avatar account={me || undefined} src={me!.icon_url || undefined} size="md" />
                <VStack align="flex-start" spacing="sticky" className="body">
                  <Text weight="bold" ellipsis>
                    {me!.display_name}
                  </Text>
                  {me!.screen_name && (
                    <Text size="small" weight="bold" color="note" ellipsis>
                      @{me!.screen_name}
                    </Text>
                  )}
                </VStack>
                <Icon name="keyboard_arrow_right" />
              </Link>
            </HStack>
          </Card>
        </aside>
      </nav>
    </VStack>
  );
};

const UnauthenticatedMenu: FC<{ isVisible: boolean; onClose: () => void }> = ({ isVisible }) => {
  const { i18n } = useI18n();
  const { pathname } = useLocation();
  const searchParams = new URLSearchParams();
  searchParams.set('return_to', pathname);
  const to = `/login?${searchParams.toString()}`;

  return (
    <VStack
      asChild
      align="stretch"
      justify="flex-start"
      spacing="sticky"
      w="full"
      id="menu"
      role="navigation"
      data-nosnippet
      aria-hidden={!isVisible}
    >
      <nav>
        <MenuItem to="/" icon="home" name="label.menu.home" />
        <MenuItem to="/explore" icon="search" name="label.menu.search_by_tag" />
        <PostButton my="4xl" mr="auto" />
        <Card asChild borderRadius="lg">
          <VStack align="stretch" p="lg">
            <HStack spacing="sm">
              <Icon className="symbol" name="person_search" />
              <Text weight="bold">{i18n.t('label.menu.recommend_login')}</Text>
            </HStack>
            <Toolbox>
              <LinkButton to={to} variant="fill">
                {i18n.t('action.signup_or_login')}
              </LinkButton>
            </Toolbox>
          </VStack>
        </Card>
      </nav>
    </VStack>
  );
};

const MenuItem: FC<{ to: string; icon: ComponentProps<typeof Icon>['name']; name: string }> = ({ to, icon, name }) => {
  const { i18n } = useI18n();
  return (
    <HStack asChild justify="flex-start" className="item" spacing="md" px="sm" py="md">
      <Link to={to}>
        <Icon name={icon} />
        <Text variant="title" weight="bold">
          {i18n.t(name)}
        </Text>
      </Link>
    </HStack>
  );
};
