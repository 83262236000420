import { FC, PropsWithChildren } from 'react';
import { onUpdateBusinessRepresentativeContact } from 'src/actions/business';
import { Form } from 'src/components/control/Form';
import { InputField } from 'src/components/control/InputField';
import { BusinessIssuesView } from 'src/components/features/business/BusinessIssuesView';
import { useI18n } from 'src/lib/i18n';
import { Business } from 'src/models/v1/business';

export const BusinessRepresentativeContactForm: FC<
  PropsWithChildren<{ business: Business; onSuccess: () => void }>
> = ({ business, onSuccess, children }) => {
  const { i18n } = useI18n();
  const key = business.business_type == 'individual' ? 'individual' : 'representative';
  const person = business.business_type == 'individual' ? business['individual'] : business['representative'];
  return (
    <Form
      onSubmit={onUpdateBusinessRepresentativeContact({})}
      onSuccess={onSuccess}
      defaultValues={{
        business: {
          [key]: {
            phone: person.phone,
            email: person.email,
          },
        },
      }}
      defaultRequired
      readOnly={business.status != 'draft'}
    >
      <BusinessIssuesView kind="review_contact" />
      <InputField
        name={`business.${key}.phone`}
        placeholder={i18n.t('placeholder.phone')}
        autoComplete="tel"
        options={{ required: true }}
      />
      <InputField
        name={`business.${key}.email`}
        placeholder={i18n.t('placeholder.email')}
        autoComplete="email"
        options={{ required: true }}
      />
      {children}
    </Form>
  );
};
