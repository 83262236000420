import { ComponentProps, ReactNode, useEffect, useState, useMemo, forwardRef } from 'react';
import { MediaFile } from 'src/models/v1/media_file';

export type MediaImageSource = 'file:uploading' | MediaFile;

type ImageSize = '144x144' | '216x216' | '512x512' | '1024x1024' | '2048x2048' | 'banner' | 'c512' | 'c2048';

const PATH_TABLE: { [size in ImageSize]: string } = {
  '144x144': '/s144',
  '216x216': '/s216',
  '512x512': '/s512',
  '1024x1024': '/s1024',
  '2048x2048': '/s2048',
  banner: '/banner',
  c512: '/c512',
  c2048: '/c2048',
};

const resolveURL = (src: string | undefined, currentSize: ImageSize) => {
  if (typeof src == 'undefined') return;
  try {
    const url = new URL(src);
    if (url.host == 'picsum.photos') return src;
  } catch {}
  return src + (src.startsWith('/') ? '' : PATH_TABLE[currentSize]);
};

export const MediaImage = forwardRef<
  HTMLImageElement,
  { size: ImageSize; preloadedSize?: ImageSize; fallback?: ReactNode } & ComponentProps<'img'>
>(({ size, preloadedSize, src, className, fallback, ...props }, ref) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [currentSize, setCurrentSize] = useState<ImageSize>(preloadedSize || size);
  const url = useMemo(() => resolveURL(src, currentSize), [src, currentSize]);
  useEffect(() => {
    if (preloadedSize && src && !src.startsWith('/')) {
      const targetSrc = src + PATH_TABLE[size];
      const imgLoader = new Image();
      setLoading(true);
      imgLoader.onload = () => {
        setCurrentSize(size);
        setLoading(false);
      };
      imgLoader.src = targetSrc;
    }
  }, [preloadedSize, src, size]);

  if (isLoading && fallback) return fallback;

  return <img ref={ref} className={`media-image ${className}`} src={url} {...props} loading="lazy" />;
});
