import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Heading } from 'src/components/primitives/Heading';
import { useLoaderData } from 'react-router';
import { SettingItem } from 'src/components/features/setting/SettingItem';
import { NavigationLayout } from 'src/components/Layout';
import { Button } from 'src/components/primitives/Button';
import { Card } from 'src/components/primitives/Card';
import { Icon } from 'src/components/primitives/Icon';
import { NoticeLabel } from 'src/components/primitives/NoticeLabel';
import { VStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { api, PageLoader } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { Account } from 'src/models/v1/account';
import { Business } from 'src/models/v1/business';

type Data = {
  account: Account;
  business?: Business;
};

export const SettingsPage = () => {
  const { i18n } = useI18n();
  const { account, business } = useLoaderData() as Data;
  const onLogout = useCallback(async () => {
    await api.dispatch('DELETE', '/v1/auth');
    window.location.replace('/');
  }, []);
  return (
    <NavigationLayout>
      <Helmet>
        <title>{`${i18n.t('title.setting')} | ${i18n.t('nagaku')}`}</title>
        <meta name="description" content="" />
      </Helmet>
      <Heading>{i18n.t('title.setting')}</Heading>
      <Card asChild borderRadius="lg">
        <VStack asChild spacing="xs" align="stretch" p="lg">
          <section>
            <SettingItem to="/settings/account">{i18n.t('label.setting_items.account_setting')}</SettingItem>
            <SettingItem to="/settings/addresses">{i18n.t('label.setting_items.addresses_setting')}</SettingItem>
            <SettingItem to={`/accounts/${account.gid}/edit`}>
              {i18n.t('label.setting_items.profile_edit_page')}
            </SettingItem>
          </section>
        </VStack>
      </Card>
      {business && (
        <section style={{ display: 'contents' }}>
          <Text asChild variant="title" size="large" weight="bold">
            <h2>{i18n.t('label.business')}</h2>
          </Text>
          <Card asChild borderRadius="lg">
            <VStack spacing="xs" align="stretch" p="lg">
              {business.status != 'open' && (
                <SettingItem
                  to="/settings/business_application/edit"
                  status={
                    <NoticeLabel asChild size="small" variant="primary">
                      <small>{i18n.t(`enum.business.status.${business.status}`)}</small>
                    </NoticeLabel>
                  }
                >
                  {i18n.t('label.setting_items.business_application')}
                </SettingItem>
              )}
              {(business.status == 'verified' || business.status == 'open') && (
                <>
                  <SettingItem to="/settings/business_profile/edit">
                    {i18n.t('label.setting_items.business_profile')}
                  </SettingItem>
                  <SettingItem to="/settings/reception/edit">{i18n.t('label.setting_items.reception')}</SettingItem>
                  <SettingItem to="/settings/jp_act_on_specified_commercial_transaction/edit">
                    {i18n.t('label.setting_items.jp_act_on_spacified_commercial_transaction')}
                  </SettingItem>
                </>
              )}
              {business.status == 'open' && (
                <SettingItem to="/settings/business">
                  {i18n.t('label.setting_items.business_application_information')}
                </SettingItem>
              )}
            </VStack>
          </Card>
        </section>
      )}
      <Button
        variant="ghost"
        onClick={onLogout}
        icon={<Icon name="sensor_door" aria-label={i18n.t('action.logout')} />}
      >
        {i18n.t('action.logout')}
      </Button>
    </NavigationLayout>
  );
};

export const settingsLoader: PageLoader = async ({ api }) => {
  return await api.combine(api.fetch('/v1/accounts/me'), api.optionalFetch('/v1/accounts/me/business'));
};
