import './Tag.css';

import { ComponentPropsWithoutRef, forwardRef, MouseEvent } from 'react';
import { Icon } from 'src/components/primitives/Icon';
import { BoxProps, styleBoxProps } from 'src/components/primitives/PrimitiveComponent';
import { useI18n } from 'src/lib/i18n';

import { Primitive } from '@radix-ui/react-primitive';
import { Slot, Slottable } from '@radix-ui/react-slot';

type TagElement = React.ElementRef<typeof Primitive.div>;
type PrimitiveTagButtonProps = ComponentPropsWithoutRef<typeof Primitive.div> & BoxProps;
interface TagProps extends PrimitiveTagButtonProps {
  variant?: 'default' | 'inline';
  onClose?: (event: MouseEvent<HTMLButtonElement>) => void;
  iconic?: boolean;
}

export const Tag = forwardRef<TagElement, TagProps>(
  ({ onClose, asChild, children, variant = 'default', iconic = false, ...originalProps }, forwardRef) => {
    const Comp = asChild ? Slot : Primitive.div;
    const { i18n } = useI18n();
    const { className, ...props } = styleBoxProps(originalProps);
    return (
      <Comp className={`tag tag-${variant} ${className}`} {...props} ref={forwardRef}>
        {iconic && <Icon name="tag" width="16px" height="16px" aria-label="#" />}
        <Slottable>{children}</Slottable>
        {onClose && (
          <button type="button" className="close" aria-label={i18n.t('action.close')} onClick={onClose}>
            <Icon name="cancel" width="16" height="16" />
          </button>
        )}
      </Comp>
    );
  },
);
