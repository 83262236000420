// Generalized validation functions

import Polyglot from 'node-polyglot';
import { useI18n } from 'src/lib/i18n';

// Validate if a value matches a regex pattern
export const validatePattern = (value: string | null | undefined, pattern: RegExp): boolean => {
  if (value === null || value === undefined || value === '') return true;
  return pattern.test(value);
};

// Validate if a value is a valid postal code
export const validatePostalCode = (value: string | null | undefined): boolean => {
  if (value === null || value === undefined || value === '') return true;
  return /^\d{3}-\d{4}$/.test(value);
};

// Validate password strength
export const validatePasswordStrength = (value: string | null | undefined): boolean => {
  if (value === null || value === undefined || value === '') return true;
  const hasNumber = /\d/.test(value);
  const hasSymbol = /[!"#$%&'()*+,\-./\\:;?@[\]^_`{|}~]/.test(value);
  return hasNumber && hasSymbol;
};

// Validate include any numeric characters.
export const validateHasNumber = (value: string | null | undefined): boolean => {
  if (value === null || value === undefined || value === '') return true;
  return /\d/.test(value);
};

// Validate include any symbolic characters.
export const validateHasSymbol = (value: string | null | undefined): boolean => {
  if (value === null || value === undefined || value === '') return true;
  return /[!"#$%&'()*+,\-./\\:;?@[\]^_`{|}~ ]/.test(value);
};

// Validate include any alphabetic characters.
export const validateHasAlphabet = (value: string | null | undefined): boolean => {
  if (value === null || value === undefined || value === '') return true;
  return /[a-zA-Z]/.test(value);
};

// Validate if a value meets the password format.
export const validatePasswordFormat = (value: string | null | undefined): boolean => {
  if (value === null || value === undefined || value === '') return true;
  return /^[A-Za-z0-9!"#$%&'()*+,\-./\\:;?@[\]^_`{|}~ ]+$/.test(value);
};

// Validate if two values are equal (e.g., password confirmation)
export const validateEquality = (value1: any, value2: any): boolean => {
  return value1 === value2;
};

export const validatePositiveNumber = (value: string | null | undefined): boolean => {
  if (value === null || value === undefined || value === '') return true;
  return /^-?\d+(\.\d+)?$/.test(value) && parseFloat(value) > 0;
};

export const validateOnlyInteger = (value: string | null | undefined): boolean => {
  if (value === null || value === undefined || value === '') return true;
  return /^-?\d+$/.test(value);
};

export const validateAmount = (value: string | null | undefined): boolean => {
  if (value === null || value === undefined || value === '') return true;
  return /^[1-9][0-9\u002C\u002E]*$/.test(value);
};

// "a-zA-Z\uFF41-\uFF4A\uFF21-\uFF3A": half and full width alphabet
// "0-9\uFF10-\uFF19": half and full width number
// "\u3040-\u309C": hiragana
// "\u30A0-\u30FB": katakana
// "\-\u30FC\uFF0D\uFF70\u002D\u2212": half and full width dashes
const ADDRESS_KANA_PATTERN =
  /^[a-zA-Z\uFF41-\uFF4A\uFF21-\uFF3A0-9\uFF10-\uFF19\s\u3040-\u309C\u30A0-\u30FB\-\u30FC\uFF0D\uFF70\u002D\u2212]+$/;

export const validateAddressKana = (value: string | null | undefined): boolean => {
  if (value === null || value === undefined || value === '') return true;
  return ADDRESS_KANA_PATTERN.test(value);
};

export const validateArrayMaxLength = (
  name: string,
  maxLength: number,
): { array_max_length: (value: Array<any>) => string | undefined } => {
  const { i18n } = useI18n();
  return {
    array_max_length: (value) => {
      if (value.length > maxLength) {
        return i18n.t('validation.array_max_length', { name: i18n.t(`attributes.${name}`), length: maxLength });
      }
    },
  };
};

export const validateMaxLengthOption = ({
  name,
  maxLength,
  i18n,
}: {
  name: string;
  maxLength: number;
  i18n: Polyglot;
}) => {
  return {
    value: maxLength,
    message: i18n.t('validation.max_length', {
      name: i18n.t(`attributes.${name}`),
      length: maxLength.toString(),
    }),
  };
};

export const validateMinLengthOption = ({
  name,
  minLength,
  i18n,
}: {
  name: string;
  minLength: number;
  i18n: Polyglot;
}) => {
  return {
    value: minLength,
    message: i18n.t('validation.min_length', {
      name: i18n.t(`attributes.${name}`),
      length: minLength.toString(),
    }),
  };
};

/**
 * Validates that a string is in ULID format
 * Format: [0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}
 */
export const isValidULID = (value: string): boolean => {
  const ULID_REGEX = /^[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}$/;
  return ULID_REGEX.test(value);
};

/**
 * Validates that a string is a valid screen_name
 * Format: [a-z0-9\-_+\.]+
 */
export const isValidScreenName = (value: string): boolean => {
  const SCREEN_NAME_REGEX = /^[a-z0-9\-_+\.]+$/;
  return SCREEN_NAME_REGEX.test(value);
};
