import { FC, PropsWithChildren } from 'react';
import { onUpdateBusinessOverview } from 'src/actions/business';
import { Form } from 'src/components/control/Form';
import { InputField } from 'src/components/control/InputField';
import { RadioField } from 'src/components/control/RadioField';
import { TextField } from 'src/components/control/TextField';
import { BusinessIssuesView } from 'src/components/features/business/BusinessIssuesView';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';
import { Business, BusinessProfile } from 'src/models/v1/business';

type PartialBusiness = Pick<Business, 'status' | 'business_type' | 'jp_trn_number'> & {
  profile: Pick<BusinessProfile, 'description'>;
};

export const BusinessOverviewForm: FC<PropsWithChildren<{ business: PartialBusiness; onSuccess: () => void }>> = ({
  business,
  onSuccess,
  children,
}) => {
  const { i18n } = useI18n();
  return (
    <Form
      onSubmit={onUpdateBusinessOverview({})}
      onSuccess={onSuccess}
      defaultValues={{
        business: {
          business_type: business.business_type,
          jp_trn_number: business.jp_trn_number,
          profile: { description: business.profile.description },
        },
      }}
      defaultRequired
      readOnly={business.status != 'draft'}
    >
      <BusinessIssuesView kind="review_overview" />
      <RadioField
        name="business.business_type"
        items={{
          individual: i18n.t('values.business.business_type.individual'),
          company: i18n.t('values.business.business_type.company'),
        }}
        options={{ required: true }}
      />
      <TextField
        name="business.profile.description"
        placeholder={i18n.t('placeholder.business.description')}
        options={{ required: true }}
      >
        <Text asChild color="note">
          <p>{i18n.t('guide.business.description')}</p>
        </Text>
      </TextField>
      <InputField
        name="business.jp_trn_number"
        placeholder={i18n.t('placeholder.business.jp_trn_number')}
        options={{ pattern: /^T?\d{13}$/ }}
        autoComplete="off"
      />
      {children}
    </Form>
  );
};
