import './ToDoAlert.css';

import { ComponentProps, ComponentPropsWithoutRef, FC, forwardRef, PropsWithoutRef } from 'react';
import { Button } from 'src/components/primitives/Button';
import { BoxProps, styleBoxProps } from 'src/components/primitives/PrimitiveComponent';
import { HStack, VStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';

import { Primitive } from '@radix-ui/react-primitive';

type ToDoAlertElement = React.ElementRef<typeof Primitive.div>;
type PrimitiveToDoAlertProps = ComponentPropsWithoutRef<typeof Primitive.div> & BoxProps;
interface ToDoAlertProps extends PrimitiveToDoAlertProps {
  variant?: 'default' | 'primary' | 'warning' | 'error';
  embbed?: boolean;
}

const ToDoAlert = forwardRef<ToDoAlertElement, ToDoAlertProps>(
  ({ variant, embbed = false, children, ...originalProps }, forwardedRef) => {
    const { className, ...props } = styleBoxProps(originalProps);
    return (
      <HStack
        ref={forwardedRef}
        className={`to-do-alert to-do-alert-${variant} ${embbed ? 'embbed' : ''} ${className}`}
        {...props}
        align="center"
        spacing="lg"
      >
        {children}
      </HStack>
    );
  },
);

ToDoAlert.displayName = 'ToDoAlert';

const ToDoAlertBody: FC<PropsWithoutRef<Omit<ComponentProps<typeof VStack>, 'align' | 'w'>>> = (props) => {
  return <VStack {...props} align="stretch" w="full" spacing="sm" />;
};

ToDoAlertBody.displayName = 'ToDoAlert.Body';

const ToDoAlertHeading: FC<PropsWithoutRef<ComponentProps<typeof Text>>> = ({ className, ...props }) => {
  return (
    <Text {...props} className={`to-do-alert-heading ${className || ''}`} variant="title" size="small" weight="bold" />
  );
};

ToDoAlertHeading.displayName = 'ToDoAlert.Heading';

const ToDoAlertDescription: FC<PropsWithoutRef<ComponentProps<typeof Text>>> = ({ className, ...props }) => {
  return <Text {...props} className={`to-do-alert-description ${className || ''}`} variant="body" size="medium" />;
};

ToDoAlertDescription.displayName = 'ToDoAlert.Description';

const ToDoAlertButton: FC<PropsWithoutRef<ComponentProps<typeof Button>>> = ({ className, ...props }) => {
  return <Button {...props} className={`to-do-alert-button ${className || ''}`} size="small" />;
};

ToDoAlertButton.displayName = 'ToDoAlert.Button';

const ToDoAlertNamespace = Object.assign(ToDoAlert, {
  Heading: ToDoAlertHeading,
  Body: ToDoAlertBody,
  Description: ToDoAlertDescription,
  Button: ToDoAlertButton,
});

export { ToDoAlertNamespace as ToDoAlert };
