import './AddressInput.css';

import { FC, useId } from 'react';
import { RegisterAddressButton } from 'src/components/features/address/RegisterAddressButton';
import { Address } from 'src/models/v1/address';

export const AddressInput: FC<{
  addresses: Array<Address>;
  value: Address | undefined;
  onChange: (address: Address) => void;
}> = ({ addresses, value, onChange }) => {
  const id = useId();
  return (
    <>
      {addresses?.map((address) => (
        <label className="address-input-item" key={address.gid} htmlFor={`${id}-${address.gid}`}>
          <span className="name">{address.name}</span>
          <address>
            〒{address.postal_code}
            <br />
            {address.state}
            {address.city}
            {address.town}
            <br />
            {address.line1}
            {address.line2 && (
              <>
                <br />
                {address.line2}
              </>
            )}
          </address>
          <input
            type="radio"
            id={`${id}-${address.gid}`}
            value={address.gid}
            checked={value?.gid === address.gid}
            onChange={(event) => event.target.checked && onChange(address)}
          />
        </label>
      ))}
      <RegisterAddressButton
        onRegister={(address) => {
          if (!value) onChange(address);
        }}
      />
    </>
  );
};
