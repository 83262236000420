import { FC } from 'react';
import { NoticeLabel } from 'src/components/primitives/NoticeLabel';
import { useI18n } from 'src/lib/i18n';
import { Ticket } from 'src/models/v1/ticket';

export const TicketPhaseLabel: FC<{ phase: Ticket['phase'] }> = ({ phase }) => {
  const { i18n } = useI18n();
  // TODO: Update translation keys. The current keys misuse the 'attributes' namespace.
  switch (phase) {
    case 'consultation':
      return (
        <NoticeLabel variant="outline" size="small">
          {i18n.t('attributes.message.status.consultation')}
        </NoticeLabel>
      );
    case 'finalized':
      return (
        <NoticeLabel variant="primary" size="small">
          {i18n.t('attributes.message.status.finalized')}
        </NoticeLabel>
      );
    case 'completed':
      return (
        <NoticeLabel variant="fill" size="small">
          {i18n.t('attributes.message.status.completed')}
        </NoticeLabel>
      );
  }
};
