import './WorkMenu.css';

import DropdownMenu from 'src/components/styled-radix/DropdownMenu';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { Icon } from 'src/components/primitives/Icon';
import { api } from 'src/lib/api';
import { ApiError } from 'src/lib/errors';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';
import { useToast } from 'src/lib/toast';
import { Work } from 'src/models/v1/work';
import { onCopyWorkUrl } from 'src/actions/work';

export const WorkMenu: FC<{ work: Work }> = ({ work }) => {
  const { i18n } = useI18n();
  const navigate = useNavigate();
  const { me } = useCurrentStatus();
  const toast = useToast();
  const copyUrl = onCopyWorkUrl({ gid: work.gid });
  const onReport = useCallback(async () => {
    try {
      await api.dispatch<unknown, unknown>('POST', `/v1/works/${work.gid}/spam_reports`, {}, {});
      toast({ message: i18n.t('message.spam_report_success'), variant: 'success' });
    } catch (error: unknown) {
      if (error instanceof ApiError) {
        toast({ message: error.message, variant: 'error' });
      }
    }
  }, [work, toast]);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className="work-menu-trigger" aria-label={i18n.t('label.menu')}>
        <Icon name="more_horiz" />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className="work-menu-content menu-content" align="end">
          <DropdownMenu.Item className="item" onSelect={copyUrl}>
            <Icon className="work-menu-item" name="copy_all" />
            {i18n.t('action.copy_work_url')}
          </DropdownMenu.Item>
          {work.account.gid == me?.gid ? (
            <DropdownMenu.Item className="item" onSelect={() => navigate(`/works/${work.gid}/edit`)}>
              <Icon className="work-menu-item" name="edit" />
              {i18n.t('action.edit')}
            </DropdownMenu.Item>
          ) : (
            <DropdownMenu.Item className="item destructive" onSelect={() => onReport()}>
              <Icon className="work-menu-item" name="error" />
              {i18n.t('action.spam_report')}
            </DropdownMenu.Item>
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
