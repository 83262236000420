import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { createAction } from 'src/lib/action';
import { api } from 'src/lib/api';
import { Business } from 'src/models/v1/business';
import { UpdateJpActOnSpecifiedCommercialTransactionBody } from 'src/models/v1/jp_act_on_specified_commercial_transaction';

export const onUpdateJpActOnSpecifiedCommercialTransaction = createAction(
  'onUpdateJpActOnSpecifiedCommercialTransaction',
  ({ businessStatus }: { businessStatus: Business['status'] }) => {
    const navigate = useNavigate();
    return useCallback(
      async (body: UpdateJpActOnSpecifiedCommercialTransactionBody) => {
        await api.dispatch('PUT', '/v1/accounts/me/business/jp_act_on_specified_commercial_transaction', {}, body);
        if (businessStatus == 'open') {
          navigate('/settings');
        } else {
          navigate('/settings/business_application');
        }
      },
      [navigate, businessStatus],
    );
  },
);
