import { useCallback } from 'react';
import { createAction } from 'src/lib/action';
import {
  CreateQuotationPaymentBody,
  CreateQuotationPaymentResponse,
  Quotation,
  UpdateQuotationBody,
  UpdateQuotationResponse,
} from 'src/models/v1/quotation';
import { api } from 'src/lib/api';
import { useRevalidator } from 'react-router';

export const onUpdateQuotation = createAction(
  'onUpdateQuotation',
  ({
    quotation,
    onComplete,
  }: {
    quotation: Pick<Quotation, 'gid'>;
    onComplete?: (quotation: Quotation) => void | Promise<void>;
  }) => {
    const { gid } = quotation;
    return useCallback(
      async (body: UpdateQuotationBody) => {
        const { quotation } = await api.dispatch<UpdateQuotationBody, UpdateQuotationResponse>(
          'PUT',
          `/v1/quotations/${gid}`,
          undefined,
          body,
        );
        if (onComplete) {
          onComplete(quotation);
        }
      },
      [quotation.gid, onComplete],
    );
  },
);

export const onPayQuotation = createAction('onPayQuotation', ({ gid }: { gid: Quotation['gid'] }) => {
  const { revalidate } = useRevalidator();
  return async (body: CreateQuotationPaymentBody) => {
    const { quotation_payment } = await api.dispatch<{}, CreateQuotationPaymentResponse>(
      'POST',
      `/v1/quotations/${gid}/payment`,
      undefined,
      body,
    );
    if (quotation_payment.url) {
      window.location.href = quotation_payment.url;
    } else {
      revalidate();
    }
  };
});
