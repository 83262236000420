declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export const initializeGTM = (MESUREMENT_ID: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

  var firstScript = document.getElementsByTagName('script')[0],
    gtmScript = document.createElement('script');

  gtmScript.async = true;
  gtmScript.src = 'https://www.googletagmanager.com/gtm.js?id=' + MESUREMENT_ID;
  firstScript.parentNode?.insertBefore(gtmScript, firstScript);
};
