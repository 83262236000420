import { useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router';
import { PageLoader } from 'src/lib/api';
import { CreateWorkBody, GetWorkResponse } from 'src/models/v1/work';

export const WorksNewPage = () => {
  const { work } = useLoaderData() as GetWorkResponse;
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/works/${work.gid}/edit`, { replace: true });
  }, [work]);

  return <></>;
};

export const worksNewLoader: PageLoader = async ({ api }) => {
  return await api.dispatch<CreateWorkBody, GetWorkResponse>(
    'POST',
    '/v1/works',
    {},
    {
      work: {
        status: 'draft',
        body: '',
        delivery_days_unit: '',
      },
    },
  );
};
