import { ComponentPropsWithoutRef, FC, ReactNode } from 'react';
import { FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { ErrorMessage } from 'src/components/control/ErrorMessage';
import { FormControl } from 'src/components/control/FormControl';
import { Field, FieldAddon } from 'src/components/primitives/Field';
import { styleBoxProps } from 'src/components/primitives/PrimitiveComponent';
import { useI18n } from 'src/lib/i18n';

import { Primitive } from '@radix-ui/react-primitive';
import { validateAmount } from 'src/lib/validation';

type PrimitiveAmountFieldProps = ComponentPropsWithoutRef<typeof Primitive.input>;
interface AmountFieldProps extends PrimitiveAmountFieldProps {
  name: string;
  id?: string;
  label?: ReactNode;
  placeholder?: string;
  options?: RegisterOptions<FieldValues, string>;
}

export const AmountField: FC<AmountFieldProps> = ({
  name,
  id = name,
  label,
  placeholder,
  options,
  children,
  ...originalProps
}) => {
  const { ...props } = styleBoxProps(originalProps);
  const { i18n } = useI18n();
  const { register } = useFormContext<FieldValues>();
  return (
    <FormControl
      id={id}
      name={name}
      required={options?.required ? true : false}
      label={label || i18n.t(`attributes.${name}`)}
    >
      {children}
      <Field>
        <FieldAddon>¥</FieldAddon>
        <input
          id={name}
          placeholder={placeholder || (i18n.has(`placeholder.${name}`) ? i18n.t(`placeholder.${name}`) : '')}
          {...register(name, { ...options, validate: { ...(options?.validate || {}), amount: validateAmount } })}
          {...props}
        />
      </Field>
      <ErrorMessage name={name} label={label || i18n.t(`attributes.${name}`)} />
    </FormControl>
  );
};
