import { FC } from 'react';
import { onWithdrawBusinessApplication } from 'src/actions/business_application';
import { Button } from 'src/components/primitives/Button';
import { Icon } from 'src/components/primitives/Icon';
import { Note } from 'src/components/primitives/Note';
import { useI18n } from 'src/lib/i18n';

export const BusinessInReviewNote: FC = () => {
  const { i18n } = useI18n();
  return (
    <>
      <Note.Root>
        <Icon name="mobile_friendly" />
        <Note.Description>{i18n.t('guide.business_is_in_review')}</Note.Description>
      </Note.Root>
      <Button variant="danger" onClick={onWithdrawBusinessApplication({})}>
        {i18n.t('action.withdraw_application')}
      </Button>
    </>
  );
};
