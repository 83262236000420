import { factoryBusinessProfile } from 'src/catalog/factories/businessProfile';
import { Meta } from 'src/catalog/Story';
import { ReceptionForm } from 'src/components/features/receptions/ReceptionForm';
import { factoryAccount } from 'src/catalog/factories/account';

const meta: Meta<typeof ReceptionForm> = {
  title: 'Reception Form',
  args: {
    account: factoryAccount(),
    businessProfile: factoryBusinessProfile(),
    businessStatus: 'verified',
  },
  template: (args) => <ReceptionForm {...args} />,
};

export default meta;
