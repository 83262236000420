import './ImagePreview.css';

import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { BoxProps, styleBoxProps } from 'src/components/primitives/PrimitiveComponent';

import { Primitive } from '@radix-ui/react-primitive';

type ImagePreviewElement = React.ElementRef<typeof Primitive.img>;
type PrimitiveImagePreviewProps = ComponentPropsWithoutRef<typeof Primitive.img> & BoxProps;
interface ImagePreviewProps extends PrimitiveImagePreviewProps {
  draggableHeight?: boolean;
}

export const ImagePreview = forwardRef<ImagePreviewElement, ImagePreviewProps>(
  (
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      draggableHeight,
      ...originalProps
    },
    forwardedRef,
  ) => {
    const { className, ...props } = styleBoxProps(originalProps);
    return <Primitive.img className={['image-preview', className].join(' ')} {...props} ref={forwardedRef} />;
  },
);

ImagePreview.displayName = 'ImagePreview';
