import { Meta, Story } from 'src/catalog/Story';
import { Banner } from './Banner';
import { factoryAccount } from 'src/catalog/factories/account';

const meta: Meta<typeof Banner> = {
  title: 'Banner',
  args: {
    account: {
      ...factoryAccount(),
      icon_url: 'https://picsum.photos/300',
      banner_url: 'https://picsum.photos/1500/700',
    },
  },
  template: (args) => <Banner {...args} />,
};

export default meta;

export const alternativeBanner: Story<typeof Banner> = {
  name: 'Alternative Banner',
  args: {
    account: {
      ...factoryAccount(),
      icon_url: 'https://picsum.photos/300',
    },
  },
};

export const bannerBackground: Story<typeof Banner> = {
  name: 'Banner Background',
  args: {
    account: {
      ...factoryAccount(),
      icon_url: null,
      banner_url: null,
    },
  },
};
