import './PrintAnnotation.css';

import { FC } from 'react';
import { useI18n } from 'src/lib/i18n';

export const PrintAnnotation: FC = () => {
  const { i18n } = useI18n();
  return (
    <aside className="print-annotation">
      <p>{i18n.t('guide.print_annotation')}</p>
    </aside>
  );
};
