import Dialog from 'src/components/styled-radix/Dialog';
import { ComponentProps, FC, useState } from 'react';
import { Button } from 'src/components/primitives/Button';
import { HStack } from 'src/components/primitives/Stack';
import { Icon } from 'src/components/primitives/Icon';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';

/**
 * Confirmation Button works with i18n translation.
 *
 * {scope}.title: Dialog Title
 * {scope}.cancel: Cancel Button Label
 * {scope}.confirm: Confirm Button Label
 */
export const ConfirmationButton: FC<
  { scope: string; isConfirm?: boolean; onConfirm: () => void | Promise<void>; type?: 'button' | 'submit' } & Omit<
    ComponentProps<typeof Button> & { type: 'button' },
    'type' | 'onClick' | 'slot' | 'ref'
  >
> = ({ scope, type = 'button', isConfirm = false, onConfirm, ...props }) => {
  const { i18n } = useI18n();
  const [open, setOpen] = useState(false);
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <Button {...props} type="button" onClick={() => (isConfirm ? onConfirm() : setOpen(true))} />
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content variant="alert" className="confirmation-content">
          <Dialog.Close>
            <Icon name="close" aria-label={i18n.t('action.close')} />
          </Dialog.Close>
          {i18n.t(`${scope}.title`) && (
            <Dialog.Title className="title">
              <Text variant="title" weight="bold">
                {i18n.t(`${scope}.title`)}
              </Text>
            </Dialog.Title>
          )}
          {i18n.t(`${scope}.description`) && (
            <Dialog.Description className="description">
              <Text>{i18n.t(`${scope}.description`)}</Text>
            </Dialog.Description>
          )}
          <HStack className="actions" m="none">
            <Button variant="outline" onClick={() => setOpen(false)} size="default">
              {i18n.t(`${scope}.cancel`)}
            </Button>
            <Button variant="danger" type={type} onClick={onConfirm} size="default">
              {i18n.t(`${scope}.confirm`)}
            </Button>
          </HStack>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
