import { WorkEditForm } from 'src/components/features/work/WorkEditForm';
import { Meta, Story } from 'src/catalog/Story';
import { factoryWork } from 'src/catalog/factories/work';

const meta: Meta<typeof WorkEditForm> = {
  title: 'Work Edit Form',
  args: {
    work: factoryWork('blank'),
  },
  template: (args) => <WorkEditForm {...args} />,
};

export default meta;

export const entered: Story<typeof WorkEditForm> = {
  name: 'Entered',
  args: {
    work: factoryWork(),
  },
};
