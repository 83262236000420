import { factoryAccount } from 'src/catalog/factories/account';
import { defineFactory, FactoryBlueprint } from 'src/catalog/factories/factoryBuilder';
import { fakeGid } from 'src/catalog/factories/gid';
import { factoryTicket } from 'src/catalog/factories/ticket';
import { Quotation } from 'src/models/v1/quotation';

// MARK: base

const base: FactoryBlueprint<Quotation> = {
  gid: fakeGid(),
  title: 'スニーカーのソール交換のお見積り - 長久太郎様',
  management_number: '1234567890',
  author: factoryAccount('repairer'),
  payor: factoryAccount('caller'),
  html: {
    note: '\u003cp\u003eHere is note.\u003c/p\u003e\u003cp\u003enotes and other information can be written here.\u003c/p\u003e',
  },
  status: 'published',
  items: [
    {
      label: 'Item 1',
      amount: 2000,
      quantity: 1,
    },
  ],
  note: 'Here is note.\nnotes and other information can be written here.',
  published_at: '2024-11-30T23:00:00.000Z', // 2024/12/01 in JST
  expires_date: '2024-12-31',
  breakdown: {
    total_amount: 2200,
    subtotal_amount: 2000,
    consumption_tax_amount: 200,
    shipping_fee: 500,
  },
  estimate_delivery_days: 2,
  delivery_days_unit: 'week',
  ticket_gid: fakeGid(),
};

// MARK: ticket

const ticket: Partial<Quotation> = {
  ticket: factoryTicket(),
};

// MARK: factory

export const factoryQuotation = defineFactory(base, { ticket });
