import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router';
import { AccountBar } from 'src/components/features/AccountBar';
import { AccountProfileCard } from 'src/components/features/account/AccountProfileCard';
import { WorkMenu } from 'src/components/features/work/WorkMenu';
import { NavigationLayout } from 'src/components/Layout';
import { HStack } from 'src/components/primitives/Stack';
import { PageLoader } from 'src/lib/api';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';
import { Work } from 'src/models/v1/work';
import { WorkView } from 'src/components/features/work/WorkView';
import { LikeSwitchBar } from 'src/components/features/LikeSwitchBar';
import { Timestamp } from 'src/components/primitives/Timestamp';

type Data = {
  work: Work;
};

export const WorkDetailPage: FC = () => {
  const { i18n } = useI18n();
  const { work } = useLoaderData() as Data;
  const { me } = useCurrentStatus();

  return (
    <NavigationLayout>
      {work.status == 'published' && work.after_image_media_file ? (
        <Helmet>
          <title>
            {`${i18n.t('title.work', { display_name: work.account.display_name })} ${work.tags?.map((tag) => `#${tag}`).join(' ')} | ${i18n.t('nagaku')}`}
          </title>
          <meta
            property="og:title"
            content={`${i18n.t('title.work', { display_name: work.account.display_name })} ${work.tags?.map((tag) => `#${tag}`).join(' ')} | ${i18n.t('nagaku')}`}
          />
          <meta name="description" content={work.body || ''} />
          <meta property="og:description" content={work.body || ''} />
          <meta property="og:image" content={work.after_image_media_file.url.concat('/s2048')} />
          <meta property="og:image:width" content="2048" />
          <meta property="og:image:height" content="2048" />
          <meta name="robots" content="noindex" />
        </Helmet>
      ) : (
        <Helmet>
          <title>{i18n.t('title.draft_work')}</title>
          <meta property="og:title" content={`${i18n.t('title.draft_work')} | ${i18n.t('nagaku')}`} />
          <meta name="description" content={work.body || ''} />
          <meta property="og:description" content={work.body || ''} />
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
      <AccountBar account={work.account} avatarSize="md" menu={me?.gid == work.account.gid && <WorkMenu work={work} />}>
        {work.published_at && <Timestamp variant={'body'} value={new Date(work.published_at)} format="year-date" />}
      </AccountBar>
      <HStack justify="start" m="none" spacing="sm">
        <LikeSwitchBar
          gid={work.gid}
          liked={work.liked}
          likesCount={work.likes_count}
          returnTo={`/works/${work.gid}`}
        />
      </HStack>
      <WorkView work={work} />
      <AccountProfileCard account={work.account} />
    </NavigationLayout>
  );
};

export const worksDetailLoader: PageLoader = async ({ params, api }) => {
  const { gid } = params;
  return await api.fetch(`/v1/works/${gid}`);
};
