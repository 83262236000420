import { FC, useState, useTransition } from 'react';
import { onPostInquiry } from 'src/actions/inquiry';
import { Form } from 'src/components/control/Form';
import { FormButton } from 'src/components/control/FormButton';
import { InputField } from 'src/components/control/InputField';
import { TextField } from 'src/components/control/TextField';
import { AccountInquiryComplete } from 'src/components/features/contact/AccountInquiryComplete';
import { Icon } from 'src/components/primitives/Icon';
import { Note } from 'src/components/primitives/Note';
import { useI18n } from 'src/lib/i18n';
import { Account, AccountContact } from 'src/models/v1/account';

type InquiryState = 'inputting' | 'completed';

export const AccountInquiryForm: FC<{ account: Account; contact: AccountContact }> = ({ account, contact }) => {
  if (account.kind !== 'pro') return;
  const { i18n } = useI18n();
  const [isPending, startTransition] = useTransition();
  const [state, setState] = useState<InquiryState>('inputting');
  return (
    <Form
      defaultValues={{
        inquiry: {
          inquirer_name: '',
          phone: '',
          email: '',
          message_id: '',
          subject: '',
          body: '',
        },
      }}
      defaultRequired
      disabled={isPending}
      onSubmit={onPostInquiry({ account, startTransition, onSuccess: () => setState('completed') })}
      mode="all"
    >
      {state === 'inputting' ? (
        <>
          <InputField name="inquiry.inquirer_name" options={{ required: true }} />
          <InputField name="inquiry.phone" autoComplete="tel" />
          <InputField name="inquiry.email" options={{ required: true }} type="email" />
          <InputField name="inquiry.subject" options={{ required: true }} />
          <TextField name="inquiry.body" options={{ required: true }} />
          <FormButton>{i18n.t('action.send')}</FormButton>
        </>
      ) : (
        <AccountInquiryComplete account={account} />
      )}
      <Note.Root>
        <Icon name="info" />
        <Note.Description>{i18n.t('guide.inquiry.domain_filter', { domain: contact.email_domain })}</Note.Description>
      </Note.Root>
    </Form>
  );
};
