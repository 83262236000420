import './AccountCard.css';

import { FC } from 'react';
import { Link } from 'react-router';
import { AccountCardWorksGallery } from 'src/components/features/account/AccountCardWorksGallery';
import { AccountMenu } from 'src/components/features/account/AccountMenu';
import { Avatar } from 'src/components/primitives/Avatar';
import { Banner } from 'src/components/primitives/Banner';
import { Card, CardContent } from 'src/components/primitives/Card';
import { CounterLabel } from 'src/components/primitives/CounterLabel';
import { IconicLabel } from 'src/components/primitives/IconicLabel';
import { HStack, VStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';
import { Account } from 'src/models/v1/account';
import { BusinessProfile } from 'src/models/v1/business';

export const AccountCard: FC<{
  account: Account & {
    business_profile?: Pick<BusinessProfile, 'tagline' | 'support_types' | 'area'> | null;
  };
}> = ({ account }) => {
  const { i18n } = useI18n();
  return (
    <Card className="account-card">
      <Link className="account-banner" to={`/accounts/${account.gid}`}>
        <Banner account={account} />
      </Link>
      <CardContent asChild>
        <VStack spacing="sm" align="start">
          <VStack asChild spacing="xs" align="start">
            <Link className="link" to={`/accounts/${account.gid}`}>
              <HStack className="bar" w="full" justify="flex-end">
                <Avatar className="icon" account={account} src={account.icon_url || undefined} />
                <AccountMenu account={account} />
              </HStack>
              <Text asChild variant="title" size="medium" weight="bold">
                <p>{account.display_name}</p>
              </Text>
              {account.kind == 'pro' && account.business_profile && (
                <>
                  <Text asChild>
                    <p>{account.business_profile.tagline}</p>
                  </Text>
                  <HStack justify="start">
                    <IconicLabel icon={'location_on'} variant={'fill'}>
                      <Text weight="bold">{account.business_profile.area}</Text>
                    </IconicLabel>
                    {account.business_profile.support_types.length > 0 && (
                      <IconicLabel icon={'local_shipping'} variant={'fill'}>
                        <Text weight="bold">
                          {account.business_profile.support_types
                            .map((type) => i18n.t(`attributes.business_profile.support_${type}`))
                            .join(' / ')}
                        </Text>
                      </IconicLabel>
                    )}
                  </HStack>
                </>
              )}
            </Link>
          </VStack>
          <CounterLabel unit="unit.works" count={account.published_works_count} />
          {/* <TagCollection tags={account.tags} /> */}
          <AccountCardWorksGallery accountGid={account.gid} />
        </VStack>
      </CardContent>
    </Card>
  );
};
