import { QuotationView } from 'src/components/features/quotation/QuotationView';
import { Meta } from 'src/catalog/Story';
import { TicketContext } from 'src/lib/ticket';
import { factoryQuotation } from 'src/catalog/factories/quotation';

const meta: Meta<typeof QuotationView> = {
  title: 'Quotation View',
  args: {
    quotation: factoryQuotation(),
  },
  template: (args) => (
    <TicketContext.Provider
      value={{
        gid: '0000',
        status: 'open',
        accounts: [],
      }}
    >
      <QuotationView {...args} />
    </TicketContext.Provider>
  ),
};

export default meta;
