import { useCallback } from 'react';
import { useNavigate, useRevalidator } from 'react-router';
import { createAction } from 'src/lib/action';
import { api } from 'src/lib/api';
import { Account } from 'src/models/v1/account';

export const onSubmitBusinessApplication = createAction('onSubmitBusinessApplication', () => {
  const { revalidate } = useRevalidator();
  return useCallback(async () => {
    await api.dispatch('POST', '/v1/accounts/me/business/review', undefined, {
      stripe_tos_acceptance: {
        accepted_at: new Date(),
      },
    });
    revalidate();
  }, [revalidate]);
});

export const onWithdrawBusinessApplication = createAction('onWithdrawBusinessApplication', () => {
  const { revalidate } = useRevalidator();
  return useCallback(async () => {
    await api.dispatch('DELETE', '/v1/accounts/me/business/review');
    revalidate();
  }, [revalidate]);
});

export const onOpenBusinessAccount = createAction(
  'onOpenBusinessAccount',
  ({ accountGid }: { accountGid: Account['gid'] }) => {
    const navigate = useNavigate();
    return useCallback(async () => {
      await api.dispatch('POST', `/v1/accounts/${accountGid}/business/activation`);
      navigate('/accounts/me');
    }, [accountGid]);
  },
);
