import { FC } from 'react';
import { Link } from 'react-router';
import { Icon } from 'src/components/primitives/Icon';
import { Note } from 'src/components/primitives/Note';
import { useI18n } from 'src/lib/i18n';
import { Business } from 'src/models/v1/business';

export const PrepareBusinessNavigation: FC<{ openRequirements: Business['open_requirements'] }> = ({
  openRequirements,
}) => {
  const { i18n } = useI18n();
  return (
    <>
      <Note.Root asChild>
        <Link to="/settings/business_profile/edit">
          <Icon name="maps_home_work" />
          <Note.Description>{i18n.t('action.edit_business_profile')}</Note.Description>
          <Icon name="chevron_right" />
        </Link>
      </Note.Root>
      <Note.Root asChild>
        <Link to="/accounts/me/edit">
          <Icon name="person" />
          <Note.Description>{i18n.t('action.edit_profile')}</Note.Description>
          <Icon name="chevron_right" />
        </Link>
      </Note.Root>
      <Note.Root
        asChild
        variant={
          Object.keys(openRequirements).includes('jp_act_on_specified_commercial_transaction') ? 'error' : 'normal'
        }
      >
        <Link to="/settings/jp_act_on_specified_commercial_transaction/edit">
          <Icon name="article" />
          <Note.Description>
            {i18n.t(
              Object.keys(openRequirements).includes('jp_act_on_specified_commercial_transaction')
                ? 'action.enter_jp_act_on_specified_commercial_transaction'
                : 'action.edit_jp_act_on_specified_commercial_transaction',
            )}
          </Note.Description>
          <Icon name="chevron_right" />
        </Link>
      </Note.Root>
    </>
  );
};
