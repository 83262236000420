import './WorksGallery.css';

import { FC, ReactNode, useMemo } from 'react';
import { PagingNavigation } from 'src/components/control/PagingNavigation';
import { RuntimeErrorView } from 'src/components/error/RuntimeErrorView';
import { WorkCard } from 'src/components/features/work/WorkCard';
import { ContentEmpty } from 'src/components/features/ContentEmpty';
import { Spinner } from 'src/components/primitives/Spinner';
import { useApi } from 'src/lib/api';
import { ListWorksResponse } from 'src/models/v1/work';

export const WorksGallery: FC<{
  path: string;
  search?: { [name: string]: string };
  pageSize?: number;
  page?: string;
  empty?: ReactNode;
  enablePagination?: boolean;
}> = ({ path, search: _search, pageSize, page, empty = <ContentEmpty />, enablePagination = true }) => {
  if (import.meta.env.SSR) {
    return (
      <section className="works-gallery" suppressHydrationWarning>
        <Spinner size="medium" />
      </section>
    );
  }

  const search = useMemo(() => {
    const search = Object.assign({}, _search);
    if (typeof search.status == 'undefined') {
      search.status = 'published';
    }
    if (pageSize) {
      search.per = pageSize.toString();
    }
    if (page) {
      search.page = page;
    }
    return search;
  }, [_search, pageSize, page]);

  const { data, isLoading, error } = useApi<ListWorksResponse>(path, search, { cache: true });
  const { works, pagination } = data || {};

  return (
    <section className="works-gallery" suppressHydrationWarning>
      {works?.map((work) => <WorkCard key={work.gid} work={work} />)}
      {(works?.length || 0) === 0 && (isLoading ? <Spinner size="medium" /> : empty)}
      {error && <RuntimeErrorView error={error} />}
      {enablePagination && pagination && (
        <PagingNavigation current={pagination.current} totalPages={pagination.pages} />
      )}
    </section>
  );
};
