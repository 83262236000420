import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { createAction } from 'src/lib/action';
import { api } from 'src/lib/api';
import { UpdateEmailBody } from 'src/models/v1/account';

export const onUpdateEmail = createAction('onUpdateEmail', () => {
  return useCallback(async (body: UpdateEmailBody) => {
    await api.dispatch('POST', `/v1/accounts/me/change_email`, {}, body);
  }, []);
});
