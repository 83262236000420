import { factoryBusinessProfile } from 'src/catalog/factories/businessProfile';
import { fakeGid } from 'src/catalog/factories/gid';
import { Meta } from 'src/catalog/Story';
import { BusinessProfileView } from 'src/components/features/business_profile/BusinessProfileView';

const meta: Meta<typeof BusinessProfileView> = {
  title: 'Business Profile View',
  args: {
    businessProfile: factoryBusinessProfile(),
    accountGid: fakeGid(),
  },
  template: (args) => <BusinessProfileView {...args} />,
};

export default meta;
