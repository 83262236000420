import { FC } from 'react';
import { BackableBreadcrumb } from 'src/components/features/BackableBreadcrumb';
import { Helmet } from 'react-helmet-async';
import { redirect, useLoaderData } from 'react-router';
import { BusinessInReviewNote } from 'src/components/features/business/BusinessInReviewNote';
import { BusinessPreview } from 'src/components/features/business/BusinessPreview';
import { OpenBusinessForm } from 'src/components/features/business/OpenBusinessForm';
import { PrepareBusinessNavigation } from 'src/components/features/business/PrepareBusinessNavigation';
import { SubmitBusinessAccountApplication } from 'src/components/features/business/SubmitBusinessAccountApplicationForm';
import { NavigationLayout } from 'src/components/Layout';
import { Text } from 'src/components/primitives/Text';
import { PageLoader } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { AccountSetting } from 'src/models/v1/account';
import { Business, RetrieveBusinessResponse } from 'src/models/v1/business';

type Data = {
  business: Business;
  account_setting: AccountSetting;
};

export const BusinessApplicationPage: FC = () => {
  const { i18n } = useI18n();
  const { business, account_setting: setting } = useLoaderData() as Data;

  return (
    <NavigationLayout>
      <Helmet>
        <title>{`${i18n.t('title.pro_account_application')} · ${i18n.t('title.setting')} | ${i18n.t('nagaku')}`}</title>
        <meta name="description" content="" />
      </Helmet>
      <BackableBreadcrumb homePath="/settings" currentPath="/settings/business_application">
        {i18n.t('title.pro_account_application')}
      </BackableBreadcrumb>
      {business?.status == 'in_review' && <BusinessInReviewNote />}
      {business?.status == 'verified' ? (
        <>
          <Text asChild>
            <p>{i18n.t('guide.business.verified')}</p>
          </Text>
          <PrepareBusinessNavigation openRequirements={business.open_requirements} />
          <OpenBusinessForm business={business} emailVerified={setting.email_verified} />
        </>
      ) : (
        <BusinessPreview business={business} />
      )}
      {business?.status == 'draft' && (
        <SubmitBusinessAccountApplication business={business} emailVerified={setting.email_verified} />
      )}
    </NavigationLayout>
  );
};

export const businessApplicationLoader: PageLoader = async ({ api }) => {
  const data = await api.combine(
    api.fetch<RetrieveBusinessResponse>('/v1/accounts/me/business'),
    api.fetch('/v1/accounts/me/setting'),
  );
  if (data.business.status == 'open') {
    return redirect('/settings/business');
  }
  return data;
};
