import './Field.css';

import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { BoxProps, styleBoxProps } from 'src/components/primitives/PrimitiveComponent';

import { Primitive } from '@radix-ui/react-primitive';

type FieldElement = React.ElementRef<typeof Primitive.div>;
type PrimitiveFieldProps = ComponentPropsWithoutRef<typeof Primitive.div> & BoxProps;
interface FieldProps extends PrimitiveFieldProps {
  draggableHeight?: boolean;
  disabled?: boolean;
}

export const Field = forwardRef<FieldElement, FieldProps>(
  ({ draggableHeight, disabled = false, ...originalProps }, forwardedRef) => {
    const { className, ...props } = styleBoxProps(originalProps);
    return (
      <Primitive.div
        className={[
          'field',
          disabled ? 'disabled' : '',
          draggableHeight ? 'field-draggable-height' : '',
          className,
        ].join(' ')}
        {...props}
        ref={forwardedRef}
      />
    );
  },
);

Field.displayName = 'Field';

type FieldAddonElement = React.ElementRef<typeof Primitive.div>;
type PrimitiveFielAddonProps = ComponentPropsWithoutRef<typeof Primitive.div> & BoxProps;
interface FieldAddonProps extends PrimitiveFielAddonProps {
  variant?: 'fill' | 'ghost';
}

export const FieldAddon = forwardRef<FieldAddonElement, FieldAddonProps>(
  ({ variant = 'ghost', ...originalProps }, forwardedRef) => {
    const { className, ...props } = styleBoxProps(originalProps);
    return (
      <Primitive.div {...props} className={`field-addon field-addon-${variant} ${className}`} ref={forwardedRef} />
    );
  },
);
