import { Component, ErrorInfo, ReactNode } from 'react';
import { ErrorDescription } from 'src/components/error/ErrorDescription';

import * as Sentry from '@sentry/react';

interface Props {
  children?: ReactNode;
}

interface State {
  error: unknown;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    error: undefined,
  };

  public static getDerivedStateFromError(error: Error): State {
    Sentry.captureException(error);
    return { error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.captureException(error);
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.error) {
      return <ErrorDescription error={this.state.error} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
