import { FC } from 'react';
import { BackableBreadcrumb } from 'src/components/features/BackableBreadcrumb';
import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router';
import { NavigationLayout } from 'src/components/Layout';
import { PageLoader } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { Business } from 'src/models/v1/business';
import { ReceptionForm } from 'src/components/features/receptions/ReceptionForm';
import { Account } from 'src/models/v1/account';

type Data = {
  account: Account;
  business: Business;
};

export const EditReceptionPage: FC = () => {
  const {
    account,
    account: { business_profile },
    business,
  } = useLoaderData() as Data;
  const { i18n } = useI18n();
  return (
    <NavigationLayout>
      <Helmet>
        <title>{`${i18n.t('title.reception')} | ${i18n.t('nagaku')}`}</title>
        <meta name="description" content="" />
      </Helmet>
      <BackableBreadcrumb homePath="/settings" currentPath="/settings/business_profile/edit">
        {i18n.t('title.reception')}
      </BackableBreadcrumb>
      {business_profile && (
        <ReceptionForm account={account} businessProfile={business_profile} businessStatus={business.status} />
      )}
    </NavigationLayout>
  );
};

export const editReceptionLoader: PageLoader = async ({ api }) => {
  const [{ account }, { business }] = await Promise.all([
    api.fetch<{ account: Account }>('/v1/accounts/me', { include: 'business_profile' }),
    api.fetch<{ business: Business }>('/v1/accounts/me/business'),
  ]);

  return { account, business };
};
