import './AboutProView.css';

import { FC, useCallback } from 'react';
import { Link, useNavigate } from 'react-router';
import { AuthRequiredButton } from 'src/components/features/AuthRequiredButton';
import { Button, LinkButton } from 'src/components/primitives/Button';
import { Icon } from 'src/components/primitives/Icon';
import { Text } from 'src/components/primitives/Text';
import { api } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { Account } from 'src/models/v1/account';
import { Business, UpdateBusinessBody, UpdateBusinessResponse } from 'src/models/v1/business';

const IMAGES: { url: string; account: Pick<Account, 'display_name' | 'gid'> }[] = [
  {
    url: 'https://nagaku.com/image/about-pro/1.webp',
    account: { display_name: 'プラス デン ファニチャー', gid: '01HSQ2EZE36RDCXHX4WFG69Z0E' },
  },
];

export const AboutProView: FC<{ business: Pick<Business, 'status'> | undefined }> = ({ business }) => {
  const { i18n } = useI18n();
  return (
    <article className="about-pro-view">
      <HeroImage />
      {i18n.locale() != 'ja' && (
        <Text asChild color="hint" mt="md" data-nosnippet>
          <aside>{i18n.t('guide.only_ja')}</aside>
        </Text>
      )}
      <div className="about-pro-view-content">
        <FeaturesSection />
        <StartApplicationButton status={business?.status} />
        <PlatformFeeSection />
        <StartUsingSection />
        <FaqSection />
        <StartApplicationButton status={business?.status} />
      </div>
    </article>
  );
};

const HeroImage: FC = () => {
  const image = IMAGES[0];
  return (
    <>
      <header id="hero-image" style={{ backgroundImage: `url(${image.url})` }}>
        <h1>
          プロアカウントになって、
          <wbr />
          リペア・リメイクを
          <wbr />
          受注しませんか？
        </h1>
      </header>
      <aside id="author" data-nosnippet aria-label="Image credit">
        Photo by <Link to={`/accounts/${image.account.gid}`}>{image.account.display_name}</Link>
      </aside>
    </>
  );
};

const FeaturesSection: FC = () => {
  return (
    <section id="features-section">
      <h1>ナガクの特徴・機能</h1>
      <ol>
        <li>
          <div className="content">
            日本初！* <strong>リペアやリメイクに特化</strong>したウェブサービス
          </div>
        </li>
        <li>
          <div className="content">
            リスクなく始められる、<strong>初期費用・月額費用0円</strong>
          </div>
        </li>
        <li>
          <div className="content">
            ビフォア/アフターを魅力的に見せる投稿機能など、<strong>リペア・リメイクに最適なデザイン、機能</strong>
          </div>
        </li>
        <li>
          <div className="content">
            メッセージ機能や見積もり作成機能、決済機能などで、<strong>顧客とのやり取り、事務作業の負担を軽減</strong>
          </div>
        </li>
      </ol>
      <p className="hint">*当社調べ</p>
    </section>
  );
};

const PlatformFeeSection: FC = () => {
  return (
    <section id="platform-fee-section">
      <h1>サービス利用料</h1>
      <p>
        ナガクは<strong>初期投資なし</strong>でご利用いただけます。
      </p>
      <table>
        <tbody>
          <tr>
            <th>初期費用・月額費用</th>
            <td>
              <strong>0円</strong>
            </td>
          </tr>
          <tr>
            <th>手数料</th>
            <td>受注金額の20%</td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

const StartUsingSection: FC = () => {
  return (
    <section id="start-using-section">
      <h1>利用開始までの流れ</h1>
      <ol>
        <li>
          <h2>プロアカウントの利用申請を行う</h2>
          <ul>
            <li>ビジネスの概要</li>
            <li>責任者の住所、連絡先など</li>
            <li>身分証明書類のアップロード</li>
            <li>銀行口座</li>
          </ul>
        </li>
        <Icon name="expand_more" aria-hidden />
        <li>
          <h2>ナガクで審査・承認</h2>
          <ul>
            <li>審査は10営業日以内に完了します</li>
            <li>申請内容に問題がある場合、修正の上、再申請していただきます</li>
          </ul>
        </li>
        <Icon name="expand_more" aria-hidden />
        <li>
          <h2>プロアカウントの利用を開始</h2>
          <ul>
            <li>
              利用を開始すると、他のユーザーにプロアカウントとして表示され、依頼を受けることができるようになります
            </li>
          </ul>
        </li>
      </ol>
    </section>
  );
};

const FaqSection: FC = () => {
  return (
    <section id="faq-section">
      <h1>よくあるご質問・お問い合わせ</h1>
      <p>不明点などございましたら、お気軽にお問い合わせください。</p>
      <ul>
        <li>
          <a href="https://help.nagaku.com">
            ヘルプ・お問い合わせ
            <Icon name="chevron_right" />
          </a>
        </li>
      </ul>
    </section>
  );
};

const StartApplicationButton: FC<{ status: Business['status'] | undefined }> = ({ status }) => {
  const navigate = useNavigate();
  const onStartApplication = useCallback(async () => {
    await api.dispatch<UpdateBusinessBody, UpdateBusinessResponse>('PUT', '/v1/accounts/me/business', undefined, {
      business: { business_type: 'individual', status: 'draft' },
    });
    navigate('/settings/business_application/edit');
  }, [navigate]);
  return (
    <section className="action">
      {status == 'draft' ? (
        <LinkButton variant="primary" to="/settings/business_application/edit">
          <Icon name="article" /> プロアカウント申請を続ける
        </LinkButton>
      ) : (
        <AuthRequiredButton returnTo={'/about/pro'}>
          <Button variant="primary" onClick={onStartApplication}>
            <Icon name="article" /> プロアカウント申請する
          </Button>
        </AuthRequiredButton>
      )}
    </section>
  );
};
