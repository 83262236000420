import { FC, useRef } from 'react';
import { useSearchParams } from 'react-router';
import { TagsInput, CustomEvent } from 'src/components/control/TagsInput';
import { NavigationLayout } from 'src/components/Layout';
import { Heading } from 'src/components/primitives/Heading';
import { useI18n } from 'src/lib/i18n';
import { Helmet } from 'react-helmet-async';
import { WorksGallery } from 'src/components/features/work/WorksGallery';
import { ContentEmpty } from 'src/components/features/ContentEmpty';

export const LikesPage: FC = () => {
  const { i18n } = useI18n();
  const [searchParams, setSearchParams] = useSearchParams();
  const tag = searchParams.get('tag') || '';
  const page = searchParams.get('page') || '';
  const inputRef = useRef<HTMLInputElement>(null);

  const onChange = (event: CustomEvent<HTMLInputElement | HTMLButtonElement | null, { value: string[] }>) => {
    inputRef.current?.blur();
    setSearchParams(
      (searchParams) => {
        const values = [...event.currentTarget.value];
        const lastTag = values.length > 0 ? values[values.length - 1] : undefined;
        const newSearchParams = new URLSearchParams(searchParams);
        if (lastTag) {
          newSearchParams.set('tag', lastTag);
        } else {
          newSearchParams.delete('tag');
        }
        return newSearchParams;
      },
      { replace: true },
    );
  };

  return (
    <NavigationLayout>
      <Helmet>
        <title>{`${i18n.t('title.likes')} | ${i18n.t('nagaku')} - ${i18n.t('title.home')}`}</title>
        <meta name="description" content="" />
      </Helmet>
      <Heading>{i18n.t('title.likes')}</Heading>
      <TagsInput
        ref={inputRef}
        icon="search"
        value={tag ? [tag] : undefined}
        placeholder={i18n.t('placeholder.search_by_tag')}
        noStatistics
        onChange={onChange}
      />
      <WorksGallery
        key={`works-likes-${page}`}
        path={`/v1/works`}
        search={{ tag, liked_by: 'me', sort: 'liked-desc' }}
        page={page}
        empty={<ContentEmpty type="like" />}
      />
    </NavigationLayout>
  );
};
