import './ActionHeading.css';

import { ComponentProps, FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';
import { Button } from 'src/components/primitives/Button';
import { Heading } from 'src/components/primitives/Heading';
import { Icon } from 'src/components/primitives/Icon';

export const ActionHeading: FC<
  PropsWithChildren<{ title: string; icon?: ComponentProps<typeof Icon>['name']; to: string }>
> = ({ title, icon = 'search', to, children }) => {
  const navigate = useNavigate();

  return (
    <header className="action-heading">
      <Heading>{title}</Heading>
      <Button size="small" variant="fill" onClick={() => navigate(`${to}`)}>
        <Icon name={icon} width="16px" height="16px" />
        {children}
      </Button>
    </header>
  );
};
