import { Meta } from 'src/catalog/Story';
import { OrderTable } from 'src/components/features/order/OrderTable';
import { factoryOrder } from 'src/catalog/factories/order';

const meta: Meta<typeof OrderTable> = {
  title: 'Order Table',
  args: {
    orders: [factoryOrder(), factoryOrder(), factoryOrder()],
  },
  template: (args) => <OrderTable {...args} />,
};

export default meta;
