import { FC, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router';
import { DialogBox } from 'src/components/primitives/DialogBox';
import { api } from 'src/lib/api';
import { UpdatePaymentResponse } from 'src/models/v1/payment';

import * as Dialog from '@radix-ui/react-dialog';

export const PaymentSuccessDialog: FC<{ ticketGid: string }> = ({ ticketGid }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSuccess, setSuccess] = useState(false);
  const onClose = useCallback(() => {
    setSuccess(false);
    setSearchParams(
      (searchParams) => {
        searchParams.delete('payment');
        return searchParams;
      },
      { replace: true },
    );
  }, [setSearchParams]);
  useEffect(() => {
    const token = searchParams.get('token');
    if (token === null) return;
    (async () => {
      const { payment } = await api.dispatch<{}, UpdatePaymentResponse>(
        'PUT',
        `/v1/tickets/${ticketGid}/payments/${token}`,
      );
      if (payment.status == 'complete') {
        setSearchParams(
          (searchParams) => {
            searchParams.delete('token');
            return searchParams;
          },
          { replace: true },
        );
        setSuccess(true);
      }
    })();
  }, []);
  return (
    <Dialog.Root open={isSuccess}>
      <Dialog.Portal>
        <Dialog.Overlay className="overlay" />
        <DialogBox onClose={onClose} title="決済が完了しました">
          無事に決済が完了しました。依頼完了までしばらくお待ちください。
        </DialogBox>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
