import './Breadcrumb.css';

import { FC, PropsWithChildren, ReactNode } from 'react';
import { Link } from 'react-router';
import { Icon } from 'src/components/primitives/Icon';

interface BreadcrumbSubComponents {
  Item: FC<PropsWithChildren<{ icon?: ReactNode; to?: string; terminal?: boolean }>>;
}

export const Breadcrumb: FC<PropsWithChildren> & BreadcrumbSubComponents = ({ children }) => {
  return <nav className="breadcrump">{children}</nav>;
};

Breadcrumb.Item = ({ children, terminal = false, icon, to }) => {
  return (
    <>
      {icon}
      {to ? (
        <Link className="item" to={to}>
          {children}
        </Link>
      ) : (
        <span className="item">{children}</span>
      )}
      {!terminal && <Icon name="chevron_right" />}
    </>
  );
};
