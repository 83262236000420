import { defineFactory, FactoryBlueprint } from 'src/catalog/factories/factoryBuilder';
import { fakeGid } from 'src/catalog/factories/gid';
import { Address } from 'src/models/v1/address';

// MARK: base

const base: FactoryBlueprint<Address> = {
  gid: fakeGid(),
  name: '長久太郎',
  name_kana: 'ナガクタロウ',
  phone: '0X012341234',
  postal_code: '380-0845',
  state: '長野県',
  city: '長野市',
  city_kana: 'ナガノシ',
  town: '西後町',
  town_kana: 'ニシゴチョウ',
  line1: '610-12',
  line1_kana: '610-12',
  line2: 'R-DEPOT 2F-D',
  line2_kana: 'R-DEPOT 2F-D',
  is_outlying_island: false,
  primary: false,
};

// MARK: primary trait

const primary: Partial<Address> = {
  primary: true,
};

// MARK: factory

export const factoryAddress = defineFactory(base, {
  primary,
});
