import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router';
import { PrintAnnotation } from 'src/components/features/print/PrintAnnotation';
import { QuotationPrint } from 'src/components/features/quotation/QuotationPrint';
import { PageLoader } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { Quotation } from 'src/models/v1/quotation';

export const QuotationPrintPage: FC = () => {
  const { i18n } = useI18n();
  const { quotation } = useLoaderData<{ quotation: Quotation }>();

  return (
    <>
      <Helmet>
        <title>{`${quotation.title} - ${quotation.management_number} | ${i18n.t('nagaku')}`}</title>
      </Helmet>
      <PrintAnnotation />
      <QuotationPrint quotation={quotation} />;
    </>
  );
};

export const quotationPrintLoader: PageLoader = async ({ params, api }) => {
  return await api.fetch(`/v1/quotations/${params.gid}`, { include: 'author,payor' });
};
