import { factoryTag } from 'src/catalog/factories/tag';
import { Meta, Story } from 'src/catalog/Story';
import { TagHeader } from 'src/components/features/tag/TagHeader';

const meta: Meta<typeof TagHeader> = {
  title: 'Tag Header',
  args: {
    typeface: factoryTag().typeface,
    url: factoryTag().hero?.url,
  },
  template: (args) => <TagHeader {...args} />,
};

export default meta;

export const heroBlank: Story<typeof TagHeader> = {
  name: 'Hero Blank',
  args: {
    typeface: factoryTag().typeface,
    url: factoryTag('heroBlank').hero?.url,
  },
};

export const tooLong: Story<typeof TagHeader> = {
  name: 'Too Long',
  args: {
    typeface: 'あいうえおかきくけこさしすせそたちつてとなにぬねのはひふへほまみむめもやゆよらりるれろわをん',
    url: factoryTag().hero?.url,
  },
};
