import './AccountCardWorksGallery.css';

import { FC } from 'react';
import { MediaImage } from 'src/components/primitives/MediaImage';
import { Link } from 'react-router';
import { HStack } from 'src/components/primitives/Stack';
import { useApi } from 'src/lib/api';
import { ListWorksResponse } from 'src/models/v1/work';
import { useDragScroll } from 'src/lib/dragScroll';

export const AccountCardWorksGallery: FC<{ accountGid: string }> = ({ accountGid }) => {
  const { data, isLoading } = useApi<ListWorksResponse>(`/v1/accounts/${accountGid}/works?status=published&per=5`);
  const dragScroll = useDragScroll();

  return (
    <>
      {!isLoading && (
        <HStack justify="start" className="account-card-works-gallery" ref={dragScroll.targetRef}>
          {data?.works.map((work) => (
            <Link to={`/works/${work.gid}`}>
              <MediaImage
                className="account-card-works-gallery-image"
                src={work.after_image_media_file?.url}
                size={'512x512'}
              />
            </Link>
          ))}
        </HStack>
      )}
    </>
  );
};
