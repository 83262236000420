import './TicketSummary.css';

import { FC } from 'react';
import { Link } from 'react-router';
import { TicketPhaseLabel } from 'src/components/features/ticket/TicketPhaseLabel';
import { Avatar } from 'src/components/primitives/Avatar';
import { MediaImage } from 'src/components/primitives/MediaImage';
import { HStack, StackAddon, VStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { Message, MessageTicket } from 'src/models/v1/message';

export const TicketSummary: FC<{
  ticket: MessageTicket;
  message: Pick<Message, 'body'>;
  isSelected: boolean;
}> = ({ ticket, message, isSelected = false }) => {
  const { me } = useCurrentStatus();
  return (
    <HStack
      asChild
      className={isSelected ? 'ticket-summary selected' : 'ticket-summary'}
      align="flex-start"
      spacing="md"
    >
      <Link to={`/messages/${ticket.gid}`}>
        <StackAddon asChild className="ticket-summary-icon">
          <MediaImage width="88" height="88" src={ticket.photos[0].url} size="512x512" />
        </StackAddon>
        <VStack className="ticket-summary-body" align="stretch" w="full" spacing="sm">
          {ticket.participants
            .filter((participant) => participant.gid != me?.gid)
            .map((participant) => (
              <HStack
                asChild
                key={participant.gid}
                className="ticket-summary-account"
                spacing="sm"
                justify="flex-start"
              >
                <h3>
                  <Avatar account={participant} src={participant.icon_url || undefined} size="xs" />
                  <Text className="ticket-summary-display-name" variant="label" size="large">
                    {participant.display_name}
                  </Text>
                </h3>
              </HStack>
            ))}
          <Text lineClamp={2} color="note">
            {message.body}
          </Text>
          {/* TODO: remove div and attach style in TicketPhaseLabel */}
          <div>
            <TicketPhaseLabel phase={ticket.phase} />
          </div>
        </VStack>
      </Link>
    </HStack>
  );
};
