import { FC, useEffect, useRef } from 'react';
import { Link } from 'react-router';
import { onSubmitBusinessApplication } from 'src/actions/business_application';
import { Button } from 'src/components/primitives/Button';
import { Icon } from 'src/components/primitives/Icon';
import { Note } from 'src/components/primitives/Note';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';
import { AccountSetting } from 'src/models/v1/account';
import { Business } from 'src/models/v1/business';

export const SubmitBusinessAccountApplication: FC<{
  business: Pick<Business, 'submit_allowed'>;
  emailVerified: AccountSetting['email_verified'];
}> = ({ business, emailVerified }) => {
  const { i18n } = useI18n();
  const isSubmitted = useRef<boolean>(false);
  const onSubmit = onSubmitBusinessApplication({});
  useEffect(() => {
    return () => {
      // Scroll to top after submitting the application with unmounting this component.
      if (!isSubmitted.current) {
        return;
      }
      isSubmitted.current = false;
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    };
  }, []);
  return (
    <>
      {emailVerified == false && (
        <Note.Root asChild variant="error">
          <Link to="/settings/account">
            <Icon name="mark_email_read" />
            <Note.Description>{i18n.t('guide.business.need_to_verify_email_to_submit')}</Note.Description>
            <Icon name="chevron_right" />
          </Link>
        </Note.Root>
      )}
      <Button
        variant="primary"
        type="submit"
        size="default"
        disabled={!emailVerified || !business.submit_allowed}
        onClick={() => {
          isSubmitted.current = true;
          onSubmit({});
        }}
      >
        {i18n.t(`confirmation.business.submit_application.confirm`)}
      </Button>
      <Text asChild color="note">
        <p>{i18n.t('confirmation.business.submit_application.description')}</p>
      </Text>
      <Text asChild color="note">
        <p>
          <StripeAgreement />
        </p>
      </Text>
    </>
  );
};

const StripeAgreement: FC = () => {
  const { i18n } = useI18n();
  switch (i18n.locale()) {
    case 'ja':
      return (
        <>
          申請を送信することによって、Stripe（決済サービス）の
          <a href="https://stripe.com/jp/legal/ssa" target="_blank">
            Stripe 利用規約
          </a>
          および
          <a href="https://stripe.com/jp/legal/connect-account" target="_blank">
            Stripe の Connect アカウント契約
          </a>
          に同意したことになります。
        </>
      );
    default:
      return (
        <>
          By submitting your pro account application, you agree to{' '}
          <a href="https://stripe.com/legal/ssa" target="_blank">
            the Stripe Services Agreement
          </a>{' '}
          and{' '}
          <a href="https://stripe.com/connect-account/legal/full" target="_blank">
            the Stripe Connected Account Agreement
          </a>
          .
        </>
      );
  }
};
