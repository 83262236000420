import './StepSection.css';

import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { Icon } from 'src/components/primitives/Icon';
import { SectionHeading } from 'src/components/primitives/SectionHeading';

export const StepSectionContext = createContext<{ current: string | undefined; opens: Array<string> }>({
  current: undefined,
  opens: [],
});

export const StepSection: FC<
  PropsWithChildren<{ title: string; value: string; isCompleted?: boolean; onToggle?: (value: string) => void }>
> = ({ title, value, isCompleted = false, onToggle, children }) => {
  const { current, opens } = useContext(StepSectionContext);
  const isCurrent = current == value;
  const isOpen = isCurrent || opens.includes(value);
  return (
    <section
      className={[
        'step-section',
        isCurrent ? 'step-section-current' : undefined,
        isCompleted ? 'step-section-completed' : undefined,
        onToggle ? 'step-section-toggle' : undefined,
        isOpen ? 'open' : 'close',
      ]
        .filter((className) => className)
        .join(' ')}
    >
      <SectionHeading asChild className="header" onClick={() => onToggle && onToggle(value)}>
        <h1>
          <Icon name={isCompleted ? 'check_circle_outline' : 'edit'} className="icon" />
          {title}
          {onToggle && <Icon aria-hidden className="arrow" name="keyboard_arrow_down" />}
        </h1>
      </SectionHeading>
      <div className="content" aria-hidden={!isOpen}>
        {children}
      </div>
    </section>
  );
};
