import './TicketCommentControl.css';
import 'src/components/primitives/Overlay.css';

import {
  createContext,
  FC,
  KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
  useTransition,
} from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router';
import { onComment, onUploadImage } from 'src/actions/ticket';
import { ErrorMessage } from 'src/components/control/ErrorMessage';
import { Form } from 'src/components/control/Form';
import { FormButton } from 'src/components/control/FormButton';
import { EstimateForm } from 'src/components/features/estimate/EstimateForm';
import { QuotationForm } from 'src/components/features/quotation/QuotationForm';
import { TicketCommentAttachmentsPreview } from 'src/components/features/ticket/TicketCommentAttachmentsPreview';
import { Field } from 'src/components/primitives/Field';
import { Icon } from 'src/components/primitives/Icon';
import { Spinner } from 'src/components/primitives/Spinner';
import { HStack, VStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import Dialog from 'src/components/styled-radix/Dialog';
import { api, useApi } from 'src/lib/api';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';
import { findTicketRole } from 'src/lib/ticket';
import { ACCEPT_IMAGE } from 'src/lib/uploadFile';
import { validateMaxLengthOption } from 'src/lib/validation';
import { CreateEstimateBody, Estimate, ListEstimatesResponse, RetrieveEstimateResponse } from 'src/models/v1/estimate';
import {
  CreateQuotationBody,
  CreateQuotationResponse,
  ListQuotationsResponse,
  Quotation,
} from 'src/models/v1/quotation';
import { PostTicketCommentBody, Ticket } from 'src/models/v1/ticket';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button } from 'src/components/styles/Button';

type TicketCommentControlState = {
  isOpen: 'menu' | 'draft_quotation' | 'draft_estimate' | null;
};

const TicketCommentControlContext = createContext<TicketCommentControlState>({
  isOpen: null,
});

export const TicketCommentControl: FC<{
  ticket: Pick<Ticket, 'gid' | 'status' | 'accounts' | 'repair_request' | 'permitted_attachments'>;
  onComment?: (body: PostTicketCommentBody) => Promise<void>; // for Development
}> = ({ ticket, onComment: onCommentOverride }) => {
  const { i18n } = useI18n();
  const [state, setState] = useState<TicketCommentControlState>({ isOpen: null });
  const [isPending, startTransition] = useTransition();
  return (
    <TicketCommentControlContext.Provider value={state}>
      <Form
        key="ticket-comment-control"
        className="ticket-comment-control"
        onSubmit={onComment({ ticket, onCommentOverride })}
        onSuccess={({ reset }) => reset()}
        defaultValues={{ ticket_comment: { body: '', attachments: [] } }}
        mode="all"
      >
        <BodyField />
        <HStack justify="space-between">
          <AttachmentControl
            ticket={ticket}
            onOpen={(open) => setState((state) => ({ ...state, isOpen: open }))}
            startTransition={startTransition}
          />
          <FormButton disabled={isPending}>{i18n.t('action.send')}</FormButton>
        </HStack>
        <AttachmentDialog ticket={ticket} onClose={() => setState((state) => ({ ...state, isOpen: null }))} />
      </Form>
    </TicketCommentControlContext.Provider>
  );
};

const BodyField: FC = () => {
  const { i18n } = useI18n();
  const { register, watch, setValue } = useFormContext<PostTicketCommentBody>();
  const onKeyDown = useCallback((event: KeyboardEvent<HTMLTextAreaElement>) => {
    // see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/isComposing
    if (event.nativeEvent.isComposing || event.key === 'Process' || event.keyCode === 229) {
      return; // ignore event within composition session
    }
    // Command + Enter / Ctrl + Enter
    if ((event.ctrlKey || event.metaKey) && event.key == 'Enter') {
      event.currentTarget?.closest('form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }, []);
  const [searchParams] = useSearchParams();
  const quotationGid = searchParams.get('quotation');

  const onDelete = useCallback(
    (targetGid: string) => {
      const attachments = watch('ticket_comment.attachments');
      setValue(
        'ticket_comment.attachments',
        attachments.filter((attachment) => attachment.gid !== targetGid),
      );
    },
    [setValue],
  );
  useEffect(() => {
    if (quotationGid) {
      const attachments = watch('ticket_comment.attachments');
      if (attachments.find((attachment) => attachment.type == 'quotation') == null) {
        setValue('ticket_comment.attachments', [...attachments, { type: 'quotation', gid: quotationGid }]);
      }
    }
  }, [quotationGid]);
  return (
    <>
      <Field asChild className="ticket-comment-control-body">
        <fieldset>
          <TicketCommentAttachmentsPreview attachments={watch('ticket_comment.attachments')} onDelete={onDelete} />
          <textarea
            {...register('ticket_comment.body', {
              required: true,
              maxLength: validateMaxLengthOption({ name: 'ticket_comment.body', maxLength: 1000, i18n }),
            })}
            placeholder={i18n.t('placeholder.ticket_comment.body')}
            onKeyDown={onKeyDown}
          />
        </fieldset>
      </Field>
      <ErrorMessage name="ticket_comment.body" />
    </>
  );
};

const AttachmentControl: FC<{
  ticket: Pick<Ticket, 'gid' | 'status' | 'accounts' | 'permitted_attachments'>;
  onOpen: (open: 'menu' | 'draft_quotation' | 'draft_estimate' | null) => void;
  startTransition: (callback: () => void) => void;
}> = ({ ticket, onOpen, startTransition }) => {
  const { i18n } = useI18n();
  const state = useContext(TicketCommentControlContext);
  const { control } = useFormContext<PostTicketCommentBody>();
  const { append } = useFieldArray({ control, name: 'ticket_comment.attachments' });
  const { me } = useCurrentStatus();
  const id = useId();
  const role = useMemo(() => findTicketRole({ account: me!, ticket }), [ticket, me]);
  const onAttachImage = useCallback(() => document.getElementById(id)?.click(), [id]);
  const uploadImage = onUploadImage({ append, startTransition });

  return (
    <div className="ticket-comment-control-attachment-control">
      <button
        type="button"
        className={Button.class}
        {...Button.build({})}
        aria-label={i18n.t('action.attach_file')}
        onClick={onAttachImage}
      >
        <input
          id={id}
          type="file"
          multiple={true}
          accept={ACCEPT_IMAGE}
          style={{ display: 'none' }}
          onChange={uploadImage}
        />
        <Icon name="image" />
        {i18n.t('action.attach_file')}
      </button>
      {role == 'repairer' && (
        <DropdownMenu.Root open={state.isOpen == 'menu'} onOpenChange={(open) => onOpen(open ? 'menu' : null)}>
          <DropdownMenu.Trigger asChild>
            <button
              type="button"
              className={Button.class}
              {...Button.build({})}
              aria-label={i18n.t('action.attach_estimates')}
              onClick={onAttachImage}
            >
              <Icon name="article" />
              {i18n.t('action.attach_estimates')}
            </button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content asChild className="ticket-comment-control-attachment-menu" align="start">
            <nav>
              {ticket.permitted_attachments.includes('estimate') && (
                <DropdownMenu.Item className="item" onClick={() => onOpen('draft_estimate')}>
                  <Icon name="article" />
                  <Text size="small" weight="bold">
                    {i18n.t('action.attach_estimate')}
                  </Text>
                </DropdownMenu.Item>
              )}
              {ticket.permitted_attachments.includes('quotation') && (
                <DropdownMenu.Item className="item" onClick={() => onOpen('draft_quotation')}>
                  <Icon name="payment" />
                  <Text size="small" weight="bold">
                    {i18n.t('action.attach_quotation')}
                  </Text>
                </DropdownMenu.Item>
              )}
            </nav>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      )}
    </div>
  );
};

const AttachmentDialog: FC<{ ticket: Pick<Ticket, 'gid' | 'repair_request'>; onClose: () => void }> = ({
  ticket,
  onClose,
}) => {
  const state = useContext(TicketCommentControlContext);

  const { control } = useFormContext<PostTicketCommentBody>();
  const { append } = useFieldArray({ control, name: 'ticket_comment.attachments' });
  const onAttachQuotation = (quotation: Quotation) => {
    append({ type: 'quotation', gid: quotation.gid });
    onClose();
  };
  const onAttachEstimate = (estimate: Estimate) => {
    append({ type: 'estimate', gid: estimate.gid });
    onClose();
  };

  return (
    <Dialog.Root open={!!(state.isOpen && state.isOpen != 'menu')} onOpenChange={(open) => open == false && onClose()}>
      <Dialog.Portal>
        <Dialog.Overlay className="overlay" />
        <Dialog.Content variant="fill-container" className="ticket-comment-control-dialog">
          {state.isOpen == 'draft_quotation' && (
            <DraftQuotationDialogContent ticket={ticket} onAttachQuotation={onAttachQuotation} />
          )}
          {state.isOpen == 'draft_estimate' && (
            <DraftEstimateDialogContent ticket={ticket} onAttachEstimate={onAttachEstimate} />
          )}
          <Dialog.Close />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const DraftQuotationDialogContent: FC<{
  ticket: Pick<Ticket, 'gid' | 'repair_request'>;
  onAttachQuotation: (quotation: Quotation) => void;
}> = ({ ticket, onAttachQuotation }) => {
  const { data } = useApi<ListQuotationsResponse>(`/v1/tickets/${ticket.gid}/quotations?status=draft`);
  const [draft, setDraft] = useState<Quotation | undefined>();
  const { i18n } = useI18n();

  useEffect(() => {
    if (typeof data == 'undefined') return;
    if (!draft) {
      const foundDraft = data.quotations.find((quotation) => quotation.status == 'draft');
      if (foundDraft) {
        setDraft(foundDraft);
      } else {
        api
          .dispatch<CreateQuotationBody, CreateQuotationResponse>('POST', `/v1/tickets/${ticket.gid}/quotations`)
          .then((response) => setDraft(response.quotation));
      }
    }
  }, [draft, data, ticket.gid]);
  const account = ticket.repair_request.account;
  if (draft) {
    return (
      <>
        <Dialog.Title>{i18n.t('label.draft_quotation')}</Dialog.Title>
        <Dialog.Description>
          <QuotationForm quotation={draft} account={account} onComplete={onAttachQuotation} />
        </Dialog.Description>
      </>
    );
  }
  return (
    <VStack spacing="sticky" m="none">
      <Spinner size="medium" />
    </VStack>
  );
};

const DraftEstimateDialogContent: FC<{
  ticket: Pick<Ticket, 'gid' | 'repair_request'>;
  onAttachEstimate: (estimate: Estimate) => void;
}> = ({ ticket, onAttachEstimate }) => {
  const { data } = useApi<ListEstimatesResponse>(`/v1/tickets/${ticket.gid}/estimates?status=draft`);
  const [draft, setDraft] = useState<Estimate | undefined>();
  const { i18n } = useI18n();

  useEffect(() => {
    if (typeof data == 'undefined') return;
    if (!draft) {
      const foundDraft = data.estimates.find((estimate) => estimate.status == 'draft');
      if (foundDraft) {
        setDraft(foundDraft);
      } else {
        api
          .dispatch<CreateEstimateBody, RetrieveEstimateResponse>('POST', `/v1/tickets/${ticket.gid}/estimates`)
          .then((response) => setDraft(response.estimate));
      }
    }
  }, [draft, data, ticket.gid]);
  const account = ticket.repair_request.account;
  if (draft) {
    return (
      <>
        <Dialog.Title>{i18n.t('label.draft_estimate')}</Dialog.Title>
        <Dialog.Description>
          <EstimateForm estimate={draft} account={account} onComplete={onAttachEstimate} />
        </Dialog.Description>
      </>
    );
  }
  return (
    <VStack spacing="sticky" m="none">
      <Spinner size="medium" />
    </VStack>
  );
};
