import './TagHeader.css';

import { Link } from 'react-router';
import { FC } from 'react';
import { Icon } from 'src/components/primitives/Icon';
import { MediaImage } from 'src/components/primitives/MediaImage';
import { Text } from 'src/components/primitives/Text';

export const TagHeader: FC<{ typeface: string; url: string | undefined }> = ({ typeface, url }) => {
  return (
    <Link className="tag-header" to={`/tags/${encodeURIComponent(typeface)}`}>
      {url ? (
        <MediaImage className="hero" src={url} size="144x144" alt="" />
      ) : (
        <div className="hero-blank" aria-hidden />
      )}
      <Text asChild variant="title" weight="bold">
        <h2>{typeface}</h2>
      </Text>
      <Icon className="arrow" name="keyboard_arrow_right" />
    </Link>
  );
};
