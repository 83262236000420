import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useI18n } from 'src/lib/i18n';

export const CommonHead: FC = () => {
  const { locale } = useI18n();

  return (
    <Helmet>
      <meta property="og:locale" content={locale === 'ja' ? 'ja_JP' : 'en_US'} />
      <meta property="og:locale:alternate" content={locale === 'ja' ? 'en_US' : 'ja_JP'} />
    </Helmet>
  );
};
