import { FC, PropsWithChildren } from 'react';
import { onRegisterAddress } from 'src/actions/address';
import { AddressField } from 'src/components/control/AddressField';
import { Form } from 'src/components/control/Form';
import { FormButton } from 'src/components/control/FormButton';
import { InputField } from 'src/components/control/InputField';
import { HStack } from 'src/components/primitives/Stack';
import { useI18n } from 'src/lib/i18n';
import { Address } from 'src/models/v1/address';

export const RegisterAddressForm: FC<PropsWithChildren<{ onRegister?: (address: Address) => Promise<void> }>> = ({
  onRegister,
  children,
}) => {
  const { i18n } = useI18n();

  return (
    <Form
      variant="sectional"
      onSubmit={onRegisterAddress({ onSuccess: onRegister })}
      onSuccess={({ reset }) => reset()}
      defaultValues={{
        address: {
          name: '',
          name_kana: '',
          phone: '',
          postal_code: '',
          line1: '',
          line1_kana: '',
          line2: '',
          line2_kana: '',
        },
      }}
      defaultRequired
    >
      <InputField name="address.name" options={{ required: true }} autoComplete="name" autoFocus />
      <InputField name="address.name_kana" options={{ required: true }} autoComplete="off" />
      <InputField name="address.phone" options={{ required: true }} autoComplete="tel" />
      <AddressField namePrefix="address" options={{ required: true }} />
      <HStack justify="space-between">
        <FormButton>{i18n.t('action.save')}</FormButton>
        {children}
      </HStack>
    </Form>
  );
};
