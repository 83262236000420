import './AccountBar.css';

import { ComponentProps, FC, PropsWithChildren, ReactNode } from 'react';
import { Link } from 'react-router';
import { Avatar } from 'src/components/primitives/Avatar';
import { IconicStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { Account } from 'src/models/v1/account';

export const AccountBar: FC<
  PropsWithChildren<{ account: Account; menu?: ReactNode; avatarSize?: ComponentProps<typeof Avatar>['size'] }>
> = ({ account, menu, avatarSize = 'sm', children }) => {
  return (
    <IconicStack
      icon={<Avatar account={account} src={account.icon_url ?? undefined} size={avatarSize} />}
      addon={menu}
      spacing="sticky"
      gap="md"
      w="full"
      className="account-bar"
    >
      <Text variant="body" size="medium" weight="bold" className="display-name">
        <Link to={`/accounts/${account.gid}`} className="link">
          {account.display_name}
        </Link>
      </Text>
      {children}
    </IconicStack>
  );
};
