import './SubSection.css';

import { Primitive } from '@radix-ui/react-primitive';
import { Slot } from '@radix-ui/react-slot';

import { ComponentPropsWithoutRef, forwardRef } from 'react';

type SubSectionElement = React.ElementRef<typeof Primitive.div>;
type SubSectionProps = ComponentPropsWithoutRef<typeof Primitive.div>;

export const SubSection = forwardRef<SubSectionElement, SubSectionProps>(
  ({ asChild, className, ...props }, forwardedRef) => {
    const Comp = asChild ? Slot : 'section';
    return <Comp {...props} className={`sub-section ${className || ''}`} ref={forwardedRef} />;
  },
);
