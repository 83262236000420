import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { Text } from 'src/components/primitives/Text';

type HeadingElement = React.ElementRef<typeof Text>;
type PrimitiveHeadingProps = Omit<ComponentPropsWithoutRef<typeof Text>, 'variant' | 'size' | 'weight'>;
interface HeadingProps extends PrimitiveHeadingProps {}

export const Heading = forwardRef<HeadingElement, HeadingProps>((props, forwardedRef) => {
  return <Text {...props} ref={forwardedRef} variant="headline" size="medium" weight="bold" />;
});
