import { FC } from 'react';
import { BackableBreadcrumb } from 'src/components/features/BackableBreadcrumb';
import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router';
import { BusinessProfileForm } from 'src/components/features/business_profile/BusinessProfileForm';
import { NavigationLayout } from 'src/components/Layout';
import { PageLoader } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { Business, BusinessProfile } from 'src/models/v1/business';

type Data = {
  business: Business;
  business_profile: BusinessProfile;
};

export const EditBusinessProfilePage: FC = () => {
  const { business, business_profile } = useLoaderData() as Data;
  const { i18n } = useI18n();
  return (
    <NavigationLayout>
      <Helmet>
        <title>{`${i18n.t('title.business_profile')} | ${i18n.t('nagaku')}`}</title>
        <meta name="description" content="" />
      </Helmet>
      <BackableBreadcrumb homePath="/settings" currentPath="/settings/business_profile/edit">
        {i18n.t('title.business_profile')}
      </BackableBreadcrumb>
      <BusinessProfileForm businessProfile={business_profile} businessStatus={business.status} />
    </NavigationLayout>
  );
};

export const editBusinessProfileLoader: PageLoader = async ({ api }) => {
  return await api.combine(api.fetch('/v1/accounts/me/business'), api.fetch('/v1/accounts/me/business/profile'));
};
