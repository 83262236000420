import { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { AmountField } from 'src/components/control/AmountField';
import { Form } from 'src/components/control/Form';
import { MultipleMediaFilesField } from 'src/components/control/MultipleMediaFilesField';
import { SingleMediaFileField } from 'src/components/control/SingleMediaFileField';
import { TagsField } from 'src/components/control/TagsField';
import { TextField } from 'src/components/control/TextField';
import { Text } from 'src/components/primitives/Text';
import { ConfirmationButton } from 'src/components/features/ConfirmationButton';
import { Button } from 'src/components/primitives/Button';
import { Toolbox } from 'src/components/singleton/Toolbox';
import { api } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { ACCEPT_IMAGE } from 'src/lib/uploadFile';
import { UpdateWorkBody, Work } from 'src/models/v1/work';
import { DaysField } from 'src/components/control/DaysField';
import { onPublishWork, onSaveDraftWork, onUpdateWork } from 'src/actions/work';
import { usePermission } from 'src/lib/currentStatus';
import { SaveDraft } from 'src/components/control/SaveDraft';

export const WorkEditForm: FC<{
  work: Omit<Work, 'html' | 'liked' | 'likes_count'>;
  onPublish?: ReturnType<typeof onPublishWork>;
  onSaveDraft?: ReturnType<typeof onSaveDraftWork>;
}> = ({ work, onPublish = onPublishWork({ gid: work.gid }), onSaveDraft = onSaveDraftWork({ gid: work.gid }) }) => {
  const { i18n } = useI18n();
  const navigate = useNavigate();
  const { gid } = work;
  const { permit: permitProFeature } = usePermission('pro_feature');

  const onClose = useCallback(() => {
    navigate(`/works/${gid}`);
  }, [navigate, gid]);

  const onDelete = useCallback(async () => {
    await api.dispatch<undefined, null>('DELETE', `/v1/works/${gid}`, {});
    navigate(`/accounts/${work.account.gid}`);
  }, [gid, work.account.gid, onClose, navigate]);

  const onSubmit = onUpdateWork({ onPublish, onSaveDraft });

  return (
    <Form
      className="work-edit-form"
      onSubmit={onSubmit}
      mode="all"
      defaultValues={{
        work: {
          body: work.body,
          status: work.status,
          estimate_amount: work.estimate_amount ?? undefined,
          estimate_delivery_days: work.estimate_delivery_days ?? undefined,
          delivery_days_unit: work.delivery_days_unit,
          before_image_media_file: work.before_image_media_file?.gid,
          after_image_media_file: work.after_image_media_file?.gid,
          image_media_files: work.image_media_files.map(({ gid }) => gid),
          tags: work.tags,
        },
      }}
    >
      {work.status == 'draft' && <SaveDraft path={`/v1/works/${gid}`} />}
      <SingleMediaFileField
        name="work.before_image_media_file"
        url={work.before_image_media_file?.url}
        accept={ACCEPT_IMAGE}
      />
      <SingleMediaFileField
        name="work.after_image_media_file"
        url={work.after_image_media_file?.url}
        accept={ACCEPT_IMAGE}
        options={{ required: true }}
      />
      <AdditionalImages work={work} />
      <TextField name="work.body" placeholder={i18n.t('placeholder.work.body')} options={{ required: true }} />
      <TagsField name="work.tags" maxLength={5} options={{ required: true }} />
      <div style={{ opacity: permitProFeature ? '1.0' : '0.5' }}>
        <AmountField
          className="amount-field"
          name="work.estimate_amount"
          placeholder={i18n.t('placeholder.work.estimate_amount')}
          maxLength={15}
          disabled={!permitProFeature}
        >
          <Text color="note">{i18n.t('guide.work_estimate_amount')}</Text>
        </AmountField>
      </div>
      <div style={{ opacity: permitProFeature ? '1.0' : '0.5' }}>
        <DaysField
          name="work.estimate_delivery_days"
          unitName="work.delivery_days_unit"
          placeholder={i18n.t('placeholder.work.estimate_delivery_days')}
          options={{
            validate: {
              delivery_days: (value) => ((value?.toString() || '0').match(/^[0-9]*$/) ? true : false),
            },
          }}
          maxLength={4}
          disabled={!permitProFeature}
        />
      </div>
      <Toolbox>
        {work.status == 'draft' ? <SaveButton onSaveDraft={onSaveDraft} /> : <CloseButton onClose={onClose} />}
        {work.status == 'draft' ? <PostButton /> : <UpdateButton />}
        <ConfirmationButton w="full" scope="confirmation.work.delete" onConfirm={onDelete} variant="danger">
          {i18n.t('action.delete')}
        </ConfirmationButton>
      </Toolbox>
    </Form>
  );
};

const AdditionalImages: FC<{ work: Omit<Work, 'html' | 'liked' | 'likes_count'> }> = ({ work }) => {
  const { watch } = useFormContext<UpdateWorkBody>();
  return (
    <MultipleMediaFilesField
      name="work.image_media_files"
      hint={`最大8枚までアップロードできます ${watch('work.image_media_files')?.length}/8`}
      mediaFiles={work.image_media_files}
      accept={ACCEPT_IMAGE}
      max={8}
      options={{
        validate: {
          maxUpload: (value) => value.length < 9,
        },
      }}
    />
  );
};

export const SaveButton: FC<{ onSaveDraft: (body: UpdateWorkBody) => Promise<void> }> = ({ onSaveDraft }) => {
  const { i18n } = useI18n();
  const { getValues } = useFormContext<UpdateWorkBody>();
  return (
    <Button
      variant="outline"
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        onSaveDraft(getValues());
      }}
    >
      {i18n.t('action.save_as_draft')}
    </Button>
  );
};

export const PostButton: FC = () => {
  const { i18n } = useI18n();
  const {
    setValue,
    formState: { isValid },
  } = useFormContext();
  return (
    <Button type="submit" variant="primary" onClick={() => setValue('work.status', 'published')} disabled={!isValid}>
      {i18n.t('action.post')}
    </Button>
  );
};

export const CloseButton: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { i18n } = useI18n();
  return (
    <Button type="button" variant="outline" onClick={onClose}>
      {i18n.t('action.close')}
    </Button>
  );
};

export const UpdateButton: FC = () => {
  const { i18n } = useI18n();
  const {
    formState: { isValid },
  } = useFormContext();
  return (
    <Button type="submit" variant="primary" disabled={!isValid}>
      {i18n.t('action.update')}
    </Button>
  );
};
