import './AskFormHeader.css';

import { FC } from 'react';
import { Icon } from 'src/components/primitives/Icon';
import { useI18n } from 'src/lib/i18n';
import { Text } from 'src/components/primitives/Text';
import { Account } from 'src/models/v1/account';
import { Card } from 'src/components/styles/Card';
import { Banner } from 'src/components/primitives/Banner';
import { AccountBar } from 'src/components/features/AccountBar';
import { NoticeLabelCollection } from 'src/components/features/NoticeLabelCollection';
import { NoticeLabel } from 'src/components/primitives/NoticeLabel';
import { HtmlContent } from 'src/components/primitives/HtmlContent';

export const AskFormHeader: FC<{ account: Account; placeholder?: string }> = ({ account, placeholder }) => {
  const { i18n } = useI18n();
  const message = account.business_profile?.reception_message;
  return (
    <div className={`${Card.class} ask-form-header`}>
      <Banner account={account} />
      <AccountBar account={account} avatarSize="md">
        <NoticeLabelCollection spacing="xs">
          <NoticeLabel>
            <Icon name="location_on" />
            <Text size="small" color="body" weight="bold">
              {account.business_profile?.area}
            </Text>
          </NoticeLabel>
          <NoticeLabel>
            <Icon name="image" />
            <Text size="small" color="body" weight="bold">
              {account.published_works_count}
              {i18n.t('unit.separator')}
              {i18n.t('unit.works', account.published_works_count)}
            </Text>
          </NoticeLabel>
        </NoticeLabelCollection>
      </AccountBar>
      {message && (
        <Text variant="body" size="medium" weight="bold" className="message">
          <HtmlContent html={message} />
        </Text>
      )}
      {!message && placeholder && (
        <Text color="note" variant="body" size="medium" weight="bold" className="message">
          <HtmlContent html={placeholder} />
        </Text>
      )}
    </div>
  );
};
