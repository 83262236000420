import { createAction } from 'src/lib/action';
import { api } from 'src/lib/api';
import { Account } from 'src/models/v1/account';
import { CreateInquiryBody } from 'src/models/v1/inquiry';

export const onPostInquiry = createAction('onPostInquiry', ({ account }: { account: Account }) => {
  return async (body: CreateInquiryBody) => {
    await api.dispatch('POST', `/v1/accounts/${account.gid}/inquiries`, undefined, body);
  };
});
