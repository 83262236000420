import { FC, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router';
import { WorksGallery } from 'src/components/features/work/WorksGallery';
import { useI18n } from 'src/lib/i18n';
import { useTag } from 'src/pages/explore';

export const ExploreWorksPage: FC = () => {
  const { i18n } = useI18n();
  const { setTag } = useTag();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || '';

  useLayoutEffect(() => {
    setTag(undefined);
  });

  return (
    <>
      <Helmet>
        <title>{`${i18n.t('title.explore')} | ${i18n.t('nagaku')} - ${i18n.t('title.home')}`}</title>
        <meta
          property="og:title"
          content={`${i18n.t('title.explore')} | ${i18n.t('nagaku')} - ${i18n.t('title.home')}`}
        />
        <meta name="description" content={`${i18n.t('description.prefix')}${i18n.t('description.short')}`} />
        <meta property="og:description" content={`${i18n.t('description.prefix')}${i18n.t('description.short')}`} />
      </Helmet>
      <WorksGallery key={`works-${page}`} path="/v1/works" page={page} />
    </>
  );
};
