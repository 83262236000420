import { FC } from 'react';

export const PriceTag: FC<{ value: number; currency?: 'jpy' }> = ({ value, currency = 'jpy' }) => {
  switch (currency) {
    case 'jpy':
      return (
        <>
          {Intl.NumberFormat('en-US', { style: 'currency', currency: 'JPY', useGrouping: true }).format(
            isNaN(value) ? 0 : value,
          )}
        </>
      );
  }
};
