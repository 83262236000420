const POSTAL_CODE_ENDPOINT = 'https://nagakuinc.github.io/jp-postal-code-api/api/v1/';

const hardcodedAddresses = {
  '3998501': {
    postalCode: '3998501',
    addresses: [
      {
        prefectureCode: '20',
        en: { prefecture: 'Nagano', address1: 'Kitaazumi-gun', address2: 'Matsukawa-mura', address3: '', address4: '' },
        ja: { prefecture: '長野県', address1: '北安曇郡', address2: '松川村', address3: '', address4: '' },
        kana: {
          prefecture: 'ナガノケン',
          address1: 'キタアスガノグン',
          address2: 'マツカワムラ',
          address3: '',
          address4: '',
        },
      },
    ],
  },
};

export const getAddressByPostalCode = async (postalCode: string) => {
  if (postalCode in hardcodedAddresses) {
    return hardcodedAddresses[postalCode as keyof typeof hardcodedAddresses];
  }
  const response = await fetch(`${POSTAL_CODE_ENDPOINT}${postalCode}.json`);
  const data = await response.json();
  return data;
};
