import { TicketSummary } from 'src/components/features/message/TicketSummary';
import { Meta, Story } from 'src/catalog/Story';
import { factoryAccount } from 'src/catalog/factories/account';

const meta: Meta<typeof TicketSummary> = {
  title: 'Ticket Summary',
  args: {
    ticket: {
      gid: '0000',
      participants: [
        {
          ...factoryAccount('iconic'),
          role: 'caller',
        },
      ],
      photos: [
        {
          gid: '0000',
          url: 'https://picsum.photos/300',
        },
      ],
      phase: 'consultation',
    },
    message: {
      body: 'This is a ticket summary',
    },
    isSelected: false,
  },
  template: (args) => <TicketSummary {...args} />,
};

export default meta;

export const SelectedTicketSummary: Story<typeof TicketSummary> = {
  name: 'Selected Ticket Summary',
  args: {
    ticket: {
      gid: '0000',
      participants: [
        {
          ...factoryAccount('iconic'),
          role: 'caller',
        },
      ],
      photos: [
        {
          gid: '0000',
          url: 'https://picsum.photos/300',
        },
      ],
      phase: 'finalized',
    },
    message: {
      body: 'This is a ticket summary',
    },
    isSelected: true,
  },
};
