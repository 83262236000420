import { FC } from 'react';
import { BackableBreadcrumb } from 'src/components/features/BackableBreadcrumb';
import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router';
import { JpActOnSpecifiedCommercialTransactionForm } from 'src/components/features/business/JpActOnSpecifiedCommercialTransactionForm';
import { NavigationLayout } from 'src/components/Layout';
import { PageLoader } from 'src/lib/api';
import { ApiError } from 'src/lib/errors';
import { useI18n } from 'src/lib/i18n';
import { Business, RetrieveBusinessResponse } from 'src/models/v1/business';
import {
  JpActOnSpecifiedCommercialTransaction,
  RetrieveJpActOnSpecifiedCommercialTransactionResponse,
} from 'src/models/v1/jp_act_on_specified_commercial_transaction';

type Data = {
  business: Business;
  jp_act_on_specified_commercial_transaction: Omit<
    JpActOnSpecifiedCommercialTransaction,
    'display_address' | 'display_phone'
  >;
};

export const EditJpActOnSpecifiedCommercialTransactionPage: FC = () => {
  const { business, jp_act_on_specified_commercial_transaction } = useLoaderData() as Data;
  const { i18n } = useI18n();
  return (
    <NavigationLayout>
      <Helmet>
        <title>{`${i18n.t('title.jp_act_on_specified_commercial_transaction_edit')} | ${i18n.t('nagaku')}`}</title>
        <meta name="description" content="" />
      </Helmet>
      <BackableBreadcrumb homePath="/settings" currentPath="/settings/jp_act_on_specified_commercial_transaction/edit">
        {i18n.t('title.jp_act_on_specified_commercial_transaction_edit')}
      </BackableBreadcrumb>
      <JpActOnSpecifiedCommercialTransactionForm
        business={business}
        jp_act_on_specified_commercial_transaction={jp_act_on_specified_commercial_transaction}
      />
    </NavigationLayout>
  );
};

export const editJpActOnSpecifiedCommercialTransactionLoader: PageLoader = async ({ api }) => {
  const { business } = await api.fetch<RetrieveBusinessResponse>(`/v1/accounts/me/business`);
  try {
    const { jp_act_on_specified_commercial_transaction } =
      await api.fetch<RetrieveJpActOnSpecifiedCommercialTransactionResponse>(
        `/v1/accounts/me/business/jp_act_on_specified_commercial_transaction`,
      );
    return { business, jp_act_on_specified_commercial_transaction };
  } catch (error: unknown) {
    if (error instanceof ApiError && error.code == 'resource_not_found') {
      return {
        business,
        jp_act_on_specified_commercial_transaction: buildDefaultJpActOnSpecifiedCommercialTransaction(business),
      };
    }
  }
};

const buildDefaultJpActOnSpecifiedCommercialTransaction: (
  business: Business,
) => Omit<JpActOnSpecifiedCommercialTransaction, 'display_address' | 'display_phone'> = (business: Business) => {
  switch (business.business_type) {
    case 'company':
      return {
        corporate_name: business.company.name,
        representative_name: `${business.representative.last_name} ${business.representative.first_name}`,
        address:
          `${business.company.state}${business.company.city}${business.company.town}${business.company.line1} ${business.company.line2 || ''}`.trimEnd(),
        address_hidden: false,
        phone: business.company.phone,
        phone_hidden: false,
        business_hours_and_closed_days: `営業時間 :
定休日 : `,
        pricing: '都度お見積りいたします。',
        additional_fees: `送料 :
消費税 : 10%`,
        estimated_delivery: 'ご注文受付後、お見積り時に提示した日数を目安にお届けいたします。',
        cancellation_policy: '注文確定後のキャンセルは原則受け付けておりません。',
        after_service_policy: '',
      };
    case 'individual':
      return {
        corporate_name: '',
        representative_name: `${business.individual.last_name} ${business.individual.first_name}`,
        address:
          `${business.individual.state}${business.individual.city}${business.individual.town}${business.individual.line1} ${business.individual.line2 || ''}`.trimEnd(),
        address_hidden: true,
        phone: business.individual.phone,
        phone_hidden: true,
        business_hours_and_closed_days: `営業時間 :
定休日 : `,
        pricing: '都度お見積りいたします。',
        additional_fees: `送料 :
消費税 : 10%`,
        estimated_delivery: 'ご注文受付後、お見積り時に提示した日数を目安にお届けいたします。',
        cancellation_policy: '注文確定後のキャンセルは原則受け付けておりません。',
        after_service_policy: '',
      };
  }
};
