import './Stack.css';

import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';
import { BoxProps, GlobalValues, Sizes, styleBoxProps } from 'src/components/primitives/PrimitiveComponent';

import { Primitive } from '@radix-ui/react-primitive';

type StackElement = React.ElementRef<typeof Primitive.div>;
type PrimitiveStackProps = ComponentPropsWithoutRef<typeof Primitive.div> & BoxProps;
interface StackProps extends PrimitiveStackProps {
  direction?: 'vertical' | 'horizontal';
  align?:
    | 'normal'
    | 'stretch'
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'self-start'
    | 'self-end'
    | GlobalValues;
  justify?:
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'left'
    | 'right'
    | 'normal'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
    | GlobalValues;
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse' | GlobalValues;
  spacing?: Sizes | 'sticky';
}

export const Stack = forwardRef<StackElement, StackProps>(
  (
    { direction = 'vertical', align = 'center', justify = 'center', wrap = 'nowrap', spacing = 'md', ...originalProps },
    forwardedRef,
  ) => {
    const { className, ...props } = styleBoxProps(originalProps);
    return (
      <Primitive.div
        className={[
          'stack',
          `stack-direction-${direction}`,
          `stack-align-${align}`,
          `stack-justify-${justify}`,
          `stack-wrap-${wrap}`,
          `stack-spacing-${spacing}`,
          className,
        ].join(' ')}
        {...props}
        ref={forwardedRef}
      />
    );
  },
);

Stack.displayName = 'Stack';

export const VStack = forwardRef<StackElement, Omit<StackProps, 'direction'>>((props, forwardedRef) => {
  return <Stack direction="vertical" {...props} ref={forwardedRef} />;
});

VStack.displayName = 'VStack';

export const HStack = forwardRef<StackElement, Omit<StackProps, 'direction'>>((props, forwardedRef) => {
  return <Stack direction="horizontal" {...props} ref={forwardedRef} />;
});

HStack.displayName = 'HStack';

type IconicStackElement = React.ElementRef<typeof Primitive.div>;
type PrimitiveIconicStackProps = ComponentPropsWithoutRef<typeof Primitive.div> & BoxProps;
interface IconicStackProps extends PrimitiveIconicStackProps {
  align?:
    | 'normal'
    | 'stretch'
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'self-start'
    | 'self-end'
    | GlobalValues;
  justify?:
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'left'
    | 'right'
    | 'normal'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
    | GlobalValues;
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse' | GlobalValues;
  spacing?: Sizes | 'sticky';
  gap?: Sizes | 'sticky';
  icon: ReactNode;
  addon?: ReactNode;
}

export const IconicStack = forwardRef<IconicStackElement, IconicStackProps>(
  ({ icon, addon, children, spacing, gap, w, ...originalProps }, forwardedRef) => {
    const { className, ...props } = styleBoxProps(originalProps);
    return (
      <Stack
        direction="horizontal"
        className={`iconic-stack ${className}`}
        spacing={gap || spacing}
        w={w}
        ref={forwardedRef}
        justify="flex-start"
      >
        <div className="icon">{icon}</div>
        <VStack className="children-stack" align="stretch" {...props} spacing={spacing}>
          {children}
        </VStack>
        {addon && <div className="addon">{addon}</div>}
      </Stack>
    );
  },
);

type StackAddonElement = React.ElementRef<typeof Primitive.div>;
type PrimitiveStackAddonProps = ComponentPropsWithoutRef<typeof Primitive.div> & BoxProps;
interface StackAddonProps extends PrimitiveStackAddonProps {}

export const StackAddon = forwardRef<StackAddonElement, StackAddonProps>((originalProps, forwardRef) => {
  const { className, ...props } = styleBoxProps(originalProps);
  return <Primitive.div className={`stack-addon ${className}`} {...props} ref={forwardRef} />;
});
