import { ComponentProps, createContext, FC, useContext } from 'react';
import { Icon } from 'src/components/primitives/Icon';
import { Note } from 'src/components/primitives/Note';
import { BusinessIssue } from 'src/models/v1/business';

type BusinessIssuesCollection = {
  issues: Array<BusinessIssue>;
};

export const BusinessIssuesContext = createContext<BusinessIssuesCollection>({ issues: [] });

export const BusinessIssuesView: FC<{ kind: BusinessIssue['kind'] } & ComponentProps<typeof Note.Root>> = ({
  kind,
  ...props
}) => {
  const { issues } = useContext(BusinessIssuesContext);
  const visibleIssues = issues.filter((issue) => issue.kind == kind);
  return visibleIssues.map((issue) => (
    <Note.Root key={issue.gid} variant="error" {...props}>
      <Icon name="error" />
      <Note.Description>{issue.memo}</Note.Description>
    </Note.Root>
  ));
};
