import { ComponentPropsWithoutRef, FC, ReactNode, useContext } from 'react';
import { FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { ErrorMessage } from 'src/components/control/ErrorMessage';
import { FormControl } from 'src/components/control/FormControl';
import { Field, FieldAddon } from 'src/components/primitives/Field';
import { Icon } from 'src/components/primitives/Icon';
import { styleBoxProps } from 'src/components/primitives/PrimitiveComponent';
import { Text } from 'src/components/primitives/Text';
import UnitSelect from 'src/components/styled-radix/UnitSelect';
import { AriaAttributesContext } from 'src/lib/a11y';
import { useI18n } from 'src/lib/i18n';

import { Primitive } from '@radix-ui/react-primitive';
import { validateOnlyInteger, validatePositiveNumber } from 'src/lib/validation';

type PrimitiveDaysFieldProps = ComponentPropsWithoutRef<typeof Primitive.input>;
interface DaysFieldProps extends PrimitiveDaysFieldProps {
  name: string;
  unitName: string;
  id?: string;
  label?: ReactNode;
  placeholder?: string;
  options?: RegisterOptions<FieldValues, string>;
}

export const DaysField: FC<DaysFieldProps> = ({
  name,
  unitName,
  id = name,
  label,
  placeholder,
  options,
  children,
  ...originalProps
}) => {
  const { ...props } = styleBoxProps(originalProps);
  const { i18n } = useI18n();
  const { register } = useFormContext<FieldValues>();
  const a11yAttributes = useContext(AriaAttributesContext);

  return (
    <FormControl
      id={id}
      name={name}
      required={options?.required ? true : false}
      label={label || i18n.t(`attributes.${name}`)}
    >
      {children}
      <Field>
        <input
          id={name}
          placeholder={placeholder || (i18n.has(`placeholder.${name}`) ? i18n.t(`placeholder.${name}`) : '')}
          {...register(name, {
            ...options,
            validate: {
              ...(options?.validate || {}),
              only_integer: validateOnlyInteger,
              positive_number: validatePositiveNumber,
            },
          })}
          {...props}
          {...a11yAttributes}
        />
        <FieldAddon>
          <DaysUnitField name={unitName} />
        </FieldAddon>
      </Field>
      <ErrorMessage name={name} label={label} />
    </FormControl>
  );
};

const DaysUnitField: FC<{ name: string }> = ({ name }) => {
  const { i18n } = useI18n();
  const { setValue, watch } = useFormContext<FieldValues>();
  return (
    <UnitSelect.Root
      defaultValue={watch(name)}
      onValueChange={(value) => setValue(name, value, { shouldValidate: true })}
    >
      <UnitSelect.Trigger>
        <UnitSelect.Value />
        <UnitSelect.Icon>
          <Icon name="arrow_drop_down" />
        </UnitSelect.Icon>
      </UnitSelect.Trigger>
      <UnitSelect.Portal>
        <UnitSelect.Content position="popper">
          <UnitSelect.Viewport>
            {['day', 'week', 'month'].map((unit) => {
              return (
                <UnitSelect.Item value={unit}>
                  <UnitSelect.ItemText>
                    <Text>{i18n.t(`enum.delivery_days_unit.${unit}`)}</Text>
                  </UnitSelect.ItemText>
                </UnitSelect.Item>
              );
            })}
          </UnitSelect.Viewport>
        </UnitSelect.Content>
      </UnitSelect.Portal>
    </UnitSelect.Root>
  );
};
