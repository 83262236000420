import './DialogBox.css';

import { FC, PropsWithChildren, ReactNode } from 'react';
import Close from '@nagakuinc/icons/svg/close.svg?react';

import * as Dialog from '@radix-ui/react-dialog';

export const DialogBox: FC<
  PropsWithChildren<{ title: ReactNode; onClose?: () => void | Promise<void>; embed?: boolean }>
> = ({ children, title, onClose, embed = false }) => {
  return (
    <Dialog.Content className={`dialog-box ${embed ? '' : 'fixed'}`}>
      {onClose && (
        <Dialog.Close asChild>
          <button className="close" aria-label="Close" onClick={onClose}>
            <Close />
          </button>
        </Dialog.Close>
      )}
      <Dialog.Title asChild className="title">
        <h2>{title}</h2>
      </Dialog.Title>
      {children}
    </Dialog.Content>
  );
};
