import './DateOfBirthField.css';

import React, { ComponentPropsWithoutRef, forwardRef, HTMLInputAutoCompleteAttribute, useCallback, useId } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from 'src/components/control/ErrorMessage';
import { FormControl } from 'src/components/control/FormControl';
import { Field } from 'src/components/primitives/Field';
import { HStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { calculateAge, convertWesternToJapaneseEra, isNumericString, isValidDate, parseDateString } from 'src/lib/date';
import { useI18n } from 'src/lib/i18n';

export interface DateFieldValues {
  year: string;
  month: string;
  day: string;
}

type FormType = 'year' | 'month' | 'day';

type DateFieldElement = HTMLInputElement;
type PrimitiveDateFieldProps = ComponentPropsWithoutRef<'input'>;
interface DateFieldProps extends Omit<PrimitiveDateFieldProps, 'type' | 'inputMode'> {
  type: FormType;
  isError: boolean;
  onPaste: (e: React.ClipboardEvent<HTMLInputElement>) => void;
}

const ATTRIBUTES: Record<FormType, { autoComplete: HTMLInputAutoCompleteAttribute; size: number }> = {
  year: { autoComplete: 'bday-year', size: 4 },
  month: { autoComplete: 'bday-month', size: 2 },
  day: { autoComplete: 'bday-day', size: 2 },
};

const DateField = forwardRef<DateFieldElement, DateFieldProps>(
  ({ type, isError, onPaste, className, ...props }, forwardedRef) => {
    const now = new Date();
    const placeholders: Record<FormType, string> = {
      year: now.getFullYear().toString(),
      month: (now.getMonth() + 1).toString(),
      day: now.getDate().toString(),
    };
    return (
      <input
        {...props}
        ref={forwardedRef}
        type="text"
        inputMode="numeric"
        placeholder={placeholders[type]}
        aria-invalid={isError ? 'true' : 'false'}
        className={['input', className].filter(Boolean).join(' ')}
        onPaste={onPaste}
      />
    );
  },
);

DateField.displayName = 'DateField';

// Remove non-numeric characters and limit to the specified number of digits
const normalizeNumberInput = (value: string, maxDigits: number) => {
  const numbersOnly = value.replace(/[^\d]/g, '');
  return numbersOnly.slice(0, maxDigits);
};

// Zero-pad to the specified number of digits
const padWithZero = (value: string, digits: number) => {
  return value.padStart(digits, '0');
};

interface DateOfBirthFieldProps {
  name: string;
  options: {
    required: boolean;
  };
}

const parseValue = (value: string | null | undefined) => {
  if (typeof value == 'string') {
    const [year, month, day] = value.split('-');
    return { year: year || '', month: month || '', day: day || '' };
  } else {
    return { year: '', month: '', day: '' };
  }
};

const isFilled = (type: FormType, value: string) => {
  switch (type) {
    case 'year':
      return value.length === 4;
    default:
      return value.length === 2;
  }
};

const getNextFieldType = (type: FormType, order: FormType[]): FormType | null => {
  const currentIndex = order.indexOf(type);
  // If type is not found in order array or it's the last element
  if (currentIndex === -1 || currentIndex === order.length - 1) {
    return null;
  }
  return order[currentIndex + 1];
};

export const DateOfBirthField: React.FC<DateOfBirthFieldProps> = ({ name, options: { required } }) => {
  const { i18n } = useI18n();
  const methods = useFormContext();
  const { register, watch, setValue, getValues, getFieldState } = methods;
  const values = parseValue(watch(name));
  const { year, month, day } = values;
  const age = calculateAge(watch());
  const order: FormType[] = i18n.locale() === 'ja' ? ['year', 'month', 'day'] : ['day', 'month', 'year'];
  const id = useId();

  const onPaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      const pastedText = event.clipboardData.getData('text');
      const result = parseDateString(pastedText);
      if (!result) {
        if (!isNumericString(pastedText)) {
          // If parsing is unsuccessful and the pasted text includes non-numeric characters, prevent pasting
          event.preventDefault();
        }
        return;
      }
      // If parsing is successful, prevent pasting and set the parsed date values
      event.preventDefault();
      setValue(name, `${result.year}-${result.month}-${result.day}`, { shouldValidate: true });
    },
    [name, setValue],
  );

  const onChange = useCallback(
    (type: FormType, value: string) => {
      let formattedValue = parseValue(getValues(name));
      formattedValue[type] = normalizeNumberInput(value, type == 'year' ? 4 : 2);
      setValue(name, `${formattedValue.year}-${formattedValue.month}-${formattedValue.day}`, { shouldValidate: true });

      // focus the next field if needed
      if (isFilled(type, formattedValue[type]) && !error) {
        const nextFieldType = getNextFieldType(type, order);
        if (!nextFieldType) {
          return;
        }
        document.getElementById(`${id}-${nextFieldType}`)?.focus();
      }
    },
    [getValues, name, setValue, order],
  );

  register(name, {
    validate: {
      required: (value: string) => {
        if (!required) return false;
        const { year, month, day } = parseValue(value);
        if (!year || !month || !day) return i18n.t('validation.required', { name: label });
      },
      validDate: (value: string) => {
        const { year, month, day } = parseValue(value);
        return isValidDate({ year, month, day }) || i18n.t('valication_rule.dob');
      },
    },
  });

  const { error } = getFieldState(name);
  const label = i18n.t('form.date.dob');

  return (
    <FormControl id={id} required={required} label={label}>
      <HStack asChild justify="start" className="date-of-birth-field" spacing="sticky">
        <Field asChild>
          <fieldset>
            {order.map((type, index) => (
              <React.Fragment key={type}>
                <DateField
                  type={type}
                  id={`${id}-${type}`}
                  isError={!!error}
                  onPaste={onPaste}
                  value={values[type]}
                  onChange={(event) => onChange(type, event.target.value)}
                  {...ATTRIBUTES[type]}
                />
                {index < order.length - 1 && (
                  <div className="date-of-birth-field-separator" aria-hidden="true">
                    /
                  </div>
                )}
              </React.Fragment>
            ))}
          </fieldset>
        </Field>
      </HStack>
      <ErrorMessage name={name} label={label} />
      {!error && age && (
        <Text variant="label" size="large" color="note">
          {i18n.t('form.date.dob_and_age', {
            year: i18n.locale() === 'ja' ? convertWesternToJapaneseEra(parseInt(year)) : year,
            month: padWithZero(month, 2),
            day: padWithZero(day, 2),
            age: age,
          })}
        </Text>
      )}
    </FormControl>
  );
};
