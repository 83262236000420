import { FC, PropsWithChildren } from 'react';
import { onUpdateBusinessCompanyAddress } from 'src/actions/business';
import { AddressField } from 'src/components/control/AddressField';
import { Form } from 'src/components/control/Form';
import { BusinessIssuesView } from 'src/components/features/business/BusinessIssuesView';
import { Business } from 'src/models/v1/business';

export const BusinessCompanyAddressForm: FC<PropsWithChildren<{ business: Business; onSuccess: () => void }>> = ({
  business,
  onSuccess,
  children,
}) => {
  if (business.business_type != 'company') return;

  return (
    <Form
      onSubmit={onUpdateBusinessCompanyAddress({})}
      onSuccess={onSuccess}
      defaultValues={{
        business: {
          company: {
            postal_code: business.company.postal_code,
            state: business.company.state,
            city: business.company.city,
            city_kana: business.company.city_kana,
            town: business.company.town,
            town_kana: business.company.town_kana,
            line1: business.company.line1,
            line1_kana: business.company.line1_kana,
            line2: business.company.line2,
            line2_kana: business.company.line2_kana,
          },
        },
      }}
      defaultRequired
      readOnly={business.status != 'draft'}
    >
      <BusinessIssuesView kind="review_address" />
      <AddressField namePrefix="business.company" options={{ required: true }} />
      {children}
    </Form>
  );
};
