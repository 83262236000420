import './Alert.css';
import { ComponentProps, ComponentPropsWithoutRef, forwardRef } from 'react';
import { Icon } from 'src/components/primitives/Icon';

import { Primitive } from '@radix-ui/react-primitive';
import { BoxProps, styleBoxProps } from 'src/components/primitives/PrimitiveComponent';
import { HStack } from 'src/components/primitives/Stack';

type AlertElement = React.ElementRef<typeof Primitive.div>;
type PrimitiveAlertProps = ComponentPropsWithoutRef<typeof Primitive.div> & BoxProps;
interface AlertProps extends PrimitiveAlertProps {
  variant?: 'info';
  icon: ComponentProps<typeof Icon>['name'];
}

export const Alert = forwardRef<AlertElement, AlertProps>(
  ({ variant, icon, children, ...originalProps }, forwardedRef) => {
    const { className, ...props } = styleBoxProps(originalProps);
    return (
      <HStack
        ref={forwardedRef}
        className={`alert alert-${variant} ${className}`}
        {...props}
        align="center"
        spacing="sm"
      >
        <div className="alert-icon">
          <Icon name={icon} />
        </div>
        {children}
      </HStack>
    );
  },
);
