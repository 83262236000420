import './EmailVerificationForm.css';

import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router';
import { Button } from 'src/components/styles/Button';
import { Icon } from 'src/components/primitives/Icon';
import { Text } from 'src/components/primitives/Text';
import { api } from 'src/lib/api';
import { ApiError } from 'src/lib/errors';
import { useI18n } from 'src/lib/i18n';
import { Account } from 'src/models/v1/account';

type EmailVerificationStatus = 'stable' | 'submitting' | 'maybe_expired' | 'error' | 'completed';

export const EmailVerificationForm: FC<{ token: string; account: Account }> = ({ token, account }) => {
  const navigate = useNavigate();
  const { i18n } = useI18n();
  const [status, setStatus] = useState<EmailVerificationStatus>('stable');
  const onVerify = useCallback(async () => {
    setStatus('submitting');
    try {
      await api.dispatch('POST', `/v1/accounts/${account!.gid}/verify_email`, {}, { verification: { token } });
      setStatus('completed');
      setTimeout(() => navigate('/settings/account'), 500);
    } catch (error: unknown) {
      if (error instanceof ApiError && error.code == 'expired_or_completed_process') {
        setStatus('maybe_expired');
      } else {
        setStatus('error');
      }
    }
  }, [setStatus, navigate, account, token]);
  return (
    <section className="email-verification-form">
      <Icon name="email" className="icon" width="72px" height="72px" />
      <Text>{i18n.t('email_verification.instruction')}</Text>
      <button className={Button.class} {...Button.build({})} onClick={onVerify} disabled={status != 'stable'}>
        {i18n.t('action.verify_email')}
      </button>
      <StatusMessage status={status} />
    </section>
  );
};

const StatusMessage: FC<{ status: EmailVerificationStatus }> = ({ status }) => {
  const { i18n } = useI18n();

  switch (status) {
    case 'completed':
      return (
        <>
          <Icon name="verified" />
          <Text variant="title">{i18n.t('email_verification.status.completed.title')}</Text>
          <Text>{i18n.t('email_verification.status.completed.message')}</Text>
          <Text>
            <Link to="/">{i18n.t('email_verification.status.completed.action')}</Link>
          </Text>
        </>
      );
    case 'maybe_expired':
      return (
        <>
          <Text variant="title">{i18n.t('email_verification.status.expired.title')}</Text>
          <Text>{i18n.t('email_verification.status.expired.message')}</Text>
          {/* TODO: 再送用の案内 */}
        </>
      );
    case 'error':
      return (
        <>
          <Text variant="title">{i18n.t('email_verification.status.error.title')}</Text>
          <Text>{i18n.t('email_verification.status.error.message')}</Text>
        </>
      );
  }
};
