import './Avatar.css';

import { Icon } from 'src/components/primitives/Icon';
import { MediaImage } from 'src/components/primitives/MediaImage';

import { ComponentProps, ComponentPropsWithoutRef, forwardRef } from 'react';
import { BoxProps, styleBoxProps } from 'src/components/primitives/PrimitiveComponent';

import { Primitive } from '@radix-ui/react-primitive';
import { Account } from 'src/models/v1/account';
import { getRandomBackgroundColorByAccount } from 'src/lib/color';

type AvatarElement = React.ElementRef<typeof Primitive.div>;
type PrimitiveAvatarProps = ComponentPropsWithoutRef<typeof Primitive.div> & BoxProps;
interface AvatarProps extends PrimitiveAvatarProps {
  src?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  account?: Pick<Account, 'gid' | 'kind' | 'display_name' | 'icon_url'>;
  enabledProSymbol?: boolean;
}

const SIZE_TABLE: { [size in NonNullable<AvatarProps['size']>]: ComponentProps<typeof MediaImage>['size'] } = {
  xs: '144x144',
  sm: '144x144',
  md: '144x144',
  lg: '216x216',
  xl: '512x512',
};

export const Avatar = forwardRef<AvatarElement, AvatarProps>(
  ({ src, size = 'lg', account, enabledProSymbol = true, ...originalProps }, forwardedRef) => {
    const { className, ...props } = styleBoxProps(originalProps);
    const backgroundColor = account?.gid ? getRandomBackgroundColorByAccount(account) : 'transparent';
    return (
      <Primitive.div
        className={[`avatar avatar-size-${size}`, className].join(' ')}
        {...props}
        ref={forwardedRef}
        style={{ backgroundColor }}
      >
        {account?.kind == 'pro' && enabledProSymbol && <Icon className="pro-badge" name={'symbol_pro'} />}
        {src ? (
          <MediaImage className="avatar-icon" src={src} size={SIZE_TABLE[size]} alt="" draggable="false" />
        ) : (
          <Icon name="person" className="avatar-placeholder" />
        )}
      </Primitive.div>
    );
  },
);
