import { factoryAddress } from 'src/catalog/factories/address';
import { defineFactory, FactoryBlueprint } from 'src/catalog/factories/factoryBuilder';
import { BusinessProfile } from 'src/models/v1/business';

// MARK: base

const base: FactoryBlueprint<BusinessProfile> = {
  description: 'Business Profile Description',
  notes: null,
  cancellation_policy: 'Business Profile Cancellation Policy',
  shipping_method: null,
  support_areas: null,
  business_hours: null,
  regular_closed_days: null,
  tagline: 'Tagline',
  support_phone: null,
  support_types: [],
  accept_repair_request: false,
  area: '長野県長野市',
  address: factoryAddress(),
  address_hidden: false,
  reception_message: null,
  reception_body_annotation: null,
};

// MARK: factory

export const factoryBusinessProfile = defineFactory(base, {});
