import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router';
import { PagingNavigation } from 'src/components/control/PagingNavigation';
import { ContentEmpty } from 'src/components/features/ContentEmpty';
import { OrderTable } from 'src/components/features/order/OrderTable';
import { NavigationLayout } from 'src/components/Layout';
import { Heading } from 'src/components/primitives/Heading';
import { PageLoader } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { Pagination } from 'src/models/general';
import { Order } from 'src/models/v1/order';

type Data = {
  orders: Array<Order>;
  pagination: Pagination;
};

export const DashboardOrderListPage: FC = () => {
  const { i18n } = useI18n();
  const { orders, pagination } = useLoaderData() as Data;
  return (
    <NavigationLayout>
      <Helmet>
        <title>{`${i18n.t('title.dashboard.orders')} | ${i18n.t('nagaku')}`}</title>
        <meta property="og:title" content={`${i18n.t('title.dashboard.orders')} | ${i18n.t('nagaku')}`} />
      </Helmet>
      <Heading>{i18n.t('title.dashboard.orders')}</Heading>
      {orders.length > 0 ? <OrderTable orders={orders} /> : <ContentEmpty type="dashboard_order" />}
      <PagingNavigation current={pagination.current} totalPages={pagination.pages} />
    </NavigationLayout>
  );
};

export const dashboardOrderListLoader: PageLoader = async ({ api, request }) => {
  const search = new URL(request.url).search;
  return await api.fetch(`/v1/accounts/me/orders${search}`);
};
