import './EstimateBreakdown.css';

import { FC } from 'react';
import { PriceTag } from 'src/components/primitives/PriceTag';
import { HStack, VStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';
import { Estimate, EstimateItem } from 'src/models/v1/estimate';

export const EstimateBreakdown: FC<{ estimate: Estimate }> = ({ estimate }) => {
  const { i18n } = useI18n();
  return (
    <VStack className="estimate-breakdown" align="stretch" w="full" py="sm" px="lg" spacing="sticky">
      {estimate.items.map((item, index) => (
        <EstimateItemView key={index} item={item} />
      ))}
      <HStack w="full" justify="space-between" py="sm">
        <Text>{i18n.t('attributes.quotation.subtotal_amount')}</Text>
        <Text>
          <PriceTag value={estimate.breakdown.subtotal_amount} />
        </Text>
      </HStack>
      <div className="divider" role="separator" aria-orientation="horizontal" />
      <HStack w="full" justify="space-between" py="sm">
        <Text>{i18n.t('attributes.quotation.consumption_tax_amount')}</Text>
        <Text>
          <PriceTag value={estimate.breakdown.consumption_tax_amount} />
        </Text>
      </HStack>
      {estimate.breakdown.shipping_fee && (
        <HStack w="full" justify="space-between" py="sm">
          <Text>{i18n.t('attributes.quotation.shipping_fee')}</Text>
          <Text>
            <PriceTag value={estimate.breakdown.shipping_fee} />
          </Text>
        </HStack>
      )}
      <HStack w="full" justify="space-between" py="sm">
        <Text weight="bold">{i18n.t('attributes.quotation.total_amount')}</Text>
        <Text weight="bold">
          <PriceTag value={estimate.breakdown.total_amount} />
        </Text>
      </HStack>
    </VStack>
  );
};

const EstimateItemView: FC<{ item: EstimateItem }> = ({ item }) => {
  const { i18n } = useI18n();
  return (
    <VStack className="item" align="stretch" spacing="sm" py="sm" m="none">
      <Text asChild weight="bold" className="label">
        <h3>{item.label}</h3>
      </Text>
      <HStack asChild className="data" spacing="sm" w="full">
        <dl>
          <div className="dataitem">
            <Text asChild>
              <dt>{i18n.t('attributes.quotation.items.amount')}</dt>
            </Text>
            <Text asChild weight="bold">
              <dd>
                <PriceTag value={item.amount} />
              </dd>
            </Text>
          </div>
          <div className="dataitem">
            <Text asChild>
              <dt>{i18n.t('attributes.quotation.items.quantity')}</dt>
            </Text>
            <Text asChild weight="bold">
              <dd>{item.quantity || '-'}</dd>
            </Text>
          </div>
          <div className="dataitem subtotal">
            <Text asChild>
              <dt>{i18n.t('attributes.quotation.items.sum')}</dt>
            </Text>
            <Text asChild weight="bold">
              <dd>
                <PriceTag value={item.amount * (item.quantity || 1)} />
              </dd>
            </Text>
          </div>
        </dl>
      </HStack>
    </VStack>
  );
};
