import { createAction } from 'src/lib/action';
import { api } from 'src/lib/api';
import { Estimate, RetrieveEstimateResponse, UpdateEstimateBody } from 'src/models/v1/estimate';

export const onUpdateEstimate = createAction(
  'onUpdateEstimate',
  ({
    estimate,
    onComplete,
  }: {
    estimate: { gid: string };
    onComplete?: (estimate: Estimate) => void | Promise<void>;
  }) => {
    const { gid } = estimate;
    return async (body: UpdateEstimateBody) => {
      const { estimate } = await api.dispatch<UpdateEstimateBody, RetrieveEstimateResponse>(
        'PUT',
        `/v1/estimates/${gid}`,
        undefined,
        body,
      );
      onComplete?.(estimate);
    };
  },
);
