import './Toast.css';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { FC, PropsWithChildren } from 'react';
import { Icon } from 'src/components/primitives/Icon';

type ToastVariant = 'notification' | 'error' | 'success';

export const Toast: FC<PropsWithChildren<{ variant: ToastVariant; onClick?: () => void }>> = ({
  variant,
  onClick,
  children,
}) => {
  const rootClassName = `toast-root-${variant} `;
  const titleClassName = `toast-title-${variant}`;

  return (
    <ToastPrimitive.Provider>
      <ToastPrimitive.Root className={`${rootClassName} toast-root`} open={true} onClick={onClick}>
        <ToastPrimitive.Title className={`${titleClassName} toast`}>
          <ToastIcon variant={variant} />
          {children}
        </ToastPrimitive.Title>
      </ToastPrimitive.Root>
      <ToastPrimitive.Viewport />
    </ToastPrimitive.Provider>
  );
};

const ToastIcon: FC<{ variant: ToastVariant }> = ({ variant }) => {
  const className = 'toast-icon toast';
  switch (variant) {
    case 'success':
    case 'notification':
      return <Icon name="check_circle" className={className} />;
    case 'error':
      return <Icon name="error" className={className} />;
  }
};
