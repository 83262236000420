import { WorkCard } from 'src/components/features/work/WorkCard';
import { Meta } from 'src/catalog/Story';
import { factoryWork } from 'src/catalog/factories/work';

const meta: Meta<typeof WorkCard> = {
  title: 'Work Card',
  args: {
    work: factoryWork(),
  },
  template: (args) => <WorkCard {...args} />,
};

export default meta;
