import './LikeSwitch.css';

import { FC, HTMLAttributes, useCallback, MouseEvent } from 'react';
import { Icon } from 'src/components/primitives/Icon';
import { api } from 'src/lib/api';

export const LikeSwitch: FC<
  {
    gid: string;
    liked: boolean | null;
    onLike: () => void;
    onUnlike: () => void;
  } & HTMLAttributes<SVGSVGElement>
> = ({ gid, liked, onLike, onUnlike, ...props }) => {
  const handleLike = useCallback(async () => {
    await api.dispatch<unknown, unknown>(liked ? 'DELETE' : 'POST', `/v1/reactions/${gid}/like`, {}, {});
    liked ? onUnlike() : onLike();
  }, [liked]);

  const onClick = useCallback(
    async (event: MouseEvent<SVGSVGElement>) => {
      if (props.onClick) {
        props.onClick(event);
      }
      if (event.defaultPrevented) return;
      await handleLike();
    },
    [props.onClick],
  );

  return (
    <Icon
      name={liked ? 'favorite' : 'favorite_border'}
      className={'like-switch' + (liked ? '-liked' : '')}
      onClick={onClick}
    />
  );
};
