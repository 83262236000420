import './AccountMenu.css';

import DropdownMenu from 'src/components/styled-radix/DropdownMenu';
import { FC, useCallback, useMemo } from 'react';
import { Link } from 'react-router';
import { Icon } from 'src/components/primitives/Icon';
import { api } from 'src/lib/api';
import { ApiError } from 'src/lib/errors';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';
import { useToast } from 'src/lib/toast';
import { Account } from 'src/models/v1/account';

export const AccountMenu: FC<{ account: Pick<Account, 'gid'> }> = ({ account }) => {
  const { i18n } = useI18n();
  const { me } = useCurrentStatus();
  const toast = useToast();
  const onReport = useCallback(async () => {
    try {
      await api.dispatch<unknown, unknown>('POST', `/v1/accounts/${account.gid}/spam_reports`, {}, {});
      toast({ message: i18n.t('message.spam_report_success'), variant: 'success' });
    } catch (error: unknown) {
      if (error instanceof ApiError) {
        toast({ message: error.message, variant: 'error' });
      }
    }
  }, [account, toast]);
  const isMe = useMemo(() => {
    return me?.gid === account?.gid;
  }, [me, account]);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className="account-menu" aria-label={i18n.t('label.menu')}>
        <Icon name="more_horiz" />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className="account-menu-content" align="end">
          {isMe && (
            <DropdownMenu.Item asChild className="item">
              <Link to="/works/draft">{i18n.t('title.draft_works') /* TODO: fixit, use label or action */}</Link>
            </DropdownMenu.Item>
          )}
          {!isMe && (
            <DropdownMenu.Item className="item" onSelect={() => onReport()}>
              {i18n.t('action.spam_report')}
            </DropdownMenu.Item>
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
