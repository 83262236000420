export const defineFactory: <Base, Traits extends { [name: string]: Partial<FactoryBlueprint<Base>> }>(
  baseObject: FactoryBlueprint<Base>,
  traits: Traits,
  builder?: (baseObject: FactoryBlueprint<Base>, trait: Partial<FactoryBlueprint<Base>>) => FactoryBlueprint<Base>,
) => (name?: keyof Traits) => Base = (baseObject, traits, builder = (obj, trait) => ({ ...obj, ...trait })) => {
  return (name?) => {
    const obj = { ...baseObject };
    const trait = name ? traits[name] : {};
    return project(builder(obj, trait || {}));
  };
};

export type FactoryBlueprint<T> = {
  [K in keyof T]: T[K] | (() => T[K]);
};

function project<T>(blueprint: FactoryBlueprint<T>) {
  const result = {} as T;
  Object.entries(blueprint).forEach(([key, value]) => {
    result[key as keyof T] = typeof value === 'function' ? value() : value;
  });
  return result;
}
