import './OpenTicketStickyBar.css';

import { FC, useEffect, useState } from 'react';
import { Account } from 'src/models/v1/account';
import { OpenTicketBar } from 'src/components/features/ticket/OpenTicketBar';

export const OpenTicketStickyBar: FC<{
  account: Pick<Account, 'gid' | 'screen_name'>;
  isHidden?: boolean;
}> = ({ account, isHidden }) => {
  const [hide, setHide] = useState(true);
  useEffect(() => {
    isHidden || setHide(false);
  }, [isHidden]);

  return (
    <section className={`open-ticket-sticky-bar${isHidden ? ' slide-out' : ' slide-in'}`} aria-hidden={hide}>
      <OpenTicketBar account={account} />
    </section>
  );
};
