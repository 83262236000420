import { FC } from 'react';
import { NavigationLayout } from 'src/components/Layout';
import { Helmet } from 'react-helmet-async';
import { useI18n } from 'src/lib/i18n';
import { api, PageLoader } from 'src/lib/api';
import { useLoaderData } from 'react-router';
import { Tag } from 'src/models/v1/tag';
import { TagTileCollection } from 'src/components/features/tag/TagTileCollection';
import { TagList } from 'src/components/features/tag/TagList';
import { ActionHeading } from 'src/components/features/ActionHeading';
import { Divider } from 'src/components/features/Divider';

type Data = {
  tags: Tag[];
  featuredTags: Tag[];
};

export const TagsPage: FC = () => {
  const { i18n } = useI18n();
  const { tags, featuredTags } = useLoaderData() as Data;

  return (
    <NavigationLayout>
      <Helmet>
        <title>{`${i18n.t('title.tags')} | ${i18n.t('nagaku')}`}</title>
        <meta property="og:title" content={`${i18n.t('title.tags')} | ${i18n.t('nagaku')}`} />
        <meta name="description" content={`${i18n.t('description.prefix')}${i18n.t('description.tags')}`} />
        <meta property="og:description" content={`${i18n.t('description.prefix')}${i18n.t('description.tags')}`} />
      </Helmet>
      <ActionHeading title={i18n.t('title.tags')} to="/explore">
        {i18n.t('action.search_by_tag')}
      </ActionHeading>
      <TagTileCollection tags={featuredTags} />
      <Divider />
      <TagList tags={tags} />
    </NavigationLayout>
  );
};

export const tagListLoader: PageLoader = async () => {
  return await api.combine(
    api.fetch<{ tags: Tag }>('/v1/tags', { featured: 'true' }).then(({ tags }) => ({ featuredTags: tags })),
    api.fetch('/v1/tags', { include: 'statistics' }),
  );
};
