import './QuotationSummaryCard.css';

import { ComponentProps, FC } from 'react';
import { Card, CardContent } from 'src/components/primitives/Card';
import { PriceTag } from 'src/components/primitives/PriceTag';
import { HStack, VStack } from 'src/components/primitives/Stack';
import { Quotation } from 'src/models/v1/quotation';
import { Text } from 'src/components/primitives/Text';

import Dialog from 'src/components/styled-radix/Dialog';
import { QuotationView } from 'src/components/features/quotation/QuotationView';
import { useI18n } from 'src/lib/i18n';
import { NoticeLabel } from 'src/components/primitives/NoticeLabel';
import { Button } from 'src/components/primitives/Button';
import { checkTicketRole } from 'src/lib/ticket';
import { Timestamp } from 'src/components/primitives/Timestamp';

export const QuotationSummaryCard: FC<
  {
    quotation: Quotation;
    isAttached?: boolean; // It is true, this component is used as attached resource in comment form
  } & Omit<ComponentProps<typeof Card>, 'w' | 'borderRadius'>
> = ({ quotation, isAttached = false, className, ...props }) => {
  const { i18n } = useI18n();
  const ticketRole = checkTicketRole('repairer') ? 'repairer' : 'caller';
  return (
    <Dialog.Root>
      <Card borderRadius="sm" className={`quotation-card ${className || ''}`} w="full" {...props}>
        <CardContent>
          <NoticeLabel variant="primary" size="small" w="fit-content">
            {i18n.t('label.quotation')}
          </NoticeLabel>
          <VStack align="center" w="full">
            <Text variant="body" size="large" weight="bold" w="full">
              {quotation.title}
            </Text>
            <HStack w="full">
              <VStack spacing="sm" align="start" w="full">
                <Text color="note">{i18n.t('attributes.quotation.total_amount')}</Text>
                <Text size="large" weight="bold">
                  <PriceTag value={quotation.breakdown.total_amount} currency="jpy" />
                </Text>
              </VStack>
              <VStack spacing="sm" align="start" w="full">
                <Text color="note">{i18n.t('attributes.quotation.estimate_delivery_days')}</Text>
                <Text size="large" weight="bold">
                  {quotation.estimate_delivery_days ?? 0}{' '}
                  {i18n.t(`unit.delivery_days.${quotation.delivery_days_unit}`, quotation.estimate_delivery_days ?? 0)}
                </Text>
              </VStack>
            </HStack>
            <HStack justify="start" spacing="sticky" w="full">
              <Text color="note" size="small">
                {i18n.t('attributes.quotation.expires_date')}
                {' : '}
              </Text>
              <Timestamp color="note" size="small" value={new Date(quotation.expires_date)} format="year-date" />
            </HStack>
            {!isAttached && (
              <Dialog.Trigger asChild>
                <Button w="fit-content" variant={ticketRole == 'caller' ? 'primary' : 'outline'}>
                  {i18n.t(
                    ticketRole == 'caller' && quotation.status !== 'paid'
                      ? 'action.review_and_pay_quotation'
                      : 'action.show_quotaiton_details',
                  )}
                </Button>
              </Dialog.Trigger>
            )}
          </VStack>
        </CardContent>
      </Card>
      <Dialog.Portal>
        <Dialog.Overlay className="quotation-card-overlay" />
        <Dialog.Content variant="fill-container">
          <Dialog.Title>{quotation.title}</Dialog.Title>
          <Dialog.Description>
            <QuotationView quotation={quotation} />
          </Dialog.Description>
          <Dialog.Close />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
