import { ComponentProps, FC, PropsWithChildren, useMemo } from 'react';
import { FieldValues, RegisterOptions } from 'react-hook-form';
import { InputField } from 'src/components/control/InputField';
import { useI18n } from 'src/lib/i18n';
import {
  validateHasAlphabet,
  validateHasNumber,
  validateHasSymbol,
  validatePasswordFormat,
  validateMaxLengthOption,
  validateMinLengthOption,
} from 'src/lib/validation';

/**
 * For new password only, current password field does not require strict validations.
 */
export const PasswordField: FC<
  PropsWithChildren<{
    name: string;
    label?: ComponentProps<typeof InputField>['label'];
    autoComplete?: ComponentProps<typeof InputField>['autoComplete'];
    options?: { required: boolean };
  }>
> = ({ name, label, autoComplete = 'current-password', options, children }) => {
  const { i18n } = useI18n();
  const passwordOptions: RegisterOptions<FieldValues, string> = useMemo(
    () => ({
      ...options,
      minLength: validateMinLengthOption({ name, minLength: 8, i18n }),
      maxLength: validateMaxLengthOption({ name, maxLength: 64, i18n }),
      validate: {
        include_numeric_character: validateHasNumber,
        include_symbolic_character: validateHasSymbol,
        include_alphabetic_character: validateHasAlphabet,
        meets_password_format: validatePasswordFormat,
      },
    }),
    [i18n, options],
  );

  return (
    <InputField name={name} type="password" label={label} autoComplete={autoComplete} options={passwordOptions}>
      {children}
    </InputField>
  );
};
