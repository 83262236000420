import { useI18n } from 'src/lib/i18n';
import { validateMaxLengthOption, validateMinLengthOption } from 'src/lib/validation';
import { GID, Pagination } from 'src/models/general';
import { AuthResponse } from 'src/models/v1/auth';
import { BusinessProfile } from 'src/models/v1/business';

export type Account = {
  gid: GID;
  display_name: string;
  screen_name: string | null;
  bio: string;
  kind: 'pro' | null;
  icon_url: string | null;
  banner_url: string | null;
  published_works_count: number;
  tags?: Array<string>;
  business_profile?: BusinessProfile | null;
  followed: boolean | null;
};

export type AccountSetting = {
  email: string;
  email_verified: boolean;
  email_verification: boolean;
};

export type AccountContact = {
  email_domain: string;
};

// Get Account API - GET /:version/accounts/:gid

export type GetAccountResponse = {
  account: Account;
};

// List Account API - GET /:version/accounts

export type ListAccountsResponse = {
  accounts: Array<Account>;
  pagination: Pagination;
};

// Register Account API - POST /:version/accounts

export type RegisterAccountBody = {
  account: {
    display_name: string;
    email: string;
    password: string;
  };
};

export type RegisterAccountResponse = AuthResponse & GetAccountResponse;

// Update Account API - PUT /:version/accounts/:gid

export type UpdateAccountBody = {
  account: {
    display_name?: string;
    bio?: string;
    screen_name?: string;
    tagline?: string;
    email?: string;
    icon_media_file?: string;
    banner_media_file?: string;
  };
};

// Update Password API - POST /:version/accounts/:gid/change_password

export type UpdatePasswordBody = UpdatePasswordWithCurrentPasswordBody | UpdatePasswordWithRecoveryTokenBody;

export type UpdatePasswordWithCurrentPasswordBody = {
  account: {
    current_password: string;
    password: string;
  };
};

export type UpdatePasswordWithRecoveryTokenBody = {
  account: {
    recovery_token: string;
    password: string;
  };
};

// Update Email API - POST /:version/accounts/:gid/change_email

export type UpdateEmailBody = {
  account: {
    email: string;
    current_password: string;
  };
};

export type UpdateAccountResponse = GetAccountResponse;

// Kick Password Recovery API - POST /:version/accounts/recover_password

export type KickPasswordRecoveryBody = {
  password_recovery: { email: string };
};

// Validation

export const buildAccountDisplayNameOptions = () => {
  const { i18n } = useI18n();
  return {
    maxLength: validateMaxLengthOption({ name: 'account.display_name', maxLength: 32, i18n }),
    minLength: validateMinLengthOption({ name: 'account.display_name', minLength: 3, i18n }),
  };
};
