import './DataNote.css';

import { ComponentProps, FC, ReactNode } from 'react';
import { Icon } from 'src/components/primitives/Icon';
import { Text } from 'src/components/primitives/Text';

export const DataNote: FC<{ icon: ComponentProps<typeof Icon>['name']; label: ReactNode; value: string }> = ({
  icon,
  label,
  value,
}) => {
  return (
    <section className="data-note">
      <Icon name={icon} className="icon" />
      <Text asChild variant="label" color="note">
        <h3>{label}</h3>
      </Text>
      <Text asChild weight="bold">
        <p>{value}</p>
      </Text>
    </section>
  );
};
