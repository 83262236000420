import { FC, useState } from 'react';
import { onAcceptOrderCompletion } from 'src/actions/order';
import { MessageBalloon } from 'src/components/features/message/MessageBalloon';
import { Button } from 'src/components/primitives/Button';
import Dialog from 'src/components/styled-radix/Dialog';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';
import { Message, StartOrderCompletionMessage } from 'src/models/v1/message';

export const StartOrderCompletionMessageView: FC<{
  message: Pick<Message, 'data' | 'created_at'> & StartOrderCompletionMessage;
  onAccept?: () => Promise<void> | void;
}> = ({ message, onAccept = onAcceptOrderCompletion({ managementNumber: message.data.order.management_number }) }) => {
  const [open, setOpen] = useState(false);
  const { i18n } = useI18n();
  const { me } = useCurrentStatus();

  const { repairer_account, caller_account } = message.data.order;
  const isCaller = me?.gid === caller_account.gid;
  const isAcceptable = isCaller && message.data.order.completion_status === 'pending_acceptance';
  // Calculate expiration date (10 days after start_at)
  const startDate = new Date(message.data.start_at);
  const expirationDate = new Date(startDate);
  expirationDate.setDate(startDate.getDate() + 10);
  const expiresAt = `${expirationDate.getFullYear()}年${expirationDate.getMonth() + 1}月${expirationDate.getDate()}日`;
  const phraseVars = {
    repairer_display_name: repairer_account.display_name,
    caller_display_name: caller_account.display_name,
  };

  return (
    <MessageBalloon
      account={{ gid: 'NAGAKU', kind: null, display_name: i18n.t('nagaku'), icon_url: '/svg/logoAvatar.svg' }}
      body={i18n.t(`StartOrderCompletionMessageView.message.${isCaller ? 'for_caller' : 'for_non_caller'}`, phraseVars)}
      timestamp={message.created_at}
      action={
        isCaller && (
          <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger asChild disabled={!isAcceptable}>
              <Button variant="primary" mx="auto">
                {i18n.t('action.accept_order_completion')}
              </Button>
            </Dialog.Trigger>
            <Dialog.Portal>
              <Dialog.Overlay />
              <Dialog.Content variant="alert">
                <Dialog.Close />
                <Dialog.Title className="title">{i18n.t('StartOrderCompletionMessageView.accept.title')}</Dialog.Title>
                <Dialog.Description>
                  {i18n.t('StartOrderCompletionMessageView.accept.body', phraseVars)}
                </Dialog.Description>
                <Button
                  variant="primary"
                  onClick={async () => {
                    await onAccept({});
                    setOpen(false);
                  }}
                >
                  {i18n.t('action.accept_order_completion')}
                </Button>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
        )
      }
      hint={isAcceptable ? i18n.t('StartOrderCompletionMessageView.guide', { expires_at: expiresAt }) : undefined}
    />
  );
};
