import { FC } from 'react';
import { EmailSettingForm } from 'src/components/features/email_setting/EmailSettingForm';
import { PreviewAndEditable } from 'src/components/features/PreviewAndEditable';
import { Button } from 'src/components/primitives/Button';
import { Icon } from 'src/components/primitives/Icon';
import { NoticeLabel } from 'src/components/primitives/NoticeLabel';
import { VStack } from 'src/components/primitives/Stack';
import { SubSection } from 'src/components/primitives/SubSection';
import { Text } from 'src/components/primitives/Text';
import { useEditable } from 'src/lib/form';
import { useI18n } from 'src/lib/i18n';
import { AccountSetting } from 'src/models/v1/account';

export const EmailSettingSection: FC<{ accountSetting: Pick<AccountSetting, 'email' | 'email_verified'> }> = ({
  accountSetting,
}) => {
  const { i18n } = useI18n();
  return (
    <VStack asChild align="stretch" m="none" spacing="md">
      <SubSection>
        <EmailStatusLabel emailVerified={accountSetting.email_verified} />
        <Text variant="label" size="large" weight="bold" m="none">
          {i18n.t('attributes.account.current_email')}
        </Text>
        <Text size="large" weight="bold" m="none">
          {accountSetting.email}
        </Text>
        <PreviewAndEditable preview={<EditButton />} editable={<EmailSettingForm />} />
      </SubSection>
    </VStack>
  );
};

const EditButton = () => {
  const { i18n } = useI18n();
  const { onStartEdit } = useEditable();
  return (
    <Button variant="fill" size="small" mr="auto" onClick={onStartEdit}>
      {i18n.t('action.edit')}
    </Button>
  );
};

const EmailStatusLabel: FC<{ emailVerified: boolean }> = ({ emailVerified }) => {
  const { i18n } = useI18n();
  return (
    <NoticeLabel variant={emailVerified ? 'primary' : 'danger'} size="small" me="auto">
      <Icon name={emailVerified ? 'check_circle_outline' : 'do_not_disturb'} />
      {i18n.t(`label.${emailVerified ? 'verified' : 'unverified_email'}`)}
    </NoticeLabel>
  );
};
