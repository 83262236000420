export function isNumericString(text: string): boolean {
  return /^\d+$/.test(text);
}

export function isToday(date: Date): boolean {
  const today = new Date();

  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
}

// 年齢を計算
export const calculateAge = (values: { year?: string; month?: string; day?: string }): number | null => {
  const { year, month, day } = values;
  if (!year || !month || !day) return null;

  const birthDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

  if (isNaN(birthDate.getTime())) return null;

  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

export const isValidDate = (values: { year?: string; month?: string; day?: string }): boolean => {
  const { year, month, day } = values;
  if (!year || !month || !day) return true;

  const yearNum = parseInt(year);
  const monthNum = parseInt(month);
  const dayNum = parseInt(day);

  if (isNaN(yearNum) || isNaN(monthNum) || isNaN(dayNum)) {
    return false;
  }

  // 月の日数を取得（うるう年考慮）
  const daysInMonth = new Date(yearNum, monthNum, 0).getDate();

  return monthNum >= 1 && monthNum <= 12 && dayNum >= 1 && dayNum <= daysInMonth;
};

// 月名の定義
const monthNames = {
  Jan: '1',
  Feb: '2',
  Mar: '3',
  Apr: '4',
  May: '5',
  Jun: '6',
  Jul: '7',
  Aug: '8',
  Sep: '9',
  Oct: '10',
  Nov: '11',
  Dec: '12',
} as const;

// 文字を半角に変換
const toHalfWidth = (str: string): string => {
  return (
    str
      // 数字の変換
      .replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0))
      // 記号の変換
      .replace(/／/g, '/')
      .replace(/－/g, '-')
      .replace(/，/g, ',')
      // アルファベットの変換
      .replace(/[ａ-ｚＡ-Ｚ]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0))
  );
};

// 和暦の定義
const eraDefinitions = {
  meiji: { start: 1868, end: 1912, names: ['明治', 'M', 'ｍ', 'Ｍ'] },
  taisho: { start: 1912, end: 1926, names: ['大正', 'T', 'ｔ', 'Ｔ'] },
  showa: { start: 1926, end: 1989, names: ['昭和', 'S', 'ｓ', 'Ｓ'] },
  heisei: { start: 1989, end: 2021, names: ['平成', 'H', 'ｈ', 'Ｈ'] }, // endを2021に変更
  reiwa: { start: 2019, end: 9999, names: ['令和', 'R', 'ｒ', 'Ｒ'] },
} as const;

// 和暦から西暦に変換する関数
export const convertJapaneseEraToWestern = (yearStr: string): number | null => {
  // 数字を半角に変換
  yearStr = toHalfWidth(yearStr);

  // 「元年」を「1」に変換（「年」がない場合も考慮）
  yearStr = yearStr.replace(/元年?/, '1');

  for (const [, def] of Object.entries(eraDefinitions)) {
    for (const name of def.names) {
      const pattern = new RegExp(`^${name}[・]?(\\d+)(?:年)?$`);
      const match = yearStr.match(pattern);

      if (match) {
        const year = parseInt(match[1], 10);
        const westernYear = def.start + year - 1;

        // その年号の開始年以上、終了年以下の場合に有効とする
        if (westernYear >= def.start && westernYear <= def.end) {
          return westernYear;
        }
      }
    }
  }
  return null;
};

// パターン定義の型
type DatePattern = {
  pattern: RegExp;
  extract: (match: RegExpMatchArray) => { year: string; month: string; day: string } | null;
};

// 日付パターンの定義
const datePatterns: DatePattern[] = [
  // YYYY-MM-DD または YYYY/MM/DD
  {
    pattern: /^(\d{4})[-\/](\d{1,2})[-\/](\d{1,2})$/,
    extract: (match) => ({
      year: match[1],
      month: String(parseInt(match[2], 10)),
      day: String(parseInt(match[3], 10)),
    }),
  },
  // YYYY年MM月DD日
  {
    pattern: /^(\d{4})年[・]?(\d{1,2})月[・]?(\d{1,2})(日)?$/,
    extract: (match) => ({
      year: match[1],
      month: String(parseInt(match[2], 10)),
      day: String(parseInt(match[3], 10)),
    }),
  },
  // MM/DD, YYYY
  {
    pattern: /^(\d{1,2})\/(\d{1,2}),\s*(\d{4})$/,
    extract: (match) => ({
      year: match[3],
      month: String(parseInt(match[1], 10)),
      day: String(parseInt(match[2], 10)),
    }),
  },
  // MMM DD, YYYY
  {
    pattern: /^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s+(\d{1,2}),\s*(\d{4})$/,
    extract: (match) => ({
      year: match[3],
      month: monthNames[match[1] as keyof typeof monthNames],
      day: String(parseInt(match[2], 10)),
    }),
  },
  // 和暦（漢字）
  {
    pattern: /^([一-龥A-Za-z][一-龥]?[・]?(?:\d+|元)年)[・]?(\d{1,2})月[・]?(\d{1,2})(日)?$/,
    extract: (match) => {
      const westernYear = convertJapaneseEraToWestern(match[1]);
      if (!westernYear) return null;
      return {
        year: String(westernYear),
        month: String(parseInt(match[2], 10)),
        day: String(parseInt(match[3], 10)),
      };
    },
  },
  // 年号アルファベット（H1など）
  {
    pattern: /^([A-Za-z])(\d+)\/(\d{1,2})\/(\d{1,2})$/,
    extract: (match) => {
      const westernYear = convertJapaneseEraToWestern(match[1] + match[2]);
      if (!westernYear) return null;
      return {
        year: String(westernYear),
        month: String(parseInt(match[3], 10)),
        day: String(parseInt(match[4], 10)),
      };
    },
  },
];

// 日付文字列をパースする関数
export const parseDateString = (dateStr: string): { year: string; month: string; day: string } | null => {
  // 全角文字を半角に変換
  const normalizedStr = toHalfWidth(dateStr.trim());

  // 各パターンで順次試行
  for (const { pattern, extract } of datePatterns) {
    const match = normalizedStr.match(pattern);
    if (!match) continue;

    const result = extract(match);
    if (!result) continue;

    // 日付の妥当性チェック
    if (!isValidDate(result)) continue;

    return result;
  }

  return null;
};

type EraFormat = 'kanji' | 'short' | 'full';
type JapaneseEra = {
  era: string; // 元号（漢字または英字）
  year: number; // 和暦の年
  originalYear: number; // 入力された西暦
};

// 内部的なパース処理を行う関数
export const parseJapaneseEra = (westernYear: number | string, format: EraFormat = 'kanji'): JapaneseEra | null => {
  // 文字列が渡された場合は数値に変換
  const year = typeof westernYear === 'string' ? parseInt(westernYear, 10) : westernYear;

  // 無効な年の場合はnullを返す
  if (isNaN(year)) return null;

  // 各元号を新しい順（令和から明治まで）に確認
  const eras = Object.entries(eraDefinitions).reverse();

  for (const [, def] of eras) {
    if (year >= def.start && year <= def.end) {
      const eraYear = year - def.start + 1;

      // フォーマットに応じて元号を選択
      let eraName: string;
      switch (format) {
        case 'short':
          eraName = def.names[1]; // アルファベット1文字
          break;
        case 'full':
          // 漢字 + 数字
          eraName = `${def.names[0]}${eraYear === 1 ? '元' : eraYear}`;
          break;
        case 'kanji':
        default:
          eraName = def.names[0]; // 漢字
          break;
      }

      return {
        era: eraName,
        year: eraYear,
        originalYear: year,
      };
    }
  }

  // 明治以前の年の場合はnullを返す
  return null;
};

// 主要な変換関数
export const convertWesternToJapaneseEra = (
  westernYear: number | string,
  format: EraFormat = 'full',
): string | null => {
  const result = parseJapaneseEra(westernYear, format);
  if (!result) return null;

  if (format === 'full') {
    return result.era; // すでに完全な形式（例：令和元年）
  }

  // 元年の場合の特別処理
  const yearStr = result.year === 1 ? '元' : String(result.year);

  return format === 'short'
    ? `${result.era}${result.year}` // 例：R1
    : `${result.era}${yearStr}`; // 例：令和元年
};
