import { FC } from 'react';
import { PasswordSettingForm } from 'src/components/features/password_setting/PasswordSettingForm';
import { PasswordSettingPreview } from 'src/components/features/password_setting/PasswordSettingPreview';
import { PreviewAndEditable } from 'src/components/features/PreviewAndEditable';
import { VStack } from 'src/components/primitives/Stack';
import { SubSection } from 'src/components/primitives/SubSection';

export const PasswordSettingSection: FC = () => {
  return (
    <VStack asChild align="stretch" m="none" spacing="md">
      <SubSection>
        <PreviewAndEditable preview={<PasswordSettingPreview />} editable={<PasswordSettingForm />} />
      </SubSection>
    </VStack>
  );
};
