import { useCallback } from 'react';
import { createAction } from 'src/lib/action';
import { api } from 'src/lib/api';
import { UpdatePasswordWithCurrentPasswordBody } from 'src/models/v1/account';

export const onUpdatePassword = createAction('onUpdatePassword', () => {
  return useCallback(async (body: UpdatePasswordWithCurrentPasswordBody) => {
    await api.dispatch<UpdatePasswordWithCurrentPasswordBody, unknown>(
      'POST',
      `/v1/accounts/me/change_password`,
      {},
      body,
    );
    // for 1Password: remove filled color.
    document.querySelectorAll('[data-com-onepassword-filled]').forEach((element) => {
      element.removeAttribute('data-com-onepassword-filled');
    });
  }, []);
});
