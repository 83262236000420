import { FC, ReactNode, useState } from 'react';
import { EditableContext } from 'src/lib/form';

export const PreviewAndEditable: FC<{ preview: ReactNode; editable: ReactNode }> = ({ preview, editable }) => {
  const [isEditing, setEditing] = useState(false);
  return (
    <EditableContext.Provider
      value={{ isEditing, onStartEdit: () => setEditing(true), onEndEdit: () => setEditing(false) }}
    >
      {isEditing ? editable : preview}
    </EditableContext.Provider>
  );
};
