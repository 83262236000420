import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { createAction } from 'src/lib/action';
import { api } from 'src/lib/api';
import { Business, UpdateBusinessProfileBody, UpdateReceptionBody } from 'src/models/v1/business';

export const onUpdateBusinessProfile = createAction(
  'onUpdateBusinessProfile',
  ({ businessStatus }: { businessStatus: Business['status'] }) => {
    const navigate = useNavigate();
    return useCallback(
      async (body: UpdateBusinessProfileBody) => {
        await api.dispatch('PUT', '/v1/accounts/me/business/profile', {}, body);
        if (businessStatus == 'open') {
          navigate('/settings');
        } else {
          navigate('/settings/business_application');
        }
      },
      [navigate, businessStatus],
    );
  },
);

export const onUpdateReception = createAction(
  'onUpdateReception',
  ({ businessStatus }: { businessStatus: Business['status'] }) => {
    const navigate = useNavigate();
    return useCallback(
      async (body: UpdateReceptionBody) => {
        await api.dispatch('PUT', '/v1/accounts/me/business/profile', {}, body);
        if (businessStatus == 'open') {
          navigate('/settings');
        } else {
          navigate('/settings/business_application');
        }
      },
      [navigate, businessStatus],
    );
  },
);
