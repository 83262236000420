import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';
import { useLoaderData } from 'react-router';
import { BackableBreadcrumb } from 'src/components/features/BackableBreadcrumb';
import { QuotationBreakdown } from 'src/components/features/quotation/QuotationBreakdown';
import { QuotationNotice } from 'src/components/features/quotation/QuotationNotice';
import { QuotationOverview } from 'src/components/features/quotation/QuotationOverview';
import { QuotationPaymentButton } from 'src/components/features/quotation/QuotationPaymentButton';
import { QuotationSection } from 'src/components/features/quotation/QuotationSection';
import { AddressInput } from 'src/components/input/AddressInput';
import { NavigationLayout } from 'src/components/Layout';
import { PageLoader } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { TicketContext } from 'src/lib/ticket';
import { Address } from 'src/models/v1/address';
import { CreateQuotationPaymentBody, Quotation } from 'src/models/v1/quotation';

export const QuotationPaymentPage = () => {
  const { i18n } = useI18n();
  const { quotation, addresses } = useLoaderData<{ quotation: Quotation; addresses: Array<Address> }>();
  const methods = useForm<CreateQuotationPaymentBody>({
    defaultValues: { quotation_payment: { billing_address_gid: addresses.find((address) => address.primary)?.gid } },
  });
  const { register, setValue, watch } = methods;
  const billingAddress = addresses.find((address) => address.gid == watch('quotation_payment.billing_address_gid'));
  register('quotation_payment.billing_address_gid', { required: true });
  return (
    <NavigationLayout>
      <Helmet>
        <title>{`${i18n.t('title.quotation_payment')} - ${quotation.title} | ${i18n.t('nagaku')}`}</title>
      </Helmet>
      <BackableBreadcrumb
        currentPath={`/quotations/${quotation.gid}/payment`}
        homePath={`/messages/${quotation.ticket?.gid}`}
      >
        {i18n.t('title.quotation_payment')}
      </BackableBreadcrumb>
      <FormProvider {...methods}>
        <QuotationSection title={i18n.t('label.quotation.overview')}>
          <QuotationOverview quotation={quotation} />
        </QuotationSection>
        <QuotationSection title={i18n.t('label.quotation.billing_address')}>
          <AddressInput
            addresses={addresses}
            value={billingAddress}
            onChange={(address) =>
              setValue('quotation_payment.billing_address_gid', address.gid, { shouldValidate: true })
            }
          />
        </QuotationSection>
        <QuotationSection title={i18n.t('label.quotation.items')}>
          <QuotationBreakdown quotation={quotation} />
        </QuotationSection>
        {quotation.ticket && (
          <TicketContext value={quotation.ticket}>
            <QuotationPaymentButton quotation={quotation} />
            <QuotationNotice />
          </TicketContext>
        )}
      </FormProvider>
    </NavigationLayout>
  );
};

export const quotationPaymentLoader: PageLoader = async ({ params, api }) => {
  const { gid } = params;
  return await api.combine(
    api.fetch(`/v1/quotations/${gid}`, { include: 'author,payor,ticket' }),
    api.fetch(`/v1/accounts/me/addresses`),
  );
};
