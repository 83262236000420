import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { SimpleLayout } from 'src/components/Layout';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';

export const ContactUsPage: FC = () => {
  const { i18n } = useI18n();
  return (
    <SimpleLayout>
      <Helmet>
        <title>
          {i18n.t('title.contact')} | {i18n.t('nagaku')}
        </title>
        <meta property="og:title" content={`${i18n.t('title.contact')} | ${i18n.t('nagaku')}`} />
        <meta name="description" content="" />
      </Helmet>
      <Text asChild variant="display">
        <h1>{i18n.t('title.contact')}</h1>
      </Text>
      <Text asChild variant="body">
        <p>
          <a href="mailto:info@nagaku.jp">info@nagaku.jp</a>
        </p>
      </Text>
      <Text asChild variant="body">
        <p>{i18n.t('guide.contact')}</p>
      </Text>
    </SimpleLayout>
  );
};
