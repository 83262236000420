import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { SimpleLayout } from 'src/components/Layout';
import { List } from 'src/components/primitives/List';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';

export const TermsOfServicePage: FC = () => {
  const { i18n } = useI18n();
  return (
    <SimpleLayout isWide>
      <Helmet>
        <title>{`${i18n.t('title.terms_of_service')} | ${i18n.t('nagaku')}`}</title>
        <meta property="og:title" content={`${i18n.t('title.terms_of_service')} | ${i18n.t('nagaku')}`} />
        <meta name="description" content="" />
        <meta property="og:description" content="" />
      </Helmet>
      <Text asChild variant="display" weight="bold">
        <h1>{i18n.t('title.terms_of_service')}</h1>
      </Text>
      {i18n.t('guide.privacy_policy_translation') && (
        <Text asChild color="hint">
          <aside>{i18n.t('guide.terms_of_service_translation')}</aside>
        </Text>
      )}
      <Text asChild>
        <p>
          ナガク株式会社（以下「当社」といいます。）は、以下の利用規約（以下「本規約」といいます。）に基づき、本サービスを提供します。利用者は本規約に同意したうえで本サービスを利用するものとし、本サービスを利用することにより本規約に同意をしたものとみなされます。
        </p>
      </Text>
      <Text asChild>
        <p>
          本規約は、当社と利用者との間での本サービスの利用に関する一切の関係に適用されるものとします。
          なお、本サービスに関して当社が定める本サービスの利用に関するルールも本規約の一部を構成し、一体として適用されるものとします。なお、当該ルールと本規約の内容とが異なる場合、当該ルールが優先して適用されるものとします。
        </p>
      </Text>
      <Text asChild variant="headline" weight="bold">
        <h2>第1条 （規約の適用および変更）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            本規約は、利用契約の成立日における本サービスに関する利用者と当社との間のすべての合意事項を規定するものであり、本規約に別途定めがある場合を除き、本サービスに関する合意事項、申入れ等が本規約の内容と相違する場合は、本規約の内容が優先するものとします。ただし、当社と利用者との間で、本規約に優先するものとして別途書面による合意をした場合は、当該合意の内容が優先するものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            当社は、本規約の内容を随時変更することができ、当社が本規約の変更を利用者に通知した場合、当該通知の効力が発生した時点（但し、当社が変更の効力発生時を指定した場合は当該時点）で当該変更の効力が発生するものとします。なお、当該変更内容の通知の効力が発生した後に利用者が本サービスを利用した場合または当社所定の期間内に解約の手続をとらなかった場合には、利用者は当該変更に同意したものとみなします。
          </li>
        </Text>
        <Text asChild>
          <li>本規約における用語の定義は以下の通りとします。</li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第2条 （定義）</h2>
      </Text>
      <List>
        <Text asChild>
          <li>
            「<dfn>本サービス</dfn>
            」とは、当社が提供する「ナガク」という名称のサービス（理由の如何を問わずサービスの名称または内容が変更された場合は、当該変更後のサービスを含みます。）をいいます。
          </li>
        </Text>
        <Text asChild>
          <li>
            「<dfn>利用契約</dfn>
            」とは、本規約に基づき当社と利用者との間に締結される本サービスの利用に関する契約をいいます。
          </li>
        </Text>
        <Text asChild>
          <li>
            「<dfn>利用者</dfn>
            」とは、当社との間で利用契約を締結し、本サービスを利用する資格を持つ個人または法人をいいます。
          </li>
        </Text>
        <Text asChild>
          <li>
            「<dfn>提供サービス</dfn>」とは、本サービスにおいて取引の目的となるサービスをいいます。
          </li>
        </Text>
        <Text asChild>
          <li>
            「<dfn>コンテンツ</dfn>
            」とは、当社または利用者が本サービス上に投稿、送信、アップロードした文章、画像その他のコンテンツをいいます。
          </li>
        </Text>
        <Text asChild>
          <li>
            「<dfn>依頼者</dfn>」とは、本サービスを通じて提供サービスを購入する利用者をいいます。
          </li>
        </Text>
        <Text asChild>
          <li>
            「<dfn>プロアカウント</dfn>」とは、本サービスを通じてサービスを提供する利用者をいいます。
          </li>
        </Text>
        <Text asChild>
          <li>
            「<dfn>反社会的勢力等</dfn>
            」とは、暴力団、暴力団員、右翼団体、総会屋、社会運動等標榜ゴロ、特殊知能暴力集団その他これに準ずる者をいいます。
          </li>
        </Text>
        <Text asChild>
          <li>
            「<dfn>知的財産権等</dfn>
            」とは、特許権、実用新案権、意匠権、回路配置利用権、商標権、著作権（著作権法第27条および第28条に定める権利を含みます）およびノウハウ（知的財産権を受ける権利を含みます）をいいます。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第3条 （本サービスの内容）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            本サービスは、当社が、利用者に対して、利用者間で提供サービスを販売・購入する機会を提供するサービスです。提供サービスの提供契約は、依頼者とプロアカウントとの間で成立し、当社は利用者に対して提供サービスを販売・購入する機会を提供するもので、提供サービスの提供契約の当事者になるものではありません。当社は、本規約に定める場合を除き、提供サービスの提供契約に関与いたしません。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第4条 （会員登録）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            本サービスの利用を希望する者（以下「登録希望者」といいます。）は、当社所定の方法で、当社所定の情報（以下「登録情報」といいます。）を当社に提供することにより、本サービスの会員登録の申請を行うものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            当社は、前項に基づいて申請を行った登録希望者の会員登録の可否を判断し、登録を認める場合はその旨を登録希望者に通知します。当該通知の時点で、当社と登録希望者との間に利用契約が成立するものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            当社は、登録希望者が、以下の各号のいずれかの事由に該当すると判断した場合は、登録および再登録を拒否することがあり、またその理由について一切開示義務を負いません。
          </li>
        </Text>
        <List>
          <Text asChild>
            <li>過去に本規約違反等により、会員登録の取り消し等の処分を受けている場合</li>
          </Text>
          <Text asChild>
            <li>既に会員登録を完了している場合</li>
          </Text>
          <Text asChild>
            <li>当社のサービスの提供に支障をきたすおそれがあると当社が合理的な理由に基づき判断した場合</li>
          </Text>
          <Text asChild>
            <li>登録希望者が国内に居住していない場合</li>
          </Text>
          <Text asChild>
            <li>当社に提供した登録情報の全部または一部につき虚偽、誤記または記載漏れがあった場合</li>
          </Text>
          <Text asChild>
            <li>
              申請したアカウントIDが以下のいずれかである場合
              <List as="ul">
                <li>法令もしくは本規約に違反するものまたは犯罪行為に関連するもの</li>
                <li>公序良俗に反するもの</li>
                <li>当社または第三者の知的財産権その他の権利または利益を侵害するもの</li>
                <li>第三者へのなりすまし、偽装等の疑いがあるもの</li>
                <li>本サービスの提供に支障をきたすおそれがあるもの</li>
                <li>その他、当社が不適切と判断するもの</li>
              </List>
            </li>
          </Text>
          <Text asChild>
            <li>
              未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合
            </li>
          </Text>
          <Text asChild>
            <li>
              反社会的勢力等である、または資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合
            </li>
          </Text>
          <Text asChild>
            <li>過去当社との契約に違反した者またはその関係者であると当社が判断した場合</li>
          </Text>
          <Text asChild>
            <li>その他、登録を適当でないと当社が判断した場合</li>
          </Text>
        </List>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第5条 （登録情報の変更）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            利用者は、登録情報に変更があった場合、当社所定の方法により当該変更事項を遅滞なく当社に通知するものとします。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第6条 （ID等）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            利用者は、本サービスに関連して当社から発行されるID、パスワード等（以下「ID等」という）を自己の責任において管理するものとし、ID等を第三者に使用させたり、譲渡、貸与または担保提供したりすることはできないものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            利用者に付与されたID等が用いられた場合、当社は、その利用が利用者によるものでなくとも、これを利用者が利用したものとみなすことができます。また、ID等の使用上の誤りまたは第三者による不正使用等による損害の責任は、利用者が負うものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            利用者は、ID等が漏えいし、または第三者に使用されている可能性を認識した場合には、直ちに、その旨を当社に対して、当社所定の方法で通知するとともに、当社からの指示に従うものとします。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第7条 （会員登録の取消等）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            当社は、利用者が以下の各号のいずれかの事由に該当しまたは該当したと当社が合理的な理由に基づき判断した場合、事前の通知なく、会員登録の取消（利用契約の解除をいいます。以下同様です。）または利用停止措置（本サービスに係るサーバ、システム等へのアクセス拒否、利用者に関するコンテンツや情報の削除その他利用者による本サービスの利用を全部若しくは一部不可能にする当社による一切の措置をいいます。以下同様です。）を行うことができるものとします。この場合、当社は、その理由を説明する義務を負わず、本条の措置により生じる損害について、当社の故意または過失に起因する場合を除き、責任を負わないものとします。
          </li>
        </Text>
        <List>
          <Text asChild>
            <li>
              当社が別途定める「
              <a
                href="https://help.nagaku.com/hc/ja/articles/34848110634393-%E3%83%8A%E3%82%AC%E3%82%AF%E3%81%A7%E5%8F%96%E6%89%B1%E3%81%8C%E7%A6%81%E6%AD%A2%E3%81%95%E3%82%8C%E3%81%A6%E3%81%84%E3%82%8B%E5%93%81%E7%9B%AE-%E8%A1%8C%E7%82%BA%E3%81%AF%E3%81%82%E3%82%8A%E3%81%BE%E3%81%99%E3%81%8B"
                rel="noopener"
                target="_blank"
              >
                取扱禁止品目・行為
              </a>
              」に記載されている品目・行為を取り扱うサービスに関する掲載、提供、依頼、提案の募集、販売又は購入その他の本サービスにおける利用
            </li>
          </Text>
          <Text asChild>
            <li>法令若しくは本規約に違反する場合または犯罪行為に関連する行為があった場合</li>
          </Text>
          <Text asChild>
            <li>公序良俗に反する場合</li>
          </Text>
          <Text asChild>
            <li>当社または第三者の知的財産権、肖像権その他の権利または利益を侵害する場合</li>
          </Text>
          <Text asChild>
            <li>登録情報が虚偽の情報である場合または既存の他の登録情報と重複している場合</li>
          </Text>
          <Text asChild>
            <li>登録情報の連絡先が不通になったことが判明した場合</li>
          </Text>
          <Text asChild>
            <li>当社が定める一定期間内に一定回数以上のログインがなかった場合</li>
          </Text>
          <Text asChild>
            <li>
              SNS等の外部サービスその他本サービスで定めた連絡方法以外の方法での連絡を促した場合またはこれに応じた場合
            </li>
          </Text>
          <Text asChild>
            <li>本サービスで定めた決済方法以外の方法での決済を促した場合またはこれに応じた場合</li>
          </Text>
          <Text asChild>
            <li>
              本サービスを介在せずに、直接、提供サービスの提供をした場合若しくは金銭の授受を行った場合またはこれらのおそれがあると認められた場合
            </li>
          </Text>
          <Text asChild>
            <li>本規約上必要となる手続または当社への連絡を行わなかった場合</li>
          </Text>
          <Text asChild>
            <li>利用者が債務超過、無資力、支払停止または支払不能の状態に陥った場合</li>
          </Text>
          <Text asChild>
            <li>
              破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはその他適用ある倒産手続開始の申立が行われた場合または解散若しくは営業停止状態である場合
            </li>
          </Text>
          <Text asChild>
            <li>第4条第3項各号のいずれかの事由がある場合</li>
          </Text>
          <Text asChild>
            <li>
              利用者が自らまたは第三者を利用して、暴力的な要求行為、法的な責任を超えた不当な要求行為、脅迫的な言動または暴力を用いる行為、風評を流布し、偽計を用いまたは威力を用いて、信用を毀損または業務を妨害する行為をした場合
            </li>
          </Text>
          <Text asChild>
            <li>その他当社が利用者に相応しくないと判断した場合</li>
          </Text>
        </List>
        <Text asChild>
          <li>
            当社は、会員登録を取り消された利用者に対し、将来にわたって当社が提供する本サービスの利用およびアクセスを禁止することができることとします。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第8条 （利用者の退会）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            利用者は、当社所定の手続により退会することができます。ただし、退会の手続を行った時点で、取引の決済や提供サービスの郵送等取引の手続が未完のものがある場合は退会することができません。
          </li>
        </Text>
        <Text asChild>
          <li>
            利用者は、退会手続を行う前に、本サービスによって受領できる提供サービス、金銭等の受領のために必要な手続を行うものとします。当社は、当社が利用者の退会を認めた時点において、当該提供サービス、金銭等については放棄したものとして取り扱うものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            当社は、利用者による退会手続終了後も、本サービスの円滑な運用に必要な合理的な期間において、利用者の退会手続を留保できるものとします。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第9条 （サービスの提供）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            プロアカウントは、当社所定の手続により、提供サービスの掲載を行うものとし、詳細は当社が別途定めるものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            プロアカウントの提供サービスの掲載によって、利用者および第三者に生じる損害につき、当社の故意または過失に起因する場合を除き、当社は責任を負わないものとします。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第10条 （提供サービスの購入）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            依頼者は、当社所定の手続により、プロアカウントに対して、提供サービスの依頼または提案の募集（特定の提供サービスについてプロアカウントからの提案を募集することをいいます。以下同様です。）を行うものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            依頼者は、いたずら目的等の真に購入する意思がない場合または自己が掲載した提供サービスを購入しようとする場合その他当社が禁止する目的もしくは方法での購入をしようとする場合は、依頼または提案の募集をすることはできません。依頼者は、当該依頼または提案の募集によって、他の利用者および第三者に生じる損害につき、責任を負うものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            プロアカウントは、依頼者による依頼または提案の募集があった後、見積もりを提案することができます。依頼者は、当該見積もりを承諾し、提供サービスを購入することができます。
          </li>
        </Text>
        <Text asChild>
          <li>
            依頼者の提供サービスの購入または依頼もしくは提案の募集等によって、利用者および第三者に生じる損害につき、当社の故意または過失に起因する場合を除き、当社は責任を負わないものとします。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第11条 （取引の実行等）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            提供サービスの提供契約は、依頼者が、当社所定の手続きにより、特定のプロアカウントの見積もりを承諾した時点で、当該プロアカウントと依頼者との間に成立するものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            依頼者は、当社所定の期間内に、当社所定の方法により、提供サービス代金、手数料の代金を支払うものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            依頼者は、プロアカウントから提供サービスを受領した場合、直ちに提供サービスの内容および提供サービスの説明との重大な差異の有無等を確認し、当社所定の方法により、速やかに提供サービスを受領したことをプロアカウントに対し通知するものとします。
          </li>
        </Text>
        <Text asChild>
          <li>依頼者が、当社所定の期間内に、前項の通知を行わない場合、取引は完了したものとみなされます。</li>
        </Text>
        <Text asChild>
          <li>
            プロアカウントが、提供サービスを実施後、依頼者から預かった物品を発送したにもかかわらず、依頼者がこれを受領しない場合で、かつ、最初の荷戻りが発生した日から、３か月が経過しても依頼者に連絡がつかない場合、プロアカウントは、当該物品を自己の裁量で処分することができます。ただし、処分にあたっては、事前に、本サービスを通じて依頼者に通知するものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            提供サービスに起因するトラブルは、当事者が自らの費用と責任において解決するものとします。ただし、当社の判断により、当社も協議に入ることができるものとします。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第12条 （キャンセル）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            プロアカウントと依頼者との間の合意がある場合を除き、提供契約成立後のキャンセルはできないものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            提供サービスに瑕疵がある場合、提供サービスの説明と実際の提供サービスが明らかに異なるなどの場合はプロアカウントが責任を負うものとし、プロアカウントの責任および費用により、返金、提供サービスの返品、修理、交換等の対応を行うものとします。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第13条 （支払い等）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            利用者が、本サービスの利用に関して支払いまたは決済が必要になる場合、その詳細は本規約および当社が別途定めるところに従うものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            本サービスの利用に関する支払いに決済手数料その他の費用が必要になる場合は、当該手数料その他の費用は、当該利用者が負担するものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            利用者が本規約に従って必要な支払いを行わない場合若しくは遅延した場合または本サービスに入力したクレジットカード若しくは金融機関の口座の利用が停止されたことが認められた場合には、当社は、当該利用者に通知することなく、会員登録の取消または利用停止措置を行うことができるものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            利用者が本サービスに入力した決済手段または金融機関の情報が不正確であったこと、および当該情報の管理不十分による情報の漏洩、使用上の過誤、第三者の使用、不正アクセス等によって利用者に生じた損害について、当該利用者が責任を負うものとします。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第14条 （提供契約の取り消し等）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            提供契約が成立した場合においても、当社所定の期間内に、依頼者が提供サービス代金および手数料の合計額を支払わない場合その他当社が本サービスの適切な運営のために必要と認める場合には、当社は、当該提供契約を取り消すことができるものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            提供契約が解除、取消し、無効等の理由により効力を失った場合（決済事業者等が定めるチャージバック事由その他の当社に対する支払いの拒絶又は返還事由に該当する場合を含みます。）、プロアカウントは、速やかに当該提供契約に関連して当社がプロアカウントに対して支払った金額（提供サービス代金を含みます。）を返金しなければなりません。この場合、当社は、当社の裁量で当該返金の額を、プロアカウントに対して支払われるべき金額から控除することができるものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            当社は、提供契約が解除、取消し、無効等の理由により効力を失うことが予想される場合又は決済事業者等が定めるチャージバック事由その他の当社に対する支払いの拒絶若しくは返還事由の発生が予想される場合、当社は当社の裁量によりプロアカウントに対する提供サービス代金の支払いを留保することができ、プロアカウントは予めこれに同意するものとし、当社からプロアカウントに対して提供サービス代金の支払いを行っていた場合は、プロアカウントは当該提供サービス代金を当社に返金しなければならないものとします。当社は、本項に定める措置によりプロアカウントに生じた損害に関し、一切責任を負いません。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第15条 （手数料）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            プロアカウントは、当社に対し、提供サービスの提供契約が成立した場合、当社所定の手数料を支払うものとします。本サービスの手数料は、当社およびプロアカウントとの間の契約に従って、当社がプロアカウントに代わって受領する提供サービスの代金から差し引く方法により徴収されるものとし、プロアカウントは、かかる方法により徴収することにつき予め同意するものとします。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第16条 （他のサービスへの遷移）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            利用者が本サービスを利用するにあたり、本サービスから、第三者が運営する他のサービス（以下「外部サービス」といいます。）に遷移する場合があります。利用者は、予めこれに同意するものとし、外部サービスの定める利用規約等の利用条件を自己の費用と責任で遵守するものとします。なお当社は、外部サービスの内容、品質等について保証するものではありません。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第17条 （本サービスの停止、終了等）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            当社は、当社の都合により、事前の通知なく、本サービスの内容や仕様を変更することができるものとします。この場合、当社は、当社所定の方法により、利用者に対し、当該変更の内容を事前または事後速やかに通知するものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            当社は、次の各号のいずれかの事由が生じた場合には、本サービスの全部または一部を停止することができます。
          </li>
        </Text>
        <List>
          <Text asChild>
            <li>
              本サービスを含む本サービス提供にあたり必要なシステム、設備等に障害が発生し、またはメンテナンス、保守若しくは工事等が必要となった場合
            </li>
          </Text>
          <Text asChild>
            <li>
              電気通信事業者が電気通信サービスの提供を中止するなど、当社以外の第三者の行為に起因して、本サービスの提供を行うことが困難になった場合
            </li>
          </Text>
          <Text asChild>
            <li>
              非常事態（天災、戦争、テロ、疫病、暴動、騒乱、官の処分、労働争議等）の発生により、本サービスの提供が困難になった場合、または困難になる可能性のある場合
            </li>
          </Text>
          <Text asChild>
            <li>本サービスと連携する外部サービスの事情等により、当該外部サービスが利用できなくなった場合</li>
          </Text>
          <Text asChild>
            <li>法令規制、行政命令等により、本サービスの提供が困難になった場合</li>
          </Text>
          <Text asChild>
            <li>その他、当社が本サービスの停止が必要と判断した場合</li>
          </Text>
        </List>
        <Text asChild>
          <li>
            当社は、当社の都合により、本サービスの提供を終了することができるものとする。この場合、当社は可能な限り事前に利用者に通知する。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第18条 （権利の帰属等）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            本サービスに係る知的財産権を含むすべての権利は、すべて当社または当社に許諾を与えた第三者に帰属しており、利用者は、方法または形態の如何を問わず、これを当社に無断で複製、複写、転載、転送、蓄積、販売、出版その他本契約の範囲を超えて権利を侵害する一切の行為をしてはならないものとします。本規約に基づく本サービスの利用許諾は、本サービスに関する当社または当社にライセンスを許諾している者の当該権利の使用許諾を意味するものではありません。
          </li>
        </Text>
        <Text asChild>
          <li>
            前項の定めにかかわらず、本サービス上で利用者が投稿したコンテンツの知的財産権は、利用者または利用者が指定する第三者に帰属するものとします。利用者は、本サービス上で投稿したコンテンツに関する一切の責任を負うものとし、当社は当該コンテンツの正確性、適法性等を確認する義務を負わないものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            利用者により投稿されたコンテンツについては、利用者は、当社に対し、当社のサービスの改善、プロモーション、お客様対応、または研究開発の目的で、地域または期間の制限なく、無償で自由に利用することを許諾するものとします。利用者は、当該コンテンツにつき、自身が権利を有していない場合は、権利者に対して、当該利用を承諾させるものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            利用者は、他の利用者が投稿したコンテンツの正確性、適法性等は自身で判断するものとし、これにより生じた損害について、当社は、当社の故意または過失に起因する場合を除き、責任を負わないものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            本サービスのメッセージ機能においては、当社は、利用者による本規約に違反する行為・不正の有無を確認するためその他本サービスの提供上必要な限り、かつ、法令に違反しない限りにおいて、メッセージ、通話その他これらの機能上でやり取りされる一切のコンテンツの内容を閲覧又は確認することができるものとします。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第19条 （利用環境の整備）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            利用者は、本サービスを利用するために必要なあらゆる機器、ソフトウェア、通信手段その他本サービスを利用するために必要となるあらゆる環境（以下「利用環境」といいます。）を自己の責任と費用において、適切に整備するものとします。なお、当社は、当社が指定した利用環境であったとしても、当該利用環境における本サービスの正常な動作または提供を保証するものではなく、本サービスの全部または一部を利用できない場合であっても、当社はサポート等を含め一切責任を負わないものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            利用者は、自己の利用環境に応じて、自己の責任と費用において、コンピュータウィルスの感染の防止、不正アクセスおよび情報漏洩の防止等のセキュリティ対策を講じるものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            当社は、利用者が本サービスを利用することで、利用者の機器の動作に支障が生じた場合、当該機器に記録されている情報やプログラムなどが消失または破損した場合、コンピュータウィルスに感染した場合等、その他利用者に生じた損害について、当社の故意または過失に起因する場合を除き、責任を負わないものとします。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第20条 （紛争）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            利用者は、本サービスに関連して利用者間または利用者と第三者間で発生したトラブルに関して、自己の費用と責任で解決するものとします。当該トラブルにより当社が損害を被った場合は、当事者は連帯して当該損害を賠償するものとします。ただし、当社の判断により、当社も協議に入ることができるものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            利用者と第三者との間で、本サービスに関連して、裁判、クレーム、請求等の紛争が生じた場合、利用者各自の責任や費用で解決するものとし、当社は、当該紛争に一切関与しません。当該紛争が利用者の故意または過失に起因して生じた場合には、利用者は、当該紛争により当社に生じた損害を連帯して賠償するものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            当社と利用者間で紛争が生じた場合において、当該紛争が利用者の故意または過失に起因して生じた場合には、利用者は当該紛争に関連して当社に発生した損害を賠償するものとします。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第21条 （損害賠償）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            利用者は、本規約に違反した場合、当該違反により損害を受けた利用者および第三者に対する損害賠償責任を含む、一切の責任を負うものとします。利用者がかかる違反行為を行ったことにより、当社が損害を被った場合は、当社に対して当該損害を賠償するものとします。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第22条 （保証および免責）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            当社は、本サービスに関して、正確性、最新性、有用性、適合性、完全性、安全性、合法性を有すること、継続的に利用できること、および不具合が生じないことについて保証するものではありません。
          </li>
        </Text>
        <Text asChild>
          <li>
            当社は、本サービス上において、当社が提供する一切の情報に関して、情報の正確性や有用性を保証するものではありません。
          </li>
        </Text>
        <Text asChild>
          <li>
            当社は、本サービスについて、利用者および第三者に対して損害が生じた場合、当社の故意または過失に起因する場合を除き、当該損害について責任を負わないものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            本規約のその他の規定にかかわらず、当社が利用者に対して損害賠償責任を負うこととなった場合、当社に故意または重過失がある場合を除き、損害賠償の範囲は現実に発生した直接かつ通常の損害に限るものとします。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第23条 （個人情報の取り扱い）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            当社が本サービスを通じて取得した個人情報の取扱いについては、当社が別途定める「プライバシーポリシー」に従うものとします。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第24条 （通知）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            当社が利用者に対して行う本サービスについての一切の通知は、原則として、当社所定のウェブサイト上にて行い、これらの通知の効力は、当社が当該通知を掲載または送信した時点をもって発生するものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            当社が前項に定める通知以外の通知方法を用いる場合、その通知の効力は、当該通知が利用者に到達するために合理的に必要な期間が経過した時点で発生するものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            前二項に定める通知の効力は、利用者が現実に通知を受領または認識したかどうかを問わず、発生するものとします。
          </li>
        </Text>
        <Text asChild>
          <li>
            利用者は、当社に通知、連絡または問い合わせ等をする必要が生じた場合、当社所定のフォームを利用するものとし、電話や来訪を行うことはできないものとします。当社は、かかる通知、連絡または問い合わせ等があった場合、当社所定の方法により、利用者の本人確認を行うことができるものとします。また、当社は、適切と考える回答方法を利用することができるものとし、その回答方法を利用者が決めることはできないものとします。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第25条 （反社会的勢力の排除）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            当社は、反社会的勢力等、反社会的勢力等と密接な関わり（資金その他の便益提供行為を含みます）を有する者またはこれらに準じる者による本サービスの利用を禁止します。当社は、利用者がこれらの者に該当するまたは該当するおそれがあると判断した場合、事前に利用者に通知することなく、会員登録の取消または利用停止措置を行うことができるものとします。当社は、この措置によって利用者に生じた損害や不利益について、責任を負いません。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第26条 （譲渡禁止）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            利用者は、当社の書面による事前の承諾なく、本規約に基づく契約上の地位または本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、貸与、その他の処分をすることはできません。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第27条 （事業譲渡）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            当社が本サービスに係る事業を第三者に譲渡する場合（事業譲渡、会社分割その他本サービスの主体が移転する一切の場合を含みます。）には、当社は、当該事業の譲渡に伴い、利用者の本規約に基づく契約上の地位、本規約に基づく権利義務および登録情報その他の情報を当該事業の譲受人に譲渡することができるものとし、利用者は、かかる譲渡につき予め承諾するものとします。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第28条 （分離可能性）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定および一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
          </li>
        </Text>
      </List>
      <Text asChild variant="headline" weight="bold">
        <h2>第29条 （準拠法および裁判管轄）</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>本規約は、日本法に基づき解釈されるものとします。</li>
        </Text>
        <Text asChild>
          <li>
            当社と利用者との間で生じた紛争については、その内容に応じて東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
          </li>
        </Text>
      </List>
      <Text asChild>
        <p>以上</p>
      </Text>
      <Text asChild>
        <p>
          制定：2024年2月1日
          <br />
          改定：2024年11月27日
          <br />
          改定：2025年2月5日
        </p>
      </Text>
    </SimpleLayout>
  );
};
