import { factoryAccount } from 'src/catalog/factories/account';
import { Meta, Story } from 'src/catalog/Story';
import { AccountLinkCard } from 'src/components/features/account/AccountLinkCard';
import { Icon } from 'src/components/primitives/Icon';

const meta: Meta<typeof AccountLinkCard> = {
  title: 'Account Link Card',
  args: {
    account: factoryAccount(),
    title: 'Any Text',
  },
  template: (args) => <AccountLinkCard {...args} />,
};

export default meta;

export const iconic: Story<typeof AccountLinkCard> = {
  name: 'with Icon',
  args: {
    account: factoryAccount('iconic'),
    title: 'This is iconic account',
  },
};

export const maxLengthDisplayName: Story<typeof AccountLinkCard> = {
  name: 'with Max Length Display Name',
  args: {
    account: factoryAccount('maxLengthDisplayName'),
    title: 'This account have max length display_name',
  },
};

export const withAnnotation: Story<typeof AccountLinkCard> = {
  name: 'with Annotation',
  args: {
    account: factoryAccount(),
    title: 'Any Text',
    annotation: (
      <>
        <Icon name="access_time" />
        This is annotation
      </>
    ),
  },
};
