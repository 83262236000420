import { useCallback } from 'react';
import { useNavigate, useRevalidator, useSearchParams } from 'react-router';
import { api } from 'src/lib/api';
import { resolveReturnPath } from 'src/lib/auth';
import { createAction } from 'src/lib/action';
import { AuthResponse, EmailAuthenticationBody } from 'src/models/v1/auth';

export const onLogin = createAction('onLogin', () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();

  return useCallback(
    async (param: EmailAuthenticationBody) => {
      await api.dispatch<EmailAuthenticationBody, AuthResponse>('POST', '/v1/auth/email', {}, param);
      api.update({ auth: 'authenticated' });
      const returnTo = resolveReturnPath(searchParams);
      navigate(returnTo, { replace: true });
      await revalidate();
    },
    [navigate, searchParams, revalidate],
  );
});
