import './SettingItem.css';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { Link } from 'react-router';
import { Icon } from 'src/components/primitives/Icon';
import { Text } from 'src/components/primitives/Text';

export const SettingItem: FC<PropsWithChildren<{ to: string; disabled?: boolean; status?: ReactNode }>> = ({
  to,
  status,
  disabled = false,
  children,
}) => {
  if (disabled) {
    return (
      <div className="setting-item disabled">
        <div className="setting-item-body">
          <Text weight="bold">{children}</Text>
          {status}
        </div>
        <Icon name="keyboard_arrow_right" className="arrow" />
      </div>
    );
  }
  return (
    <Link to={to} className="setting-item">
      <div className="setting-item-body">
        <Text weight="bold">{children}</Text>
        {status}
      </div>
      <Icon name="keyboard_arrow_right" className="arrow" />
    </Link>
  );
};
