import './TextField.css';

import { FC, PropsWithChildren, ReactNode, useContext } from 'react';
import { FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { ErrorMessage } from 'src/components/control/ErrorMessage';
import { FormOptionsContext } from 'src/components/control/Form';
import { FormControl } from 'src/components/control/FormControl';
import { Field } from 'src/components/primitives/Field';
import { AriaAttributesContext } from 'src/lib/a11y';
import { useI18n } from 'src/lib/i18n';

export const TextField: FC<
  PropsWithChildren<{
    name: string;
    id?: string;
    label?: ReactNode;
    placeholder?: string;
    rows?: number | undefined;
    scale?: 'basic' | 'thin';
    options?: RegisterOptions<FieldValues, string>;
  }>
> = ({ name, id = name, label, placeholder, rows, scale = 'basic', options, children }) => {
  const { i18n } = useI18n();
  const { register } = useFormContext<FieldValues>();
  const { readOnly } = useContext(FormOptionsContext);
  const a11yAttributes = useContext(AriaAttributesContext);

  return (
    <FormControl
      id={id}
      name={name}
      required={options?.required ? true : false}
      label={label || i18n.t(`attributes.${name}`)}
    >
      {children}
      <Field asChild className={`text-field text-field-${scale}`}>
        <textarea
          id={name}
          placeholder={placeholder || (i18n.has(`placeholder.${name}`) ? i18n.t(`placeholder.${name}`) : '')}
          rows={rows}
          readOnly={readOnly}
          {...register(name, options)}
          {...a11yAttributes}
        ></textarea>
      </Field>
      <ErrorMessage name={name} />
    </FormControl>
  );
};
