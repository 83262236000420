import { FC, PropsWithChildren } from 'react';
import { FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';

export const HiddenField: FC<
  PropsWithChildren<{
    name: string;
    id?: string;
    options?: RegisterOptions<FieldValues, string>;
  }>
> = ({ name, id = name, options }) => {
  const { register } = useFormContext<FieldValues>();
  return <input className="input-field" id={id} type="hidden" {...register(name, options)} />;
};
