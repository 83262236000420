import { FC } from 'react';
import { onKickRecoveryPassword } from 'src/actions/account';
import { Form } from 'src/components/control/Form';
import { FormButton } from 'src/components/control/FormButton';
import { InputField } from 'src/components/control/InputField';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';

export const KickPasswordRecoveryForm: FC<{ onComplete: () => void }> = ({ onComplete }) => {
  const { i18n } = useI18n();
  return (
    <Form onSubmit={onKickRecoveryPassword({ onComplete })}>
      <Text>{i18n.t('guide.recover_password')}</Text>
      <InputField
        type="email"
        name="password_recovery.email"
        label={i18n.t('label.email')}
        placeholder="you@nagaku.com"
      />
      <FormButton>{i18n.t('action.send')}</FormButton>
    </Form>
  );
};
