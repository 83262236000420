import { createContext, useContext } from 'react';

type EditableState = {
  isEditing: boolean;
  onStartEdit: () => void;
  onEndEdit: () => void;
};

export const EditableContext = createContext<EditableState>({
  isEditing: false,
  onStartEdit: () => {
    throw 'not implemented EditableContext.onStartEdit';
  },
  onEndEdit: () => {
    throw 'not implemented EditableContext.onEndEdit';
  },
});

export const useEditable = () => {
  const { onStartEdit, onEndEdit } = useContext(EditableContext);
  return { onStartEdit, onEndEdit };
};
