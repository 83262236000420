import { FC, useContext } from 'react';
import { BusinessProfileView } from 'src/components/features/business_profile/BusinessProfileView';

import { AccountContext } from 'src/lib/account';

export const AboutOrderPage: FC = () => {
  const account = useContext(AccountContext);
  if (!account?.business_profile) return <></>;
  return <BusinessProfileView businessProfile={account.business_profile} accountGid={account.gid} />;
};
