import { FC, PropsWithChildren } from 'react';

import { HStack } from '../primitives/Stack';

export const Toolbox: FC<PropsWithChildren> = ({ children }) => {
  return (
    <HStack className="toolbox" justify="stretch" m="none" spacing="lg" wrap="wrap">
      {children}
    </HStack>
  );
};
