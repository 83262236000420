import './EstimateForm.css';

import { FC, PropsWithChildren, useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { onUpdateEstimate } from 'src/actions/estimate';
import { AmountField } from 'src/components/control/AmountField';
import { DaysField } from 'src/components/control/DaysField';
import { Form } from 'src/components/control/Form';
import { FormButton } from 'src/components/control/FormButton';
import { InputField } from 'src/components/control/InputField';
import { SaveDraft } from 'src/components/control/SaveDraft';
import { TextField } from 'src/components/control/TextField';
import { EstimateCalcItem } from 'src/components/features/estimate/EstimateCalcItem';
import { EstimateItemFieldset } from 'src/components/features/estimate/EstimateItemFieldset';
import { Button } from 'src/components/styles/Button';
import { Icon } from 'src/components/primitives/Icon';
import { VStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { Toolbox } from 'src/components/singleton/Toolbox';
import { calcEstimateItems, getEstimateExpiryDateRange } from 'src/lib/estimate';
import { useI18n } from 'src/lib/i18n';
import { Account } from 'src/models/v1/account';
import { Estimate, EstimateItem, UpdateEstimateBody } from 'src/models/v1/estimate';

export const EstimateForm: FC<
  PropsWithChildren<{
    estimate: Estimate;
    account: Pick<Account, 'display_name'>;
    onComplete?: (estimate: Estimate) => void | Promise<void>;
  }>
> = ({ estimate, account, children, onComplete }) => {
  const { i18n } = useI18n();
  const { gid } = estimate;
  const onSubmit = onUpdateEstimate({ estimate, onComplete });
  return (
    <Form
      className="estimate-form"
      onSubmit={onSubmit}
      defaultValues={{
        estimate: {
          title: estimate.title,
          expires_date: estimate.expires_date,
          items: estimate.items,
          estimate_delivery_days: estimate.estimate_delivery_days,
          delivery_days_unit: estimate.delivery_days_unit,
          note: estimate.note,
          shipping_fee: estimate.breakdown.shipping_fee?.toString() || undefined,
        },
      }}
      defaultRequired
      mode="all"
    >
      <SaveDraft path={`/v1/estimates/${gid}`} />
      <InputField
        name="estimate.title"
        placeholder={i18n.t('placeholder.estimate.title', account)}
        options={{ required: true }}
      />
      <InputField
        type="date"
        name="estimate.expires_date"
        options={{
          required: true,
          valueAsDate: true,
        }}
        {...getEstimateExpiryDateRange()}
      />
      <AmountField name={'estimate.shipping_fee'} />
      <EstimateItems />
      <DaysField
        name="estimate.estimate_delivery_days"
        unitName="estimate.delivery_days_unit"
        options={{
          required: true,
        }}
      />
      <TextField name="estimate.note" />
      <Toolbox>
        <FormButton>{i18n.t('action.complete_edit')}</FormButton>
        {children}
      </Toolbox>
      <DiscardButton />
    </Form>
  );
};

const DiscardButton: FC = () => {
  const { i18n } = useI18n();
  const { reset } = useFormContext();

  const onDelete = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      reset({
        estimate: {
          title: '',
          expires_date: '',
          items: [],
          estimate_delivery_days: null,
          delivery_days_unit: 'week',
          note: '',
          shipping_fee: null,
        },
      });
    },
    [reset],
  );

  return (
    <button className={Button.class} {...Button.build({ variant: 'ghost' })} onClick={onDelete}>
      <Icon name="delete" width="20px" height="20px" />
      {i18n.t('action.quotation_delete')}
    </button>
  );
};

const DEFAULT_ESTIMATE_ITEM: EstimateItem = { label: '', amount: 0, quantity: 1 };

const EstimateItems: FC = () => {
  const { i18n } = useI18n();
  const { control, setValue } = useFormContext<UpdateEstimateBody>();
  const { fields, append, remove } = useFieldArray({ control, name: 'estimate.items' });
  const { watch } = useFormContext();
  const shipping_fee = Number(watch('estimate.shipping_fee')) || 0;
  const { subtotal, consumptionTax, platformFee, applicationFeeTargetAmount } = calcEstimateItems(
    watch('estimate.items') || [],
  );
  const onDelete = useCallback(
    (index: number) => {
      if (fields.length == 1) {
        // Reset last item, [!] replace and update produced by useFieldArray are not working.
        (Object.keys(DEFAULT_ESTIMATE_ITEM) as Array<keyof EstimateItem>).forEach((key) => {
          setValue(`estimate.items.${index}.${key}`, DEFAULT_ESTIMATE_ITEM[key]);
        });
      } else {
        remove(index);
      }
    },
    [remove, setValue, fields.length],
  );
  return (
    <VStack spacing="lg" align="start" w="full">
      <VStack spacing="lg" align="start" w="full" className="estimate-items">
        {fields.map((_, index) => (
          <EstimateItemFieldset key={index} namePrefix={`estimate.items.${index}`} onDelete={() => onDelete(index)} />
        ))}
        <button
          className={Button.class}
          {...Button.build({ variant: 'black', size: 'sm' })}
          onClick={() => append({ ...DEFAULT_ESTIMATE_ITEM })}
        >
          {i18n.t('attributes.estimate.add_item')}
        </button>
        <VStack align="start" spacing="sticky" w="full">
          <EstimateCalcItem label={i18n.t('attributes.estimate.subtotal_amount')} amount={subtotal} />
          <div className="divider" />
          <EstimateCalcItem label={i18n.t('attributes.estimate.consumption_tax_amount')} amount={consumptionTax} />
          <EstimateCalcItem label={i18n.t('attributes.estimate.shipping_fee')} amount={shipping_fee} />
          <EstimateCalcItem
            label={i18n.t('attributes.estimate.total_amount')}
            amount={subtotal + consumptionTax + shipping_fee}
            bold={true}
          />
        </VStack>
      </VStack>
      <VStack align="start" spacing="sticky" w="full" className="estimate-items">
        <EstimateCalcItem
          label={i18n.t('attributes.estimate.application_fee_base')}
          amount={applicationFeeTargetAmount}
        />
        <EstimateCalcItem label={i18n.t('attributes.estimate.application_fee')} amount={platformFee} />
        <EstimateCalcItem
          label={i18n.t('attributes.estimate.revenue')}
          amount={subtotal + consumptionTax - platformFee}
          bold={true}
        />
        <Text className="hint-label" color="sub" align="center" w="full">
          {i18n.t('attributes.estimate.estimate_not_included')}
        </Text>
      </VStack>
    </VStack>
  );
};
