import { ComponentProps, FC, PropsWithChildren } from 'react';
import { Text } from 'src/components/primitives/Text';
import Tabs from 'src/components/styled-radix/Tabs';
import { useI18n } from 'src/lib/i18n';

export type AccountNavigationValue = 'works' | 'about';

export const AccountNavigation: FC<
  Omit<ComponentProps<typeof Tabs.Root>, 'defaultValue' | 'onValueChange'> & {
    defaultValue?: AccountNavigationValue;
    onValueChange?: (value: AccountNavigationValue) => void;
  }
> = ({ defaultValue = 'works', onValueChange, ...props }) => {
  const { i18n } = useI18n();
  return (
    <Tabs.Root
      defaultValue={defaultValue}
      onValueChange={(value) => onValueChange && onValueChange(value as AccountNavigationValue)}
      {...props}
    >
      <Tabs.List className="account-navigation">
        <NavigationItem value="works">{i18n.t('label.works')}</NavigationItem>
        <NavigationItem value="about">{i18n.t('label.about_order')}</NavigationItem>
      </Tabs.List>
    </Tabs.Root>
  );
};

const NavigationItem: FC<PropsWithChildren<{ value: AccountNavigationValue }>> = ({ value, children }) => {
  return (
    <Tabs.Trigger className="item" value={value}>
      <Text size="large" weight="bold">
        {children}
      </Text>
    </Tabs.Trigger>
  );
};
