import { FC, PropsWithChildren } from 'react';
import { onUpdateBusinessRepresentativeAddress } from 'src/actions/business';
import { AddressField } from 'src/components/control/AddressField';
import { Form } from 'src/components/control/Form';
import { BusinessIssuesView } from 'src/components/features/business/BusinessIssuesView';
import { Business } from 'src/models/v1/business';

export const BusinessRepresentativeAddressForm: FC<
  PropsWithChildren<{ business: Business; onSuccess: () => void }>
> = ({ business, onSuccess, children }) => {
  const key = business.business_type == 'individual' ? 'individual' : 'representative';
  const person = business.business_type == 'individual' ? business['individual'] : business['representative'];
  return (
    <Form
      onSubmit={onUpdateBusinessRepresentativeAddress({})}
      onSuccess={onSuccess}
      defaultValues={{
        business: {
          [key]: {
            postal_code: person.postal_code,
            state: person.state,
            city: person.city,
            city_kana: person.city_kana,
            town: person.town,
            town_kana: person.town_kana,
            line1: person.line1,
            line1_kana: person.line1_kana,
            line2: person.line2,
            line2_kana: person.line2_kana,
          },
        },
      }}
      defaultRequired
      readOnly={business.status != 'draft'}
    >
      <BusinessIssuesView kind="review_address" />
      <AddressField namePrefix={`business.${key}`} options={{ required: true }} />
      {children}
    </Form>
  );
};
