import './FollowButton.css';
import { FC, useCallback } from 'react';
import { useI18n } from 'src/lib/i18n';
import { AuthRequiredButton } from 'src/components/features/AuthRequiredButton';
import { useFollowCache } from 'src/lib/storage';
import { Button } from 'src/components/styles/Button';
import { api } from 'src/lib/api';

export const FollowButton: FC<{
  gid: string;
  followed: boolean | null;
  returnTo: string;
}> = ({ gid, followed, returnTo }) => {
  const { i18n } = useI18n();
  const { followState, onFollow, onUnfollow } = useFollowCache(gid, followed);
  const onClick = useCallback(async () => {
    await api.dispatch<unknown, unknown>(
      followState.followed ? 'DELETE' : 'POST',
      `/v1/accounts/${gid}/follow`,
      {},
      {},
    );
    followState.followed ? onUnfollow() : onFollow();
  }, [followState.followed]);
  return (
    <AuthRequiredButton returnTo={returnTo}>
      <button
        className={[Button.class, 'follow-button', followState.followed ? 'followed' : ''].join(' ')}
        {...Button.build({ variant: followState.followed ? 'black' : 'outline', size: 'sm' })}
        data-hover-text={followState.followed ? i18n.t('action.unfollow') : i18n.t('action.follow')}
        onClick={onClick}
      >
        {followState.followed ? i18n.t('status.account.following') : i18n.t('action.follow')}
      </button>
    </AuthRequiredButton>
  );
};
