import './TagsInput.css';

import {
  FC,
  useCallback,
  useState,
  KeyboardEvent,
  ComponentProps,
  MouseEventHandler,
  useContext,
  forwardRef,
  ChangeEvent,
  FocusEventHandler,
  MouseEvent,
} from 'react';
import { FormFieldSuggestion } from 'src/components/control/FormFieldSuggestion';
import { Field, FieldAddon } from 'src/components/primitives/Field';
import { Icon } from 'src/components/primitives/Icon';
import { IconicLabel } from 'src/components/primitives/IconicLabel';
import { HStack } from 'src/components/primitives/Stack';
import { Tag } from 'src/components/primitives/Tag';
import { Text } from 'src/components/primitives/Text';
import { AriaAttributesContext } from 'src/lib/a11y';
import { api } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { splitTags, normalizedTypeface } from 'src/lib/value';
import { Tag as TagModel } from 'src/models/v1/tag';

type TagSuggest = Pick<TagModel, 'statistics'> & { value: string };

export type CustomEvent<T extends HTMLElement | null, S> = {
  currentTarget: T & S;
};

export const TagsInput = forwardRef<
  HTMLInputElement,
  {
    value?: Array<string>;
    placeholder?: string;
    noStatistics?: boolean;
    icon?: ComponentProps<typeof Icon>['name'];
    onChange: (event: CustomEvent<HTMLInputElement | HTMLButtonElement | null, { value: string[] }>) => void;
  }
>(({ value = [], placeholder, icon, onChange, noStatistics = false }, ref) => {
  const a11yAttributes = useContext(AriaAttributesContext);
  const [typeface, setTypeface] = useState<string>('');
  const [isBlurPrevented, setBlurPrevented] = useState<boolean>(false);

  const onSearch = useCallback(async (value: string) => {
    const { tags } = await api.fetch<{ tags: Array<TagModel> }>(`/v1/tags`, { q: value, include: 'statistics' });
    return tags
      .filter((tag) => tag.statistics?.works !== 0)
      .map((tag) => ({
        statistics: tag.statistics,
        value: tag.typeface,
      }));
  }, []);

  const onSelect = useCallback(
    (tag: TagSuggest) => {
      if (!value.includes(tag.value)) {
        if (ref !== null && 'current' in ref) {
          const customEvent = convertRefToCustomEvent(ref, { value: [...value, tag.value] });
          ref?.current?.focus();
          // onChangeの中でblur()したいケースがあるのでfocus()の後に呼ぶ
          onChange(customEvent);
        }
      }
      setTypeface('');
    },
    [value, typeface, onChange],
  );

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (typeface == '') {
        if (event.key === 'Backspace') {
          if (value.length > 0) {
            const customEvent = convertToCustomEvent(event, { value: value.slice(0, -1) });
            onChange(customEvent);
          }
        }
      }
      /**
       * TODO: replace isComposing
       * see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/isComposing
       */
      if (event.keyCode == 13) {
        const typefaces = splitTags(typeface)
          .map(normalizedTypeface)
          .filter((typeface) => !value.includes(typeface));
        if (typefaces.length > 0) {
          const customEvent = convertToCustomEvent(event, { value: [...value, ...typefaces] });
          onChange(customEvent);
        }
        setTypeface('');
        // Skip form submitting.
        event.preventDefault();
      }
    },
    [typeface, value],
  );

  const onBlur: FocusEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (isBlurPrevented) {
        setBlurPrevented(false);
        return;
      }
      const typefaces = splitTags(typeface)
        .map(normalizedTypeface)
        .filter((typeface) => !value.includes(typeface));
      if (typefaces.length > 0) {
        const customEvent = convertToCustomEvent(event, { value: [...value, ...typefaces] });
        onChange(customEvent);
        setTypeface('');
      }
      event.currentTarget.value = '';
    },
    [value, ref, isBlurPrevented, typeface],
  );
  const onFieldMouseDown: MouseEventHandler<HTMLDivElement> = useCallback((event) => {
    // inputRef.current 上のクリックではない場合はinputと同じように振る舞うように
    if (ref && 'current' in ref && event.target !== ref.current) {
      event.preventDefault();
      ref.current?.focus();
    }
  }, []);

  const onTagsSuggestMouseDown: MouseEventHandler<HTMLDivElement> = useCallback(
    () => setBlurPrevented(true),
    [setBlurPrevented],
  );

  const onClose = useCallback(
    (event: MouseEvent<HTMLButtonElement>, index: number) => {
      const customEvent = convertToCustomEvent(event, { value: value.filter((_, i) => i !== index) });
      onChange(customEvent);
    },
    [value],
  );

  return (
    <FormFieldSuggestion<TagSuggest>
      onSuggest={onSearch}
      onSelect={onSelect}
      value={typeface}
      item={(item) => <TagSuggest tag={item} noStatistics={noStatistics} onMouseDown={onTagsSuggestMouseDown} />}
    >
      <Field className="tags-input" onMouseDown={onFieldMouseDown}>
        {icon && (
          <FieldAddon className="icon">
            <Icon name="search" />
          </FieldAddon>
        )}
        {value?.map((tag, index) => (
          <Tag iconic className="item" key={tag} onClose={(event) => onClose(event, index)}>
            {tag}
          </Tag>
        ))}
        <input
          ref={ref}
          type="text"
          placeholder={value.length > 0 ? '' : placeholder}
          value={typeface}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setTypeface(event.currentTarget.value)}
          onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => onKeyDown(event)}
          onBlur={onBlur}
          {...a11yAttributes}
        />
      </Field>
    </FormFieldSuggestion>
  );
});

const TagSuggest: FC<{
  tag: TagSuggest;
  noStatistics: boolean;
  onMouseDown: MouseEventHandler<HTMLDivElement>;
}> = ({ tag, onMouseDown, noStatistics }) => {
  const { i18n } = useI18n();

  return (
    <HStack justify="space-between" onMouseDown={onMouseDown}>
      <IconicLabel icon={'tag'} variant="black">
        <Text weight="bold">{tag.value}</Text>
      </IconicLabel>
      {!noStatistics && (
        <Text variant="label" color="note">
          {tag.statistics?.works}
          {i18n.t('label.works')}
        </Text>
      )}
    </HStack>
  );
};

const convertToCustomEvent = <T extends HTMLInputElement | HTMLButtonElement>(
  event: { currentTarget: T },
  customValue: { value: string[] },
): CustomEvent<T, { value: string[] }> => {
  return {
    currentTarget: {
      ...event.currentTarget,
      ...customValue,
    },
  };
};

const convertRefToCustomEvent = <T extends HTMLInputElement | null>(
  ref: React.RefObject<T>,
  customValue: { value: string[] },
): CustomEvent<T, { value: string[] }> => {
  return {
    currentTarget: {
      ...ref.current,
      ...customValue,
    },
  };
};
