import './EstimateSummaryCard.css';

import { ComponentProps, FC } from 'react';
import { Card, CardContent } from 'src/components/primitives/Card';
import { PriceTag } from 'src/components/primitives/PriceTag';
import { HStack, VStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';

import Dialog from 'src/components/styled-radix/Dialog';
import { useI18n } from 'src/lib/i18n';
import { NoticeLabel } from 'src/components/primitives/NoticeLabel';
import { Button } from 'src/components/primitives/Button';
import { checkTicketRole } from 'src/lib/ticket';
import { Estimate } from 'src/models/v1/estimate';
import { EstimateView } from 'src/components/features/estimate/EstimateView';
import { Timestamp } from 'src/components/primitives/Timestamp';

export const EstimateSummaryCard: FC<
  {
    estimate: Estimate;
    isAttached?: boolean; // It is true, this component is used as attached resource in comment form
  } & Omit<ComponentProps<typeof Card>, 'w' | 'borderRadius'>
> = ({ estimate, isAttached = false, className, ...props }) => {
  const { i18n } = useI18n();
  const ticketRole = checkTicketRole('repairer') ? 'repairer' : 'caller';
  return (
    <Dialog.Root>
      <Card borderRadius="sm" className={`estimate-card ${className || ''}`} w="full" {...props}>
        <CardContent>
          <NoticeLabel variant="normal" size="small" w="fit-content">
            {i18n.t('label.estimate')}
          </NoticeLabel>
          <VStack align="center" w="full">
            <Text variant="body" size="large" weight="bold" w="full">
              {estimate.title}
            </Text>
            <HStack w="full">
              <VStack spacing="sm" align="start" w="full">
                <Text color="note">{i18n.t('attributes.estimate.total_amount')}</Text>
                <Text size="large" weight="bold">
                  <PriceTag value={estimate.breakdown.total_amount} currency="jpy" />
                </Text>
              </VStack>
              <VStack spacing="sm" align="start" w="full">
                <Text color="note">{i18n.t('attributes.estimate.estimate_delivery_days')}</Text>
                <Text size="large" weight="bold">
                  {estimate.estimate_delivery_days ?? 0}{' '}
                  {i18n.t(`unit.delivery_days.${estimate.delivery_days_unit}`, estimate.estimate_delivery_days ?? 0)}
                </Text>
              </VStack>
            </HStack>
            <HStack justify="start" spacing="sticky" w="full">
              <Text color="note" size="small">
                {i18n.t('attributes.estimate.expires_date')}
                {' : '}
              </Text>
              <Timestamp color="note" size="small" value={new Date(estimate.expires_date)} format="year-date" />
            </HStack>
            {!isAttached && (
              <Dialog.Trigger asChild>
                <Button w="fit-content" variant={ticketRole == 'caller' ? 'primary' : 'outline'}>
                  {i18n.t('action.show_estimate_details')}
                </Button>
              </Dialog.Trigger>
            )}
          </VStack>
        </CardContent>
      </Card>
      <Dialog.Portal>
        <Dialog.Overlay className="estimate-card-overlay" />
        <Dialog.Content variant="fill-container">
          <Dialog.Title>{estimate.title}</Dialog.Title>
          <Dialog.Description>
            <EstimateView estimate={estimate} />
          </Dialog.Description>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
