import { FC } from 'react';
import { Avatar } from 'src/components/primitives/Avatar';
import { LinkButton } from 'src/components/primitives/Button';
import { Card, CardContent } from 'src/components/primitives/Card';
import { HStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { Meta, Story } from 'src/catalog/Story';
import { Account } from 'src/models/v1/account';
import { factoryAccount } from 'src/catalog/factories/account';

const account: Account = factoryAccount();

const AccountProfileContent: FC = () => {
  return (
    <CardContent>
      <HStack>
        <Avatar account={account} />
        <LinkButton to={`/accounts/${account.gid}`}>Profile</LinkButton>
      </HStack>
      <Text>{account.display_name}</Text>
      <div>{account.bio}</div>
    </CardContent>
  );
};

const meta: Meta<typeof Card> = {
  title: 'Card',
  args: {
    children: <AccountProfileContent />,
  },
  template: (args) => <Card {...args} />,
};

export default meta;

export const grayCard: Story<typeof Card> = {
  name: 'Gray Card',
  args: {
    variant: 'gray',
    children: <AccountProfileContent />,
  },
};
