import './TicketAcceptanceMessageView.css';

import { FC } from 'react';
import { MessageSymbol } from 'src/components/features/message/MessageSymbol';
import { Card, CardContent } from 'src/components/primitives/Card';
import { VStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { Timestamp } from 'src/components/primitives/Timestamp';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';
import { AcceptanceMessage, Message } from 'src/models/v1/message';

export const TicketAcceptanceMessageView: FC<{ message: Message & AcceptanceMessage }> = ({ message }) => {
  const { i18n } = useI18n();
  const { me } = useCurrentStatus();
  return (
    <section className={`ticket-acceptance-message-view ${me?.gid == message.data.account.gid ? 'me' : 'other'}`}>
      <Text asChild variant="label" size="medium" weight="bold">
        <p>{i18n.t('TicketAcceptanceMessageView.message', { account: message.data.account.display_name })}</p>
      </Text>
      <Card borderRadius="sm" w="full">
        <CardContent>
          <MessageSymbol aria-hidden name="check_circle" />
          <VStack align="stretch" w="full">
            <h3>{i18n.t('status.ticket.accept')}</h3>
            <dl className="ticket-acceptance-message-view-data">
              <dt className="ticket-acceptance-message-view-data-date">
                {i18n.t('TicketAcceptanceMessageView.date')}:
              </dt>
              <dd className="ticket-acceptance-message-view-data-date">
                <Timestamp value={new Date(message.created_at)} format="year-date" />
              </dd>
            </dl>
          </VStack>
        </CardContent>
      </Card>
    </section>
  );
};
