import { FC, PropsWithChildren } from 'react';
import { onUpdateBusinessCompany } from 'src/actions/business';
import { Form } from 'src/components/control/Form';
import { InputField } from 'src/components/control/InputField';
import { BusinessIssuesView } from 'src/components/features/business/BusinessIssuesView';
import { useI18n } from 'src/lib/i18n';
import { Business } from 'src/models/v1/business';

export const BusinessCompanyForm: FC<PropsWithChildren<{ business: Business; onSuccess: () => void }>> = ({
  business,
  onSuccess,
  children,
}) => {
  if (business.business_type != 'company') return;
  const { i18n } = useI18n();
  return (
    <Form
      onSubmit={onUpdateBusinessCompany({})}
      onSuccess={onSuccess}
      defaultValues={{
        business: {
          company: {
            name: business.company.name,
            name_kanji: business.company.name_kanji,
            name_kana: business.company.name_kana,
            phone: business.company.phone,
            tax_id: business.company.tax_id,
          },
        },
      }}
      defaultRequired
      readOnly={business.status != 'draft'}
      mode="all"
    >
      <BusinessIssuesView kind="review_company" />
      <InputField name={`business.company.name_kanji`} options={{ required: true }} />
      <InputField
        name={`business.company.name_kana`}
        options={{
          required: true,
          pattern: {
            value: /^[\u3040-\u309F\u30A0-\u30FFー\u3000]+$/,
            message: i18n.t('validation.kana', { name: i18n.t(`attributes.business.company.name_kana`) }),
          },
        }}
      />
      <InputField name={`business.company.name`} options={{ required: true }} />
      <InputField
        name={`business.company.phone`}
        placeholder={i18n.t('placeholder.phone')}
        options={{ required: true }}
      />
      <InputField name={`business.company.tax_id`} options={{ required: true }} />
      {children}
    </Form>
  );
};
