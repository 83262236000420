import { redirect } from 'react-router';
import { SimpleLayout } from 'src/components/Layout';
import { PageLoader } from 'src/lib/api';
import { RevalidateQuotationPaymentRequest, RevalidateQuotationPaymentResponse } from 'src/models/v1/quotation';

export const QuotationPaymentCallback = () => {
  return (
    <SimpleLayout>
      <p>Payment...</p>
    </SimpleLayout>
  );
};

export const quotationPaymentCallbackFunc: PageLoader = async ({ api, request, params }) => {
  const { gid } = params;
  const { searchParams } = new URL(request.url);
  const { quotation } = await api.dispatch<RevalidateQuotationPaymentRequest, RevalidateQuotationPaymentResponse>(
    'PUT',
    `/v1/quotations/${gid}/payment`,
    undefined,
    { token: searchParams.get('token')! },
  );
  return redirect(`/messages/${quotation.ticket_gid}?payment=success`, { status: 301 });
};
