import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { AccountsGallery } from 'src/components/features/account/AccountsGallery';
import { NavigationLayout } from 'src/components/Layout';
import { Heading } from 'src/components/primitives/Heading';
import { useI18n } from 'src/lib/i18n';

export const FollowersPage: FC = () => {
  const { i18n } = useI18n();
  return (
    <NavigationLayout>
      <Helmet>
        <title>
          {i18n.t('title.followers')} | {i18n.t('nagaku')}
        </title>
        <meta property="og:title" content={`${i18n.t('title.followers')} | ${i18n.t('nagaku')}`} />
        <meta name="description" content="" />
      </Helmet>
      <Heading>{i18n.t('title.followers')}</Heading>
      <AccountsGallery path={`/v1/accounts`} search={{ per: '100', following_to: 'me' }} />
    </NavigationLayout>
  );
};
