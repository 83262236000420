import { factoryAccount } from 'src/catalog/factories/account';
import { PasswordRecoveryForm } from './PasswordRecoveryForm';
import { Meta } from 'src/catalog/Story';

const meta: Meta<typeof PasswordRecoveryForm> = {
  title: 'Password Recovery Form',
  args: {
    account: factoryAccount(),
    token: '',
  },
  template: (args) => <PasswordRecoveryForm {...args} />,
};

export default meta;
