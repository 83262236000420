import './PagingNavigation.css';

import { FC, KeyboardEventHandler, useMemo } from 'react';
import { Link, useLocation } from 'react-router';
import { useI18n } from 'src/lib/i18n';
import { paginationCalc } from 'src/lib/pagination';

const keyboardNavigationHandler: KeyboardEventHandler<HTMLElement> = (event) => {
  const item = document.activeElement?.closest('.paging-button');
  if (!item) return;

  const nav = item.closest('nav');
  if (!nav) return;

  const isNavigationKey = ['ArrowLeft', 'ArrowRight', 'Home', 'End'].includes(event.key);
  if (!isNavigationKey) return;

  event.preventDefault();

  let nextItem;
  if (event.key === 'Home' || ((event.shiftKey || event.metaKey) && event.key === 'ArrowLeft')) {
    nextItem = nav.querySelector('.paging-button');
  } else if (event.key === 'End' || ((event.shiftKey || event.metaKey) && event.key === 'ArrowRight')) {
    nextItem = nav.querySelectorAll('.paging-button')[nav.querySelectorAll('.paging-button').length - 1];
  } else if (event.key === 'ArrowLeft') {
    nextItem = item.previousElementSibling || nav.querySelector('.paging-button:last-child');
  } else if (event.key === 'ArrowRight') {
    nextItem = item.nextElementSibling || nav.querySelector('.paging-button');
  }

  (nextItem as HTMLElement)?.focus();
};

export const buildPageLink = (page: number | null) => {
  const { hash, pathname, search } = useLocation();
  return useMemo(() => {
    if (page === null) return null;
    const searchParams = new URLSearchParams(search);
    if (page == 1) searchParams.delete('page');
    else searchParams.set('page', page.toString() || '1');
    return `${pathname}?${searchParams.toString()}#${hash}`;
  }, [search, pathname, hash]);
};

export const PagingNavigation: FC<{
  current: number;
  totalPages: number;
}> = ({ current, totalPages }) => {
  const { i18n } = useI18n();
  if (totalPages <= 1) return;
  return (
    <nav className="paging-navigation" aria-label={i18n.t('label.pagination')} onKeyDown={keyboardNavigationHandler}>
      {paginationCalc(current, totalPages).map((page, index) => (
        <PageItem key={index} current={current} totalPages={totalPages} page={page} />
      ))}
    </nav>
  );
};

const PageItem: FC<{
  page: number | null;
  current: number;
  totalPages: number;
}> = ({ page, current }) => {
  const pagingLink = buildPageLink(page);
  return pagingLink ? (
    <Link to={pagingLink} className="paging-button" aria-current={page == current ? 'page' : undefined}>
      {page}
    </Link>
  ) : (
    '...'
  );
};
