import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router';
import { FeatureAnnouncementCard } from 'src/components/features/announcements/FeatureAnnouncementCard';
import { TagTileCollection } from 'src/components/features/tag/TagTileCollection';
import { TagSection } from 'src/components/features/tag/TagSection';
import { NavigationLayout } from 'src/components/Layout';
import { LinkButton } from 'src/components/primitives/Button';
import { Heading } from 'src/components/primitives/Heading';
import { HomeCurousel } from 'src/components/promote/HomeCarousel';
import { PageLoader } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { Announcement } from 'src/models/v1/announcement';
import { Tag } from 'src/models/v1/tag';
import { WorksGallery } from 'src/components/features/work/WorksGallery';
import { TagHeader } from 'src/components/features/tag/TagHeader';

export type Data = {
  announcements: [Announcement];
  tags: Tag[];
};

export const HomePage = () => {
  const { i18n } = useI18n();
  const { announcements, tags } = useLoaderData() as Data;

  return (
    <NavigationLayout>
      <Helmet>
        <title>
          {i18n.t('nagaku')} - {i18n.t('title.home')}
        </title>
        <meta property="og:title" content={`${i18n.t('title.home')} | ${i18n.t('nagaku')}`} />
        <meta name="description" content={i18n.t('description.short')} />
        <meta property="og:description" content={i18n.t('description.short')} />
      </Helmet>
      {announcements.map((announcement) => (
        <FeatureAnnouncementCard key={announcement.gid} announcement={announcement} />
      ))}
      <HomeCurousel />
      <Heading asChild>
        <header role="heading" className="sr-only">
          {i18n.t('title.feed')}
        </header>
      </Heading>
      <TagTileCollection tags={tags} />
      <LinkButton mr="auto" ml="auto" to="/tags">
        {i18n.t('action.see_more_tag')}
      </LinkButton>
      {tags.map((tag) => (
        <TagSection key={tag.typeface}>
          <TagHeader typeface={tag.typeface} url={tag.hero?.url} />
          <WorksGallery path="/v1/works" search={{ tag: tag.typeface }} pageSize={3} enablePagination={false} />
        </TagSection>
      ))}
    </NavigationLayout>
  );
};

export const homeLoader: PageLoader = async ({ api }) => {
  return await api.combine(
    api.fetch('/v1/announcements', { featured: 'true', per: '1' }),
    api.fetch('/v1/tags', { featured: 'true' }),
  );
};
