import './Dialog.css';

import { forwardRef, ForwardRefExoticComponent } from 'react';

import * as Dialog from '@radix-ui/react-dialog';
import { IconButton } from 'src/components/primitives/IconButton';
import { useI18n } from 'src/lib/i18n';

const Overlay: ForwardRefExoticComponent<Dialog.DialogOverlayProps & React.RefAttributes<HTMLDivElement>> = forwardRef(
  ({ className, ...props }, forwardedRef) => {
    return <Dialog.Overlay className={`dialog-overlay ${className}`} {...props} ref={forwardedRef} />;
  },
);

const Title: ForwardRefExoticComponent<Dialog.DialogTitleProps & React.RefAttributes<HTMLDivElement>> = forwardRef(
  ({ className, ...props }, forwardedRef) => {
    return <Dialog.Title className={`dialog-title ${className}`} {...props} ref={forwardedRef} />;
  },
);

const Content: ForwardRefExoticComponent<
  Dialog.DialogContentProps &
    React.RefAttributes<HTMLDivElement> & { variant: 'fit-content' | 'fill-container' | 'alert' }
> = forwardRef(({ className, variant, ...props }, forwardedRef) => {
  return (
    <Dialog.Content className={`dialog-content dialog-content-${variant} ${className}`} {...props} ref={forwardedRef} />
  );
});

const Description: ForwardRefExoticComponent<Dialog.DialogDescriptionProps & React.RefAttributes<HTMLDivElement>> =
  forwardRef(({ className, ...props }, forwardedRef) => {
    return <Dialog.Description className={`dialog-description ${className}`} {...props} ref={forwardedRef} />;
  });

const Close: ForwardRefExoticComponent<Dialog.DialogCloseProps & React.RefAttributes<HTMLButtonElement>> = forwardRef(
  ({ className, asChild, children, ...props }, forwardedRef) => {
    const { i18n } = useI18n();
    return (
      <Dialog.Close
        asChild={asChild || typeof children == 'undefined'}
        className={`dialog-close ${className || ''}`}
        {...props}
        ref={forwardedRef}
      >
        {children || <IconButton icon="close" size="small" variant="ghost" aria-label={i18n.t('action.close')} />}
      </Dialog.Close>
    );
  },
);

export default {
  ...Dialog,
  Overlay,
  Title,
  Content,
  Description,
  Close,
};
