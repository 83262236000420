import './TagsField.css';

import { FC, PropsWithChildren, useRef } from 'react';
import { FormControl } from 'src/components/control/FormControl';
import { useI18n } from 'src/lib/i18n';
import { TagsInput, CustomEvent } from 'src/components/control/TagsInput';
import { FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { ErrorMessage } from 'src/components/control/ErrorMessage';
import { validateArrayMaxLength } from 'src/lib/validation';

export const TagsField: FC<
  PropsWithChildren<{
    name: string;
    placeholder?: string;
    maxLength?: number;
    options?: RegisterOptions<FieldValues, string>;
  }>
> = ({ name, placeholder, maxLength, options, children }) => {
  const { i18n } = useI18n();
  const inputRef = useRef<HTMLInputElement>(null);
  const { register, watch, setValue } = useFormContext();
  register(name, { ...options, validate: maxLength ? validateArrayMaxLength(name, maxLength) : {} });

  return (
    <FormControl id="tags" name={name} label={i18n.t(`attributes.${name}`)} required={options?.required ? true : false}>
      {children}
      <TagsInput
        ref={inputRef}
        placeholder={placeholder}
        value={watch(name)}
        onChange={(event: CustomEvent<HTMLInputElement | HTMLButtonElement | null, { value: string[] }>) =>
          setValue(name, event.currentTarget.value, { shouldDirty: true, shouldValidate: true })
        }
      />
      <ErrorMessage name={name} />
    </FormControl>
  );
};
