import { Link, useSearchParams } from 'react-router';
import { onLogin } from 'src/actions/auth';
import { Form } from 'src/components/control/Form';
import { FormButton } from 'src/components/control/FormButton';
import { InputField } from 'src/components/control/InputField';
import { LinkButton } from 'src/components/primitives/Button';
import { HorizontalDivider } from 'src/components/primitives/HorizontalDivider';
import { useI18n } from 'src/lib/i18n';
import { Text } from 'src/components/primitives/Text';

export const LoginForm = () => {
  const { i18n } = useI18n();
  const [searchParams] = useSearchParams();
  return (
    <>
      <Form onSubmit={onLogin({})} mode="all" defaultRequired>
        <InputField name="email" type="email" label={i18n.t('label.email')} options={{ required: true }} />
        <InputField name="password" type="password" label={i18n.t('label.password')} options={{ required: true }} />
        <FormButton>{i18n.t('action.login')}</FormButton>
      </Form>
      {/* <GoogleCredentialButton /> */}
      <HorizontalDivider>{i18n.t('label.or')}</HorizontalDivider>
      <LinkButton to={`/register?${searchParams.toString()}`} variant="outline" size="small">
        {i18n.t('action.signup')}
      </LinkButton>
      <Text asChild variant="label" size="medium" color="sub" align="center" mx="auto">
        <Link to={`/recover_password`}>{i18n.t('guide.forget_password')}</Link>
      </Text>
    </>
  );
};
