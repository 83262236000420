import { FC, useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useRevalidator } from 'react-router';
import { FormOptionsContext } from 'src/components/control/Form';
import { BusinessBankAccountForm } from 'src/components/features/business/BusinessBankAccountForm';
import { BusinessCompanyAddressForm } from 'src/components/features/business/BusinessCompanyAddressForm';
import { BusinessCompanyForm } from 'src/components/features/business/BusinessCompanyForm';
import { BusinessIssuesContext } from 'src/components/features/business/BusinessIssuesView';
import { BusinessOverviewForm } from 'src/components/features/business/BusinessOverviewForm';
import { BusinessRepresentativeAddressForm } from 'src/components/features/business/BusinessRepresentativeAddressForm';
import { BusinessRepresentativeContactForm } from 'src/components/features/business/BusinessRepresentativeContactForm';
import { BusinessRepresentativeForm } from 'src/components/features/business/BusinessRepresentativeForm';
import { BusinessVerificationDocumentForm } from 'src/components/features/business/BusinessVerificationDocumentForm';
import { StepSection, StepSectionContext } from 'src/components/features/business/StepSection';
import { Alert } from 'src/components/primitives/Alert';
import { Button, LinkButton } from 'src/components/primitives/Button';
import { Text } from 'src/components/primitives/Text';
import { BusinessFormStep, checkBusinessSteps } from 'src/lib/business';
import { useI18n } from 'src/lib/i18n';
import { Business, BusinessIssue } from 'src/models/v1/business';

export const BusinessForm: FC<{ business: Business; issues: Array<BusinessIssue> }> = ({ business, issues }) => {
  const { i18n } = useI18n();
  const { revalidate } = useRevalidator();
  const { completedSteps, current, openSteps } = checkBusinessSteps(business, issues);
  const [opens, setOpens] = useState<Array<BusinessFormStep>>(openSteps);
  const navigate = useNavigate();
  const onToggleOpen = useCallback(
    (value: string) => {
      setOpens((opens) => {
        if (opens.includes(value as BusinessFormStep)) {
          return opens.filter((e) => e != value);
        } else {
          return [...opens, value as BusinessFormStep];
        }
      });
    },
    [setOpens],
  );
  return (
    <BusinessIssuesContext.Provider value={{ issues }}>
      <StepSectionContext.Provider value={{ current, opens }}>
        <Text asChild color="note">
          <p>
            {i18n.t('guide.business_draft')}
            <br />
            {i18n.t('guide.business_privacy_policy')}
          </p>
        </Text>
        <StepSection
          title={i18n.t('form.business.overview')}
          value="overview"
          isCompleted={completedSteps.has('overview')}
          onToggle={onToggleOpen}
        >
          <BusinessOverviewForm business={business} onSuccess={revalidate}>
            <SaveButton />
          </BusinessOverviewForm>
        </StepSection>
        {business.business_type == 'company' && (
          <>
            <StepSection
              title={i18n.t('form.business.company_overview')}
              value="company_overview"
              isCompleted={completedSteps.has('company_overview')}
              onToggle={onToggleOpen}
            >
              <BusinessCompanyForm business={business} onSuccess={revalidate}>
                <SaveButton />
              </BusinessCompanyForm>
            </StepSection>
            <StepSection
              title={i18n.t('form.business.company_address')}
              value="company_address"
              isCompleted={completedSteps.has('company_address')}
              onToggle={onToggleOpen}
            >
              <BusinessCompanyAddressForm business={business} onSuccess={revalidate}>
                <SaveButton />
              </BusinessCompanyAddressForm>
            </StepSection>
          </>
        )}
        <StepSection
          title={i18n.t('form.business.representative')}
          value="person"
          isCompleted={completedSteps.has('person')}
          onToggle={onToggleOpen}
        >
          <BusinessRepresentativeForm business={business} onSuccess={revalidate}>
            <SaveButton />
          </BusinessRepresentativeForm>
        </StepSection>
        <StepSection
          title={i18n.t('form.business.representative_address')}
          value="address"
          isCompleted={completedSteps.has('address')}
          onToggle={onToggleOpen}
        >
          <BusinessRepresentativeAddressForm business={business} onSuccess={revalidate}>
            <SaveButton />
          </BusinessRepresentativeAddressForm>
        </StepSection>
        <StepSection
          title={i18n.t('form.business.id_documents')}
          value="verification_documents"
          isCompleted={completedSteps.has('verification_documents')}
          onToggle={onToggleOpen}
        >
          <FormOptionsContext.Provider value={{ defaultRequired: true, readOnly: business.status != 'draft' }}>
            <BusinessVerificationDocumentForm
              documents={business.verification_documents}
              onComplete={() => {
                revalidate();
                if (completedSteps.has('verification_documents')) {
                  onToggleOpen('verification_documents');
                }
              }}
            />
          </FormOptionsContext.Provider>
        </StepSection>
        <StepSection
          title={i18n.t('form.business.contact')}
          value="contact"
          isCompleted={completedSteps.has('contact')}
          onToggle={onToggleOpen}
        >
          <BusinessRepresentativeContactForm business={business} onSuccess={revalidate}>
            <SaveButton />
          </BusinessRepresentativeContactForm>
        </StepSection>
        <StepSection
          title={i18n.t('form.business.bank_account')}
          value="bank_account"
          isCompleted={completedSteps.has('bank_account')}
          onToggle={onToggleOpen}
        >
          <BusinessBankAccountForm
            business={business}
            onSuccess={() => {
              revalidate();
              navigate('/settings/business_application');
            }}
          >
            <SaveButton />
          </BusinessBankAccountForm>
        </StepSection>
        {business.status == 'in_review' && (
          <Alert variant="info" icon="hourglass">
            {i18n.t('guide.business_is_in_review')}
          </Alert>
        )}
        {business.status == 'draft' && (
          <LinkButton to="/settings/business_application" variant="fill">
            {i18n.t('action.review_input')}
          </LinkButton>
        )}
      </StepSectionContext.Provider>
    </BusinessIssuesContext.Provider>
  );
};

const SaveButton: FC = () => {
  const { i18n } = useI18n();
  const {
    formState: { isSubmitting },
  } = useFormContext();
  return (
    <Button type="submit" variant="outline" size="small" loading={isSubmitting}>
      {i18n.t('action.save')}
    </Button>
  );
};
