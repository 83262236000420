import { FC, useState } from 'react';
import { AccountCardFooter } from 'src/components/features/account/AccountCardFooter';
import { Card, CardContent } from 'src/components/primitives/Card';
import { VStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { Timestamp } from 'src/components/primitives/Timestamp';
import { RepairRequest } from 'src/models/v1/ticket';
import { ImageViewer } from 'src/components/features/ImageViewer';
import { ThumbnailCollection } from 'src/components/features/ThumbnailCollection';

export const RepairRequestCard: FC<{ repairRequest: RepairRequest }> = ({ repairRequest }) => {
  const images = repairRequest.photo_media_files.map(({ url }) => url);
  const [currentImage, setCurrentImage] = useState<string | undefined>(undefined);
  return (
    <Card asChild w="full">
      <section>
        <CardContent asChild>
          <VStack align="stretch" spacing="md">
            <VStack align="stretch" spacing="xs">
              <Timestamp variant="body" value={new Date(repairRequest.created_at)} format="year-date" />
              <Text asChild variant="title" weight="bold">
                <h2>{repairRequest.title}</h2>
              </Text>
            </VStack>
            <ImageViewer images={images} currentImage={currentImage} setCurrentImage={setCurrentImage} />
            <ThumbnailCollection images={images} onSelect={(image) => setCurrentImage(image)} />
          </VStack>
        </CardContent>
        <AccountCardFooter account={repairRequest.account}></AccountCardFooter>
      </section>
    </Card>
  );
};
