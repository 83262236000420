import { factoryAccount } from 'src/catalog/factories/account';
import { EmailVerificationForm } from './EmailVerificationForm';
import { Meta } from 'src/catalog/Story';

const meta: Meta<typeof EmailVerificationForm> = {
  title: 'Email Verification Form',
  args: {
    token: '',
    account: factoryAccount(),
  },
  template: (args) => <EmailVerificationForm {...args} />,
};

export default meta;
