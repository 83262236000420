import { FC } from 'react';
import { BackableBreadcrumb } from 'src/components/features/BackableBreadcrumb';
import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router';
import { BusinessPreview } from 'src/components/features/business/BusinessPreview';
import { NavigationLayout } from 'src/components/Layout';
import { PageLoader } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { Business } from 'src/models/v1/business';

type Data = {
  business: Business;
};

export const BusinessSettingPage: FC = () => {
  const { i18n } = useI18n();
  const { business } = useLoaderData() as Data;

  return (
    <NavigationLayout>
      <Helmet>
        <title>{`${i18n.t('title.business_application_information')} · ${i18n.t('title.setting')} | ${i18n.t('nagaku')}`}</title>
        <meta name="description" content="" />
      </Helmet>
      <BackableBreadcrumb homePath="/settings" currentPath="/settings/business">
        {i18n.t('title.business_application_information')}
      </BackableBreadcrumb>
      <BusinessPreview business={business} />
    </NavigationLayout>
  );
};

export const businessSettingLoader: PageLoader = async ({ api }) => {
  return await api.fetch('/v1/accounts/me/business');
};
