import './WorkView.css';

import { FC, useState } from 'react';
import { BeforeAfterSlider } from 'src/components/features/BeforeAfterSlider';
import { TagCollection } from 'src/components/features/tag/TagCollection';
import { HtmlContent } from 'src/components/primitives/HtmlContent';
import { Icon } from 'src/components/primitives/Icon';
import { NoticeLabel } from 'src/components/primitives/NoticeLabel';
import { HStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';
import { normalizeAmount } from 'src/lib/value';
import { Work } from 'src/models/v1/work';
import { ImageViewer } from 'src/components/features/ImageViewer';
import { ThumbnailCollection } from 'src/components/features/ThumbnailCollection';

export const WorkView: FC<{ work: Work }> = ({ work }) => {
  const { i18n } = useI18n();
  const images = work.image_media_files.map(({ url }) => url);
  const [currentImage, setCurrentImage] = useState<string | undefined>(undefined);
  return (
    <>
      <ImageViewer images={images} currentImage={currentImage} setCurrentImage={setCurrentImage} />
      <BeforeAfterSlider
        className="work-view-slider"
        beforeImage={work.before_image_media_file?.url}
        afterImage={work.after_image_media_file?.url}
      />
      <ThumbnailCollection images={images} onSelect={(image) => setCurrentImage(image)} />
      <Text size="large">
        <HtmlContent html={work.html.body} />
      </Text>
      <HStack justify="start">
        {work.estimate_amount && (
          <NoticeLabel variant="normal" size="small">
            <Icon name="currency_yen" aria-label={i18n.t('attributes.work.estimate_amount')} />
            {normalizeAmount(work.estimate_amount.toString())}
          </NoticeLabel>
        )}
        {work.estimate_delivery_days && (
          <NoticeLabel variant="normal" size="small">
            <Icon name="access_time" aria-label={i18n.t('attributes.work.estimate_delivery_days')} />
            {work.estimate_delivery_days}
            {i18n.t('unit.separator')}
            {i18n.t(`unit.delivery_days.${work.delivery_days_unit}`, work.estimate_delivery_days ?? 0)}
          </NoticeLabel>
        )}
      </HStack>
      <TagCollection tags={work.tags} wrap />
    </>
  );
};
