import { FC } from 'react';
import { useI18n } from 'src/lib/i18n';
import { Text } from 'src/components/primitives/Text';
import { HStack } from 'src/components/primitives/Stack';

export const CounterLabel: FC<{ unit: string; count: number }> = ({ unit, count }) => {
  const { i18n } = useI18n();
  return (
    <HStack justify="start" align="center" spacing="xs">
      <Text size="large" weight="bold">
        {count.toString()}
      </Text>
      <Text asChild size="small">
        <small>{i18n.t(unit, count)}</small>
      </Text>
    </HStack>
  );
};
