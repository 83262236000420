import './TagCollection.css';

import { FC, KeyboardEventHandler } from 'react';
import { Link } from 'react-router';
import { Tag } from 'src/components/primitives/Tag';

export const TagCollection: FC<{ tags: string[] | undefined; wrap?: boolean }> = ({ tags, wrap = false }) => {
  if (typeof tags == 'undefined' || tags.length == 0) return;
  return (
    <ul className={`tag-collection ${wrap ? 'wrap' : ''}`} role="list" onKeyDown={keyboardAccessibilityHandler}>
      {tags.map((tag) => (
        <li className="item" role="listitem" key={tag}>
          <Tag asChild iconic>
            <Link to={`/explore/${encodeURIComponent(tag)}`} title={`#${tag}`}>
              {tag}
            </Link>
          </Tag>
        </li>
      ))}
    </ul>
  );
};

/**
 * Keyboard A11y
 *
 * ArrowLeft: focus previous tag
 * ArrowRight: focus next tag
 * Home, Shift + ArrowLeft, Command + ArrowLeft: focus first tag
 * End, Shift + ArrowRight, Command + ArrowRight: focus last tag
 *
 * Note: This function is written in pure JavaScript and operates directly on the DOM.
 * Therefore, it does not need to be defined within a React component.
 **/
const keyboardAccessibilityHandler: KeyboardEventHandler<HTMLUListElement> = (event) => {
  // assert parent of current focus element = <li class="item" />
  const item = document.activeElement?.parentElement;
  if (!item || item.tagName.toLowerCase() != 'li' || item.className != 'item') return;

  // assert collection element = <ul class="tag-collection" />
  const collection = item.parentElement;
  if (!collection || collection.tagName.toLowerCase() != 'ul' || collection.className != 'tag-collection') return;

  // assert key is one of navigation key
  const isNavigationKey = ['ArrowLeft', 'ArrowRight', 'Home', 'End'].includes(event.key);
  if (!isNavigationKey) return;

  event.preventDefault();

  let nextItem;

  // find next focus target
  if (event.key == 'Home' || ((event.shiftKey || event.metaKey) && event.key == 'ArrowLeft')) {
    nextItem = item.parentElement?.firstElementChild;
  } else if (event.key == 'End' || ((event.shiftKey || event.metaKey) && event.key == 'ArrowRight')) {
    nextItem = item.parentElement?.lastElementChild;
  } else if (event.key == 'ArrowLeft') {
    nextItem = item.previousElementSibling || item.parentElement?.lastElementChild;
  } else if (event.key == 'ArrowRight') {
    nextItem = item.nextElementSibling || item.parentElement?.firstElementChild;
  }

  (nextItem?.getElementsByClassName('tag').item(0) as HTMLAnchorElement)?.focus();
};
