import { FC } from 'react';
import { useI18n } from 'src/lib/i18n';
import { Button } from 'src/components/primitives/Button';
import { Text } from 'src/components/primitives/Text';
import { useEditable } from 'src/lib/form';

export const PasswordSettingPreview: FC = () => {
  const { i18n } = useI18n();
  const { onStartEdit } = useEditable();
  return (
    <>
      <Text variant="label" size="large" weight="bold">
        {i18n.t('label.password')}
      </Text>
      <Text size="large" weight="bold">
        ************
      </Text>
      <Button variant="fill" size="small" mr="auto" onClick={onStartEdit}>
        {i18n.t('action.edit')}
      </Button>
    </>
  );
};
