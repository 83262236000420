import './Card.css';

import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { BoxProps, Sizes, styleBoxProps } from 'src/components/primitives/PrimitiveComponent';

import { Primitive } from '@radix-ui/react-primitive';

type CardElement = React.ElementRef<typeof Primitive.div>;
type PrimitiveCardProps = ComponentPropsWithoutRef<typeof Primitive.div> & BoxProps;
interface CardProps extends PrimitiveCardProps {
  variant?: 'outline' | 'gray';
  borderRadius?: Sizes;
}

export const Card = forwardRef<CardElement, CardProps>(
  ({ variant = 'outline', borderRadius = 'lg', children, ...originalProps }, forwardedRef) => {
    const { className, ...props } = styleBoxProps(originalProps);
    return (
      <Primitive.div
        className={['card', `card-${variant}`, `card-border-radius-${borderRadius}`, className].join(' ')}
        {...props}
        ref={forwardedRef}
      >
        {children}
      </Primitive.div>
    );
  },
);

Card.displayName = 'Card';

type CardContentElement = React.ElementRef<typeof Primitive.div>;
type PrimitiveCardContentProps = ComponentPropsWithoutRef<typeof Primitive.div> & BoxProps;
interface CardContentProps extends PrimitiveCardContentProps {}

export const CardContent = forwardRef<CardContentElement, CardContentProps>(
  ({ children, ...originalProps }, forwardedRef) => {
    const { className, ...props } = styleBoxProps(originalProps);
    return (
      <Primitive.div className={['card-content', className].join(' ')} {...props} ref={forwardedRef}>
        {children}
      </Primitive.div>
    );
  },
);

CardContent.displayName = 'CardContent';

type CardFooterElement = React.ElementRef<typeof Primitive.div>;
type PrimitiveCardFooterProps = ComponentPropsWithoutRef<typeof Primitive.div> & BoxProps;
interface CardFooterProps extends PrimitiveCardFooterProps {}

export const CardFooter = forwardRef<CardFooterElement, CardFooterProps>(
  ({ children, ...originalProps }, forwardedRef) => {
    const { className, ...props } = styleBoxProps(originalProps);
    return (
      <Primitive.div className={['card-footer', className].join(' ')} {...props} ref={forwardedRef}>
        {children}
      </Primitive.div>
    );
  },
);
