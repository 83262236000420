import './MenuToggleButton.css';

import { ButtonHTMLAttributes, FC } from 'react';
import { Avatar } from 'src/components/primitives/Avatar';
import { Button } from 'src/components/primitives/Button';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';

export const MenuToggleButton: FC<{ isVisibleMenu: boolean; onToggleMenu: () => void }> = ({
  isVisibleMenu,
  onToggleMenu,
}) => {
  const { i18n } = useI18n();
  const { me } = useCurrentStatus();
  const props: ButtonHTMLAttributes<HTMLButtonElement> = {
    id: 'menu-toggle-button',
    'aria-controls': 'menu',
    'aria-expanded': isVisibleMenu,
    onClick: onToggleMenu,
  };
  return me ? (
    <button className="menu-avatar" {...props}>
      <Avatar account={me} src={me.icon_url || undefined} size="sm" />
    </button>
  ) : (
    <Button variant="fill" size="small" {...props}>
      {i18n.t('action.signup_or_login')}
    </Button>
  );
};
