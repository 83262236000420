import { FC } from 'react';
import { useSearchParams } from 'react-router';
import { BackableBreadcrumb } from 'src/components/features/BackableBreadcrumb';
import { ContentEmpty } from 'src/components/features/ContentEmpty';
import { WorksGallery } from 'src/components/features/work/WorksGallery';
import { NavigationLayout } from 'src/components/Layout';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';

export const DraftWorksListPage: FC = () => {
  const { i18n } = useI18n();
  const { me } = useCurrentStatus();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || '';

  return (
    <NavigationLayout>
      <BackableBreadcrumb homePath="/accounts/me" currentPath="/works/draft">
        {i18n.t('title.draft_works')}
      </BackableBreadcrumb>
      <WorksGallery
        key={`works-draft-${page}`}
        path={`/v1/accounts/${me!.gid}/works`}
        search={{ status: 'draft' }}
        empty={<ContentEmpty />}
        page={page}
      />
    </NavigationLayout>
  );
};
