import './Layout.css';

import { FC, PropsWithChildren, ReactNode, useCallback, useEffect, useState } from 'react';
import ErrorBoundary from 'src/components/error/ErrorBoundary';
import { RemoveScroll } from 'src/components/RemoveScroll';
import { Footer } from 'src/components/singleton/Footer';
import { Header } from 'src/components/singleton/Header';
import { Menu } from 'src/components/singleton/Menu';
import { MenuToggleButton } from 'src/components/singleton/MenuToggleButton';

export type MenuVisibility = 'open' | 'close' | 'auto';

const getWidnowWidth = () => {
  if (!import.meta.env.SSR) {
    return window.innerWidth;
  } else {
    return 320;
  }
};

/**
 * Navigation Layout: 1 ~ 3 columns layout system
 */
export const NavigationLayout: FC<PropsWithChildren<{ menu?: ReactNode; footer?: ReactNode }>> = ({
  menu,
  footer,
  children,
}) => {
  const [menuVisibility, setMenuVisibility] = useState<MenuVisibility | null>(null);
  const isVisibleMenu = menuVisibility == 'open' || (menuVisibility == 'auto' && 748 <= getWidnowWidth());
  const onResize = useCallback(() => {
    if (748 <= getWidnowWidth()) setMenuVisibility('auto');
    else if (menuVisibility == 'auto') setMenuVisibility('close');
  }, [menuVisibility, setMenuVisibility]);
  useEffect(() => {
    if (import.meta.env.SSR == false) {
      onResize();
      window.addEventListener('resize', onResize);
      return () => window.removeEventListener('resize', onResize);
    }
  }, [onResize]);
  return (
    <RemoveScroll enabled={menuVisibility == 'open'} className="navigation-layout" removeScrollBar={false}>
      <Header isEnableMenu>
        <MenuToggleButton
          isVisibleMenu={isVisibleMenu}
          onToggleMenu={() => setMenuVisibility(() => (isVisibleMenu ? 'close' : 'open'))}
        />
      </Header>
      <Menu key="menu" isVisible={isVisibleMenu} onClose={() => setMenuVisibility('close')}>
        {menu}
      </Menu>
      <main data-menu-visibility={menuVisibility}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>
      {footer || <Footer key="footer" />}
    </RemoveScroll>
  );
};

/**
 * Simple Layout: 1 column only layout system.
 */
export const SimpleLayout: FC<PropsWithChildren<{ isWide?: boolean }>> = ({ children, isWide = false }) => {
  return (
    <div className={`simple-layout ${isWide ? 'wide' : ''}`}>
      <Header isEnableMenu={false} />
      <main>
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>
      <Footer key="footer" />
    </div>
  );
};
