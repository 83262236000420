import './FeatureAnnouncementCard.css';

import { FC, useState } from 'react';
import { Text } from 'src/components/primitives/Text';
import { HStack, VStack } from 'src/components/primitives/Stack';
import { Icon } from 'src/components/primitives/Icon';
import { Card } from 'src/components/primitives/Card';
import { Announcement } from 'src/models/v1/announcement';
import { Link } from 'react-router';

export const FeatureAnnouncementCard: FC<{
  announcement: Pick<Announcement, 'title' | 'summary' | 'slug' | 'published_date'>;
}> = ({ announcement }) => {
  const [isHidden, setIsHidden] = useState(false);

  if (isHidden) return;
  return (
    <Card className="feature-announcement" borderRadius="sm">
      <HStack align="start" spacing="sticky">
        <VStack asChild align="start" spacing="sticky" w="full">
          <Link className="link" to={`/news/${announcement.published_date}/${announcement.slug}`}>
            <Text weight="bold" className="title">
              {announcement.title}
            </Text>
            <Text>{announcement.summary}</Text>
          </Link>
        </VStack>
        <Icon name="close" onClick={() => setIsHidden(true)} />
      </HStack>
    </Card>
  );
};
