import './AnnouncementCard.css';

import { FC } from 'react';
import { Text } from 'src/components/primitives/Text';
import { HStack, VStack } from 'src/components/primitives/Stack';
import { Icon } from 'src/components/primitives/Icon';
import { Card } from 'src/components/primitives/Card';
import { Announcement } from 'src/models/v1/announcement';
import { Link } from 'react-router';

export const AnnouncementCard: FC<{
  announcement: Pick<Announcement, 'title' | 'slug' | 'published_date'>;
}> = ({ announcement }) => {
  return (
    <Card asChild className="announcement-card" borderRadius="sm">
      <Link className="announcement-card-link" to={`/news/${announcement.published_date}/${announcement.slug}`}>
        <HStack align="center" spacing="sticky">
          <VStack align="start" spacing="sm" w="full">
            <Text color="sub">{announcement.published_date}</Text>
            <Text weight="bold" className="title">
              {announcement.title}
            </Text>
          </VStack>
          <Icon name="keyboard_arrow_right" />
        </HStack>
      </Link>
    </Card>
  );
};
