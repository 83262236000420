import './TagList.css';

import { IconicLabel } from 'src/components/primitives/IconicLabel';
import { Text } from 'src/components/primitives/Text';
import { Link } from 'react-router';
import { FC } from 'react';
import { useI18n } from 'src/lib/i18n';
import { Tag } from 'src/models/v1/tag';

export const TagList: FC<{ tags: Pick<Tag, 'typeface' | 'statistics'>[] }> = ({ tags }) => {
  return (
    <div className="tag-list">
      {tags
        .filter((tag) => tag.statistics?.works !== 0)
        .map((tag) => (
          <TagItem typeface={tag.typeface} works={tag.statistics?.works} />
        ))}
    </div>
  );
};

export const TagItem: FC<{ typeface: string; works: number | undefined }> = ({ typeface, works }) => {
  const { i18n } = useI18n();

  return (
    <Link className="item" to={`/tags/${typeface}`}>
      <IconicLabel icon="tag" variant="black">
        <Text weight="bold">{typeface}</Text>
      </IconicLabel>
      <Text variant="label" color="note">
        {works}
        {i18n.t('label.works')}
      </Text>
    </Link>
  );
};
