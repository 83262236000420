import { FC } from 'react';
import { BackableBreadcrumb } from 'src/components/features/BackableBreadcrumb';
import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router';
import { VerifyEmailAlert } from 'src/components/features/setting/VerifyEmailAlert';
import { NavigationLayout } from 'src/components/Layout';
import { PageLoader } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { AccountSetting } from 'src/models/v1/account';
import { EmailSettingSection } from 'src/components/features/email_setting/EmailSettingSection';
import { PasswordSettingSection } from 'src/components/features/password_setting/PasswordSettingSection';

type Data = {
  account_setting: AccountSetting;
};

export const AccountSettingPage: FC = () => {
  const { i18n } = useI18n();
  const { account_setting } = useLoaderData() as Data;

  return (
    <NavigationLayout>
      <Helmet>
        <title>
          {i18n.t('title.account_setting')} · {i18n.t('title.setting')} | {i18n.t('nagaku')}
        </title>
        <meta
          property="og:title"
          content={`${i18n.t('title.account_setting')} · ${i18n.t('title.setting')} | ${i18n.t('nagaku')}`}
        />
        <meta name="description" content="" />
        <meta property="og:description" content="" />
      </Helmet>
      <BackableBreadcrumb homePath="/settings" currentPath="/settings/account">
        {i18n.t('title.account_setting')}
      </BackableBreadcrumb>
      {account_setting.email_verified == false && <VerifyEmailAlert />}
      <EmailSettingSection accountSetting={account_setting} />
      <PasswordSettingSection />
    </NavigationLayout>
  );
};

export const accountSettingLoader: PageLoader = async ({ api }) => {
  return await api.combine(api.fetch('/v1/accounts/me/setting'));
};
