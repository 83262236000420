import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { redirect, useLoaderData } from 'react-router';
import { SimpleLayout } from 'src/components/Layout';
import { List } from 'src/components/primitives/List';
import { Text } from 'src/components/primitives/Text';
import { PageLoader } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { Account } from 'src/models/v1/account';
import { JpActOnSpecifiedCommercialTransaction } from 'src/models/v1/jp_act_on_specified_commercial_transaction';

type Data = {
  jp_act_on_specified_commercial_transaction: JpActOnSpecifiedCommercialTransaction;
};

export const AccountPrivacyPolicyPage: FC = () => {
  const { i18n } = useI18n();
  const { jp_act_on_specified_commercial_transaction } = useLoaderData() as Data;

  return (
    <SimpleLayout isWide>
      <Helmet>
        <title>{`${i18n.t('title.privacy_policy')} | ${i18n.t('nagaku')}`}</title>
        <meta property="og:title" content={`${i18n.t('title.privacy_policy')} | ${i18n.t('nagaku')}`} />
        <meta name="description" content="" />
        <meta property="og:description" content="" />
      </Helmet>
      <Text asChild variant="display" weight="bold">
        <h1>{i18n.t('title.privacy_policy')}</h1>
      </Text>
      <Text asChild variant="headline" weight="bold">
        <h2>第1条 定義</h2>
      </Text>
      <Text asChild>
        <p>
          {jp_act_on_specified_commercial_transaction.representative_name ||
            jp_act_on_specified_commercial_transaction.corporate_name}
          （以下「当事業者」といいます）は、個人情報の取扱いにおいて、個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守するとともに、適切な管理を行います。
        </p>
      </Text>
      <Text asChild>
        <p>
          なお、本文中の用語の定義は個人情報の保護に関する法律（以下「個人情報保護法」といいます。）及び関連法令に従います。
        </p>
      </Text>

      <Text asChild variant="headline" weight="bold">
        <h2>第2条 個人情報の取得</h2>
      </Text>
      <Text asChild>
        <p>
          当事業者は、当事業者が提供するサービス（以下「当事業者サービス」といいます）の利用者の個人情報を適法かつ適正な手段により取得します。当事業者は、次項の利用目的の達成に必要な範囲で、氏名、住所、電話番号、メールアドレスなどの個人情報を、利用者より取得することがあります。
        </p>
      </Text>

      <Text asChild variant="headline" weight="bold">
        <h2>第3条 個人情報の利用目的</h2>
      </Text>
      <Text asChild>
        <p>当事業者は個人情報を次の目的に利用します。</p>
      </Text>
      <List>
        <Text asChild>
          <li>当事業者サービスを提供するため</li>
        </Text>
        <Text asChild>
          <li>当事業者サービスの企画、開発、保守、改善のため</li>
        </Text>
        <Text asChild>
          <li>メンテナンスのお知らせその他当事業者サービスに関する重要なお知らせ等のため</li>
        </Text>
        <Text asChild>
          <li>利用者から寄せられたご意見、ご要望にご回答するため</li>
        </Text>
        <Text asChild>
          <li>利用者の本人確認のため</li>
        </Text>
        <Text asChild>
          <li>利用者への料金請求のため</li>
        </Text>
        <Text asChild>
          <li>当事業者サービスに関するご案内・営業活動及びマーケティング活動のため</li>
        </Text>
        <Text asChild>
          <li>その他、上記利用目的に付随した利用を行うため</li>
        </Text>
      </List>

      <Text asChild variant="headline" weight="bold">
        <h2>第4条 安全管理措置に関する事項</h2>
      </Text>
      <Text asChild>
        <p>
          当事業者は、個人データについて、漏えい、滅失又は毀損の防止等、その管理のために必要かつ適切な安全管理措置を講じます。また、
          個人データを取り扱う従業者や委託先（再委託先等を含みます。）に対して、必要かつ適切な監督を行います。
        </p>
      </Text>

      <Text asChild variant="headline" weight="bold">
        <h2>第5条 個人データの第三者提供</h2>
      </Text>
      <Text asChild>
        <p>
          当事業者は、利用者ご本人の事前同意がある場合又は法令で認められている場合を除き、当事業者が取得した利用者の個人データは、第三者（委託先を除きます。）に提供いたしません。
        </p>
      </Text>

      <Text asChild variant="headline" weight="bold">
        <h2>第6条 プライバシーポリシーの改定等</h2>
      </Text>
      <Text asChild>
        <p>
          当事業者は、本プライバシーポリシーの内容を適宜見直し、必要に応じて変更することがあります。この場合、改定版の公表の日から、変更の効力を生じるものとします。
        </p>
      </Text>

      <Text asChild variant="headline" weight="bold">
        <h2>第7条 個人情報の開示等の請求</h2>
      </Text>
      <Text asChild>
        <p>
          当事業者は､個人データの開示･訂正等･利用停止等のご要望があったときは､所定の手続でご本人様であることを確認のうえ､法令に従って対応します。
        </p>
      </Text>

      <Text asChild variant="headline" weight="bold">
        <h2>第8条 問い合わせ先</h2>
      </Text>
      <Text asChild>
        <p>個人情報の取扱いに関するお問い合わせは、メッセージフォームよりお問い合わせください。</p>
      </Text>
    </SimpleLayout>
  );
};

export const accountPrivacyPolicyLoader: PageLoader = async ({ request, params, api }) => {
  const { gid, screen_name } = params;
  const { account } = await api.fetch<{ account: Account }>(`/v1/accounts/${gid || screen_name}`);
  if (account.screen_name && !screen_name) {
    return redirect(request.url.replace(`/accounts/${account.gid}`, `/${account.screen_name}`), { status: 301 });
  }

  return await api.fetch(`/v1/accounts/${account.gid}/business/jp_act_on_specified_commercial_transaction`);
};
