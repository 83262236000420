import './EstimateItemFieldset.css';

import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { AmountField } from 'src/components/control/AmountField';
import { InputField } from 'src/components/control/InputField';
import { Icon } from 'src/components/primitives/Icon';
import { PriceTag } from 'src/components/primitives/PriceTag';
import { HStack, VStack } from 'src/components/primitives/Stack';
import { useI18n } from 'src/lib/i18n';
import { validateOnlyInteger, validatePositiveNumber } from 'src/lib/validation';

export const EstimateItemFieldset: FC<{
  namePrefix: string;
  onDelete: () => void;
}> = ({ namePrefix, onDelete }) => {
  const { i18n } = useI18n();
  const { watch } = useFormContext();
  return (
    <fieldset className="estimate-item-fieldset">
      <VStack align="stretch" className="estimate-item-fieldset-field" w="full">
        <InputField
          name={namePrefix.concat('.label')}
          placeholder={i18n.t('placeholder.estimate.items.label')}
          label={i18n.t('attributes.estimate.items.label')}
          options={{ required: true }}
        />
        <HStack align="flex-start" spacing="lg" w="full">
          <AmountField
            name={`${namePrefix}.amount`}
            label={`${i18n.t('attributes.estimate.items.amount')} (${i18n.t('attributes.estimate.items.ex_tax')})`}
            placeholder={i18n.t('placeholder.estimate.items.amount')}
            options={{ required: true }}
          />
          <InputField
            name={namePrefix.concat('.quantity')}
            placeholder="1"
            label={i18n.t('attributes.estimate.items.quantity')}
            options={{
              required: true,
              validate: {
                only_integer: validateOnlyInteger,
                positive_number: validatePositiveNumber,
              },
            }}
          />
        </HStack>
        <HStack asChild justify="space-between" w="full" className="estimate-item-footer">
          <footer>
            <Icon name="delete" onClick={onDelete} className="estimate-item-delete" />
            <PriceTag value={(watch(`${namePrefix}.amount`) || 0) * (watch(`${namePrefix}.quantity`) || 1)} />
          </footer>
        </HStack>
      </VStack>
    </fieldset>
  );
};
