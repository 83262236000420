import './AccountProfileCard.css';

import { FC } from 'react';
import { Text } from 'src/components/primitives/Text';
import { VStack } from 'src/components/primitives/Stack';
import { useI18n } from 'src/lib/i18n';
import { Account } from 'src/models/v1/account';

import { Avatar } from '../../primitives/Avatar';
import { LinkButton } from '../../primitives/Button';

export const AccountProfileCard: FC<{ account: Account }> = ({ account }) => {
  const { i18n } = useI18n();
  return (
    <VStack className="user-profile-card" align="center" m="none" p="md">
      <Avatar account={account} src={account.icon_url || undefined} />
      <Text variant="title" weight="bold">
        {account.display_name}
      </Text>
      <LinkButton to={`/accounts/${account.gid}`} state={{ close: 'back' }} variant="fill" w="full">
        {i18n.t('action.visit_profile')}
      </LinkButton>
    </VStack>
  );
};
