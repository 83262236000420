import { factoryAccount } from 'src/catalog/factories/account';
import { factoryAccountSetting } from 'src/catalog/factories/accountSetting';
import { Meta } from 'src/catalog/Story';
import { AskForm } from 'src/components/features/ticket/AskForm';

const meta: Meta<typeof AskForm> = {
  title: 'Ask Form',
  args: {
    account: factoryAccount(),
    setting: factoryAccountSetting(),
  },
  template: (args) => <AskForm {...args} />,
};

export default meta;
