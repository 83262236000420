import { FC, useCallback, useState } from 'react';
import { ToDoAlert } from 'src/components/features/ToDoAlert';
import { Icon } from 'src/components/primitives/Icon';
import { api } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';

export const VerifyEmailAlert: FC = () => {
  const [isDone, setDone] = useState(false);
  const onResend = useCallback(async () => {
    await api.dispatch('POST', '/v1/accounts/me/resend_verification_email');
    setDone(true);
  }, [setDone]);
  const { i18n } = useI18n();
  return (
    <ToDoAlert variant="warning" embbed>
      <ToDoAlert.Body>
        <ToDoAlert.Heading>{i18n.t('label.please_verify_email')}</ToDoAlert.Heading>
        <ToDoAlert.Description>{i18n.t('guide.please_verify_email')}</ToDoAlert.Description>
      </ToDoAlert.Body>
      {isDone ? (
        <Icon fill="var(--color-text-hollow-out)" name="check_circle_outline" />
      ) : (
        <ToDoAlert.Button onClick={onResend}>{i18n.t('action.resend_verification_email')}</ToDoAlert.Button>
      )}
    </ToDoAlert>
  );
};
