import './StepIndicator.css';

import { Text } from 'src/components/primitives/Text';
import { FC, PropsWithChildren } from 'react';

type StepIndicatorDirection = 'row' | 'column';
interface StepIndicatorSubComponents {
  Item: FC<PropsWithChildren<{ active?: boolean }>>;
}

export const StepIndicator: FC<PropsWithChildren<{ direction?: StepIndicatorDirection }>> &
  StepIndicatorSubComponents = ({ direction = 'row', children }) => {
  return <ol className={`step-indicator ${direction}`}>{children}</ol>;
};

StepIndicator.Item = ({ children, active }) => {
  return (
    <Text asChild size="medium" className={`step-indicator-item ${active ? 'active' : ''}`}>
      <li>{children}</li>
    </Text>
  );
};
