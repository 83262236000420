import './HomeCarousel.css';

import { FC } from 'react';
import { useI18n } from 'src/lib/i18n';
import { Account } from 'src/models/v1/account';

const IMAGES: { urls: { src: string; srcset: string }; account: Pick<Account, 'display_name' | 'gid'> }[] = [
  {
    urls: {
      src: 'https://nagaku.com/image/home/hero_1_512.webp',
      srcset:
        'https://nagaku.com/image/home/hero_1_512.webp 512w, https://nagaku.com/image/home/hero_1_1024.webp 1024w, https://nagaku.com/image/home/hero_1_2048.webp 2048w',
    },
    account: { display_name: 'プラス デン ファニチャー', gid: '01HSQ2EZE36RDCXHX4WFG69Z0E' },
  },
];

export const HomeCurousel: FC = () => {
  const { i18n } = useI18n();
  return (
    <ul className="home-carousel">
      <li className="item">
        <img
          aria-hidden
          src={IMAGES[0].urls.src}
          srcSet={IMAGES[0].urls.srcset}
          sizes="(min-width: 748px) 388px, 100vw" // works with Layout.css
          alt={i18n.t('alt.photo_by', IMAGES[0].account)}
        />
        <p>
          プロや事例を探して
          <br />
          依頼できる
          <br />
          リペア・リメイクサービス
        </p>
      </li>
    </ul>
  );
};
