import './Error.css';

import { FC, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { isRouteErrorResponse, useRouteError } from 'react-router';
import ErrorBoundary from 'src/components/error/ErrorBoundary';
import { ErrorDescription } from 'src/components/error/ErrorDescription';
import { SimpleLayout } from 'src/components/Layout';
import { Text } from 'src/components/primitives/Text';
import { ApiError } from 'src/lib/errors';
import { useI18n } from 'src/lib/i18n';

import * as Sentry from '@sentry/react';

/**
 * use as errorElement of any routes.
 */
export const LoaderErrorBoundary: FC = () => {
  const error = useRouteError();
  if (import.meta.env.SSR) {
    if (error instanceof Error && error.message == 'refresh_access_token') {
      throw new Response(null, {
        status: 302,
        headers: {
          location: '/auth/refresh',
        },
      });
    }
  }
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return (
    <SimpleLayout>
      <div className="runtime-error-view">
        <ErrorBoundary>
          <ErrorCapture />
        </ErrorBoundary>
      </div>
    </SimpleLayout>
  );
};

const ErrorCapture: FC = () => {
  const error = useRouteError();
  const { i18n } = useI18n();

  const title = useMemo(() => {
    console.error(error);
    if (error instanceof ApiError) {
      return error.response.statusText;
    }
    if (error instanceof Error) {
      return error.name;
    }
    return 'Error';
  }, [error]);
  return (
    <>
      <Helmet>
        <title>
          {title} - {i18n.t('nagaku')}
        </title>
      </Helmet>
      {isRouteErrorResponse(error) && (
        <Text asChild variant="headline" weight="bold">
          <h1>
            {error.status} {error.statusText}
          </h1>
        </Text>
      )}
      <ErrorDescription error={error} m="lg" />
    </>
  );
};
