import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router';
import { VStack } from 'src/components/primitives/Stack';
import { PageLoader } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { Announcement } from 'src/models/v1/announcement';
import { Text } from 'src/components/primitives/Text';
import { HtmlContent } from 'src/components/primitives/HtmlContent';
import { Breadcrumb } from 'src/components/Breadcrumb';
import { NavigationLayout } from 'src/components/Layout';

type Data = {
  announcement: Announcement;
};

export const AnnouncementDetailPage: FC = () => {
  const { i18n } = useI18n();
  const { announcement } = useLoaderData() as Data;

  return (
    <NavigationLayout>
      <Helmet>
        <title>{`${announcement.title} | ${i18n.t('nagaku')}`}</title>
        <meta property="og:title" content={`${announcement.title} | ${i18n.t('nagaku')}`} />
        <meta name="description" content={announcement.body || ''} />
        <meta property="og:description" content={announcement.body || ''} />
      </Helmet>
      <Breadcrumb>
        <Breadcrumb.Item to="/news">{i18n.t('title.announcements')}</Breadcrumb.Item>
        <Breadcrumb.Item terminal>{announcement.title}</Breadcrumb.Item>
      </Breadcrumb>
      <VStack align="start">
        <Text color="sub">{announcement.published_date}</Text>
        <Text variant="title" size="large" weight="bold">
          {announcement.title}
        </Text>
        <Text>
          <HtmlContent html={announcement.html.body} />
        </Text>
      </VStack>
    </NavigationLayout>
  );
};

export const announcementsDetailLoader: PageLoader = async ({ params, api }) => {
  const { date, slug } = params;
  return await api.fetch(`/v1/announcements/${date}/${slug}`);
};
