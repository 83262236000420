import { ComponentProps, FC, PropsWithChildren, ReactNode, useContext } from 'react';
import { FieldValues, RegisterOptions, useFormContext } from 'react-hook-form';
import { ErrorMessage } from 'src/components/control/ErrorMessage';
import { FormOptionsContext } from 'src/components/control/Form';
import { FormControl } from 'src/components/control/FormControl';
import { Field } from 'src/components/primitives/Field';
import { AriaAttributesContext } from 'src/lib/a11y';
import { useI18n } from 'src/lib/i18n';

export const InputField: FC<
  PropsWithChildren<
    {
      name: string;
      id?: string;
      label?: ReactNode;
      options?: RegisterOptions<FieldValues, string>;
    } & Partial<
      Pick<
        ComponentProps<'input'>,
        | 'disabled'
        | 'type'
        | 'autoComplete'
        | 'autoFocus'
        | 'placeholder'
        | 'style'
        | 'maxLength'
        | 'minLength'
        | 'inputMode'
        | 'onKeyDown'
        | 'onPaste'
        | 'onCompositionStart'
        | 'onCompositionEnd'
      >
    >
  >
> = ({ name, id = name, label, type = 'text', placeholder, autoComplete = 'off', options, children, ...props }) => {
  const { i18n } = useI18n();
  const { register } = useFormContext<FieldValues>();
  const { readOnly } = useContext(FormOptionsContext);
  const a11yAttributes = useContext(AriaAttributesContext);

  return (
    <FormControl
      id={id}
      name={name}
      required={options?.required ? true : false}
      label={label || i18n.t(`attributes.${name}`)}
    >
      {children}
      <Field asChild className="input-field">
        <input
          id={id}
          type={type}
          autoComplete={autoComplete}
          readOnly={readOnly}
          placeholder={placeholder || (i18n.has(`placeholder.${name}`) ? i18n.t(`placeholder.${name}`) : '')}
          {...props}
          {...register(name, options)}
          {...a11yAttributes}
        />
      </Field>
      <ErrorMessage name={name} label={label} />
    </FormControl>
  );
};
