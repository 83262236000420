import './Footer.css';

import { Link } from 'react-router';
import { Text } from 'src/components/primitives/Text';
import { CareersView } from 'src/components/promote/CareersView';
import { ProPromoteView } from 'src/components/promote/ProPromoteView';
import { useI18n } from 'src/lib/i18n';

export const Footer = () => {
  const { i18n } = useI18n();
  return (
    <footer id="footer" data-nosnippet>
      <ProPromoteView />
      <CareersView />
      <ul className="menu" role="navigation">
        <Text asChild variant="body" weight="bold">
          <li>
            <Link to="/tags" className="link">
              {i18n.t('label.tags')}
            </Link>
          </li>
        </Text>
        <Text asChild variant="body" weight="bold">
          <li>
            <a href="https://mag.nagaku.com" target="_blank" rel="noreferrer" className="link">
              NAGAKU Magazine
            </a>
          </li>
        </Text>
        <Text asChild variant="body" weight="bold">
          <li>
            <Link to="/help" className="link">
              {i18n.t('label.help_and_contact_us')}
            </Link>
          </li>
        </Text>
        <Text asChild variant="body" weight="bold">
          <li>
            <a href="https://corp.nagaku.com" target="_blank" rel="noreferrer" className="link">
              {i18n.t('link.about_us')}
            </a>
          </li>
        </Text>
      </ul>
      <div className="info">
        <Text asChild variant="body" size="small">
          <p>© 2025 NAGAKU Inc.</p>
        </Text>
        <Text size="small" weight="bold">
          <Link to="/privacy_policy" className="link">
            {i18n.t('link.privacy_policy')}
          </Link>
          {' · '}
          <Link to="/terms_of_service" className="link">
            {i18n.t('link.terms_of_service')}
          </Link>
          {' · '}
          <Link to="/japanese_act_on_specified_transactions" className="link">
            {i18n.t('link.japanese_act_on_specified_transactions')}
          </Link>
        </Text>
      </div>
    </footer>
  );
};
