import './Primitive.css';

export type Sizes =
  | '4xl' // 32px
  | '3xl' // 28px
  | '2xl' // 24px
  | 'xl' // 20px
  | 'lg' // 16px
  | 'md' // 12px
  | 'sm' // 8px
  | 'xs'; // 4px

export type GlobalValues = 'inherit' | 'initial' | 'revert' | 'revert-layer' | 'unset';

export type SpacingValue = Sizes | 'none'; // 0px

export type MarginProps = {
  /**
   * Margin
   */
  m?: SpacingValue | 'auto';
  /**
   * Margin Y (Top and Bottom)
   */
  my?: SpacingValue | 'auto';
  /**
   * Margin X (Left and Right, or Start and End)
   */
  mx?: SpacingValue | 'auto';
  /**
   * Margin Top
   */
  mt?: SpacingValue | 'auto';
  /**
   * Margin Bottom
   */
  mb?: SpacingValue | 'auto';
  /**
   * Margin Start
   */
  ms?: SpacingValue | 'auto';
  /**
   * Margin End
   */
  me?: SpacingValue | 'auto';
  /**
   * Margin Left
   */
  ml?: SpacingValue | 'auto';
  /**
   * Margin Right
   */
  mr?: SpacingValue | 'auto';
};

export type PaddingProps = {
  /**
   * Padding
   */
  p?: SpacingValue;
  /**
   * Padding Y (Top and Bottom)
   */
  py?: SpacingValue;
  /**
   * Padding X (Left and Right, or Start and End)
   */
  px?: SpacingValue;
  /**
   * Padding Top
   */
  pt?: SpacingValue;
  /**
   * Padding Bottom
   */
  pb?: SpacingValue;
  /**
   * Padding Start
   */
  ps?: SpacingValue;
  /**
   * Padding End
   */
  pe?: SpacingValue;
  /**
   * Padding Left
   */
  pl?: SpacingValue;
  /**
   * Padding Right
   */
  pr?: SpacingValue;
};

export type BoxSizeValue = 'full' | 'auto' | 'fit-content';

export type BoxSizeProps = {
  w?: BoxSizeValue;
  h?: BoxSizeValue;
};

export type BoxProps = MarginProps & PaddingProps & BoxSizeProps;
type BoxStyle = {
  className: string[];
};

export const styleBoxProps = <BaseProps extends {} = BoxProps>(props: BaseProps & { className?: string }) => {
  const style: BoxStyle = { className: props.className ? [props.className] : [] };

  if ('m' in props && props.m) {
    style.className.push(`margin-${props.m}`);
  }

  if ('my' in props && props.my) {
    style.className.push(`margin-y-${props.my}`);
  }

  if ('mx' in props && props.mx) {
    style.className.push(`margin-x-${props.mx}`);
  }

  if ('mt' in props && props.mt) {
    style.className.push(`margin-top-${props.mt}`);
  }

  if ('mb' in props && props.mb) {
    style.className.push(`margin-bottom-${props.mb}`);
  }

  if ('ml' in props && props.ml) {
    style.className.push(`margin-left-${props.ml}`);
  }

  if ('mr' in props && props.mr) {
    style.className.push(`margin-right-${props.mr}`);
  }

  if ('ms' in props && props.ms) {
    style.className.push(`margin-start-${props.ms}`);
  }

  if ('me' in props && props.me) {
    style.className.push(`margin-end-${props.me}`);
  }

  if ('p' in props && props.p) {
    style.className.push(`padding-${props.p}`);
  }

  if ('py' in props && props.py) {
    style.className.push(`padding-y-${props.py}`);
  }

  if ('px' in props && props.px) {
    style.className.push(`padding-x-${props.px}`);
  }

  if ('pt' in props && props.pt) {
    style.className.push(`padding-top-${props.pt}`);
  }

  if ('pb' in props && props.pb) {
    style.className.push(`padding-bottom-${props.pb}`);
  }

  if ('pl' in props && props.pl) {
    style.className.push(`padding-left-${props.pl}`);
  }

  if ('pr' in props && props.pr) {
    style.className.push(`padding-right-${props.pr}`);
  }

  if ('ps' in props && props.ps) {
    style.className.push(`padding-start-${props.ps}`);
  }

  if ('pe' in props && props.pe) {
    style.className.push(`padding-end-${props.pe}`);
  }

  if ('w' in props && props.w && props.w != 'auto') {
    style.className.push(`width-${props.w}`);
  }

  if ('h' in props && props.h && props.h != 'auto') {
    style.className.push(`height-${props.h}`);
  }

  return {
    ...props,
    className: style.className.join(' '),
  };
};
