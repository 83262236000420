import { useEffect, useRef } from 'react';

type Drag = {
  start: {
    mouse: { x: number; y: number };
    scroll: { left: number; top: number };
  };
};

export const useDragScroll = () => {
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (targetRef.current === null) return;

    const target = targetRef.current;
    let drag: Drag | null = null;
    let scrolled = false;

    function onMouseDown(event: MouseEvent) {
      event.preventDefault();
      const currentTarget = event.currentTarget as HTMLDivElement;
      drag = {
        start: {
          mouse: { x: event.clientX, y: event.clientY },
          scroll: { left: currentTarget.scrollLeft, top: currentTarget.scrollTop },
        },
      };
    }

    function onClick(event: MouseEvent) {
      if (scrolled) {
        event.preventDefault();
        event.stopPropagation();
      }
    }

    function onMouseMove(event: MouseEvent) {
      if (drag !== null) {
        target.scrollLeft = drag.start.scroll.left + drag.start.mouse.x - event.clientX;
        target.scrollTop = drag.start.scroll.top + drag.start.mouse.y - event.clientY;
      }
    }

    function onMouseUp(event: MouseEvent) {
      scrolled = false;
      if (drag !== null) {
        const move = Math.sqrt((drag.start.mouse.x - event.clientX) ** 2 + (drag.start.mouse.y - event.clientY) ** 2);
        if (move >= 10) {
          scrolled = true;
        }
        drag = null;
      }
    }

    target.addEventListener('mousedown', onMouseDown);
    target.addEventListener('click', onClick, true);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      target.removeEventListener('mousedown', onMouseDown);
      target.removeEventListener('click', onClick, true);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [targetRef.current]);

  return {
    targetRef,
  };
};
