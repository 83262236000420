import { ComponentProps, FC } from 'react';
import { Icon } from 'src/components/primitives/Icon';
import { useI18n } from 'src/lib/i18n';
import { AuthRequiredButton } from 'src/components/features/AuthRequiredButton';
import { LinkButton } from 'src/components/primitives/Button';

export const PostButton: FC<Omit<ComponentProps<typeof LinkButton>, 'icon' | 'to' | 'variant'>> = ({ ...props }) => {
  const { i18n } = useI18n();

  return (
    <AuthRequiredButton returnTo="/works/new">
      <LinkButton icon={<Icon name="photo_camera" aria-label="Post" />} to="/works/new" variant="primary" {...props}>
        {i18n.t('action.post')}
      </LinkButton>
    </AuthRequiredButton>
  );
};
