/// <reference types="vite-plugin-svgr/client" />

import { FC, SVGAttributes } from 'react';
import ChairTwoTone from 'src/icons/chair_two_tone.svg?react';

import AccessTime from '@nagakuinc/icons/svg/access_time.svg?react';
import AccessTimeFill from '@nagakuinc/icons/svg/access_time-fill.svg?react';
import Add from '@nagakuinc/icons/svg/add.svg?react';
import AddFill from '@nagakuinc/icons/svg/add-fill.svg?react';
import AddPhotoAlternate from '@nagakuinc/icons/svg/add_photo_alternate.svg?react';
import AddPhotoAlternateFill from '@nagakuinc/icons/svg/add_photo_alternate-fill.svg?react';
import Adjust from '@nagakuinc/icons/svg/adjust.svg?react';
import AdjustFill from '@nagakuinc/icons/svg/adjust-fill.svg?react';
import Archive from '@nagakuinc/icons/svg/archive.svg?react';
import ArchiveFill from '@nagakuinc/icons/svg/archive-fill.svg?react';
import ArrowBackIosNew from '@nagakuinc/icons/svg/arrow_back_ios_new.svg?react';
import ArrowBackIosNewFill from '@nagakuinc/icons/svg/arrow_back_ios_new-fill.svg?react';
import ArrowDropDown from '@nagakuinc/icons/svg/arrow_drop_down.svg?react';
import ArrowDropDownFill from '@nagakuinc/icons/svg/arrow_drop_down-fill.svg?react';
import ArrowForward from '@nagakuinc/icons/svg/arrow_forward.svg?react';
import ArrowForwardFill from '@nagakuinc/icons/svg/arrow_forward-fill.svg?react';
import Article from '@nagakuinc/icons/svg/article.svg?react';
import ArticleFill from '@nagakuinc/icons/svg/article-fill.svg?react';
import AttachFile from '@nagakuinc/icons/svg/attach_file.svg?react';
import AttachFileFill from '@nagakuinc/icons/svg/attach_file-fill.svg?react';
import Attachment from '@nagakuinc/icons/svg/attachment.svg?react';
import AttachmentFill from '@nagakuinc/icons/svg/attachment-fill.svg?react';
import BuildCircle from '@nagakuinc/icons/svg/build_circle.svg?react';
import BuildCircleFill from '@nagakuinc/icons/svg/build_circle-fill.svg?react';
import CalendarMonth from '@nagakuinc/icons/svg/calendar_month.svg?react';
import CalendarMonthFill from '@nagakuinc/icons/svg/calendar_month-fill.svg?react';
import Cancel from '@nagakuinc/icons/svg/cancel.svg?react';
import CancelFill from '@nagakuinc/icons/svg/cancel-fill.svg?react';
import CheckCircle from '@nagakuinc/icons/svg/check_circle.svg?react';
import CheckCircleFill from '@nagakuinc/icons/svg/check_circle-fill.svg?react';
import CheckCircleOutline from '@nagakuinc/icons/svg/check_circle_outline.svg?react';
import ChevronLeft from '@nagakuinc/icons/svg/chevron_left.svg?react';
import ChevronLeftFill from '@nagakuinc/icons/svg/chevron_left-fill.svg?react';
import ChevronRight from '@nagakuinc/icons/svg/chevron_right.svg?react';
import ChevronRightFill from '@nagakuinc/icons/svg/chevron_right-fill.svg?react';
import Close from '@nagakuinc/icons/svg/close.svg?react';
import CloseFill from '@nagakuinc/icons/svg/close-fill.svg?react';
import Construction from '@nagakuinc/icons/svg/construction.svg?react';
import ConstructionFill from '@nagakuinc/icons/svg/construction-fill.svg?react';
import CopyAll from '@nagakuinc/icons/svg/copy_all.svg?react';
import CopyAllFill from '@nagakuinc/icons/svg/copy_all-fill.svg?react';
import CurrencyYen from '@nagakuinc/icons/svg/currency_yen.svg?react';
import CurrencyYenFill from '@nagakuinc/icons/svg/currency_yen-fill.svg?react';
import Delete from '@nagakuinc/icons/svg/delete.svg?react';
import DeleteFill from '@nagakuinc/icons/svg/delete-fill.svg?react';
import DoNotDisturb from '@nagakuinc/icons/svg/do_not_disturb.svg?react';
import DoNotDisturbFill from '@nagakuinc/icons/svg/do_not_disturb-fill.svg?react';
import DoubleArrow from '@nagakuinc/icons/svg/double_arrow.svg?react';
import DoubleArrowFill from '@nagakuinc/icons/svg/double_arrow-fill.svg?react';
import Edit from '@nagakuinc/icons/svg/edit.svg?react';
import EditFill from '@nagakuinc/icons/svg/edit-fill.svg?react';
import Email from '@nagakuinc/icons/svg/email.svg?react';
import EmailFill from '@nagakuinc/icons/svg/email-fill.svg?react';
import Error from '@nagakuinc/icons/svg/error_outline.svg?react';
import ErrorFill from '@nagakuinc/icons/svg/error_outline-fill.svg?react';
import ExpandMore from '@nagakuinc/icons/svg/expand_more.svg?react';
import ExpandMoreFill from '@nagakuinc/icons/svg/expand_more-fill.svg?react';
import Favorite from '@nagakuinc/icons/svg/favorite.svg?react';
import FavoriteFill from '@nagakuinc/icons/svg/favorite-fill.svg?react';
import FavoriteBorder from '@nagakuinc/icons/svg/favorite_border.svg?react';
import FavoriteBorderFill from '@nagakuinc/icons/svg/favorite_border-fill.svg?react';
import Forest from '@nagakuinc/icons/svg/forest.svg?react';
import ForestFill from '@nagakuinc/icons/svg/forest-fill.svg?react';
import Handshake from '@nagakuinc/icons/svg/handshake.svg?react';
import HandshakeFill from '@nagakuinc/icons/svg/handshake-fill.svg?react';
import Highlight from '@nagakuinc/icons/svg/highlight.svg?react';
import HighlightFill from '@nagakuinc/icons/svg/highlight-fill.svg?react';
import HighlightOff from '@nagakuinc/icons/svg/highlight_off.svg?react';
import HighlightOffFill from '@nagakuinc/icons/svg/highlight_off-fill.svg?react';
import Home from '@nagakuinc/icons/svg/home.svg?react';
import HomeFill from '@nagakuinc/icons/svg/home-fill.svg?react';
import Hourglass from '@nagakuinc/icons/svg/hourglass_empty.svg?react';
import HourglassFill from '@nagakuinc/icons/svg/hourglass_empty-fill.svg?react';
import Image from '@nagakuinc/icons/svg/image.svg?react';
import ImageFill from '@nagakuinc/icons/svg/image-fill.svg?react';
import Info from '@nagakuinc/icons/svg/info.svg?react';
import InfoFill from '@nagakuinc/icons/svg/info-fill.svg?react';
import KeyboardArrowDown from '@nagakuinc/icons/svg/keyboard_arrow_down.svg?react';
import KeyboardArrowDownFill from '@nagakuinc/icons/svg/keyboard_arrow_down-fill.svg?react';
import KeyboardArrowLeft from '@nagakuinc/icons/svg/keyboard_arrow_left.svg?react';
import KeyboardArrowLeftFill from '@nagakuinc/icons/svg/keyboard_arrow_left-fill.svg?react';
import KeyboardArrowRight from '@nagakuinc/icons/svg/keyboard_arrow_right.svg?react';
import KeyboardArrowRightFill from '@nagakuinc/icons/svg/keyboard_arrow_right-fill.svg?react';
import KeyboardArrowUp from '@nagakuinc/icons/svg/keyboard_arrow_up.svg?react';
import KeyboardArrowUpFill from '@nagakuinc/icons/svg/keyboard_arrow_up-fill.svg?react';
import Layers from '@nagakuinc/icons/svg/layers.svg?react';
import LayersFill from '@nagakuinc/icons/svg/layers-fill.svg?react';
import ListAlt from '@nagakuinc/icons/svg/list_alt.svg?react';
import ListAltFill from '@nagakuinc/icons/svg/list_alt-fill.svg?react';
import Login from '@nagakuinc/icons/svg/login.svg?react';
import LoginFill from '@nagakuinc/icons/svg/login-fill.svg?react';
import LocalShipping from '@nagakuinc/icons/svg/local_shipping.svg?react';
import LocalShippingFill from '@nagakuinc/icons/svg/local_shipping-fill.svg?react';
import LocationOn from '@nagakuinc/icons/svg/location_on.svg?react';
import LocationOnFill from '@nagakuinc/icons/svg/location_on-fill.svg?react';
import Lock from '@nagakuinc/icons/svg/lock.svg?react';
import LockFill from '@nagakuinc/icons/svg/lock-fill.svg?react';
import MapsHomeWork from '@nagakuinc/icons/svg/maps_home_work.svg?react';
import MapsHomeWorkFill from '@nagakuinc/icons/svg/maps_home_work-fill.svg?react';
import MarkEmailRead from '@nagakuinc/icons/svg/mark_email_read.svg?react';
import MarkEmailReadFill from '@nagakuinc/icons/svg/mark_email_read-fill.svg?react';
import MobileFriendly from '@nagakuinc/icons/svg/mobile_friendly.svg?react';
import MobileFriendlyFill from '@nagakuinc/icons/svg/mobile_friendly-fill.svg?react';
import MoreHoriz from '@nagakuinc/icons/svg/more_horiz.svg?react';
import MoreHorizFill from '@nagakuinc/icons/svg/more_horiz-fill.svg?react';
import MoreVert from '@nagakuinc/icons/svg/more_vert.svg?react';
import MoreVertFill from '@nagakuinc/icons/svg/more_vert-fill.svg?react';
import Notifications from '@nagakuinc/icons/svg/notifications.svg?react';
import NotificationsFill from '@nagakuinc/icons/svg/notifications-fill.svg?react';
import PartyMode from '@nagakuinc/icons/svg/party_mode.svg?react';
import PartyModeFill from '@nagakuinc/icons/svg/party_mode-fill.svg?react';
import Payment from '@nagakuinc/icons/svg/payment.svg?react';
import PaymentFill from '@nagakuinc/icons/svg/payment-fill.svg?react';
import Person from '@nagakuinc/icons/svg/person.svg?react';
import PersonFill from '@nagakuinc/icons/svg/person-fill.svg?react';
import PersonAdd from '@nagakuinc/icons/svg/person_add.svg?react';
import PersonAddFill from '@nagakuinc/icons/svg/person_add-fill.svg?react';
import PersonSearch from '@nagakuinc/icons/svg/person_search.svg?react';
import PersonSearchFill from '@nagakuinc/icons/svg/person_search-fill.svg?react';
import PhotoCamera from '@nagakuinc/icons/svg/photo_camera.svg?react';
import PhotoCameraFill from '@nagakuinc/icons/svg/photo_camera-fill.svg?react';
import QuestionAnswer from '@nagakuinc/icons/svg/question_answer.svg?react';
import QuestionAnswerFill from '@nagakuinc/icons/svg/question_answer-fill.svg?react';
import Search from '@nagakuinc/icons/svg/search.svg?react';
import SearchFill from '@nagakuinc/icons/svg/search-fill.svg?react';
import Send from '@nagakuinc/icons/svg/send.svg?react';
import SendFill from '@nagakuinc/icons/svg/send-fill.svg?react';
import SensorDoor from '@nagakuinc/icons/svg/sensor_door.svg?react';
import SensorDoorFill from '@nagakuinc/icons/svg/sensor_door-fill.svg?react';
import Settings from '@nagakuinc/icons/svg/settings.svg?react';
import SettingsFill from '@nagakuinc/icons/svg/settings-fill.svg?react';
import SliderArrow from '@nagakuinc/icons/svg/slider_arrow.svg?react';
import Stars from '@nagakuinc/icons/svg/stars.svg?react';
import StarsFill from '@nagakuinc/icons/svg/stars-fill.svg?react';
import SwitchLeft from '@nagakuinc/icons/svg/switch_left.svg?react';
import SwitchLeftFill from '@nagakuinc/icons/svg/switch_left-fill.svg?react';
import SwitchRight from '@nagakuinc/icons/svg/switch_right.svg?react';
import SwitchRightFill from '@nagakuinc/icons/svg/switch_right-fill.svg?react';
import SymbolPro from '@nagakuinc/icons/svg/symbol_pro.svg?react';
import Tag from '@nagakuinc/icons/svg/tag.svg?react';
import TagFill from '@nagakuinc/icons/svg/tag-fill.svg?react';
import Verified from '@nagakuinc/icons/svg/verified.svg?react';
import VerifiedFill from '@nagakuinc/icons/svg/verified-fill.svg?react';

type IconName =
  | 'access_time'
  | 'access_time_fill'
  | 'add'
  | 'add_fill'
  | 'add_photo_alternate'
  | 'add_photo_alternate_fill'
  | 'adjust'
  | 'adjust_fill'
  | 'archive'
  | 'archive_fill'
  | 'arrow_back_ios_new'
  | 'arrow_back_ios_new_fill'
  | 'arrow_drop_down'
  | 'arrow_drop_down_fill'
  | 'arrow_forward'
  | 'arrow_forward_fill'
  | 'article'
  | 'article_fill'
  | 'attach_file'
  | 'attach_file_fill'
  | 'attachment'
  | 'attachment_fill'
  | 'build_circle'
  | 'build_circle_fill'
  | 'calendar_month'
  | 'calendar_month_fill'
  | 'cancel'
  | 'cancel_fill'
  | 'chair'
  | 'chair_fill'
  | 'check_circle'
  | 'check_circle_fill'
  | 'check_circle_outline'
  | 'chevron_left'
  | 'chevron_left_fill'
  | 'chevron_right'
  | 'chevron_right_fill'
  | 'close'
  | 'close_fill'
  | 'construction'
  | 'construction_fill'
  | 'copy_all'
  | 'copy_all_fill'
  | 'currency_yen'
  | 'currency_yen_fill'
  | 'delete'
  | 'delete_fill'
  | 'do_not_disturb'
  | 'do_not_disturb_fill'
  | 'double_arrow'
  | 'double_arrow_fill'
  | 'edit'
  | 'edit_fill'
  | 'email'
  | 'email_fill'
  | 'error'
  | 'error_fill'
  | 'expand_more'
  | 'expand_more_fill'
  | 'favorite'
  | 'favorite_fill'
  | 'favorite_border'
  | 'favorite_border_fill'
  | 'forest'
  | 'forest_fill'
  | 'handshake'
  | 'handshake_fill'
  | 'highlight'
  | 'highlight_fill'
  | 'highlight_off'
  | 'highlight_off_fill'
  | 'home'
  | 'home_fill'
  | 'hourglass'
  | 'hourglass_fill'
  | 'image'
  | 'image_fill'
  | 'info'
  | 'info_fill'
  | 'keyboard_arrow_down'
  | 'keyboard_arrow_down_fill'
  | 'keyboard_arrow_left'
  | 'keyboard_arrow_left_fill'
  | 'keyboard_arrow_right'
  | 'keyboard_arrow_right_fill'
  | 'keyboard_arrow_up'
  | 'keyboard_arrow_up_fill'
  | 'layers'
  | 'layers_fill'
  | 'list_alt'
  | 'list_alt_fill'
  | 'login'
  | 'login_fill'
  | 'local_shipping'
  | 'local_shipping_fill'
  | 'location_on'
  | 'location_on_fill'
  | 'lock'
  | 'lock_fill'
  | 'maps_home_work'
  | 'maps_home_work_fill'
  | 'mark_email_read'
  | 'mark_email_read_fill'
  | 'mobile_friendly'
  | 'mobile_friendly_fill'
  | 'more_horiz'
  | 'more_horiz_fill'
  | 'more_vert'
  | 'more_vert_fill'
  | 'notifications'
  | 'notifications_fill'
  | 'party_mode'
  | 'party_mode_fill'
  | 'payment'
  | 'payment_fill'
  | 'person'
  | 'person_fill'
  | 'person_add'
  | 'person_add_fill'
  | 'person_search'
  | 'person_search_fill'
  | 'photo_camera'
  | 'photo_camera_fill'
  | 'question_answer'
  | 'question_answer_fill'
  | 'search'
  | 'search_fill'
  | 'send'
  | 'send_fill'
  | 'sensor_door'
  | 'sensor_door_fill'
  | 'settings'
  | 'settings_fill'
  | 'slider_arrow'
  | 'slider_arrow_fill'
  | 'stars'
  | 'stars_fill'
  | 'switch_left'
  | 'switch_left_fill'
  | 'switch_right'
  | 'switch_right_fill'
  | 'symbol_pro'
  | 'symbol_pro_fill'
  | 'tag'
  | 'tag_fill'
  | 'verified'
  | 'verified_fill';

export const Icon: FC<{ name: IconName } & SVGAttributes<SVGSVGElement>> = ({ name, ...props }) => {
  switch (name) {
    case 'access_time':
      return <AccessTime {...props} />;
    case 'access_time_fill':
      return <AccessTimeFill {...props} />;
    case 'add':
      return <Add {...props} />;
    case 'add_fill':
      return <AddFill {...props} />;
    case 'add_photo_alternate':
      return <AddPhotoAlternate {...props} />;
    case 'add_photo_alternate_fill':
      return <AddPhotoAlternateFill {...props} />;
    case 'adjust':
      return <Adjust {...props} />;
    case 'adjust_fill':
      return <AdjustFill {...props} />;
    case 'archive':
      return <Archive {...props} />;
    case 'archive_fill':
      return <ArchiveFill {...props} />;
    case 'arrow_back_ios_new':
      return <ArrowBackIosNew {...props} />;
    case 'arrow_back_ios_new_fill':
      return <ArrowBackIosNewFill {...props} />;
    case 'arrow_drop_down':
      return <ArrowDropDown {...props} />;
    case 'arrow_drop_down_fill':
      return <ArrowDropDownFill {...props} />;
    case 'arrow_forward':
      return <ArrowForward {...props} />;
    case 'arrow_forward_fill':
      return <ArrowForwardFill {...props} />;
    case 'article':
      return <Article {...props} />;
    case 'article_fill':
      return <ArticleFill {...props} />;
    case 'attach_file':
      return <AttachFile {...props} />;
    case 'attach_file_fill':
      return <AttachFileFill {...props} />;
    case 'attachment':
      return <Attachment {...props} />;
    case 'attachment_fill':
      return <AttachmentFill {...props} />;
    case 'build_circle':
      return <BuildCircle {...props} />;
    case 'build_circle_fill':
      return <BuildCircleFill {...props} />;
    case 'calendar_month':
      return <CalendarMonth {...props} />;
    case 'calendar_month_fill':
      return <CalendarMonthFill {...props} />;
    case 'cancel':
      return <Cancel {...props} />;
    case 'cancel_fill':
      return <CancelFill {...props} />;
    case 'chair':
      return <ChairTwoTone {...props} />;
    case 'check_circle':
      return <CheckCircle {...props} />;
    case 'check_circle_fill':
      return <CheckCircleFill {...props} />;
    case 'check_circle_outline':
      return <CheckCircleOutline {...props} />;
    case 'chevron_left':
      return <ChevronLeft {...props} />;
    case 'chevron_left_fill':
      return <ChevronLeftFill {...props} />;
    case 'chevron_right':
      return <ChevronRight {...props} />;
    case 'chevron_right_fill':
      return <ChevronRightFill {...props} />;
    case 'close':
      return <Close {...props} />;
    case 'close_fill':
      return <CloseFill {...props} />;
    case 'construction':
      return <Construction {...props} />;
    case 'construction_fill':
      return <ConstructionFill {...props} />;
    case 'copy_all':
      return <CopyAll {...props} />;
    case 'copy_all_fill':
      return <CopyAllFill {...props} />;
    case 'currency_yen':
      return <CurrencyYen {...props} />;
    case 'currency_yen_fill':
      return <CurrencyYenFill {...props} />;
    case 'delete':
      return <Delete {...props} />;
    case 'delete_fill':
      return <DeleteFill {...props} />;
    case 'do_not_disturb':
      return <DoNotDisturb {...props} />;
    case 'do_not_disturb_fill':
      return <DoNotDisturbFill {...props} />;
    case 'double_arrow':
      return <DoubleArrow {...props} />;
    case 'double_arrow_fill':
      return <DoubleArrowFill {...props} />;
    case 'edit':
      return <Edit {...props} />;
    case 'edit_fill':
      return <EditFill {...props} />;
    case 'email':
      return <Email {...props} />;
    case 'email_fill':
      return <EmailFill {...props} />;
    case 'error':
      return <Error {...props} />;
    case 'error_fill':
      return <ErrorFill {...props} />;
    case 'expand_more':
      return <ExpandMore {...props} />;
    case 'expand_more_fill':
      return <ExpandMoreFill {...props} />;
    case 'favorite':
      return <Favorite {...props} />;
    case 'favorite_fill':
      return <FavoriteFill {...props} />;
    case 'favorite_border':
      return <FavoriteBorder {...props} />;
    case 'favorite_border_fill':
      return <FavoriteBorderFill {...props} />;
    case 'forest':
      return <Forest {...props} />;
    case 'forest_fill':
      return <ForestFill {...props} />;
    case 'handshake':
      return <Handshake {...props} />;
    case 'handshake_fill':
      return <HandshakeFill {...props} />;
    case 'highlight':
      return <Highlight {...props} />;
    case 'highlight_fill':
      return <HighlightFill {...props} />;
    case 'highlight_off':
      return <HighlightOff {...props} />;
    case 'highlight_off_fill':
      return <HighlightOffFill {...props} />;
    case 'home':
      return <Home {...props} />;
    case 'home_fill':
      return <HomeFill {...props} />;
    case 'hourglass':
      return <Hourglass {...props} />;
    case 'hourglass_fill':
      return <HourglassFill {...props} />;
    case 'image':
      return <Image {...props} />;
    case 'image_fill':
      return <ImageFill {...props} />;
    case 'info':
      return <Info {...props} />;
    case 'info_fill':
      return <InfoFill {...props} />;
    case 'keyboard_arrow_down':
      return <KeyboardArrowDown {...props} />;
    case 'keyboard_arrow_down_fill':
      return <KeyboardArrowDownFill {...props} />;
    case 'keyboard_arrow_left':
      return <KeyboardArrowLeft {...props} />;
    case 'keyboard_arrow_left_fill':
      return <KeyboardArrowLeftFill {...props} />;
    case 'keyboard_arrow_right':
      return <KeyboardArrowRight {...props} />;
    case 'keyboard_arrow_right_fill':
      return <KeyboardArrowRightFill {...props} />;
    case 'keyboard_arrow_up':
      return <KeyboardArrowUp {...props} />;
    case 'keyboard_arrow_up_fill':
      return <KeyboardArrowUpFill {...props} />;
    case 'layers':
      return <Layers {...props} />;
    case 'layers_fill':
      return <LayersFill {...props} />;
    case 'list_alt':
      return <ListAlt {...props} />;
    case 'list_alt_fill':
      return <ListAltFill {...props} />;
    case 'login':
      return <Login {...props} />;
    case 'login_fill':
      return <LoginFill {...props} />;
    case 'local_shipping':
      return <LocalShipping {...props} />;
    case 'local_shipping_fill':
      return <LocalShippingFill {...props} />;
    case 'location_on':
      return <LocationOn {...props} />;
    case 'location_on_fill':
      return <LocationOnFill {...props} />;
    case 'lock':
      return <Lock {...props} />;
    case 'lock_fill':
      return <LockFill {...props} />;
    case 'maps_home_work':
      return <MapsHomeWork {...props} />;
    case 'maps_home_work_fill':
      return <MapsHomeWorkFill {...props} />;
    case 'mark_email_read':
      return <MarkEmailRead {...props} />;
    case 'mark_email_read_fill':
      return <MarkEmailReadFill {...props} />;
    case 'mobile_friendly':
      return <MobileFriendly {...props} />;
    case 'mobile_friendly_fill':
      return <MobileFriendlyFill {...props} />;
    case 'more_horiz':
      return <MoreHoriz {...props} />;
    case 'more_horiz_fill':
      return <MoreHorizFill {...props} />;
    case 'more_vert':
      return <MoreVert {...props} />;
    case 'more_vert_fill':
      return <MoreVertFill {...props} />;
    case 'notifications':
      return <Notifications {...props} />;
    case 'notifications_fill':
      return <NotificationsFill {...props} />;
    case 'party_mode':
      return <PartyMode {...props} />;
    case 'party_mode_fill':
      return <PartyModeFill {...props} />;
    case 'payment':
      return <Payment {...props} />;
    case 'payment_fill':
      return <PaymentFill {...props} />;
    case 'person':
      return <Person {...props} />;
    case 'person_fill':
      return <PersonFill {...props} />;
    case 'person_add':
      return <PersonAdd {...props} />;
    case 'person_add_fill':
      return <PersonAddFill {...props} />;
    case 'person_search':
      return <PersonSearch {...props} />;
    case 'person_search_fill':
      return <PersonSearchFill {...props} />;
    case 'photo_camera':
      return <PhotoCamera {...props} />;
    case 'photo_camera_fill':
      return <PhotoCameraFill {...props} />;
    case 'question_answer':
      return <QuestionAnswer {...props} />;
    case 'question_answer_fill':
      return <QuestionAnswerFill {...props} />;
    case 'search':
      return <Search {...props} />;
    case 'search_fill':
      return <SearchFill {...props} />;
    case 'send':
      return <Send {...props} />;
    case 'send_fill':
      return <SendFill {...props} />;
    case 'sensor_door':
      return <SensorDoor {...props} />;
    case 'sensor_door_fill':
      return <SensorDoorFill {...props} />;
    case 'settings':
      return <Settings {...props} />;
    case 'settings_fill':
      return <SettingsFill {...props} />;
    case 'slider_arrow':
      return <SliderArrow {...props} />;
    case 'stars':
      return <Stars {...props} />;
    case 'stars_fill':
      return <StarsFill {...props} />;
    case 'switch_left':
      return <SwitchLeft {...props} />;
    case 'switch_left_fill':
      return <SwitchLeftFill {...props} />;
    case 'switch_right':
      return <SwitchRight {...props} />;
    case 'switch_right_fill':
      return <SwitchRightFill {...props} />;
    case 'symbol_pro':
      return <SymbolPro {...props} />;
    case 'tag':
      return <Tag {...props} />;
    case 'tag_fill':
      return <TagFill {...props} />;
    case 'verified':
      return <Verified {...props} />;
    case 'verified_fill':
      return <VerifiedFill {...props} />;
  }
};
