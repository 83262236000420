import { FC, useCallback, useEffect, useState } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { FormFieldSuggestion } from 'src/components/control/FormFieldSuggestion';
import { InputField } from 'src/components/control/InputField';
import { Text } from 'src/components/primitives/Text';
import { api } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { validateMinLengthOption } from 'src/lib/validation';
import { Bank } from 'src/models/v1/bank';

type PartialBank = Pick<Bank, 'name' | 'routing_number'> & { value: string };

export const BankAccountField: FC<{
  namePrefix: string;
  required?: boolean;
}> = ({ namePrefix, required = false }) => {
  const { i18n } = useI18n();
  const { watch, getValues, setValue } = useFormContext<FieldValues>();
  const bankNumberName = namePrefix.concat('.bank_number');
  const bankNumber = watch(bankNumberName);
  const defaultBank = bankNumber
    ? { name: getValues(namePrefix.concat('.bank_name')), routing_number: bankNumber, value: bankNumber }
    : null;
  const onSetRoutingNumber = useCallback(
    (bank: PartialBank | null) => {
      if (bank) {
        setValue(`${namePrefix}.bank_name`, bank.name, { shouldDirty: true, shouldValidate: true });
      }
      setValue(`${namePrefix}.bank_number`, bank?.routing_number, { shouldDirty: true, shouldValidate: true });
    },
    [setValue],
  );
  return (
    <>
      <BankNameField
        name={namePrefix.concat('.bank_name')}
        onSetRoutingNumber={onSetRoutingNumber}
        defaultBank={defaultBank}
        required={required}
      />
      {bankNumber && (
        <>
          <InputField
            name={namePrefix.concat('.branch_number')}
            inputMode="decimal"
            options={{
              required: true,
              minLength: validateMinLengthOption({ name: namePrefix.concat('.branch_number'), minLength: 3, i18n }),
            }}
            maxLength={3}
          />
          <InputField
            name={namePrefix.concat('.account_number')}
            inputMode="decimal"
            options={{
              required: true,
              minLength: validateMinLengthOption({ name: namePrefix.concat('.branch_number'), minLength: 6, i18n }),
              onBlur: () =>
                setValue(`${namePrefix}.account_number`, getValues(`${namePrefix}.account_number`).padStart(7, '0'), {
                  shouldValidate: true,
                }),
            }}
            maxLength={7}
          />
          <InputField
            name={namePrefix.concat('.account_holder_name')}
            options={{
              required: true,
              pattern: {
                value: /^[\u3040-\u309F\u30A0-\u30FFー\uff08\uff09\u3000]+$/,
                message: i18n.t('validation.kana', { name: i18n.t(`attributes.${namePrefix}.account_holder_name`) }),
              },
            }}
          />
        </>
      )}
    </>
  );
};

const BankNameField: FC<{
  name: string;
  defaultBank: PartialBank | null;
  onSetRoutingNumber: (value: PartialBank | null) => void;
  required?: boolean;
}> = ({ name, defaultBank, onSetRoutingNumber, required = false }) => {
  const { i18n } = useI18n();
  const { watch } = useFormContext<FieldValues>();
  const [selectedBank, setSelectedBank] = useState<PartialBank | null>(defaultBank || null);
  const onSearch = useCallback(
    async (value: string) => {
      if (value != selectedBank?.name) onSetRoutingNumber(null);
      if (value == '') return [];
      const { banks } = await api.fetch<{ banks: Array<Bank> }>('/v1/banks', { q: value });
      return banks.map((bank) => ({ ...bank, value: bank.routing_number }));
    },
    [selectedBank, onSetRoutingNumber],
  );
  useEffect(() => {
    onSetRoutingNumber(selectedBank);
  }, [selectedBank, onSetRoutingNumber]);
  return (
    <FormFieldSuggestion<PartialBank>
      onSuggest={onSearch}
      onSelect={setSelectedBank}
      value={watch(name)}
      item={(item) => <BankSuggest bank={item} />}
      fallback={
        <Text variant="label" size="medium" color="error">
          {i18n.t('validation.unknown_bank_name')}
        </Text>
      }
    >
      <InputField name={name} options={{ required }} />
    </FormFieldSuggestion>
  );
};

const BankSuggest = ({ bank }: { bank: PartialBank }) => {
  return (
    <>
      {bank.name}{' '}
      <Text asChild size="small" color="sub">
        <small>{bank.routing_number}</small>
      </Text>
    </>
  );
};
