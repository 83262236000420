import { factoryAccount } from 'src/catalog/factories/account';
import { defineFactory, FactoryBlueprint } from 'src/catalog/factories/factoryBuilder';
import { fakeGid } from 'src/catalog/factories/gid';
import { factoryRepairRequest } from 'src/catalog/factories/repairRequest';
import { MessageAttachmentType } from 'src/models/v1/attachment';
import { Ticket } from 'src/models/v1/ticket';

// MARK: base

const base: FactoryBlueprint<Ticket> = {
  gid: fakeGid(),
  repair_request: factoryRepairRequest(),
  procedure: 'undefined',
  status: 'open',
  phase: 'finalized',
  finalized: false,
  completed: false,
  accounts: [
    { ...factoryAccount('caller'), role: 'caller' },
    { ...factoryAccount('repairer'), role: 'repairer' },
  ],
  permitted_attachments: [] as Array<MessageAttachmentType>,
};

// MARK: factory

export const factoryTicket = defineFactory(base, {});
