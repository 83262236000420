import { createContext } from 'react';
import { Account } from 'src/models/v1/account';

export const AccountContext = createContext<Account | undefined>(undefined);

export const generateTitle = (account: Account, defaultTitle: string) => {
  if (account.kind === 'pro' && account.business_profile?.tagline) {
    return `${account.display_name} - ${account.business_profile.tagline} | ${defaultTitle}`;
  }
  return `${account.display_name} | ${defaultTitle}`;
};
