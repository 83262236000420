import './CareersView.css';

import { FC } from 'react';
import { LinkButton } from 'src/components/primitives/Button';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';

const IMAGES = ['https://nagaku.com/image/careers/1.webp'];

export const CareersView: FC = () => {
  const { i18n } = useI18n();
  return (
    <aside className="careers-view" data-nosnippet style={{ backgroundImage: `url(${IMAGES[0]})` }}>
      <Text asChild size="medium" weight="bold" color="invert">
        <p>
          <Body />
        </p>
      </Text>
      <LinkButton to="https://corp.nagaku.com" variant="whiteline" size="small" target="_blank" rel="noreferrer">
        {i18n.t('link.careers')}
      </LinkButton>
    </aside>
  );
};

const Body: FC = () => {
  const { i18n } = useI18n();
  switch (i18n.locale()) {
    case 'en':
      return (
        <>
          Curious about Careers at Nagaku?
          <br />
          Learn More
        </>
      );
    default:
      return (
        <>
          ナガクのサービス開発・運営に
          <br />
          興味がある方はこちら
        </>
      );
  }
};
