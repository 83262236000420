import { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { BackableBreadcrumb } from 'src/components/features/BackableBreadcrumb';
import { KickPasswordRecoveryForm } from 'src/components/features/setting/KickPasswordRecoveryForm';
import { KickPasswordRecoveryNext } from 'src/components/features/setting/KickPasswordRecoveryNext';
import { SimpleLayout } from 'src/components/Layout';
import { useI18n } from 'src/lib/i18n';

export const KickPasswordRecoveryPage: FC = () => {
  const { i18n } = useI18n();
  const [completed, setCompleted] = useState(false);
  return (
    <SimpleLayout>
      <Helmet>
        <title>
          {i18n.t('title.reset_password')} | {i18n.t('nagaku')}
        </title>
        <meta property="og:title" content={`${i18n.t('title.reset_password')} | ${i18n.t('nagaku')}`} />
        <meta name="robots" content="noindex" />
      </Helmet>
      <BackableBreadcrumb homePath="/login" currentPath="/recover_password">
        {i18n.t('title.reset_password')}
      </BackableBreadcrumb>
      {!completed ? <KickPasswordRecoveryForm onComplete={() => setCompleted(true)} /> : <KickPasswordRecoveryNext />}
    </SimpleLayout>
  );
};
