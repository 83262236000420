import { Icon } from 'src/components/primitives/Icon';
import './AddressCard.css';

import { FC, PropsWithChildren, useId } from 'react';
import { NoticeLabel } from 'src/components/primitives/NoticeLabel';
import { Card } from 'src/components/styles/Card';
import { useI18n } from 'src/lib/i18n';
import { Address } from 'src/models/v1/address';

/**
 * Address Card
 *
 * @param address: The address to display
 * @param selected: Whether the address is selected
 * @param onSelect: Optional callback for when the card is clicked. When specified, the address card will be rendered in selectable mode.
 * @param children: Allows injecting custom actions/content from parent components (e.g. edit/delete buttons)
 * @returns A card component displaying address information
 */
export const AddressCard: FC<
  PropsWithChildren<{ address: Address; name?: string; selected?: boolean; onSelect?: (address: Address) => void }>
> = ({ address, name, children, selected = false, onSelect }) => {
  const { i18n } = useI18n();
  const Comp = onSelect ? 'label' : 'section';
  const id = useId();
  return (
    <Comp htmlFor={id} className={`address-card ${Card.class}`} {...Card.build({ color: 'gray' })}>
      {address.primary && (
        <NoticeLabel asChild className="primary-label" variant="outline">
          <Icon name="home" aria-hidden />
          {i18n.t('label.primary_address')}
        </NoticeLabel>
      )}
      <header>
        {address.name} <small>{address.name_kana}</small>
      </header>
      <address>
        <p>〒 {address.postal_code}</p>
        <p>
          {address.state}
          {address.city}
          {address.town}
          {address.line1}
        </p>
        {address.line2 && <p>{address.line2}</p>}
      </address>
      <p className="phone">{address.phone}</p>
      {children && <footer>{children}</footer>}
      {onSelect && (
        <input
          id={id}
          name={name}
          type="radio"
          checked={selected}
          onChange={(event) => event.target.value && onSelect(address)}
        />
      )}
    </Comp>
  );
};
