import { redirect, RouteObject } from 'react-router';
import devRoutes from 'src/catalog/routes';
import { LoaderErrorBoundary } from 'src/components/error/LoaderErrorBoundary';
import { Root } from 'src/components/Root';
import { pageLoaderWrapper as buildLoader } from 'src/lib/api';
import { checkUnauthenticationLoader, currentAccountLoader } from 'src/lib/loader';
import { emulateCheckoutLoader, EmulateCheckoutPage } from 'src/pages/@debug/checkout';
import { accountPrivacyPolicyLoader, AccountPrivacyPolicyPage } from 'src/pages/@docs/account_privacy_policy';
import { JapaneseActOnSpecifiedTransactionPage } from 'src/pages/@docs/japanese_act_on_specified_transactions';
import { PrivacyPolicyPage } from 'src/pages/@docs/privacy_policy';
import { TermsOfServicePage } from 'src/pages/@docs/terms_of_service';
import { aboutProLoader, AboutProPage } from 'src/pages/about/pro';
import { AboutOrderPage } from 'src/pages/accounts/_gid/about';
import { AskPage, askPageLoader } from 'src/pages/accounts/_gid/ask';
import { editProfileLoader, EditProfilePage } from 'src/pages/accounts/_gid/edit';
import { accountProfileLoader, AccountProfilePage } from 'src/pages/accounts/_gid/index';
import { contactLoader, InquiryPage } from 'src/pages/accounts/_gid/inquiry';
import {
  accountJpActOnSpecifiedCommercialTransactionLoader,
  AccountJpActOnSpecifiedCommercialTransactionPage,
} from 'src/pages/accounts/_gid/jp_act_on_specified_commercial_transaction';
import { AccountWorksPage } from 'src/pages/accounts/_gid/works';
import { announcementsListLoader, AnnouncementsListPage } from 'src/pages/announcements';
import { AnnouncementDetailPage, announcementsDetailLoader } from 'src/pages/announcements/_date/_slug';
import { AccessTokenRefreshPage } from 'src/pages/auth/refresh';
import { ContactUsPage } from 'src/pages/contact_us';
import { dashboardOrderListLoader, DashboardOrderListPage } from 'src/pages/dashboard/orders';
import { ExplorePage, exploreTagLoader } from 'src/pages/explore';
import { ExploreTagWorksPage } from 'src/pages/explore/tags/works';
import { ExploreWorksPage } from 'src/pages/explore/works';
import { homeLoader, HomePage } from 'src/pages/index';
import { LikesPage } from 'src/pages/likes';
import { LoginPage } from 'src/pages/login';
import { MessageBoxPage } from 'src/pages/messages';
import { MessageThreadPage, ticketLoader } from 'src/pages/messages/_ticket_gid/index';
import { orderHistoryLoader, OrderHistoryPage } from 'src/pages/orders';
import { OrderPage, orderPageLoader } from 'src/pages/orders/_management_number';
import { QuotationPaymentCallback, quotationPaymentCallbackFunc } from 'src/pages/quotations/_gid/callback';
import { quotationPaymentLoader, QuotationPaymentPage } from 'src/pages/quotations/_gid/payment';
import { quotationPrintLoader, QuotationPrintPage } from 'src/pages/quotations/_gid/print';
import { KickPasswordRecoveryPage } from 'src/pages/recover_password';
import { RegisterFormPage } from 'src/pages/register';
import { settingsLoader, SettingsPage } from 'src/pages/settings';
import { accountSettingLoader, AccountSettingPage } from 'src/pages/settings/account';
import { addressesSettingLoader, AddressesSettingPage } from 'src/pages/settings/addresses';
import { businessSettingLoader, BusinessSettingPage } from 'src/pages/settings/business';
import { businessApplicationLoader, BusinessApplicationPage } from 'src/pages/settings/business_application';
import {
  BusinessApplicationEditPage,
  editBusinessApplicationLoader,
} from 'src/pages/settings/business_application/edit';
import { editBusinessProfileLoader, EditBusinessProfilePage } from 'src/pages/settings/business_profile/edit';
import { EmailVerificationPage } from 'src/pages/settings/email/verification';
import {
  editJpActOnSpecifiedCommercialTransactionLoader,
  EditJpActOnSpecifiedCommercialTransactionPage,
} from 'src/pages/settings/jp_act_on_specified_commercial_transaction/edit';
import { passwordRecoveryLoader, PasswordRecoveryPage } from 'src/pages/settings/password/recovery';
import { tagListLoader, TagsPage } from 'src/pages/tags';
import { WorkDetailPage, worksDetailLoader } from 'src/pages/works/_gid';
import { editWorkLoader, EditWorkPage } from 'src/pages/works/_gid/edit';
import { DraftWorksListPage } from 'src/pages/works/draft';
import { worksNewLoader, WorksNewPage } from 'src/pages/works/new';
import { FollowsPage } from 'src/pages/follows';
import { FollowersPage } from 'src/pages/followers';
import { editReceptionLoader, EditReceptionPage } from 'src/pages/settings/reception/edit';

export const REFRESH_PATH = '/auth/refresh';

const appRoutes: RouteObject[] = [
  {
    path: '/',
    element: <HomePage />,
    loader: buildLoader(homeLoader),
  },
  {
    path: 'follows',
    element: <FollowsPage />,
  },
  {
    path: 'followers',
    element: <FollowersPage />,
  },
  {
    path: 'accounts/:gid',
    element: <AccountProfilePage />,
    loader: buildLoader(accountProfileLoader),
    children: [
      { path: '', element: <AccountWorksPage /> },
      { path: 'about', element: <AboutOrderPage /> },
    ],
  },
  {
    path: 'accounts/:gid/edit',
    element: <EditProfilePage />,
    loader: buildLoader(editProfileLoader),
  },
  {
    path: 'accounts/:gid/ask',
    element: <AskPage />,
    loader: buildLoader(askPageLoader),
  },
  {
    path: 'accounts/:gid/inquiry',
    element: <InquiryPage />,
    loader: buildLoader(contactLoader),
  },
  {
    path: 'accounts/:gid/privacy_policy',
    element: <AccountPrivacyPolicyPage />,
    loader: buildLoader(accountPrivacyPolicyLoader),
  },
  {
    path: 'accounts/:gid/jp_act_on_specified_commercial_transaction',
    element: <AccountJpActOnSpecifiedCommercialTransactionPage />,
    loader: buildLoader(accountJpActOnSpecifiedCommercialTransactionLoader),
  },
  {
    path: 'explore',
    element: <ExplorePage />,
    children: [
      { path: '', element: <ExploreWorksPage /> },
      { path: ':typeface', element: <ExploreTagWorksPage />, loader: buildLoader(exploreTagLoader) },
      // {
      //   path: 'accounts',
      //   element: <ExploreTagAccountsPage />,
      // },
    ],
  },
  {
    path: 'tags',
    element: <TagsPage />,
    loader: buildLoader(tagListLoader),
  },
  {
    id: 'tag',
    path: '/tags',
    children: [
      {
        path: '*',
        loader: ({ request }) => {
          const url = new URL(request.url);
          return redirect(
            `${url.pathname.replace(/^\/tags\//, '/explore/')}${url.search ? '?' + url.searchParams.toString() : ''}`,
            { status: 301 },
          );
        },
      },
    ],
  },
  {
    path: 'messages',
    element: <MessageBoxPage />,
    children: [
      {
        path: ':ticket_gid',
        element: <MessageThreadPage />,
        loader: buildLoader(ticketLoader),
      },
    ],
  },
  {
    path: 'quotations/:gid/callback',
    element: <QuotationPaymentCallback />,
    loader: buildLoader(quotationPaymentCallbackFunc),
  },
  {
    path: 'quotations/:gid/payment',
    element: <QuotationPaymentPage />,
    loader: buildLoader(quotationPaymentLoader),
  },
  {
    path: 'quotations/:gid/print',
    element: <QuotationPrintPage />,
    loader: buildLoader(quotationPrintLoader),
  },
  {
    path: 'works/draft',
    element: <DraftWorksListPage />,
  },
  {
    path: 'works/new',
    element: <WorksNewPage />,
    loader: buildLoader(worksNewLoader),
  },
  {
    path: 'works/:gid',
    element: <WorkDetailPage />,
    loader: buildLoader(worksDetailLoader),
  },
  {
    path: 'works/:gid/edit',
    element: <EditWorkPage />,
    loader: buildLoader(editWorkLoader),
  },
  {
    path: 'news',
    element: <AnnouncementsListPage />,
    loader: buildLoader(announcementsListLoader),
  },
  {
    path: 'news/:date/:slug',
    element: <AnnouncementDetailPage />,
    loader: buildLoader(announcementsDetailLoader),
  },
  {
    path: 'settings',
    element: <SettingsPage />,
    loader: buildLoader(settingsLoader),
  },
  {
    path: 'settings/account',
    element: <AccountSettingPage />,
    loader: buildLoader(accountSettingLoader),
  },
  {
    path: 'settings/addresses',
    element: <AddressesSettingPage />,
    loader: buildLoader(addressesSettingLoader),
  },
  {
    path: 'settings/reception/edit',
    element: <EditReceptionPage />,
    loader: buildLoader(editReceptionLoader),
  },
  {
    path: 'settings/business',
    element: <BusinessSettingPage />,
    loader: buildLoader(businessSettingLoader),
  },
  {
    path: 'settings/business_application',
    element: <BusinessApplicationPage />,
    loader: buildLoader(businessApplicationLoader),
  },
  {
    path: 'settings/business_application/edit',
    element: <BusinessApplicationEditPage />,
    loader: buildLoader(editBusinessApplicationLoader),
  },
  {
    path: 'settings/business_profile/edit',
    element: <EditBusinessProfilePage />,
    loader: buildLoader(editBusinessProfileLoader),
  },
  {
    path: 'settings/jp_act_on_specified_commercial_transaction/edit',
    element: <EditJpActOnSpecifiedCommercialTransactionPage />,
    loader: buildLoader(editJpActOnSpecifiedCommercialTransactionLoader),
  },
  {
    path: 'settings/email/verification',
    element: <EmailVerificationPage />,
  },
  {
    path: 'settings/password/recovery',
    element: <PasswordRecoveryPage />,
    loader: buildLoader(passwordRecoveryLoader),
  },
  {
    path: 'likes',
    element: <LikesPage />,
  },
  {
    path: 'orders',
    element: <OrderHistoryPage />,
    loader: buildLoader(orderHistoryLoader),
  },
  {
    path: 'orders/:management_number',
    element: <OrderPage />,
    loader: buildLoader(orderPageLoader),
  },
  {
    path: 'dashboard',
    children: [
      {
        path: 'orders',
        element: <DashboardOrderListPage />,
        loader: buildLoader(dashboardOrderListLoader),
      },
    ],
  },
  {
    path: 'login',
    element: <LoginPage />,
    loader: buildLoader(checkUnauthenticationLoader),
    ErrorBoundary: LoaderErrorBoundary,
  },
  {
    path: 'register',
    element: <RegisterFormPage />,
    loader: buildLoader(checkUnauthenticationLoader),
    ErrorBoundary: LoaderErrorBoundary,
  },
  {
    path: 'recover_password',
    element: <KickPasswordRecoveryPage />,
  },
  {
    path: 'contact_us',
    element: <ContactUsPage />,
  },
  {
    path: 'terms_of_service',
    element: <TermsOfServicePage />,
  },
  {
    path: 'privacy_policy',
    element: <PrivacyPolicyPage />,
  },
  {
    path: 'japanese_act_on_specified_transactions',
    element: <JapaneseActOnSpecifiedTransactionPage />,
  },
  {
    path: 'about/pro',
    element: <AboutProPage />,
    loader: buildLoader(aboutProLoader),
  },
  {
    path: ':screen_name',
    element: <AccountProfilePage />,
    loader: buildLoader(accountProfileLoader),
    children: [
      { path: '', element: <AccountWorksPage /> },
      { path: 'about', element: <AboutOrderPage /> },
    ],
  },
  {
    path: ':screen_name/ask',
    element: <AskPage />,
    loader: buildLoader(askPageLoader),
  },
  {
    path: ':screen_name/inquiry',
    element: <InquiryPage />,
    loader: buildLoader(contactLoader),
  },
  {
    path: ':screen_name/privacy_policy',
    element: <AccountPrivacyPolicyPage />,
    loader: buildLoader(accountPrivacyPolicyLoader),
  },
  {
    path: ':screen_name/jp_act_on_specified_commercial_transaction',
    element: <AccountJpActOnSpecifiedCommercialTransactionPage />,
    loader: buildLoader(accountJpActOnSpecifiedCommercialTransactionLoader),
  },
];

export const routes: RouteObject[] = [
  {
    path: '/index.html',
    loader: ({ request }) => {
      const url = new URL(request.url);
      return redirect(`/${url.search ? '?' + url.searchParams.toString() : ''}`, { status: 301 });
    },
  },
  {
    path: '/magazine',
    children: [
      {
        path: '*',
        loader: ({ request }) => {
          const url = new URL(request.url);
          return redirect(
            `https://mag.nagaku.com${url.pathname.replace(/^\/magazine/, '')}${url.search ? '?' + url.searchParams.toString() : ''}`,
            { status: 301 },
          );
        },
      },
    ],
  },
  {
    path: '/help',
    loader: () => {
      return redirect('https://help.nagaku.com/hc/ja', { status: 301 });
    },
  },
  {
    path: '/users',
    children: [
      {
        path: '*',
        loader: ({ request }) => {
          const url = new URL(request.url);
          return redirect(
            `${url.pathname.replace(/^\/users\//, '/accounts/')}${url.search ? '?' + url.searchParams.toString() : ''}`,
            { status: 301 },
          );
        },
      },
    ],
  },
  {
    // Page to refresh access token in Browser (client-side)
    // to solve access token refresh race condition issue.
    path: REFRESH_PATH,
    element: <AccessTokenRefreshPage />,
  },
  {
    element: <Root />,
    loader: buildLoader(currentAccountLoader),
    ErrorBoundary: LoaderErrorBoundary,
    children: appRoutes.concat(devRoutes),
  },
];

if (import.meta.env.DEV) {
  // Corraborate with ExternalGateway::Stripe::OpenCheckoutSession and
  // ExternalGateway::Stripe::Mock#post_checkout_session.
  routes.unshift({
    path: '/checkout/:id',
    element: <EmulateCheckoutPage />,
    loader: buildLoader(emulateCheckoutLoader),
  });
}
