import './TicketCommentMessageView.css';

import { FC, useState } from 'react';
import { MessageBalloon } from 'src/components/features/message/MessageBalloon';
import { QuotationSummaryCard } from 'src/components/features/quotation/QuotationSummaryCard';
import { Spinner } from 'src/components/primitives/Spinner';
import { useApi } from 'src/lib/api';
import { attachmentsByType } from 'src/lib/ticketComment';
import { Message, TicketCommentMessage } from 'src/models/v1/message';
import { Quotation, RetrieveQuotationResponse } from 'src/models/v1/quotation';
import { Estimate, RetrieveEstimateResponse } from 'src/models/v1/estimate';
import { EstimateSummaryCard } from 'src/components/features/estimate/EstimateSummaryCard';
import { ImageViewer } from 'src/components/features/ImageViewer';
import { ThumbnailCollection } from 'src/components/features/ThumbnailCollection';

export const TicketCommentMessageView: FC<{
  message: Pick<Message, 'data' | 'html'> & TicketCommentMessage;
}> = ({ message }) => {
  const { quotations, estimates, files } = attachmentsByType(message.data.attachments);
  const images = files.map((file) => file.url ?? '');
  const [currentImage, setCurrentImage] = useState<string | undefined>(undefined);
  return (
    <MessageBalloon account={message.data.account} body={message.html.body} timestamp={message.data.updated_at}>
      {quotations.length !== 0 && (
        <div className="ticket-comment-attachments-stack">
          {quotations.map((quotation) => (
            <QuotationCardPreview key={quotation.gid} gid={quotation.gid} />
          ))}
        </div>
      )}
      {estimates.length !== 0 && (
        <div className="ticket-comment-attachments-stack">
          {estimates.map((estimate) => (
            <EstimateCardPreview key={estimate.gid} gid={estimate.gid} />
          ))}
        </div>
      )}
      {files.length !== 0 && (
        <div className="ticket-comment-attachments-stack">
          <ImageViewer images={images} currentImage={currentImage} setCurrentImage={setCurrentImage} />
          <ThumbnailCollection images={images} onSelect={(image) => setCurrentImage(image)} />
        </div>
      )}
    </MessageBalloon>
  );
};

const QuotationCardPreview: FC<{ gid: Quotation['gid'] }> = ({ gid }) => {
  const { data, isLoading } = useApi<RetrieveQuotationResponse>(`/v1/quotations/${gid}`);
  const { quotation } = data || {};
  if (isLoading) return <Spinner />;
  if (quotation)
    return <QuotationSummaryCard className="ticket-comment-attachments-preview-quotation" quotation={quotation} />;
};

const EstimateCardPreview: FC<{ gid: Estimate['gid'] }> = ({ gid }) => {
  const { data, isLoading } = useApi<RetrieveEstimateResponse>(`/v1/estimates/${gid}`);
  const { estimate } = data || {};
  if (isLoading) return <Spinner />;
  if (estimate) return <EstimateSummaryCard estimate={estimate} />;
};
