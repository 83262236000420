import { FC } from 'react';
import { MessageBalloon } from 'src/components/features/message/MessageBalloon';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';
import { Message, AcceptOrderCompletionMessage } from 'src/models/v1/message';

export const AcceptOrderCompletionMessageView: FC<{
  message: Pick<Message, 'data' | 'created_at'> & AcceptOrderCompletionMessage;
}> = ({ message }) => {
  const { i18n } = useI18n();
  const { me } = useCurrentStatus();

  const { repairer_account, caller_account } = message.data.order;
  const isCaller = me?.gid == caller_account.gid;
  const phraseVars = {
    repairer_display_name: repairer_account.display_name,
    caller_display_name: caller_account.display_name,
  };

  return (
    <MessageBalloon
      account={{ gid: 'NAGAKU', kind: null, display_name: i18n.t('nagaku'), icon_url: '/svg/logoAvatar.svg' }}
      body={i18n.t(
        `AcceptOrderCompletionMessageView.message.${isCaller ? 'for_caller' : 'for_non_caller'}`,
        phraseVars,
      )}
      timestamp={message.created_at}
    />
  );
};
