import './OpenTicketBar.css';

import { FC } from 'react';
import { LinkButton } from 'src/components/primitives/Button';
import { Icon } from 'src/components/primitives/Icon';
import { useI18n } from 'src/lib/i18n';
import { Account } from 'src/models/v1/account';
import { AuthRequiredButton } from 'src/components/features/AuthRequiredButton';

export const OpenTicketBar: FC<{ account: Pick<Account, 'gid' | 'screen_name'> }> = ({ account }) => {
  const { i18n } = useI18n();
  return (
    <div className="open-ticket-bar">
      <AuthRequiredButton returnTo={`/${account.screen_name || `accounts/${account.gid}`}/ask`}>
        <LinkButton to={`/${account.screen_name || `accounts/${account.gid}`}/ask`} w="full" variant="primary-black">
          <Icon name="send" />
          {i18n.t('action.ask')}
        </LinkButton>
      </AuthRequiredButton>
    </div>
  );
};
