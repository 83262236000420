import { createContext, TransitionStartFunction, useCallback, useContext } from 'react';
import { useI18n } from 'src/lib/i18n';
import { useToast } from 'src/lib/toast';
import { ActionError, PreventAction, captureErrorWithCustomFingerprint } from 'src/lib/errors';
import { log } from 'src/lib/log';

type ActionFunction<P, A, R> = {
  (props: P & BuilderProps<R>): (args: A) => Promise<void>;
  key: string;
};

type BuilderProps<R> = {
  onSuccess?: (result: R) => void | Promise<void>;
  onError?: (error: unknown) => void | Promise<void>;
  startTransition?: TransitionStartFunction;
};

const actionRepository: Array<string> = [];

type ActionContextOptions = {
  hijack?: <A, R>(name: string, args: A, func: (args: A) => Promise<R>) => Promise<void>;
};

export const ActionContext = createContext<ActionContextOptions>({});

export const createAction = <P extends {}, A, R>(
  key: string,
  builder: (props: P) => (args: A) => Promise<R>,
): ActionFunction<P, A, R> => {
  if (import.meta.env.DEV || import.meta.env.VITE_STAGE == 'staging') {
    actionRepository.push(key);
  }
  const actionBuilder: ActionFunction<P, A, R> = ({
    onSuccess,
    onError,
    startTransition,
    ...props
  }: P & BuilderProps<R>) => {
    const func = builder(props as P);
    const toast = useToast();
    const { i18n } = useI18n();
    const { hijack } = useContext(ActionContext);
    const action = useCallback(
      async (args) => {
        if (hijack) {
          await hijack(key, args, func);
          return;
        }
        try {
          log('action_start', {
            action: actionBuilder.key,
          });
          const result = await func(args);
          toast({
            message: i18n.t(`${actionBuilder.key}.success`, props || {}),
            variant: 'success',
          });
          await onSuccess?.(result);
          log('action_success', {
            action: actionBuilder.key,
          });
        } catch (error: unknown) {
          log('action_error', {
            action: actionBuilder.key,
          });
          if (error instanceof PreventAction) {
            return;
          } else if (error instanceof ActionError) {
            toast({
              message: i18n.t(`${actionBuilder.key}.error`, { ...(props || {}), message: error.message }),
              variant: 'error',
            });
          } else {
            toast({
              message: i18n.t(`${actionBuilder.key}.error`, props || {}),
              variant: 'error',
            });
          }
          await onError?.(error);
          captureErrorWithCustomFingerprint(error, key);
        }
      },
      [props, onSuccess, onError],
    ) as (args: A) => Promise<void>;
    if (startTransition) {
      return async (args: A) => startTransition(async () => await action(args));
    } else {
      return action;
    }
  };
  actionBuilder.key = key;
  return actionBuilder;
};

export const getActions = () => actionRepository;
