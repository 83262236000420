import { defineFactory, FactoryBlueprint } from 'src/catalog/factories/factoryBuilder';
import { factoryAccount } from 'src/catalog/factories/account';
import { factoryTicket } from 'src/catalog/factories/ticket';
import { Order } from 'src/models/v1/order';

// MARK: base

const base: FactoryBlueprint<Order> = {
  management_number: 'OXXXXXXXXXXXXXX',
  timestamp: '2024-12-06T07:45:56.792Z',
  completion_status: 'pending_acceptance',
  repairer_account: factoryAccount('repairer'),
  caller_account: factoryAccount('caller'),
  ticket: factoryTicket(),
  amount: 100000,
};

// MARK: completed trait

const completed: Partial<Order> = {
  completion_status: 'completed',
};

// MARK: factory

export const factoryOrder = defineFactory(base, { completed });
