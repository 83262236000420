import './OrderMessageView.css';

import { FC } from 'react';
import { onCompleteOrder } from 'src/actions/order';
import { MessageBalloon } from 'src/components/features/message/MessageBalloon';
import { Button } from 'src/components/styles/Button';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';
import { Message, OrderMessage } from 'src/models/v1/message';
import { Order } from 'src/models/v1/order';

export const OrderMessageView: FC<{
  message: Pick<Message, 'data' | 'created_at'> & OrderMessage;
  onComplete?: (managementNumber: Order['management_number']) => Promise<void> | void;
}> = ({ message, onComplete = onCompleteOrder({}) }) => {
  const { i18n } = useI18n();
  const { me } = useCurrentStatus();
  const { caller_account, repairer_account } = message.data;
  const isCaller = me?.gid == caller_account.gid;
  const isRepairer = me?.gid == repairer_account.gid;

  return (
    <MessageBalloon
      account={{ gid: 'NAGAKU', kind: null, display_name: i18n.t('nagaku'), icon_url: '/svg/logoAvatar.svg' }}
      body={i18n.t(`OrderMessageView.message.${isCaller ? 'for_caller' : 'for_non_caller'}`, {
        caller_display_name: caller_account.display_name,
        repairer_display_name: repairer_account.display_name,
      })}
      timestamp={message.created_at}
      action={
        isRepairer &&
        message.data.completion_status == 'pending_acceptance' && (
          <button
            className={[Button.class, 'order-completion-button'].join(' ')}
            {...Button.build({ variant: 'primary-action' })}
            onClick={() => onComplete(message.data.management_number)}
          >
            {i18n.t('action.complete_order')}
          </button>
        )
      }
    />
  );
};
