import { EstimateView } from 'src/components/features/estimate/EstimateView';
import { Meta } from 'src/catalog/Story';
import { TicketContext } from 'src/lib/ticket';
import { factoryEstimate } from 'src/catalog/factories/estimate';

const meta: Meta<typeof EstimateView> = {
  title: 'Estimate View',
  args: {
    estimate: factoryEstimate(),
  },
  template: (args) => (
    <TicketContext.Provider
      value={{
        gid: '0000',
        status: 'open',
        accounts: [],
      }}
    >
      <EstimateView {...args} />
    </TicketContext.Provider>
  ),
};

export default meta;
