import { factoryTag } from 'src/catalog/factories/tag';
import { Meta, Story } from 'src/catalog/Story';
import { TagTileCollection } from 'src/components/features/tag/TagTileCollection';

const meta: Meta<typeof TagTileCollection> = {
  title: 'Tag Tile Collection',
  args: {
    tags: [factoryTag(), factoryTag('shortTypeface'), factoryTag('shortTypeface'), factoryTag('longTypeface')],
  },
  template: (args) => <TagTileCollection {...args} />,
};

export default meta;

export const heroBlank: Story<typeof TagTileCollection> = {
  name: 'Hero Blank',
  args: {
    tags: [factoryTag('heroBlank')],
  },
};
