import { createContext, useContext } from 'react';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { Account } from 'src/models/v1/account';
import { Ticket, TicketParticipantRole } from 'src/models/v1/ticket';

/*
 * Retrieves the role of a specified account as a participant in the given ticket.
 *
 * @param {Account} args.account - The account whose role is to be found.
 * @param {Ticket} args.ticket - The ticket containing a list of accounts participating in it.
 *
 * @returns {TicketParticipantRole | undefined} - The role of the account in the ticket, or undefined if the account is not found.
 */
export const findTicketRole: (args: {
  account: Pick<Account, 'gid'>;
  ticket: { accounts: Array<Pick<Account, 'gid'> & { role: TicketParticipantRole }> };
}) => TicketParticipantRole | undefined = ({ account, ticket }) =>
  ticket.accounts.find((participant) => participant.gid == account.gid)?.role;

export const TicketContext = createContext<Pick<Ticket, 'gid' | 'status' | 'accounts'> | null>(null);

export const checkTicketRole: (role: 'repairer' | 'caller') => boolean = (role) => {
  const { me } = useCurrentStatus();
  const ticket = useContext(TicketContext);
  return ticket?.accounts.find((participant) => participant.gid == me?.gid)?.role == role;
};
