import { FC } from 'react';
import { useLoaderData } from 'react-router';
import { PagingNavigation } from 'src/components/control/PagingNavigation';
import { AnnouncementCard } from 'src/components/features/announcements/AnnouncementCard';
import { NavigationLayout } from 'src/components/Layout';
import { Heading } from 'src/components/primitives/Heading';
import { PageLoader } from 'src/lib/api';
import { useI18n } from 'src/lib/i18n';
import { Pagination } from 'src/models/general';
import { Announcement } from 'src/models/v1/announcement';

type Data = {
  announcements: [Announcement];
  pagination: Pagination;
};

export const AnnouncementsListPage: FC = () => {
  const { i18n } = useI18n();
  const { announcements, pagination } = useLoaderData() as Data;
  return (
    <NavigationLayout>
      <Heading asChild>
        <h2>{i18n.t('title.latest_announcement')}</h2>
      </Heading>
      {announcements.map((announcement) => (
        <AnnouncementCard key={announcement.gid} announcement={announcement} />
      ))}
      <PagingNavigation current={pagination.current} totalPages={pagination.pages} />
    </NavigationLayout>
  );
};

export const announcementsListLoader: PageLoader = async ({ request, api }) => {
  const search = new URL(request.url).search;
  return await api.fetch(`/v1/announcements${search}`);
};
