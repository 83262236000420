import './AccountCardFooter.css';

import { FC, PropsWithChildren, ReactNode } from 'react';
import { Link } from 'react-router';
import { Avatar } from 'src/components/primitives/Avatar';
import { CardFooter } from 'src/components/primitives/Card';
import { Text } from 'src/components/primitives/Text';
import { Button } from 'src/components/styles/Button';
import { useCurrentStatus } from 'src/lib/currentStatus';
import { useI18n } from 'src/lib/i18n';
import { Account } from 'src/models/v1/account';

export const AccountCardFooter: FC<PropsWithChildren<{ account: Account; addon?: ReactNode }>> = ({
  account,
  addon,
  children,
}) => {
  const { i18n } = useI18n();
  const { me } = useCurrentStatus();
  const isMe = me?.gid === account?.gid;
  return (
    <CardFooter asChild className="account-card-footer">
      <footer>
        <Link tabIndex={-1} to={`/accounts/${account.gid}`} className="account-card-footer-link">
          <Avatar className="icon" size="sm" account={account} src={account.icon_url || undefined} />
          <Text variant="title" size="small" weight="bold" ellipsis={true}>
            {account.display_name}
          </Text>
          {children && <section className="content">{children}</section>}
        </Link>
        {addon}
        {!isMe && account.kind == 'pro' && (
          <Link
            to={`/accounts/${account.gid}/about`}
            className={Button.class}
            {...Button.build({ variant: 'black', size: 'sm' })}
          >
            {i18n.t('action.contact')}
          </Link>
        )}
      </footer>
    </CardFooter>
  );
};
