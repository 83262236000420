import './AddressField.css';

import { FC, useCallback, useContext, useEffect, useId, useRef, useState } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { ErrorMessage } from 'src/components/control/ErrorMessage';
import { FormOptionsContext } from 'src/components/control/Form';
import { FormControl } from 'src/components/control/FormControl';
import { FormFieldSuggestion } from 'src/components/control/FormFieldSuggestion';
import { FormLabel } from 'src/components/control/FormLabel';
import { InputField } from 'src/components/control/InputField';
import { Field, FieldAddon } from 'src/components/primitives/Field';
import { NoticeLabel } from 'src/components/primitives/NoticeLabel';
import { Spinner } from 'src/components/primitives/Spinner';
import { Text } from 'src/components/primitives/Text';
import { getAddressByPostalCode } from 'src/lib/address';
import { useI18n } from 'src/lib/i18n';
import { convertHalfWidthToFullWidth } from 'src/lib/katakana';
import { validateAddressKana } from 'src/lib/validation';
import { normalizePostalCode } from 'src/lib/value';

/**
 * AddressField
 *
 * A composite component for entering a postal address that includes:
 * - Postal code (with automatic address search/suggestion)
 * - Prefecture/City/Town display (auto-filled and read-only)
 * - Line1 and Line2 inputs (with optional Kana inputs)
 */
export const AddressField: FC<{
  namePrefix: string;
  options?: { required: boolean };
}> = ({ namePrefix, options }) => {
  const { i18n } = useI18n();
  const { watch, setValue } = useFormContext<FieldValues>();
  const { state, city, town } = watch(namePrefix) || {};

  const [nextFocus, setNextFocus] = useState<HTMLInputElement | undefined>(undefined);

  /**
   * Handle selection of a suggested address from postal code lookup.
   * It sets form values for postal code, prefecture/state, city, town, etc.
   * and tries to focus on the subsequent field (Line1).
   */
  const onSelectAddress = useCallback(
    (value: PostalCodeAddress) => {
      setValue(namePrefix.concat('.postal_code'), normalizePostalCode(value.postalCode));
      setValue(namePrefix.concat('.state'), value.address.ja.prefecture);
      setValue(namePrefix.concat('.city'), value.address.ja.address1);
      setValue(namePrefix.concat('.town'), value.address.ja.address2);
      setValue(namePrefix.concat('.city_kana'), value.address.kana.address1);
      setValue(namePrefix.concat('.town_kana'), value.address.kana.address2, { shouldTouch: true });

      // Attempt to set the focus on the ".line1" input field
      const activeElement = document.activeElement;
      if (activeElement?.tagName === 'INPUT') {
        const line1Field = activeElement
          .closest('fieldset')
          ?.querySelector(`input[name="${namePrefix}.line1"]`) as HTMLInputElement;
        setNextFocus(line1Field);
      }
    },
    [setValue, namePrefix],
  );

  // When nextFocus is set, focus the element and reset.
  useEffect(() => {
    if (nextFocus) {
      nextFocus.focus();
      setNextFocus(undefined);
    }
  }, [nextFocus]);

  return (
    <fieldset className="address-field">
      <PostalCodeField name={namePrefix.concat('.postal_code')} options={options} onSelectAddress={onSelectAddress} />

      {state && city && (
        <div className="address-level">
          <FormLabel htmlFor={namePrefix.concat('address_level')}>
            {i18n.t(`attributes.${namePrefix}.address_level`)}
            <NoticeLabel size="small">{i18n.t('control.auto_filled')}</NoticeLabel>
          </FormLabel>
          <Field disabled asChild>
            <input
              tabIndex={-1}
              id={namePrefix.concat('address_level')}
              value={`${state} ${city} ${town || ''}`}
              readOnly
            />
          </Field>
        </div>
      )}

      <KanaSupportInputField
        active={Boolean(state && city)}
        name={namePrefix.concat('.line1')}
        kanaName={namePrefix.concat('.line1_kana')}
        required={true}
        maxLength={32}
        kanaMaxLength={64}
      />

      <KanaSupportInputField
        active={Boolean(state && city)}
        name={namePrefix.concat('.line2')}
        kanaName={namePrefix.concat('.line2_kana')}
        required={false}
        maxLength={32}
        kanaMaxLength={64}
      />
    </fieldset>
  );
};

type PostalCodeAddress = {
  postalCode: string;
  address: {
    ja: { prefecture: string; address1: string; address2: string };
    kana: { prefecture: string; address1: string; address2: string };
  };
  value: string;
};

type PostalCodeState = 'input' | 'loading' | 'noResult' | 'completed';

/**
 * PostalCodeField
 *
 * Renders a text field for postal code input, automatically fetching address suggestions
 * from an external API when a valid 7-digit postal code is entered.
 */
const PostalCodeField: FC<{
  name: string;
  options?: { required: boolean };
  onSelectAddress: (address: PostalCodeAddress) => void;
}> = ({ name, options, onSelectAddress }) => {
  const { i18n } = useI18n();
  const { register, watch } = useFormContext<FieldValues>();
  const { readOnly } = useContext(FormOptionsContext);

  const label = i18n.t(`attributes.${name}`);
  const id = useId();

  const [state, setState] = useState<PostalCodeState>('input');

  /**
   * Fetch and suggest possible addresses based on a given postal code.
   */
  const onSearch = useCallback(async (value: string) => {
    const postalCode = normalizePostalCode(value)?.replace('-', '');
    if (postalCode?.match(/^\d{7}$/)) {
      setState('loading');
      try {
        const data = await getAddressByPostalCode(postalCode);
        setState('completed');
        return data.addresses.map((item: any) => ({
          postalCode: data.postalCode,
          address: {
            ja: item.ja,
            kana: item.kana,
          },
          value: `${item.ja.prefecture} ${item.ja.address1} ${item.ja.address2}`,
        })) as PostalCodeAddress[];
      } catch {
        setState('noResult');
      }
    } else {
      setState('input');
    }
    return [] as PostalCodeAddress[];
  }, []);

  const onSelect = useCallback(
    (address: PostalCodeAddress) => {
      onSelectAddress(address);
    },
    [onSelectAddress],
  );

  return (
    <FormFieldSuggestion<PostalCodeAddress>
      onSuggest={onSearch}
      onSelect={onSelect}
      value={normalizePostalCode(watch(name)) || ''}
      item={(item) => <AddressSuggest address={item} />}
      /**
       * Displays this component if the search completes successfully but yields no results.
       */
      fallback={
        <Text variant="label" size="medium" color="error">
          {i18n.t('validation.unknown_postalcode')}
        </Text>
      }
    >
      <FormControl id={id} name={name} required={options?.required ?? false} label={label}>
        <Field>
          <FieldAddon variant="fill">〒</FieldAddon>
          <input
            id={id}
            type="text"
            autoComplete="postal-code"
            placeholder={i18n.t(`placeholder.address.postal_code`)}
            inputMode="numeric"
            readOnly={readOnly}
            maxLength={8} // e.g., 000-0000 in Japan
            {...register(name, options)}
            data-1p-ignore // 1Password compatibility: https://developer.1password.com/docs/web/compatible-website-design#build-logical-forms
          />
          {state === 'loading' && (
            <FieldAddon>
              <Spinner size="small" />
            </FieldAddon>
          )}
        </Field>
        <ErrorMessage name={name} label={label} />
      </FormControl>
    </FormFieldSuggestion>
  );
};

/**
 * Simple presentation component to display an address suggestion.
 */
const AddressSuggest = ({ address }: { address: PostalCodeAddress }) => {
  const postalCode = address.postalCode.replace(/^(\d{3})(\d{4})$/, '$1-$2');
  const { prefecture, address1, address2 } = address.address.ja;

  return (
    <>
      <Text asChild weight="bold">
        <strong>
          {prefecture} {address1} {address2}
        </strong>
      </Text>
      <Text asChild size="small">
        <small>{postalCode}</small>
      </Text>
    </>
  );
};

/**
 * KanaSupportInputField
 *
 * Renders a pair of inputs: a main text input (e.g., address line) and
 * a corresponding kana field. It automatically converts pasted text
 * or IME composition end events into full-width kana characters.
 */
const KanaSupportInputField: FC<{
  name: string;
  kanaName: string;
  active: boolean;
  required: boolean;
  maxLength: number;
  kanaMaxLength: number;
}> = ({ name, kanaName, active, required, maxLength, kanaMaxLength }) => {
  const { i18n } = useI18n();
  const { watch, setValue } = useFormContext<FieldValues>();
  const mainFieldValue = watch(name);

  /**
   * Converts half-width characters to full-width and sets the form field value.
   */
  const convertAndSetValue = useCallback(
    (fieldName: string, text: string) => {
      const convertedText = convertHalfWidthToFullWidth(text);
      setValue(fieldName, convertedText, { shouldValidate: true });
    },
    [setValue],
  );

  /**
   * Handler for IME input start (composition).
   * We only need to note if composition is in progress if we want to
   * delay certain validations or conversions, but here we simply track it.
   */
  const composingFieldName = useRef<string | undefined>(undefined);

  const onCompositionStart = useCallback((fieldName: string) => {
    composingFieldName.current = fieldName;
  }, []);

  /**
   * Handler for IME input end (composition end).
   * Here we perform the full-width conversion once composition is finalized.
   */
  const onCompositionEnd = useCallback(
    (fieldName: string, event: React.CompositionEvent<HTMLInputElement>) => {
      composingFieldName.current = undefined;
      convertAndSetValue(fieldName, event.currentTarget.value);
    },
    [convertAndSetValue],
  );

  /**
   * Handler for paste events. Prevents default paste and performs
   * full-width conversion on the pasted text.
   */
  const onPaste = useCallback(
    (fieldName: string, event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      const pastedText = event.clipboardData.getData('text');
      convertAndSetValue(fieldName, pastedText);
    },
    [convertAndSetValue],
  );

  return (
    <>
      <InputField
        name={name}
        data-active={active ? 'active' : 'inactive'} // Hidden via CSS when inactive
        onPaste={(e) => onPaste(name, e)}
        onCompositionStart={() => onCompositionStart(name)}
        onCompositionEnd={(e) => onCompositionEnd(name, e)}
        options={{
          required,
          maxLength: {
            value: maxLength,
            message: i18n.t('validation.max_length', {
              name: i18n.t(`attributes.${name}`),
              length: maxLength.toString(),
            }),
          },
        }}
      />

      <InputField
        name={kanaName}
        data-active={active ? 'active' : 'inactive'} // Hidden via CSS when inactive
        onPaste={(e) => onPaste(kanaName, e)}
        onCompositionStart={() => onCompositionStart(kanaName)}
        onCompositionEnd={(e) => onCompositionEnd(kanaName, e)}
        options={{
          required,
          maxLength: {
            value: kanaMaxLength,
            message: i18n.t('validation.max_length', {
              name: i18n.t(`attributes.${kanaName}`),
              length: kanaMaxLength.toString(),
            }),
          },
          validate: {
            address_line_kana: validateAddressKana,
            dependentField: (fieldValue) => {
              // If the main field has a value, the kana field is also required.
              if (mainFieldValue && !fieldValue) {
                return i18n.t('validation.required', {
                  name: i18n.t(`attributes.${kanaName}`),
                });
              }
              return true;
            },
          },
        }}
      />
    </>
  );
};
