import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { SimpleLayout } from 'src/components/Layout';
import { List } from 'src/components/primitives/List';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';

export const PrivacyPolicyPage: FC = () => {
  const { i18n } = useI18n();
  return (
    <SimpleLayout isWide>
      <Helmet>
        <title>{`${i18n.t('title.privacy_policy')} | ${i18n.t('nagaku')}`}</title>
        <meta property="og:title" content={`${i18n.t('title.privacy_policy')} | ${i18n.t('nagaku')}`} />
        <meta name="description" content="" />
        <meta property="og:description" content="" />
      </Helmet>
      <Text asChild variant="display" weight="bold">
        <h1>{i18n.t('title.privacy_policy')}</h1>
      </Text>
      {i18n.t('guide.privacy_policy_translation') && (
        <Text asChild color="hint">
          <aside>{i18n.t('guide.privacy_policy_translation')}</aside>
        </Text>
      )}
      <Text asChild variant="headline" weight="bold">
        <h2>第1条 定義</h2>
      </Text>
      <Text asChild>
        <p>
          ナガク株式会社（以下「当社」といいます）は、個人情報の取扱いにおいて、個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守するとともに、適切な管理を行います。
        </p>
      </Text>
      <Text asChild>
        <p>
          なお、本文中の用語の定義は個人情報の保護に関する法律（以下「個人情報保護法」といいます。）及び関連法令に従います。
        </p>
      </Text>

      <Text asChild variant="headline" weight="bold">
        <h2>第2条 個人情報の取得</h2>
      </Text>
      <Text asChild>
        <p>
          当社は、当社サービスの利用者、取引先（以下総称して「利用者等」といいます。）の個人情報を適法かつ適正な手段により取得します。
        </p>
      </Text>

      <Text asChild variant="headline" weight="bold">
        <h2>第3条 個人情報の利用目的</h2>
      </Text>
      <Text asChild>
        <p>当社は個人情報を次の目的に利用します。</p>
      </Text>
      <List>
        <Text asChild>
          <li>商品またはサービスを提供するため</li>
        </Text>
        <Text asChild>
          <li>商品またはサービスの企画、開発、保守、改善のため</li>
        </Text>
        <Text asChild>
          <li>商品またはサービスに関する重要なお知らせ等の通知のため</li>
        </Text>
        <Text asChild>
          <li>利用者等から寄せられたご意見、ご要望にお応えするため</li>
        </Text>
        <Text asChild>
          <li>当社の最新情報や各種セミナー等のご連絡のため</li>
        </Text>
        <Text asChild>
          <li>当社の商品またはサービスに関するご案内営業活動及びマーケティング活動のため</li>
        </Text>
        <Text asChild>
          <li>利用者等から請求された資料、カタログ等をお届けするため</li>
        </Text>
        <Text asChild>
          <li>取引先情報及び取引内容等の管理のため</li>
        </Text>
        <Text asChild>
          <li>取引の遂行のため</li>
        </Text>
        <Text asChild>
          <li>必要書類等の送付、本人確認、問い合わせや苦情への対応のため</li>
        </Text>
        <Text asChild>
          <li>メール配信サービスのお申込み確認および配信のため</li>
        </Text>
        <Text asChild>
          <li>調査およびイベントへの参加の依頼や、その結果のご報告のため</li>
        </Text>
        <Text asChild>
          <li>その他、なんらかの理由で利用者、取引先の皆様と接触する必要が生じたときのため</li>
        </Text>
      </List>

      <Text asChild variant="headline" weight="bold">
        <h2>第4条 CookieやGoogleAnalyticsの利用</h2>
      </Text>
      <List as="ol">
        <Text asChild>
          <li>
            当社のウェブサイトでは、お客様により適切なサービスをご提供するためCookie（クッキー）その他のトラッキング又は解析を行うための類似技術を使用しております。
          </li>
        </Text>
        <Text asChild>
          <li>
            当社のウェブサイトでは、当社のウェブサイトの改良等を目的として本ウェブサイトの利用頻度等を測定するため
            Google 社のサービスである Google Analyticsを利用して、利用者等が閲覧したウェブページのURL、IP
            アドレス等の情報を Google 社に送信しています。Google Analytics
            の収集する情報およびその取扱いについては、同社のプライバシーポリシーおよび「
            <a href="https://policies.google.com/technologies/partner-sites?hl=ja" rel="noopener" target="_blank">
              Google のサービスを使用するサイトやアプリから収集した情報の Google による使用 – ポリシーと規約 – Google
            </a>
            」のページをご参照ください。
          </li>
        </Text>

        <Text asChild>
          <li>
            当社のウェブサイトでは、当社のサービスのエラー検知等を目的としてFunctional Software,
            IncのサービスであるSentryを利用して、利用者等の通信端末機器等に関する機器及びブラウザ情報、アクセス記録並びにエラー状況等の情報をFunctional
            Software, Incに送信しています。Sentry の情報の取扱いについては、
            <a href="https://sentry.io/privacy/" rel="noopener" target="_blank">
              同社のプライバシーポリシー
            </a>
            をご参照ください。
          </li>
        </Text>
      </List>

      <Text asChild variant="headline" weight="bold">
        <h2>第5条 安全管理措置に関する事項</h2>
      </Text>
      <Text asChild>
        <p>
          当社は、個人データについて、漏えい、滅失又は毀損の防止等、その管理のために必要かつ適切な安全管理措置を講じます。また、
          個人データを取り扱う従業者や委託先（再委託先等を含みます。）に対して、必要かつ適切な監督を行います。
        </p>
      </Text>

      <Text asChild variant="headline" weight="bold">
        <h2>第6条 個人データの第三者提供</h2>
      </Text>
      <Text asChild>
        <p>
          当社は、利用者等ご本人の事前同意がある場合又は法令で認められている場合を除き、当社が取得した利用者等の個人データは、第三者（委託先を除きます。）に提供いたしません。
        </p>
      </Text>

      <Text asChild variant="headline" weight="bold">
        <h2>第7条 プライバシーポリシーの改定等</h2>
      </Text>
      <Text asChild>
        <p>
          当社は、本プライバシーポリシーの内容を適宜見直し、必要に応じて変更することがあります。この場合、改定版の公表の日から、変更の効力を生じるものとします。
        </p>
      </Text>

      <Text asChild variant="headline" weight="bold">
        <h2>第8条 個人情報の開示等の請求</h2>
      </Text>
      <Text asChild>
        <p>
          当社は､個人データの開示･訂正等･利用停止等のご要望があったときは､所定の手続でご本人様であることを確認のうえ､法令に従って対応します。
        </p>
      </Text>

      <Text asChild variant="headline" weight="bold">
        <h2>第9条 問い合わせ先</h2>
      </Text>
      <Text asChild>
        <p>
          個人情報に関するお問合せ先：ナガク株式会社個人情報管理責任者
          <br />
          <a href="mailto:info@nagaku.jp">info@nagaku.jp</a>
        </p>
      </Text>

      <Text asChild variant="headline" weight="bold">
        <h2>第10条 当社の名称・住所・代表者の氏名</h2>
      </Text>
      <Text asChild>
        <p>
          ナガク株式会社
          <br />
          長野県長野市西後町610-12 R-DEPOT 2F-D
          <br />
          代表取締役&emsp;渡部一紀
        </p>
      </Text>
      <Text asChild>
        <p>
          制定　　：2024年2月1日
          <br />
          最終更新：2024年11月27日
        </p>
      </Text>
    </SimpleLayout>
  );
};
