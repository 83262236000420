import './WorkCard.css';

import { FC } from 'react';
import { Link } from 'react-router';
import { AccountCardFooter } from 'src/components/features/account/AccountCardFooter';
import { BeforeAfterSlider } from 'src/components/features/BeforeAfterSlider';
import { LikeSwitchBar } from 'src/components/features/LikeSwitchBar';
import { TagCollection } from 'src/components/features/tag/TagCollection';
import { WorkMenu } from 'src/components/features/work/WorkMenu';
import { Card, CardContent } from 'src/components/primitives/Card';
import { Icon } from 'src/components/primitives/Icon';
import { NoticeLabel } from 'src/components/primitives/NoticeLabel';
import { HStack, VStack } from 'src/components/primitives/Stack';
import { Text } from 'src/components/primitives/Text';
import { useI18n } from 'src/lib/i18n';
import { normalizeAmount } from 'src/lib/value';
import { Work } from 'src/models/v1/work';

export const WorkCard: FC<{ work: Work }> = ({ work }) => {
  const { i18n } = useI18n();
  const totalImagesCount =
    (work.before_image_media_file ? 2 : work.after_image_media_file ? 1 : 0) + work.image_media_files?.length;
  return (
    <Card asChild className="work-card">
      <section>
        <Link
          to={`/works/${work.gid}${work.status === 'draft' ? '/edit' : ''}`}
          tabIndex={-1}
          className="image"
          draggable="false"
        >
          <BeforeAfterSlider
            beforeImage={work.before_image_media_file?.url}
            afterImage={work.after_image_media_file?.url}
          />
          <NoticeLabel
            className="image-count-label"
            variant="overlay"
            size="small"
            aria-label={i18n.t('WorkCard.ImageCountLabel', { count: totalImagesCount })}
          >
            <Icon name="photo_camera" color="action" className="layer-icon" />
            {totalImagesCount}
          </NoticeLabel>
        </Link>
        <VStack asChild spacing="sm" align="start">
          <CardContent className="card-content" m="none">
            <HStack justify="space-between" w="full">
              <LikeSwitchBar gid={work.gid} liked={work.liked} likesCount={work.likes_count} returnTo="/" />
              <WorkMenu work={work} />
            </HStack>
            <HStack justify="start">
              {work.estimate_amount && (
                <NoticeLabel variant="normal" size="small">
                  <Icon name="currency_yen" aria-label={i18n.t('attributes.work.estimate_amount')} />
                  {normalizeAmount(work.estimate_amount.toString())}
                </NoticeLabel>
              )}
              {work.estimate_delivery_days && (
                <NoticeLabel variant="normal" size="small">
                  <Icon name="access_time" aria-label={i18n.t('attributes.work.estimate_delivery_days')} />
                  {work.estimate_delivery_days}
                  {i18n.t('unit.separator')}
                  {i18n.t(`unit.delivery_days.${work.delivery_days_unit}`, work.estimate_delivery_days ?? 0)}
                </NoticeLabel>
              )}
            </HStack>
            <Link to={`/works/${work.gid}${work.status === 'draft' ? '/edit' : ''}`}>
              <Text color="guide" lineClamp={2}>
                {work.body}
              </Text>
              <Text asChild className="more" color="guide" align="end">
                <div>{i18n.t('action.view_all')}</div>
              </Text>
              {work.status === 'draft' && <DraftLabel />}
            </Link>
            <TagCollection tags={work.tags} />
          </CardContent>
        </VStack>
        <AccountCardFooter account={work.account} />
      </section>
    </Card>
  );
};

const DraftLabel: FC = () => {
  const { i18n } = useI18n();
  return (
    <div className="draft-label">
      <HStack spacing="sm" m="none">
        <Icon name="lock" color="action" fontSize="small" />
        <Text size="small" weight="bold">
          {i18n.t('status.work.draft')}
        </Text>
      </HStack>
    </div>
  );
};
