import { QuotationSummaryCard } from 'src/components/features/quotation/QuotationSummaryCard';
import { Meta, Story } from 'src/catalog/Story';
import { factoryQuotation } from 'src/catalog/factories/quotation';

const meta: Meta<typeof QuotationSummaryCard> = {
  title: 'Quotation Summary Card',
  args: {
    quotation: factoryQuotation(),
  },
  template: (args) => <QuotationSummaryCard {...args} />,
};

export default meta;

export const asAttachment: Story<typeof QuotationSummaryCard> = {
  name: 'Quotation Summary Card in Ticket Comment Attachment Preview',
  args: {
    quotation: factoryQuotation(),
    isAttached: true,
  },
};
